import React, { useState } from 'react';
// api
// import axios from "axios";
import baseUrl from '../../../api';
import axiosInstance from '../../../api/axiosInstance';

// import RctSectionLoader from '../../../component/RctSectionLoader/RctSectionLoader';
import { Modal, Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

//multiselect
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const SearchModal = (props) => {
    // const [searchText, setSearchText] = useState('');
    // const [addTestSelect, setAddTestSelect] = React.useState(false);
    const [tableData, setTableData] = useState([]);
    const [itemDetails, setItemDetails] = useState({
        searchType: 'PatientName',
        searchText: ''
    });

    const onChangeItemDetails = (key, value) => {
        var arr = { ...itemDetails };
        arr[key] = value;
        setItemDetails((prevState) => arr);
    };
    const zeroPad = (num, places) => String(num).padStart(places, '0');

    const onSearch = async (e) => {
        e.preventDefault();
        var primaryData = await axiosInstance.get(`searchByType/${itemDetails.searchType}/${itemDetails.searchText}`);
        // console.log('primary data is', primaryData.data);
        primaryData.data.labNo = 'Lab-' + zeroPad(primaryData.data.labNo, 4);
        primaryData.data.map(function (item, i) {
            item.labNo = 'Lab-' + zeroPad(item.labNo, 4);
            return item;
        });
        setTableData(primaryData.data);
    };

    const onAddUpdateItemModalClose = () => {
        props.setSearchModal(false);
        props.setEditItem(null);
    };

    return (
        <>
            <Modal size="xl" isOpen={props.searchModal} toggle={() => onAddUpdateItemModalClose()}>
                <ModalHeader className="custommodalheader" toggle={() => onAddUpdateItemModalClose()}>
                    <FormControl sx={{ minWidth: '50%' }} size="small">
                        <InputLabel id="patientTitle">Search</InputLabel>
                        <Select
                            id="searchType"
                            sx={{ m: 1, width: '95%' }}
                            value={itemDetails.patientTitle}
                            label="Search"
                            onChange={(e) => onChangeItemDetails('searchType', e.target.value)}
                        >
                            <MenuItem value={'name'}>PatientName</MenuItem>
                            <MenuItem value={'MobileNo'}>MobileNo</MenuItem>
                            <MenuItem value={'constName'}>ConsltantName</MenuItem>
                            <MenuItem value={'LedgerNo'}>LedgerNo</MenuItem>
                            <MenuItem value={'LabNo'}>LabNo</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{ minWidth: '50%' }} size="small">
                        <TextField
                            sx={{ m: 1, width: '95%' }}
                            type="text"
                            label="Search"
                            value={itemDetails.searchText}
                            onChange={(e) => onChangeItemDetails('searchText', e.target.value)}
                            size="small"
                        />
                    </FormControl>

                    <Button variant="contained" className="text-white btn-success w-100" onClick={(e) => onSearch(e)}>
                        Search
                    </Button>
                </ModalHeader>
                <ModalBody>
                    {/* {loading &&
                        <RctSectionLoader />
                    } */}
                    <div className="table-responsive">
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>LedgerNo</TableCell>
                                    <TableCell>LabNo</TableCell>
                                    <TableCell>PatientName</TableCell>
                                    <TableCell>Year</TableCell>
                                    <TableCell>MobileNo</TableCell>
                                    <TableCell>ConsultantName</TableCell>
                                    <TableCell>Date</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableData.map((rowData, index) => (
                                    <TableRow key={index} onClick={() => props.onRecordClick(rowData)}>
                                        <TableCell>{rowData.ledgerNo}</TableCell>
                                        <TableCell>{rowData.labNo}</TableCell>
                                        <TableCell>{rowData.name}</TableCell>
                                        <TableCell>{rowData.yy}</TableCell>
                                        <TableCell>{rowData.mobile}</TableCell>
                                        <TableCell>{rowData.constName}</TableCell>
                                        <TableCell>{rowData.date}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button variant="contained" className="text-white btn-success">
                        Ok
                    </Button>
                    <Button variant="contained" className="text-white btn-danger" onClick={() => onAddUpdateItemModalClose()}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};
export default SearchModal;
