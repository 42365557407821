// assets
import ReportIcon from '@mui/icons-material/Report';

// constant
const icons = {
    ReportIcon
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const report = {
    id: 'report',
    title: 'Report',
    caption: '',
    type: 'group',
    children: [
        {
            id: 'report',
            title: 'Report',
            type: 'collapse',
            icon: icons.ReportIcon,
            children: [
                {
                    id: 'viewresult',
                    title: 'View Result',
                    type: 'item',
                    url: '/app/view-result',
                    breadcrumbs: false
                },
                {
                    id: 'generatereport',
                    title: 'Generate Report',
                    type: 'item',
                    url: '/app/generate-report',
                    breadcrumbs: false
                },
                {
                    id: 'consultantreport',
                    title: 'Consultant Report',
                    type: 'item',
                    url: '/app/consultant-report',
                    breadcrumbs: false
                },
                {
                    id: 'dailyreport',
                    title: 'Daily Report',
                    type: 'item',
                    url: '/app/daily-report',
                    breadcrumbs: false
                },
                {
                    id: 'monthreport',
                    title: 'Month Report',
                    type: 'item',
                    url: '/app/month-report',
                    breadcrumbs: false
                },
                {
                    id: 'agentreport',
                    title: 'Agent Report',
                    type: 'item',
                    url: '/app/agent-report',
                    breadcrumbs: false
                },
                {
                    id: 'expenditurereport',
                    title: 'Expenditure Report',
                    type: 'item',
                    url: '/app/expenditure-report',
                    breadcrumbs: false
                },
                {
                    id: 'userreport',
                    title: 'User Report',
                    type: 'item',
                    url: '/app/user-report',
                    breadcrumbs: false
                },
                {
                    id: 'duesreport',
                    title: 'Dues Report',
                    type: 'item',
                    url: '/app/dues-report',
                    breadcrumbs: false
                },
                {
                    id: 'testcounterreport',
                    title: 'Test Counter Report',
                    type: 'item',
                    url: '/app/test-counter-report',
                    breadcrumbs: false
                },
                {
                    id: 'cancelationreport',
                    title: 'Cancelation Report',
                    type: 'item',
                    url: '/app/cancelation-report',
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default report;
