// assets
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';

// constant
const icons = { PermContactCalendarIcon };

// ==============================|| TABLE MENU ITEMS ||============================== //

const contactUs = {
    id: 'contactUs',
    title: 'Contact Us',
    type: 'group',
    children: [
        {
            id: 'contactUs',
            title: 'Contact Us',
            type: 'item',
            url: 'app/contact-us',
            icon: icons.PermContactCalendarIcon,
            breadcrumbs: false
        }
    ]
};

export default contactUs;