import React, { useState, useEffect } from 'react';
import { Form, FormGroup } from 'reactstrap';
import Button from '@mui/material/Button';
// api
import axios from 'axios';
import baseUrl from '../../../api';

// import RctSectionLoader from '../../../component/RctSectionLoader/RctSectionLoader';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

//multiselect
import TextField from '@mui/material/TextField';

const AddAgent = (props) => {
    const [itemDetails, setItemDetails] = useState({
        name: '',
        address: '',
        pathologyCommission: '',
        xrayCommission: '',
        ultraSoundCommission: '',
        ctScanCommission: '',
        mriCommission: '',
        mobileNo: ''
    });

    const onChangeItemDetails = (key, value) => {
        var arr = { ...itemDetails };
        arr[key] = value;
        setItemDetails((prevState) => arr);
    };

    const handleOnAddItem = (e) => {
        e.preventDefault();
        // axios.get("ec2-15-206-84-254.ap-south-1.compute.amazonaws.com:3000/api/master/site").then((response) => console.log("response",response));

        // axios
        // .post(`${baseUrl}/api/master/addAgentMaster`, dataArray
        // )
        // .then((response) => {
        //     // successfully uploaded response
        // })
        // .catch((error) => {
        //     // error response
        // });
    };

    const onAddUpdateItemModalClose = () => {
        props.setAddNewItemModal(false);
        props.setEditItem(null);
    };

    return (
        <>
            <Modal className="modal-lg" isOpen={props.addNewItemModal} toggle={() => onAddUpdateItemModalClose()}>
                <ModalHeader toggle={() => onAddUpdateItemModalClose()}>Add New Item</ModalHeader>
                <ModalBody>
                    {/* {loading &&
                        <RctSectionLoader />
                    } */}
                    <Form className="row">
                        <FormGroup className="col-lg-4">
                            <TextField
                                fullWidth
                                type="text"
                                label="Name"
                                defaultValue={itemDetails.name}
                                onChange={(e) => onChangeItemDetails('name', e.target.value)}
                                id="name"
                                size="small"
                            />
                        </FormGroup>
                        <FormGroup className="col-lg-4">
                            <TextField
                                fullWidth
                                type="text"
                                label="Pathology Commission"
                                defaultValue={itemDetails.pathologyCommission}
                                onChange={(e) => onChangeItemDetails('pathologyCommission', e.target.value)}
                                id="pathologyCommission"
                                size="small"
                            />
                        </FormGroup>
                        <FormGroup className="col-lg-4">
                            <TextField
                                fullWidth
                                type="text"
                                label="Xray Commission"
                                defaultValue={itemDetails.xrayCommission}
                                onChange={(e) => onChangeItemDetails('xrayCommission', e.target.value)}
                                id="xrayCommission"
                                size="small"
                            />
                        </FormGroup>
                        <FormGroup className="col-lg-6">
                            <TextField
                                fullWidth
                                type="text"
                                label="Ultra Sound Commission"
                                defaultValue={itemDetails.ultraSoundCommission}
                                onChange={(e) => onChangeItemDetails('ultraSoundCommission', e.target.value)}
                                id="ultraSoundCommission"
                                size="small"
                            />
                        </FormGroup>
                        <FormGroup className="col-lg-6">
                            {' '}
                            <TextField
                                fullWidth
                                type="text"
                                label="Ct Scan Commission"
                                defaultValue={itemDetails.ctScanCommission}
                                onChange={(e) => onChangeItemDetails('ctScanCommission', e.target.value)}
                                id="ctScanCommission"
                                size="small"
                            />
                        </FormGroup>
                        <FormGroup className="col-lg-6">
                            {' '}
                            <TextField
                                fullWidth
                                type="text"
                                label="Mri Commission"
                                defaultValue={itemDetails.mriCommission}
                                onChange={(e) => onChangeItemDetails('mriCommission', e.target.value)}
                                id="mriCommission"
                                size="small"
                            />
                        </FormGroup>
                        <FormGroup className="col-lg-6">
                            {' '}
                            <TextField
                                fullWidth
                                type="text"
                                label="Mobile No"
                                defaultValue={itemDetails.mobileNo}
                                onChange={(e) => onChangeItemDetails('mobileNo', e.target.value)}
                                id="mobileNo"
                                size="small"
                            />
                        </FormGroup>
                        <FormGroup className="col-lg-12">
                            <TextField
                                fullWidth
                                multiline
                                rows={2}
                                type="text"
                                label="Address"
                                defaultValue={itemDetails.address}
                                onChange={(e) => onChangeItemDetails('address', e.target.value)}
                                id="address"
                                size="small"
                            />
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button variant="contained" className="text-white btn-success" onClick={handleOnAddItem}>
                        Save
                    </Button>
                    <Button variant="contained" className="text-white btn-danger" onClick={() => onAddUpdateItemModalClose()}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};
export default AddAgent;
