import React, { useState, useEffect } from 'react';

//multiselect
import Radio from '@mui/material/Radio';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
// import Button from '@mui/material/Button';
// api
// import axios from "axios";
import baseUrl from '../../../api';

// import RctSectionLoader from '../../../component/RctSectionLoader/RctSectionLoader';
import { Modal, ModalHeader, ModalBody, Form, Row, FormGroup, ModalFooter, Button, Col } from 'reactstrap';

const WidalTest = (props) => {
    const [positivity, setPositivity] = useState('');
    const [itemDetails, setItemDetails] = useState({
        investReq: '',
        typhiO20: '',
        typhiO40: '',
        typhiO80: '',
        typhiO160: '',
        typhiO320: '',
        typhiH20: '',
        typhiH40: '',
        typhiH80: '',
        typhiH160: '',
        typhiH320: '',
        paratyphiAH20: '',
        paratyphiAH40: '',
        paratyphiAH80: '',
        paratyphiAH160: '',
        paratyphiAH320: '',
        paratyphiBH20: '',
        paratyphiBH40: '',
        paratyphiBH80: '',
        paratyphiBH160: '',
        paratyphiBH320: ''
    });

    const onChangeItemDetails = (key, value) => {
        var arr = { ...itemDetails };
        arr[key] = value;
        setItemDetails((prevState) => arr);
    };
    const handleRadioChange = (event) => {
        console.log('event.target.value on 49', event.target.value);

        setPositivity(event.target.value);
    };
    useEffect(() => {
        if (positivity == 'positive') {
            console.log('coming in useEffecgt');
            setItemDetails({
                typhiO20: '+',
                typhiO40: '+',
                typhiO80: '+',
                typhiO160: '+',
                typhiO320: '+',
                typhiH20: '+',
                typhiH40: '+',
                typhiH80: '+',
                typhiH160: '+',
                typhiH320: '+',
                paratyphiAH20: '+',
                paratyphiAH40: '+',
                paratyphiAH80: '+',
                paratyphiAH160: '+',
                paratyphiAH320: '+',
                paratyphiBH20: '+',
                paratyphiBH40: '+',
                paratyphiBH80: '+',
                paratyphiBH160: '+',
                paratyphiBH320: '+'
            });
        }
        if (positivity == 'negative') {
            console.log('coming in useEffecgt');
            setItemDetails({
                typhiO20: '-',
                typhiO40: '-',
                typhiO80: '-',
                typhiO160: '-',
                typhiO320: '-',
                typhiH20: '-',
                typhiH40: '-',
                typhiH80: '-',
                typhiH160: '-',
                typhiH320: '-',
                paratyphiAH20: '-',
                paratyphiAH40: '-',
                paratyphiAH80: '-',
                paratyphiAH160: '-',
                paratyphiAH320: '-',
                paratyphiBH20: '-',
                paratyphiBH40: '-',
                paratyphiBH80: '-',
                paratyphiBH160: '-',
                paratyphiBH320: '-'
            });
        }
    }, [positivity]);

    const handleOnAddItem = (e) => {
        e.preventDefault();
        // axios.get("ec2-15-206-84-254.ap-south-1.compute.amazonaws.com:3000/api/master/site").then((response) => console.log("response",response));

        props.widalOk(itemDetails);
        // axios
        // .post(`${baseUrl}/api/master/addConsultantDoctorMaster`,
        // )
        // .then((response) => {
        //     // successfully uploaded response
        // })
        // .catch((error) => {
        //     // error response
        // });
        // alert(`${baseUrl}/api/master/ConsultantDoctorMaster`);
    };

    const onAddUpdateItemModalClose = () => {
        props.setAddNewItemModal(false);
        // props.setEditItem(null);
    };

    return (
        <>
            <Modal size="lg" isOpen={props.addNewItemModal} toggle={() => onAddUpdateItemModalClose()}>
                <ModalHeader toggle={() => onAddUpdateItemModalClose()}>Widal Test</ModalHeader>
                <ModalBody>
                    {/* {loading &&
                        <RctSectionLoader />
                    } */}
                    <Form className="row">
                        <Col lg="12">
                            <Row>
                                <FormGroup className="col-lg-4">
                                    <label className="mt-2">Investigation Requested</label>
                                </FormGroup>
                                <FormGroup className="col-lg-8">
                                    <FormControl>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            value={itemDetails.investReq}
                                            onChange={(e) => onChangeItemDetails('investReq', e.target.value)}
                                        >
                                            <FormControlLabel
                                                value="positive"
                                                onChange={handleRadioChange}
                                                checked={positivity == 'positive'}
                                                control={<Radio />}
                                                label="Positive"
                                            />
                                            <FormControlLabel
                                                value="negative"
                                                checked={positivity == 'negative'}
                                                onChange={handleRadioChange}
                                                control={<Radio />}
                                                label="Negative"
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </FormGroup>
                            </Row>
                            <Row>
                                <FormGroup className="col-lg-2">
                                    <label className="mt-2">S.TYPHI'O'</label>
                                </FormGroup>
                                <FormGroup className="col-lg-2">
                                    <TextField
                                        fullWidth
                                        type="text"
                                        label="1/20"
                                        value={itemDetails.typhiO20}
                                        onChange={(e) => onChangeItemDetails('typhiO20', e.target.value)}
                                        size="small"
                                    />
                                </FormGroup>
                                <FormGroup className="col-lg-2">
                                    <TextField
                                        fullWidth
                                        type="text"
                                        label="1/40"
                                        value={itemDetails.typhiO40}
                                        onChange={(e) => onChangeItemDetails('typhiO40', e.target.value)}
                                        size="small"
                                    />
                                </FormGroup>
                                <FormGroup className="col-lg-2">
                                    <TextField
                                        fullWidth
                                        type="text"
                                        label="1/80"
                                        value={itemDetails.typhiO80}
                                        onChange={(e) => onChangeItemDetails('typhiO80', e.target.value)}
                                        size="small"
                                    />
                                </FormGroup>
                                <FormGroup className="col-lg-2">
                                    <TextField
                                        fullWidth
                                        type="text"
                                        label="1/160"
                                        value={itemDetails.typhiO160}
                                        onChange={(e) => onChangeItemDetails('typhiO160', e.target.value)}
                                        size="small"
                                    />
                                </FormGroup>
                                <FormGroup className="col-lg-2">
                                    <TextField
                                        fullWidth
                                        type="text"
                                        label="1/320"
                                        value={itemDetails.typhiO320}
                                        onChange={(e) => onChangeItemDetails('typhiO320', e.target.value)}
                                        size="small"
                                    />
                                </FormGroup>
                            </Row>
                            <Row>
                                <FormGroup className="col-lg-2">
                                    <label className="mt-2">S.TYPHI'H'</label>
                                </FormGroup>
                                <FormGroup className="col-lg-2">
                                    <TextField
                                        fullWidth
                                        type="text"
                                        label="1/20"
                                        value={itemDetails.typhiH20}
                                        onChange={(e) => onChangeItemDetails('typhiH20', e.target.value)}
                                        size="small"
                                    />
                                </FormGroup>
                                <FormGroup className="col-lg-2">
                                    <TextField
                                        fullWidth
                                        type="text"
                                        label="1/40"
                                        value={itemDetails.typhiH40}
                                        onChange={(e) => onChangeItemDetails('typhiH40', e.target.value)}
                                        size="small"
                                    />
                                </FormGroup>
                                <FormGroup className="col-lg-2">
                                    <TextField
                                        fullWidth
                                        type="text"
                                        label="1/80"
                                        value={itemDetails.typhiH80}
                                        onChange={(e) => onChangeItemDetails('typhiH80', e.target.value)}
                                        size="small"
                                    />
                                </FormGroup>
                                <FormGroup className="col-lg-2">
                                    <TextField
                                        fullWidth
                                        type="text"
                                        label="1/160"
                                        value={itemDetails.typhiH160}
                                        onChange={(e) => onChangeItemDetails('typhiH160', e.target.value)}
                                        size="small"
                                    />
                                </FormGroup>
                                <FormGroup className="col-lg-2">
                                    <TextField
                                        fullWidth
                                        type="text"
                                        label="1/320"
                                        value={itemDetails.typhiH320}
                                        onChange={(e) => onChangeItemDetails('typhiH320', e.target.value)}
                                        size="small"
                                    />
                                </FormGroup>
                            </Row>
                            <Row>
                                <FormGroup className="col-lg-2">
                                    <label className="mt-2">S.PARATYPHI'AH'</label>
                                </FormGroup>
                                <FormGroup className="col-lg-2">
                                    <TextField
                                        fullWidth
                                        type="text"
                                        label="1/20"
                                        value={itemDetails.paratyphiAH20}
                                        onChange={(e) => onChangeItemDetails('paratyphiAH20', e.target.value)}
                                        size="small"
                                    />
                                </FormGroup>
                                <FormGroup className="col-lg-2">
                                    <TextField
                                        fullWidth
                                        type="text"
                                        label="1/40"
                                        value={itemDetails.paratyphiAH40}
                                        onChange={(e) => onChangeItemDetails('paratyphiAH40', e.target.value)}
                                        size="small"
                                    />
                                </FormGroup>
                                <FormGroup className="col-lg-2">
                                    <TextField
                                        fullWidth
                                        type="text"
                                        label="1/80"
                                        value={itemDetails.paratyphiAH80}
                                        onChange={(e) => onChangeItemDetails('paratyphiAH80', e.target.value)}
                                        size="small"
                                    />
                                </FormGroup>
                                <FormGroup className="col-lg-2">
                                    <TextField
                                        fullWidth
                                        type="text"
                                        label="1/160"
                                        value={itemDetails.paratyphiAH160}
                                        onChange={(e) => onChangeItemDetails('paratyphiAH160', e.target.value)}
                                        size="small"
                                    />
                                </FormGroup>
                                <FormGroup className="col-lg-2">
                                    <TextField
                                        fullWidth
                                        type="text"
                                        label="1/320"
                                        value={itemDetails.paratyphiAH320}
                                        onChange={(e) => onChangeItemDetails('paratyphiAH320', e.target.value)}
                                        size="small"
                                    />
                                </FormGroup>
                            </Row>
                            <Row>
                                <FormGroup className="col-lg-2">
                                    <label className="mt-2">S.PARATYPHI'BH'</label>
                                </FormGroup>
                                <FormGroup className="col-lg-2">
                                    <TextField
                                        fullWidth
                                        type="text"
                                        label="1/20"
                                        value={itemDetails.paratyphiBH20}
                                        onChange={(e) => onChangeItemDetails('paratyphiBH20', e.target.value)}
                                        size="small"
                                    />
                                </FormGroup>
                                <FormGroup className="col-lg-2">
                                    <TextField
                                        fullWidth
                                        type="text"
                                        label="1/40"
                                        value={itemDetails.paratyphiBH40}
                                        onChange={(e) => onChangeItemDetails('paratyphiBH40', e.target.value)}
                                        size="small"
                                    />
                                </FormGroup>
                                <FormGroup className="col-lg-2">
                                    <TextField
                                        fullWidth
                                        type="text"
                                        label="1/80"
                                        value={itemDetails.paratyphiBH80}
                                        onChange={(e) => onChangeItemDetails('paratyphiBH80', e.target.value)}
                                        size="small"
                                    />
                                </FormGroup>
                                <FormGroup className="col-lg-2">
                                    <TextField
                                        fullWidth
                                        type="text"
                                        label="1/160"
                                        value={itemDetails.paratyphiBH160}
                                        onChange={(e) => onChangeItemDetails('paratyphiBH160', e.target.value)}
                                        size="small"
                                    />
                                </FormGroup>
                                <FormGroup className="col-lg-2">
                                    <TextField
                                        fullWidth
                                        type="text"
                                        label="1/320"
                                        value={itemDetails.paratyphiBH320}
                                        onChange={(e) => onChangeItemDetails('paratyphiBH320', e.target.value)}
                                        size="small"
                                    />
                                </FormGroup>
                            </Row>
                        </Col>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button variant="contained" className="text-white btn-success" onClick={handleOnAddItem}>
                        Ok
                    </Button>
                    <Button variant="contained" className="text-white btn-danger" onClick={() => onAddUpdateItemModalClose()}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};
export default WidalTest;
