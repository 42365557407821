import React, { useState, useEffect } from 'react';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import Button from '@mui/material/Button';
// api
import axios from 'axios';
import baseUrl from '../../../api';

// import RctSectionLoader from '../../../component/RctSectionLoader/RctSectionLoader';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

// Date
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

//multiselect
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';

const AddPerformTest = (props) => {
    const [dateTime, setDateTime] = React.useState(null);
    const [age, setAge] = React.useState(null);

    const [itemDetails, setItemDetails] = useState({
        ladgerNo: '',
        labNo: '',
        onBlankPaper: '',
        onPrintedPaper: '',
        name: '',
        gender: '',
        performT: '',
        performSt: ''
    });

    const onChangeItemDetails = (key, value) => {
        var arr = { ...itemDetails };
        arr[key] = value;
        setItemDetails((prevState) => arr);
    };

    const handleOnAddItem = (e) => {
        e.preventDefault();
        // axios.get("ec2-15-206-84-254.ap-south-1.compute.amazonaws.com:3000/api/master/site").then((response) => console.log("response",response));

        // axios
        // .post(`${baseUrl}/api/master/addConsultantDoctorMaster`,
        // )
        // .then((response) => {
        //     // successfully uploaded response
        // })
        // .catch((error) => {
        //     // error response
        // });
    };

    const onAddUpdateItemModalClose = () => {
        props.setAddNewItemModal(false);
        props.setEditItem(null);
    };

    return (
        <>
            <Modal className="modal-lg" isOpen={props.addNewItemModal} toggle={() => onAddUpdateItemModalClose()}>
                <ModalHeader toggle={() => onAddUpdateItemModalClose()}>Add New Item</ModalHeader>
                <ModalBody>
                    {/* {loading &&
                        <RctSectionLoader />
                    } */}
                    <Form className="row">
                        <FormGroup className="col-lg-4">
                            <LocalizationProvider className="managedate" dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                    label="Date & Time"
                                    value={dateTime}
                                    onChange={(newDateTime) => {
                                        setDateTime(newDateTime);
                                    }}
                                    renderInput={(params) => <TextField {...params} id="dateTime" size="small" />}
                                />
                            </LocalizationProvider>
                        </FormGroup>
                        <FormGroup className="col-lg-4">
                            <TextField
                                fullWidth
                                type="text"
                                label="Ladger No"
                                value={itemDetails.ladgerNo}
                                onChange={(e) => onChangeItemDetails('ladgerNo', e.target.value)}
                                id="ladgerNo"
                                size="small"
                            />
                        </FormGroup>
                        <FormGroup className="col-lg-4">
                            <TextField
                                fullWidth
                                type="text"
                                label="Lab No"
                                value={itemDetails.labNo}
                                onChange={(e) => onChangeItemDetails('labNo', e.target.value)}
                                id="labNo"
                                size="small"
                            />
                        </FormGroup>
                        <FormGroup className="col-lg-12 mt-2">
                            <div className="mngdevider">
                                <h3 className="mngtitle">Printing Option</h3>
                                <div className="row">
                                    <FormGroup className="col-lg-6">
                                        <Label check className="ml-20">
                                            <Input
                                                type="radio"
                                                name="paper"
                                                value={itemDetails.onBlankPaper}
                                                onChange={(e) => onChangeItemDetails('onBlankPaper', e.target.value)}
                                                id="onBlankPaper"
                                            />{' '}
                                            On Blank Paper
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="col-lg-6">
                                        <Label check className="ml-20">
                                            <Input
                                                type="radio"
                                                name="paper"
                                                value={itemDetails.paper}
                                                onChange={(e) => onChangeItemDetails('paper', e.target.value)}
                                                id="onPrintedPaper"
                                            />{' '}
                                            On Printed Paper
                                        </Label>
                                    </FormGroup>
                                </div>
                            </div>
                        </FormGroup>
                        <div className="col-12">
                            <div className="mngdevider">
                                <h3 className="mngtitle">Patient Details</h3>
                                <div className="row">
                                    <FormGroup className="col-lg-4">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Name"
                                            value={itemDetails.name}
                                            onChange={(e) => onChangeItemDetails('name', e.target.value)}
                                            id="name"
                                            size="small"
                                        />
                                    </FormGroup>
                                    <FormGroup className="col-lg-4">
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                label="Age"
                                                // value={itemDetails.marrigeDay}
                                                // onChange={(e) =>
                                                //     onChangeItemDetails("marrigeDay", e.target.value)
                                                // }
                                                value={age}
                                                onChange={(newAge) => {
                                                    setAge(newAge);
                                                }}
                                                renderInput={(params) => <TextField fullWidth {...params} size="small" id="age" />}
                                            />
                                        </LocalizationProvider>
                                    </FormGroup>
                                    <FormGroup className="col-lg-4">
                                        <FormControl sx={{ minWidth: '100%' }} size="small">
                                            <InputLabel id="gender">Gender</InputLabel>
                                            <Select
                                                id="gender"
                                                value={itemDetails.gender}
                                                label="Gender"
                                                onChange={(e) => onChangeItemDetails('gender', e.target.value)}
                                            >
                                                <MenuItem value={'Male'}>Male</MenuItem>
                                                <MenuItem value={'Female'}>Female</MenuItem>
                                                <MenuItem value={'Transgender'}>Transgender</MenuItem>
                                                <MenuItem value={'Other'}>Other</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                        <FormGroup className="col-lg-2">
                            <TextField
                                fullWidth
                                type="text"
                                label="T"
                                value={itemDetails.performT}
                                onChange={(e) => onChangeItemDetails('performT', e.target.value)}
                                id="performT"
                                size="small"
                            />
                        </FormGroup>
                        <FormGroup className="col-lg-2">
                            <TextField
                                fullWidth
                                type="text"
                                label="ST"
                                value={itemDetails.performSt}
                                onChange={(e) => onChangeItemDetails('performSt', e.target.value)}
                                id="performSt"
                                size="small"
                            />
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button variant="contained" className="text-white btn-success" onClick={handleOnAddItem}>
                        Save
                    </Button>
                    <Button variant="contained" className="text-white btn-danger" onClick={() => onAddUpdateItemModalClose()}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};
export default AddPerformTest;
