import React, { useState, useEffect, useRef } from 'react';
import { Form, FormGroup } from 'reactstrap';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import axiosInstance from '../../../api/axiosInstance';
import { css } from '@emotion/react';
import { BeatLoader } from 'react-spinners';

import { useFetchCompanyDetails } from '../../../commonFunctions/commonFunction';

// rct card box
import RctCollapsibleCard from '../../../component/RctCollapsibleCard/RctCollapsibleCard';

export default function Company(props) {
    const [logo, setLogo] = useState();
    const [signature1, setSignature1] = useState();
    const [signature2, setSignature2] = useState();
    const [signature3, setSignature3] = useState();
    const [itemDetails, setItemDetails] = useState({
        tstGrpName: '',
        companyName: '',
        address: '',
        phone: '',
        email: '',
        heading1: '',
        name1: '',
        details1: '',
        status1: '',
        position1: '',
        signature1: '',
        heading2: '',
        name2: '',
        details2: '',
        status2: '',
        position2: '',
        signature2: '',
        heading3: '',
        name3: '',
        details3: '',
        status3: '',
        position3: '',
        signature3: '',
        rheading1: '',
        rname1: '',
        rdetails1: '',
        rstatus1: '',
        rposition1: '',
        rsignature1: '',
        namerheading23: '',
        rname2: '',
        rdetails2: '',
        rstatus2: '',
        rposition2: '',
        rname3: '',
        rdetails3: '',
        rstatus3: '',
        rposition3: '',
        rsignature2: '',
        rsignature3: ''
    });
    const [posting, setPosting] = useState(false);
    const { data, isLoading } = useFetchCompanyDetails();
    useEffect(() => {
        if (data) {
            setItemDetails(data.data[0]);
        }
    }, [data]);

    // starts ----------------------------------------
    useEffect(() => {
        const postData = async () => {
            try {
                // Make a POST request to the backend
                saveCompanyDetails();
                // Data sent successfully
            } catch (error) {
                // Error occurred while sending data
                console.error('Error occurred while sending data:', error.message);
            }

            setPosting(false);
        };

        if (posting) {
            postData();
        }
    }, [posting]);

    //ens**********************************************

    if (isLoading) {
        return <div>Loading...</div>;
    }
    const spinnerStyles = css`
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
    `;
    // setItemDetails(data.data);
    // else {
    //     setItemDetails(data.data);
    // }
    // console.log(`company is ${JSON.stringify(data.data)}`);
    // const onChangeItemDetails = (key, value) => {
    //     var arr = { ...itemDetails };
    //     arr[key] = value;
    //     setItemDetails((prevState) => arr);
    // };
    const onChangeItemDetails = (key, value) => {
        setItemDetails((prevState) => ({
            ...prevState,
            [key]: value
        }));
    };

    const saveCompanyLoading = () => {
        setPosting(true);
    };

    const saveCompanyDetails = async () => {
        // e.preventDefault();
        try {
            const response = await axiosInstance.patch('/updateCompanyMaster', itemDetails);
            // console.log('company master is updating ', itemDetails);
            // setItemDetails({ ...itemDetails, ledgerNo: response.data.ledgerNo, labNo: 'Lab-' + zeroPad(response.data.ledgerNo, 4) });
        } catch (error) {
            // console.error('Error occurred while updating company master:', error.message);
        }

        // axiosInstance.patch(`/updateCompanyMaster`, itemDetails).then((response) => {
        //     // setItemDetails({ ...itemDetails, ledgerNo: response.data.ledgerNo, labNo: 'Lab-' + zeroPad(response.data.ledgerNo, 4) });
        // });
    };
    const updateLogo = (e) => {
        e.preventDefault();
        const dataArray = new FormData();
        dataArray.append('logo', logo);
        axiosInstance.patch(`/uploadLogo`, dataArray).then((response) => {});
    };
    const updateSignature1 = (e) => {
        e.preventDefault();
        const dataArray = new FormData();
        dataArray.append('signature1', signature1);
        axiosInstance.patch(`/signature1`, dataArray).then((response) => {});
    };
    const updateSignature2 = (e) => {
        e.preventDefault();
        const dataArray = new FormData();
        dataArray.append('signature2', signature2);
        axiosInstance.patch(`/signature2`, dataArray).then((response) => {});
    };
    const updateSignature3 = (e) => {
        e.preventDefault();
        const dataArray = new FormData();
        dataArray.append('signature3', signature3);
        axiosInstance.patch(`/signature3`, dataArray).then((response) => {
            alert('saved Successfully');
        });
    };
    const handleOnAddItem = (e) => {
        e.preventDefault();
        // props.handleOnAddItem(itemDetails);
        // alert('ram jane');
        axiosInstance.patch(`/updateCompanyMaster`, itemDetails).then((response) => {
            // setItemDetails({ ...itemDetails, ledgerNo: response.data.ledgerNo, labNo: 'Lab-' + zeroPad(response.data.ledgerNo, 4) });
        });
    };

    return (
        <>
            <RctCollapsibleCard fullBlock>
                <div className="py-15 px-15">
                    <div className="row border-bottom mb-20">
                        <div className="col-lg-6">
                            <h1>Company</h1>
                        </div>
                    </div>
                    <div className="mngdevider">
                        <div className="row">
                            <div className="col-lg-4">
                                <FormGroup>
                                    <TextField
                                        fullWidth
                                        type="text"
                                        label="Company Name"
                                        value={itemDetails?.companyName}
                                        // value={itemDetails.?companyName}
                                        // onChange={(e) => onChangeItemDetails('companyName', e.target.value)}
                                        id="companyName"
                                        size="small"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <TextField
                                        fullWidth
                                        type="text"
                                        label="Mobile No"
                                        value={itemDetails?.phone}
                                        onChange={(e) => onChangeItemDetails('phone', e.target.value)}
                                        id="mobileNo"
                                        size="small"
                                    />
                                </FormGroup>
                            </div>
                            <div className="col-lg-4">
                                <FormGroup>
                                    <TextField
                                        fullWidth
                                        type="text"
                                        label="Email Id"
                                        value={itemDetails?.email}
                                        onChange={(e) => onChangeItemDetails('email', e.target.value)}
                                        id="adress"
                                        size="small"
                                    />
                                </FormGroup>
                            </div>
                            <div className="col-lg-4">
                                <FormGroup>
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={2.5}
                                        type="text"
                                        label="Adress"
                                        value={itemDetails?.address}
                                        onChange={(e) => onChangeItemDetails('address', e.target.value)}
                                        id="adress"
                                        size="small"
                                    />
                                </FormGroup>
                            </div>
                        </div>
                        <div className="text-center mb-2">
                            {posting ? (
                                <BeatLoader color="#000" css={spinnerStyles} />
                            ) : (
                                <Button variant="contained" className="text-white mr-2 btn-success" onClick={saveCompanyLoading}>
                                    Save
                                </Button>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <FormGroup className="col-lg-3">
                            <label>Please Upload Logo</label>
                            <input fullWidth type="file" label="Logo" onChange={(e) => setLogo(e.target.files[0])} size="small" />
                        </FormGroup>
                        <FormGroup className="col-lg-3 text-center mt-2">
                            <Button variant="contained" className="text-white mr-2 btn-success" onClick={updateLogo}>
                                Save
                            </Button>
                        </FormGroup>
                    </div>

                    <Form className="row">
                        <div className="col-lg-6">
                            <div className="mngdevider">
                                {/* <h3 className="mngtitle">Patient Details</h3> */}
                                <div className="row">
                                    <FormGroup className="col-lg-4">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Heading1"
                                            value={itemDetails?.heading1}
                                            onChange={(e) => onChangeItemDetails('heading1', e.target.value)}
                                            size="small"
                                        />
                                    </FormGroup>
                                    <FormGroup className="col-lg-4">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Heading2"
                                            value={itemDetails?.heading2}
                                            onChange={(e) => onChangeItemDetails('heading2', e.target.value)}
                                            size="small"
                                        />
                                    </FormGroup>
                                    <FormGroup className="col-lg-4">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Heading3"
                                            value={itemDetails?.heading3}
                                            onChange={(e) => onChangeItemDetails('heading3', e.target.value)}
                                            size="small"
                                        />
                                    </FormGroup>
                                    <FormGroup className="col-lg-4">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Name1"
                                            value={itemDetails?.name1}
                                            onChange={(e) => onChangeItemDetails('name1', e.target.value)}
                                            size="small"
                                        />
                                    </FormGroup>
                                    <FormGroup className="col-lg-4">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Name2"
                                            value={itemDetails?.name2}
                                            onChange={(e) => onChangeItemDetails('name2', e.target.value)}
                                            size="small"
                                        />
                                    </FormGroup>
                                    <FormGroup className="col-lg-4">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Name3"
                                            value={itemDetails?.name3}
                                            onChange={(e) => onChangeItemDetails('name3', e.target.value)}
                                            size="small"
                                        />
                                    </FormGroup>
                                    <FormGroup className="col-lg-4">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Details1"
                                            value={itemDetails?.details1}
                                            onChange={(e) => onChangeItemDetails('details1', e.target.value)}
                                            size="small"
                                        />
                                    </FormGroup>
                                    <FormGroup className="col-lg-4">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Details2"
                                            value={itemDetails?.details2}
                                            onChange={(e) => onChangeItemDetails('details2', e.target.value)}
                                            size="small"
                                        />
                                    </FormGroup>
                                    <FormGroup className="col-lg-4">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Details3"
                                            value={itemDetails?.details3}
                                            onChange={(e) => onChangeItemDetails('details3', e.target.value)}
                                            size="small"
                                        />
                                    </FormGroup>
                                    <FormGroup className="col-lg-4">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Status1"
                                            value={itemDetails?.status1}
                                            onChange={(e) => onChangeItemDetails('status1', e.target.value)}
                                            size="small"
                                        />
                                    </FormGroup>
                                    <FormGroup className="col-lg-4">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Status2"
                                            value={itemDetails?.status2}
                                            onChange={(e) => onChangeItemDetails('status2', e.target.value)}
                                            size="small"
                                        />
                                    </FormGroup>
                                    <FormGroup className="col-lg-4">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Status3"
                                            value={itemDetails?.status3}
                                            onChange={(e) => onChangeItemDetails('status3', e.target.value)}
                                            size="small"
                                        />
                                    </FormGroup>
                                    <FormGroup className="col-lg-4">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Position1"
                                            value={itemDetails?.position1}
                                            onChange={(e) => onChangeItemDetails('position1', e.target.value)}
                                            size="small"
                                        />
                                    </FormGroup>
                                    <FormGroup className="col-lg-4">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Position2"
                                            value={itemDetails?.position2}
                                            onChange={(e) => onChangeItemDetails('position2', e.target.value)}
                                            size="small"
                                        />
                                    </FormGroup>
                                    <FormGroup className="col-lg-4">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Position3"
                                            value={itemDetails?.position3}
                                            onChange={(e) => onChangeItemDetails('position3', e.target.value)}
                                            size="small"
                                        />
                                    </FormGroup>
                                </div>

                                <div className="text-center mb-2">
                                    {posting ? (
                                        <BeatLoader color="#000" css={spinnerStyles} />
                                    ) : (
                                        <Button variant="contained" className="text-white mr-2 btn-success" onClick={saveCompanyLoading}>
                                            Save
                                        </Button>
                                    )}
                                </div>
                            </div>
                            <div className="row">
                                <FormGroup className="col-lg-4">
                                    <input
                                        fullWidth
                                        type="file"
                                        label="Signature1"
                                        // value={itemDetails.signature1}
                                        onChange={(e) => setSignature1(e.target.files[0])}
                                        size="small"
                                    />
                                    <Button variant="contained" className="text-white mt-3 btn-success" onClick={updateSignature1}>
                                        Save
                                    </Button>
                                </FormGroup>
                                <FormGroup className="col-lg-4">
                                    <input
                                        fullWidth
                                        type="file"
                                        label="Signature2"
                                        onChange={(e) => setSignature2(e.target.files[0])}
                                        size="small"
                                    />
                                    <Button variant="contained" className="text-white mt-3 btn-success" onClick={updateSignature2}>
                                        Save
                                    </Button>
                                </FormGroup>
                                <FormGroup className="col-lg-4">
                                    <input
                                        fullWidth
                                        type="file"
                                        label="Signature3"
                                        onChange={(e) => setSignature3(e.target.files[0])}
                                        size="small"
                                    />
                                    <Button variant="contained" className="text-white mt-3 btn-success" onClick={updateSignature3}>
                                        Save
                                    </Button>
                                </FormGroup>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="mngdevider">
                                {/* <h3 className="mngtitle">Patient Details</h3> */}
                                <div className="row">
                                    <FormGroup className="col-lg-4">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Rheading1"
                                            value={itemDetails?.rheading1}
                                            onChange={(e) => onChangeItemDetails('rheading1', e.target.value)}
                                            size="small"
                                        />
                                    </FormGroup>
                                    <FormGroup className="col-lg-4">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Rheading2"
                                            value={itemDetails?.rheading2}
                                            onChange={(e) => onChangeItemDetails('rheading1', e.target.value)}
                                            size="small"
                                        />
                                    </FormGroup>
                                    <FormGroup className="col-lg-4">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Rheading3"
                                            value={itemDetails?.rheading3}
                                            onChange={(e) => onChangeItemDetails('rheading3', e.target.value)}
                                            size="small"
                                        />
                                    </FormGroup>
                                    <FormGroup className="col-lg-4">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Rname1"
                                            value={itemDetails?.rname1}
                                            onChange={(e) => onChangeItemDetails('rname1', e.target.value)}
                                            size="small"
                                        />
                                    </FormGroup>
                                    <FormGroup className="col-lg-4">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Rname2"
                                            value={itemDetails?.rname2}
                                            onChange={(e) => onChangeItemDetails('rname2', e.target.value)}
                                            size="small"
                                        />
                                    </FormGroup>
                                    <FormGroup className="col-lg-4">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Rname3"
                                            value={itemDetails?.rname3}
                                            onChange={(e) => onChangeItemDetails('rname3', e.target.value)}
                                            size="small"
                                        />
                                    </FormGroup>
                                    <FormGroup className="col-lg-4">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Rdetails1"
                                            value={itemDetails?.rdetails1}
                                            onChange={(e) => onChangeItemDetails('rdetails1', e.target.value)}
                                            size="small"
                                        />
                                    </FormGroup>
                                    <FormGroup className="col-lg-4">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Rdetails2"
                                            value={itemDetails?.rdetails2}
                                            onChange={(e) => onChangeItemDetails('rdetails2', e.target.value)}
                                            size="small"
                                        />
                                    </FormGroup>
                                    <FormGroup className="col-lg-4">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Rdetails3"
                                            value={itemDetails?.rdetails3}
                                            onChange={(e) => onChangeItemDetails('rdetails3', e.target.value)}
                                            size="small"
                                        />
                                    </FormGroup>
                                    <FormGroup className="col-lg-4">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Rstatus1"
                                            value={itemDetails?.rstatus1}
                                            onChange={(e) => onChangeItemDetails('rstatus1', e.target.value)}
                                            size="small"
                                        />
                                    </FormGroup>
                                    <FormGroup className="col-lg-4">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Rstatus2"
                                            value={itemDetails?.rstatus2}
                                            onChange={(e) => onChangeItemDetails('rstatus2', e.target.value)}
                                            size="small"
                                        />
                                    </FormGroup>
                                    <FormGroup className="col-lg-4">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Rstatus3"
                                            value={itemDetails?.rstatus3}
                                            onChange={(e) => onChangeItemDetails('rstatus3', e.target.value)}
                                            size="small"
                                        />
                                    </FormGroup>
                                    <FormGroup className="col-lg-4">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Rposition1"
                                            value={itemDetails?.rposition1}
                                            onChange={(e) => onChangeItemDetails('rposition1', e.target.value)}
                                            size="small"
                                        />
                                    </FormGroup>
                                    <FormGroup className="col-lg-4">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Rposition2"
                                            value={itemDetails?.rposition2}
                                            onChange={(e) => onChangeItemDetails('rposition2', e.target.value)}
                                            size="small"
                                        />
                                    </FormGroup>
                                    <FormGroup className="col-lg-4">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Rposition3"
                                            value={itemDetails?.rposition3}
                                            onChange={(e) => onChangeItemDetails('rposition3', e.target.value)}
                                            size="small"
                                        />
                                    </FormGroup>
                                </div>
                                <div className="text-center mb-2">
                                    <Button variant="contained" className="text-white mr-2 btn-success" onClick={handleOnAddItem}>
                                        Save
                                    </Button>
                                </div>
                            </div>
                            <div className="row">
                                <FormGroup className="col-lg-4">
                                    <input
                                        fullWidth
                                        type="file"
                                        label="Signature1"
                                        onChange={(e) => onChangeItemDetails('signature1', e.target.value)}
                                        size="small"
                                    />
                                    <Button variant="contained" className="text-white mt-3 btn-success" onClick={handleOnAddItem}>
                                        Save
                                    </Button>
                                </FormGroup>
                                <FormGroup className="col-lg-4">
                                    <input
                                        fullWidth
                                        type="file"
                                        label="Signature2"
                                        onChange={(e) => onChangeItemDetails('signature2', e.target.value)}
                                        size="small"
                                    />
                                    <Button variant="contained" className="text-white mt-3 btn-success" onClick={handleOnAddItem}>
                                        Save
                                    </Button>
                                </FormGroup>
                                <FormGroup className="col-lg-4">
                                    <input
                                        fullWidth
                                        type="file"
                                        label="Signature3"
                                        onChange={(e) => onChangeItemDetails('signature3', e.target.value)}
                                        size="small"
                                    />
                                    <Button variant="contained" className="text-white mt-3 btn-success" onClick={handleOnAddItem}>
                                        Save
                                    </Button>
                                </FormGroup>
                            </div>
                        </div>
                    </Form>
                </div>
            </RctCollapsibleCard>
        </>
    );
}
