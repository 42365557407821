import React, { useState, useEffect } from 'react';
import { Form, FormGroup } from 'reactstrap';
import Button from '@mui/material/Button';
// api
import axios from "axios";
import baseUrl from "../../../api";

// import RctSectionLoader from '../../../component/RctSectionLoader/RctSectionLoader';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from 'reactstrap';

//multiselect
import TextField from '@mui/material/TextField';

const AddMedia= (props) => {
    const [itemDetails, setItemDetails] = useState({
        testGrpName: "",
        testCode: "",
        testName: "",
        media: "",
    });
    
    const onChangeItemDetails = (key, value) => {   
    var arr = { ...itemDetails };
    arr[key] = value;
    setItemDetails((prevState) => arr);
    };

    const handleOnAddItem = (e)=>{
    e.preventDefault();
    // axios.get("ec2-15-206-84-254.ap-south-1.compute.amazonaws.com:3000/api/master/site").then((response) => console.log("response",response));
    
    console.log("your form value is ", itemDetails)
    // console.log(`${baseUrl}/api/master/addMediaMaster`)
    // axios
    // .post(`${baseUrl}/api/master/addMediaMaster`, dataArray
    // )
    // .then((response) => {
    //     // successfully uploaded response
    // })
    // .catch((error) => {
    //     // error response
    // });
    alert(`${baseUrl}/api/master/addMediaMaster`);
    };

    const onAddUpdateItemModalClose = () => {
        props.setAddNewItemModal(false);
        props.setEditItem(null);
    }

    return (
        <>
            <Modal className='modal-lg' isOpen={props.addNewItemModal} toggle={() => onAddUpdateItemModalClose()}>
                <ModalHeader toggle={() => onAddUpdateItemModalClose()}>
                    Add New Item
                </ModalHeader>
                <ModalBody>
                      {/* {loading &&
                        <RctSectionLoader />
                    } */}
                    <Form className='row'>
                        <div className='col-lg-6'>
                            <FormGroup> 
                                 <TextField fullWidth 
                                    type="text"
                                    label="Test Grp Name"
                                    defaultValue={itemDetails.testGrpName}
                                    onChange={(e) =>
                                        onChangeItemDetails("testGrpName", e.target.value)
                                    }
                                    id="testGrpName"
                                    size="small"
                                />
                           </FormGroup>
                            <FormGroup> 
                                <TextField fullWidth 
                                    type="text"
                                    label="Test Code"
                                    defaultValue={itemDetails.testCode}
                                    onChange={(e) =>
                                        onChangeItemDetails("testCode", e.target.value)
                                    }
                                    id="testCode"
                                    size="small"
                                />
                            </FormGroup>
                        </div> 
                         <div className='col-lg-6'>
                            <FormGroup>
                                <TextField fullWidth 
                                    type="text"
                                    label="Test Name"
                                    defaultValue={itemDetails.testName}
                                    onChange={(e) =>
                                        onChangeItemDetails("testName", e.target.value)
                                    }
                                    id="testName"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup>  
                                <TextField fullWidth 
                                    type="text"
                                    label="Media"
                                    defaultValue={itemDetails.media}
                                    onChange={(e) =>
                                        onChangeItemDetails("media", e.target.value)
                                    }
                                    id="media"
                                    size="small"
                                />
                            </FormGroup>
                        </div>
                    </Form>
                </ModalBody> 
                 <ModalFooter>
                    <Button variant="contained" className="text-white btn-success" onClick={handleOnAddItem}>Save</Button>
                    <Button variant="contained" className="text-white btn-danger" onClick={() => onAddUpdateItemModalClose()}>Cancel</Button>
                </ModalFooter>
            </Modal >
        </>
    )
}
export default AddMedia;