// assets
import FilterIcon from '@mui/icons-material/Filter';

// constant
const icons = {
    FilterIcon
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const testperform = {
    id: 'testperform',
    title: 'Test Perform',
    caption: '',
    type: 'group',
    children: [
        {
            id: 'testperform',
            title: 'Test Perform',
            type: 'collapse',
            icon: icons.FilterIcon,
            children: [
                {
                    id: 'performtest',
                    title: 'Perform Test',
                    type: 'item',
                    url: '/app/perform-test',
                    breadcrumbs: false
                },
            ]
        }
    ]
};

export default testperform;
