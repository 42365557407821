import { jsPDF } from 'jspdf';
import { formatDateDMY, price_in_words } from '../../commonFunctions/commonFunction';

export const BillCashMemo = (tempVals, settings, itemDetails, companyDetails, dateTime, on) => {
    // const doc = new jsPDF();
    // console.log('temp vals', tempVals);
    console.log('printing//////');
    var cashMemoAddressX = parseInt(settings.filter((e) => e.nameOption === 'cashMemoAddressX')[0].value);

    const doc = new jsPDF('p', 'mm', 'a4');

    var x = 0;
    var y = -15;
    //Body
    doc.setFillColor(255, 255, 255);
    // doc.roundedRect(x+18,y+22,180,150,1,1,'F');
    doc.setFontSize(10);

    //Header
    doc.roundedRect(x + 15, y + 22, 180, 26, 3, 3);

    doc.setFont('arial', 'bold').setFontSize(11);
    doc.text('Cash Memo', x + 93, y + 28);
    doc.setFont('arial', 'bold').setFontSize(13);

    // var companyName = doc.setFontSize(companyFontSize).splitTextToSize(companyDetails[0].companyName, finalX - initialX);
    var companyName = companyDetails[0].companyName;
    var address = companyDetails[0].address ? companyDetails[0].address : '';
    var mobileNo = companyDetails[0].phone ? companyDetails[0].phone : '';
    var email = companyDetails[0].email ? companyDetails[0].email : '';
    // doc

    // doc.text(email, emailPos, emailGap);
    // Calculate the width of the text
    const textWidth = doc.getTextWidth(companyName);
    const addressWidth = doc.getTextWidth(address);
    // Get the page width
    const pageWidth = doc.internal.pageSize.getWidth();
    // Calculate the x position for centering the text
    const companyX = (pageWidth - textWidth) / 2;

    doc.text(companyName, companyX, y + 34);
    doc.setFont('arial', 'normal').setFontSize(11);

    // doc.text(address, cashMemoAddressX, y + 40);
    doc.text(address, cashMemoAddressX, y + 40);
    // doc.text('Cash Mem', x + 93, y + 28);

    doc.text('' + mobileNo, x + 190, y + 28, 'right');

    //Divider
    // doc.line(x + 15, y + 48, x + 195, y + 48);

    let age = 'Age: ';
    if (itemDetails.age_yy > 0) {
        age += `${itemDetails.age_yy}-yy `;
    }
    if (itemDetails.age_mm > 0) {
        age += `${itemDetails.age_mm}-mm `;
    }
    if (itemDetails.age_dd > 0) {
        age += `${itemDetails.age_dd}-dd`;
    }

    // doc.text(`Gender: ${itemDetails.gender}`, x + 20, y + 40);
    // doc.text(`Mobile: ${itemDetails.mobile}`, x + 20, y + 46);
    // doc.text(`Reffered By: Dr. ${itemDetails.constName}`, x + 20, y + 52);

    // doc.text(`Ledger No: ${itemDetails.ledgerNo} `, x + 128, y + 28);

    // doc.text(`Lab No: ${itemDetails.labNo} `, x + 140, y + 34);

    //Details
    doc.text(`Date: ${formatDateDMY(new Date(dateTime))}`, x + 20, y + 53);
    doc.text(`Name: ${itemDetails.title} ${itemDetails.name}`, x + 20, y + 59);
    // doc.text("Name: 25 Year", x+20, y+59);
    // doc.text(, x + 20, y + 34);
    doc.text(`Age: ${age}`, x + 20, y + 65);
    doc.text(`Sex: ${itemDetails.gender}`, x + 70, y + 65);
    // doc.text(`Reff. By: Dr.${itemDetails.constName}`, x + 20, y + 71);
    doc.text(`Address: ${itemDetails.address}`, x + 20, y + 71);

    //QR Code
    var img = new Image();
    img.src = 'bg-1.jpg';
    doc.addImage(img, 'jpeg', 10, 10, 50, 50);

    //Details Right
    doc.text(`Invoice No: ${itemDetails.invoiceNo}/${itemDetails.ledgerNo}`, x + 128, y + 53);

    doc.text(`Mobile No: ${itemDetails.mobile}`, x + 128, y + 59);
    doc.text(`Reff. By: Dr. ${itemDetails.constName}`, x + 128, y + 65);
    doc.text(`            ${itemDetails.constDesignation}`, x + 132, y + 69);

    //Divider
    doc.line(x + 15, y + 80, x + 195, y + 80);
    //Table row
    doc.setFont('arial', 'bold').setFontSize(12);
    doc.text('Sl. No.', x + 20, y + 85);
    doc.text('TEST NAME', x + 90, y + 85);
    doc.text('TEST AMOUNT', x + 162, y + 85);
    doc.setFont('arial', 'normal').setFontSize(11);
    //row1
    let totalTest = 1;
    // console.log('tring to print it');
    for (let i = 0; i < tempVals.length; i++) {
        // for (let i = 0; i < tempVals.lenght; i++) {
        // console.log('printing ', i);
        if (tempVals[i].testCode !== '') {
            doc.text(` ${totalTest}`, x + 20, y + 90);
            // doc.text(tempVals[i].units, resultLineR + 2, vOffSet + 1);
            doc.text(` ${tempVals[i].testName}`, x + 90, y + 90);
            doc.text(` ${tempVals[i].testRate}`, x + 168, y + 90);
            y = y + 7;
            totalTest = totalTest + 1;
        }
    }
    // tempVals.map((item, index) => {
    //     doc.text(`${index}`, x + 20, y + 90);
    //     doc.text(`${item.testName}`, x + 90, y + 90);
    //     doc.text(`${item.testRate}`, x + 168, y + 90);
    //     y = y + 7;
    //     totalTest = index+1
    // });

    //row2
    doc.roundedRect(x + 15, y + 92, 180, 46, 3, 3);

    doc.setFillColor(255, 255, 255);
    doc.roundedRect(x + 20, y + 96, 30, 6, 2, 2, 'B');
    doc.text(`Total Test: ${totalTest - 1}`, x + 22, y + 100);
    //right
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(x + 148, y + 96, 45, 6, 2, 2, 'B');
    doc.text(`Total Amount: ${itemDetails.subTotal}`, x + 150, y + 100);
    //row2 right
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(x + 148, y + 104, 45, 6, 2, 2, 'B');
    doc.text(`Paid Amount: ${itemDetails.paidAmount}`, x + 150, y + 108);

    //row3 left
    doc.text('Payment Mode: Cash', x + 20, y + 116);
    //right
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(x + 148, y + 112, 45, 6, 2, 2, 'B');
    doc.text(`Discount: ${itemDetails.discAmount}`, x + 150, y + 116);

    //row4 right
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(x + 148, y + 120, 45, 6, 2, 2, 'B');
    doc.text(`Dues Amount: ${itemDetails.dueAmount}`, x + 150, y + 124);

    //Word Amount
    doc.text(x + 20, y + 131, `Total Amount In Word : ${price_in_words(itemDetails.subTotal)} Rupees only`);
    doc.text(x + 20, y + 135, 'Thanks for your visit');
    // doc.roundedRect(x + 15, y + 22, 180, y + 128, 1, 1);

    doc.save(`${itemDetails.invoiceNo}`.pdf);
};
