import React, { useState, useRef, useContext, useMemo, useEffect } from 'react';
// api
import api from 'api';
// import axios from "axios";
import axiosInstance from '../../../api/axiosInstance';
import { generalTestPrint } from '../../JsReport/generaltestPrint';
import { BillCashMemo } from '../../JsReport/BillCashMemo';
// import { canvasGpt } from '../../JsReport/canvas';
import SearchIcon from '@mui/icons-material/Search';
import * as formula from './formula';
import { NotificationManager } from 'react-notifications';
import Tooltip from '@material-ui/core/Tooltip';

import AddIcon from '@mui/icons-material/Add';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PrintIcon from '@mui/icons-material/Print';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import SearchModal from './SearchModal';
// import { sayHello } from '../../../commonFunctions/commonFunction';
import { useFetchSettings, useFetchCompanyDetails, useFetchConsultant } from '../../../commonFunctions/commonFunction';
import Box from '@mui/material/Box';
import GridToCard from '../../../commonFunctions/GridToCard';

// Date
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

//multiselect
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';

//checkbox
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';

import { Form, FormGroup, Label, Input } from 'reactstrap';
import Button from '@mui/material/Button';

import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { AuthContext } from '../../../hooks/context';

// add new user form
import AddNewUserForm from './AddQuickResult';
import AddTest from './AddTest';
import WidalTest from './WidalTest';
import CultureResult from './CultureResult';
// page title bar
// import PageTitleBar from '../../../component/PageTitleBar/PageTitleBar';

// rct card box
import RctCollapsibleCard from '../../../component/RctCollapsibleCard/RctCollapsibleCard';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { SettingsSharp } from '@mui/icons-material';
const columns = [
    { field: 'id', headerName: 'ID', width: 10, hide: true },
    { field: 'ano', headerName: 'Sr.No', width: 5, headerClassName: 'super-app-theme--header' },
    {
        field: 'testCode',
        headerName: 'Test Code',
        hide: true
    },
    {
        field: 'testName',
        headerName: 'test name',
        width: 200,
        headerClassName: 'super-app-theme--header'
        //  editable: true
    },
    {
        field: 'subtest1',
        headerName: 'Sub Test',
        width: 200,
        headerClassName: 'super-app-theme--header'
        //  editable: true
    },
    {
        field: 'type',
        headerName: 'type',
        width: 50,
        headerClassName: 'super-app-theme--header',
        hide: true
        //  editable: true
    },
    {
        field: 'results',
        headerName: 'Result',
        width: 220,
        editable: true,
        headerClassName: 'super-app-theme--header'
    },
    {
        field: 'units',
        headerName: 'Unit',
        width: 70,
        editable: true,
        headerClassName: 'super-app-theme--header'
    },
    {
        field: 'normalValue',
        headerName: 'Normal Value',
        width: 180,
        editable: true,
        headerClassName: 'super-app-theme--header'
    },
    {
        field: 'comment',
        headerName: 'Comments',
        width: 180,
        editable: true,
        headerClassName: 'super-app-theme--header',
        renderCell: (params) => <div dangerouslySetInnerHTML={{ __html: params.value }} />
    }
];
const columnsForMobile = [
    { field: 'ano', headerName: 'Sr.No', width: 5, headerClassName: 'super-app-theme--header' },

    {
        field: 'testName',
        headerName: 'test name',
        width: 200,
        headerClassName: 'super-app-theme--header'
        //  editable: true
    },
    {
        field: 'subtest1',
        headerName: 'Sub Test',
        width: 200,
        headerClassName: 'super-app-theme--header'
        //  editable: true
    },
    {
        field: 'results',
        headerName: 'Result',
        width: 220,
        editable: true,
        headerClassName: 'super-app-theme--header'
    },
    {
        field: 'units',
        headerName: 'Unit',
        width: 50,
        editable: true,
        headerClassName: 'super-app-theme--header'
    },
    {
        field: 'normalValue',
        headerName: 'Normal Value',
        width: 100,
        editable: true,
        headerClassName: 'super-app-theme--header'
    },
    {
        field: 'comment',
        headerName: 'Comments',
        width: 150,
        editable: true,
        headerClassName: 'super-app-theme--header'
    }
];
//edited rows ka value prapt karne ke lye use kiye hain ye function

function useApiRef() {
    const apiRef = useRef(null);
    const _columns = useMemo(
        () =>
            columns.concat({
                field: '__HIDDEN__',
                width: 0,
                renderCell: (params) => {
                    apiRef.current = params.api;
                    return null;
                }
            }),
        [columns]
    );

    return { apiRef, columns: _columns };
}
export default function QuickResult(props) {
    //Add Modal
    const [loading, setLoading] = useState(false);
    const [selectedCell, setSelectedCell] = useState(null);
    const [items, setItems] = useState([]);
    const [addNewItemModal, setAddNewItemModal] = useState(false);
    const [editItem, setEditItem] = useState(null);
    const [importItemModal, setImportItemModal] = useState(false);
    // const [selectedItem, setSelectedItem] = useState(null);
    // const deleteConfirmationDialog = useRef(null);
    const [searchText, setSearchText] = useState('');
    const [searchModal, setSearchModal] = useState(false);

    //Add Modal 2
    const [importItemModal2, setImportItemModal2] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [items2, setItems2] = useState([]);
    const [addNewItemModal2, setAddTest] = useState(false);
    const [showWidal, setShowWidal] = useState(false);
    const [showCulture, setShowCulture] = useState(false);
    const [chemicalsOnePlus, setChemicalsOnePlus] = useState([]);
    const [chemicalsTwoPlus, setChemicalsTwoPlus] = useState([]);
    const [chemicalsThreePlus, setChemicalsThreePlus] = useState([]);
    const [chemicalsFourPlus, setChemicalsFourPlus] = useState([]);
    const [chemicalsR, setChemicalsR] = useState([]);
    const [chemicalsS, setChemicalsS] = useState([]);

    // const [editItem2, setEditItem2] = useState(null);
    const [save, setSave] = useState(true);
    const [print, setPrint] = useState(false);
    const [update, setUpdate] = useState(false);
    const [isMobileView, setIsMobileView] = useState(false);

    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [tempVals, setTempVals] = useState([]);
    const [cultureChemicals, setCultureChemicals] = useState([]);
    const [dateTime, setDateTime] = React.useState(new Date());
    const [age, setAge] = React.useState(14);
    const [on, setOn] = React.useState(new Date());
    const [inPatient, setInPatient] = React.useState(false);
    const [selectedRowIds, setSelectedRowIds] = useState([]);
    const [editorContent, setEditorContent] = useState('');

    const [itemDetails, setItemDetails] = useState({
        id: '',
        ledgerGeneration: '',
        ledgerNo: '',
        labNo: '',
        printReceipt: '',
        sampleCollectedForm: '',
        new: 'yes',
        address: '',
        title: 'Mr.',
        name: '',
        gender: 'Male',
        constId: '',
        constDesignation: '',
        constTitle: 'Mr.',
        constName: '',
        constFaculty: '',
        mobile: '',
        constHospital: '',
        performT: '',
        performSt: '',
        subTotal: 0,
        discAmount: 0,
        paidAmount: 0,
        dueAmount: 0,
        invoiceNo: 0,
        age_yy: '',
        age_mm: '',
        age_dd: '',
        email: ''
    });

    const { secondValue, setSecondValue } = useContext(AuthContext);
    const [data, setData] = useState();
    const dataGridRef = React.useRef();

    useEffect(() => {
        fetchPosts();
    }, []);
    useEffect(() => {
        calculate('discAmount');
    }, [itemDetails.discAmount]);
    useEffect(() => {
        calculate('paidAmount');
    }, [itemDetails.paidAmount]);
    useEffect(() => {
        setConsultantDetails(itemDetails.constName);
    }, [itemDetails.constName]);
    const calculate = (frm) => {
        if (frm == 'discAmount') {
            let paidAmount = itemDetails.subTotal - itemDetails.discAmount;
            let dueAmount = 0;
            setItemDetails({ ...itemDetails, paidAmount: paidAmount, dueAmount: dueAmount });
        }
        if (frm == 'paidAmount') {
            // let paidAmount = itemDetails.subTotal - itemDetails.discAmount;
            let dueAmount = itemDetails.subTotal - itemDetails.discAmount - itemDetails.paidAmount;
            setItemDetails({ ...itemDetails, dueAmount: dueAmount });
        }
    };
    const handleRecordClick = async (record) => {
        // Update the selected record in the state
        // setSelectedRecord(record);
        setSearchModal(false);
        handleNavigationClick('L', record.labNo);

        // console.log('yahi hai record', record);

        // Fetch related values from table2 based on the selected record
        // try {
        //     const response = await fetchRelatedValuesFromTable2(record);
        //     setRelatedValues(response.data); // Assuming this is how you get related values
        // } catch (error) {
        //     // Handle errors here
        //     console.error(error);
        // }
    };

    const setConsultantDetails = (constDetails) => {
        let p = consultant?.data.filter((e) => e.cName == constDetails);
        if (p !== undefined && p.length > 0) {
            setItemDetails({
                ...itemDetails,
                constId: p[0].consultantId ? p[0].consultantId : '',
                constDesignation: p[0].designation ? p[0].designation : '',
                constFaculty: p[0].faculty ? p[0].faculty : '',
                constHospital: p[0].hospital ? p[0].hospital : ''
            });
        }
    };

    const { data: company, isLoading: isCompanyLoading } = useFetchCompanyDetails();
    const { data: consultant, isLoading: isConsultantLoading } = useFetchConsultant();
    const { data: settings, isLoading: isSettingsLoading, isError, error, isSuccess } = useFetchSettings();

    if (isCompanyLoading || isConsultantLoading || isSettingsLoading) {
        return <div>Loading...</div>;
    }
    var nameEdit = settings.data.filter((e) => e.nameOption === 'nameEdit')[0].value;
    var isNameEditible = false;
    if (nameEdit == 'true') {
        isNameEditible = true;
    } else {
        isNameEditible = false;
    }

    let consultants = [];
    if (Array.isArray(consultant?.data)) {
        consultants = consultant.data.map((item, index) => (
            <MenuItem value={item.cName} key={index}>
                {item.cName}
            </MenuItem>
        ));
    }

    // let consultants = Array.isArray(consultant.data) && consultant.data?.map((item, index) => {
    //     return <MenuItem value={item.cName}>{item.cName}</MenuItem>;
    // });
    const handleCellClick = (params) => {
        //new code of comment popup
        const field = params.field;
        if (field === 'comment') {
            const commentValue = params.formattedValue;
            // setSelectedCell({ comment: commentValue });
            setSelectedCell({ id: params.id, comment: commentValue });
        } else {
            setSelectedCell(null);
        }

        if (params.field === 'results') {
            // Clicked on the 'results' column, prevent row selection

            params.api.clearRowSelection(params.row.id);
        }

        // if (params.field === 'results') {
        //     console.log('results cell clicked hai');
        //     // Clicked on the desired column, prevent row selection
        //     params.event.stopPropagation();
        // }
        // params.event.stopPropagation();
    };
    // const handleCellClick = (params) => {
    //     if (params.field === 'ano') {
    //         // Clicked on the desired column, select the whole row
    //         console.log('params.row.id', params.row.id);
    //         if (selectedRowIds.includes(params.row.id)) {
    //             // If the row is already selected, deselect it
    //             setSelectedRowIds((prevSelectedRowIds) => prevSelectedRowIds.filter((id) => id !== params.row.ano));
    //         } else {
    //             // If the row is not selected, select it
    //             setSelectedRowIds((prevSelectedRowIds) => [...prevSelectedRowIds, params.row.id]);
    //         }
    //     }
    // };
    const handleSelectionModelChange = (newSelectionModel) => {
        // Update the selected row ids
        // let arr = ['1028', '1029', '1037'];
        // const clickedRowId = newSelectionModel[newSelectionModel.length - 1];
        // // const row = dataGridRef.current?.getRowById(clickedRowId);
        // const row = rows.find((row) => row.id === clickedRowId);
        // if (row) {
        //     console.log('clicked row is ', row);
        //     //   setRecentlyClickedRow(row);
        // }
        // following logic is made to determine the clicked check box in datagrid
        // console.log('kyuuu ', newSelectionModel.length);
        if (newSelectionModel.length > selectedRowIds.length) {
            //bole to kuch extra item hua hai

            const extraElements = newSelectionModel.filter((element) => !selectedRowIds.includes(element));
            let getTheRow = rows.filter((element) => element.id == extraElements[0]);
            if (getTheRow.length > 0) {
                // console.log('lenght to bara hai', getTheRow[0].type);
                if (
                    getTheRow[0].type == 'multiple' ||
                    getTheRow[0].type == 'Multiple' ||
                    getTheRow[0].type == 'panle' ||
                    getTheRow[0].type == 'Panel'
                ) {
                    let lastAno = rows[rows.length - 1].ano;
                    let currentAno = getTheRow[0].ano;
                    for (currentAno; currentAno < lastAno; currentAno++) {
                        // console.log('ccurrentAno ', currentAno, 'testcode is ', rows[currentAno].testCode);
                        if (rows[currentAno].testCode == '') {
                            // console.log('these rows to ', rows[currentAno].id, 'and its test code is : ' + rows[currentAno].testCode);
                            newSelectionModel.push(rows[currentAno].id);
                        } else {
                            break;
                        }
                    }
                }
                // now check its type
            }
        } else {
            const missingElements = selectedRowIds.filter((element) => !newSelectionModel.includes(element));
            // console.log('Missing Elements:', missingElements); // Output: [6, 8]
            let getTheRow = rows.filter((element) => element.id == missingElements[0]);
            if (getTheRow.length > 0) {
                // console.log('lenght to bara hai', getTheRow[0].type);
                if (
                    getTheRow[0].type == 'multiple' ||
                    getTheRow[0].type == 'Multiple' ||
                    getTheRow[0].type == 'panle' ||
                    getTheRow[0].type == 'Panel'
                ) {
                    let lastAno = rows[rows.length - 1].ano;
                    let currentAno = getTheRow[0].ano;
                    for (currentAno; currentAno < lastAno; currentAno++) {
                        // console.log('ccurrentAno ', currentAno, 'testcode is ', rows[currentAno].testCode);
                        if (rows[currentAno].testCode == '') {
                            //
                            // const array = [1, 2, 3, 4, 5];
                            // const elementToDelete = 3; // Element to delete

                            // const indexToDelete = array.indexOf(elementToDelete);
                            // if (indexToDelete !== -1) {
                            //     array.splice(indexToDelete, 1);
                            // }
                            //
                            let indexToDelete = newSelectionModel.indexOf(rows[currentAno].id);
                            if (indexToDelete !== -1) {
                                newSelectionModel.splice(indexToDelete, 1);
                            }

                            // newSelectionModel.push(rows[currentAno].id);
                        } else {
                            break;
                        }
                    }
                }
                // now check its type
            }
        }
        //ab kya hai ki agar mission element hai aur ye element kisi multiple rows ka hai to fir us pure
        //test ko lana hai aur sab ko uncheck kar dena hai

        setSelectedRowIds(newSelectionModel);
        // setSelectedRowIds(arr);
        // console.log('selected rows ids are', newSelectionModel);
    };
    const handleRowSelected = (params) => {
        const clickedCheckbox = params.row.id;
    };

    // for (let item of consultant.data) {
    //     consultants.push(<option value={item.cName}>{item.cName}</option>);
    // }
    let subTotal = itemDetails.subTotal;
    const loadItems = async () => {
        (async () => {
            setLoading(true);
            await api
                .get('api/master/product?PageNumber=' + (page + 1) + '&PageSize=' + rowsPerPage, {
                    params: {
                        searchText: searchText
                    }
                })
                .then((response) => {
                    setCount(response.data.subTotalRecords);
                    setItems(response.data.data);
                })
                .catch((error) => {})
                .finally(() => {
                    setLoading(false);
                });
        })();
    };
    const opnSearchModal = (ev) => {
        ev.preventDefault();
        setSearchModal(true);
    };
    const loadItems2 = () => {
        //yahi par khela hobe.
        // console.log('value of tempvalsi in loaditem2 is ', tempVals);
        // console.log('value of tempvalsi in loaditem2 is ', subTotal);
        let valsLocal = [...tempVals];
        // valsLocal.push({ id: 'aman' });
        // return;
        let lData = data.slice();
        // valsLocal.splice(0, valsLocal.length);

        // if (valsLocal.length > 0) {
        //     valsLocal.length = 0;
        //     valsLocal.splice(0, valsLocal.length);
        // }
        //get the max ano value
        let maxAno = valsLocal.reduce((max, obj) => (obj.ano > max ? obj.ano : max), 0);

        let j = maxAno;
        // console.log('select test is ', secondValue);
        for (let i = 0; i < secondValue.length; i++) {
            //now check whether this code exists in the table of not
            if (valsLocal.some((item) => item.testCode === secondValue[i].testCode)) {
                // console.log('repeadte test code is ', secondValue[i].testCode);
                continue;
            }

            subTotal += secondValue[i].testRate;

            var p = lData.filter((e) => e.testCode == secondValue[i].testCode);

            j++;
            p[0].ano = j;
            p[0].results = p[0].defaultValue;
            //check for age.
            if (age < 13) {
                p[0].normalValue = p[0].normalValueChild;
            } else if (itemDetails.gender == 'Female') {
                p[0].normalValue = p[0].normalValueWomen;
            } else {
                p[0].normalValue = p[0].normalValueMen;
            }
            valsLocal.push(p[0]);
            if (p[0].type == 'Multiple' || p[0].type == 'panel' || p[0].type == 'multiple' || p[0].type == 'Panel') {
                // For secondlocali As Integer = locali + 1 To ds.Tables("testmaster").Rows.Count - 1
                // If ds.Tables("testmaster").Rows(secondlocali).Item("testcode") = "" Or ds.Tables("testmaster").Rows(secondlocali).Item("testcode") = Nothing Then
                //     If

                let index = lData.findIndex((e) => e['id'] == p[0].id);

                //bcz its not sure that id will start from 1

                for (var locali = index + 1; locali < lData.length; locali++) {
                    // console.log('p is ', p[0], 'locali is ', locali, 'lData.length is ', lData.length);
                    // alert('testcode is ' + lData[locali].testCode);
                    if (lData[locali].testCode == '.') {
                        continue;
                    }
                    if (lData[locali].testCode == '') {
                        j++;
                        lData[locali].ano = j;
                        lData[locali].results = lData[locali].defaultValue;

                        if (age < 13) {
                            lData[locali].normalValue = lData[locali].normalValueChild;
                        } else if (itemDetails.gender == 'Female') {
                            lData[locali].normalValue = lData[locali].normalValueWomen;
                        } else {
                            lData[locali].normalValue = lData[locali].normalValueMen;
                        }
                        valsLocal.push(lData[locali]);
                    } else {
                        // console.log('coming in break ', 'locali is ', locali, 'testname is ', lData[locali].testName);
                        break;
                    }
                }
            }
            // let temp = secondValue[i];
        }
        let dues = subTotal - itemDetails.paidAmount - itemDetails.discAmount;
        setItemDetails({ ...itemDetails, subTotal: subTotal, dueAmount: dues });
        setTempVals(valsLocal);

        // const mergedArray = tempVals.concat(valsLocal);
        // const mergedResults = mergeByTestCode(tempVals, valsLocal);
        // setTempVals(mergedResults);

        // setTempVals(mergedArray);

        // console.log(valsLocal);
        //  row = valsLocal[0]
    };
    // Assuming tempVals and valsLocal are already defined and populated arrays

    // Merge valsLocal into tempVals based on testCode

    // Use the merge function

    const widalOk = (widalItems) => {
        setShowWidal(false);
        var p = tempVals.filter((e) => e.testName == 'S.TYPHI"O" 1/20');
        var arr = [...tempVals];
        let index = arr.findIndex((e) => e['id'] == p[0].id);
        arr[index]['results'] = widalItems.typhiO20;

        index = arr.findIndex((e) => e['id'] == p[0].id + 1);
        arr[index]['results'] = widalItems.typhiH20;

        index = arr.findIndex((e) => e['id'] == p[0].id + 2);
        arr[index]['results'] = widalItems.paratyphiAH20;

        index = arr.findIndex((e) => e['id'] == p[0].id + 3);
        arr[index]['results'] = widalItems.paratyphiBH20;

        index = arr.findIndex((e) => e['id'] == p[0].id + 4);
        arr[index]['results'] = widalItems.typhiO40;
        arr[index + 1]['results'] = widalItems.typhiH40;
        arr[index + 2]['results'] = widalItems.paratyphiAH40;
        arr[index + 3]['results'] = widalItems.paratyphiBH40;

        arr[index + 4]['results'] = widalItems.typhiO80;
        arr[index + 5]['results'] = widalItems.typhiH80;
        arr[index + 6]['results'] = widalItems.paratyphiAH80;
        arr[index + 7]['results'] = widalItems.paratyphiBH80;

        arr[index + 8]['results'] = widalItems.typhiO160;
        arr[index + 9]['results'] = widalItems.typhiH160;
        arr[index + 10]['results'] = widalItems.paratyphiAH160;
        arr[index + 11]['results'] = widalItems.paratyphiBH160;

        arr[index + 12]['results'] = widalItems.typhiO320;
        arr[index + 13]['results'] = widalItems.typhiH320;
        arr[index + 14]['results'] = widalItems.paratyphiAH320;
        arr[index + 15]['results'] = widalItems.paratyphiBH320;

        var ano = p[0].id;
        setTempVals(arr);

        // let arrs = [...tempVals];
        // const elementsIndex = arrs.findIndex((element) => element['id'] == id);
        // arrs[elementsIndex][`${field}`] = value;
        //  setTempVals(arrs);
    };
    const cultureOk = (widalItems) => {
        setShowCulture(false);
        var p = tempVals.filter((e) => e.testName == 'S.TYPHI"O" 1/20');
        var arr = [...tempVals];
        let index = arr.findIndex((e) => e['id'] == p[0].id);
        arr[index]['results'] = widalItems.typhiO20;

        index = arr.findIndex((e) => e['id'] == p[0].id + 1);
        arr[index]['results'] = widalItems.typhiH20;

        index = arr.findIndex((e) => e['id'] == p[0].id + 2);
        arr[index]['results'] = widalItems.paratyphiAH20;

        index = arr.findIndex((e) => e['id'] == p[0].id + 3);
        arr[index]['results'] = widalItems.paratyphiBH20;

        index = arr.findIndex((e) => e['id'] == p[0].id + 4);
        arr[index]['results'] = widalItems.typhiO40;
        arr[index + 1]['results'] = widalItems.typhiH40;
        arr[index + 2]['results'] = widalItems.paratyphiAH40;
        arr[index + 3]['results'] = widalItems.paratyphiBH40;

        arr[index + 4]['results'] = widalItems.typhiO80;
        arr[index + 5]['results'] = widalItems.typhiH80;
        arr[index + 6]['results'] = widalItems.paratyphiAH80;
        arr[index + 7]['results'] = widalItems.paratyphiBH80;

        arr[index + 8]['results'] = widalItems.typhiO160;
        arr[index + 9]['results'] = widalItems.typhiH160;
        arr[index + 10]['results'] = widalItems.paratyphiAH160;
        arr[index + 11]['results'] = widalItems.paratyphiBH160;

        arr[index + 12]['results'] = widalItems.typhiO320;
        arr[index + 13]['results'] = widalItems.typhiH320;
        arr[index + 14]['results'] = widalItems.paratyphiAH320;
        arr[index + 15]['results'] = widalItems.paratyphiBH320;

        var ano = p[0].id;
        setTempVals(arr);

        // let arrs = [...tempVals];
        // const elementsIndex = arrs.findIndex((element) => element['id'] == id);
        // arrs[elementsIndex][`${field}`] = value;
        //  setTempVals(arrs);
    };

    // const onDelete = (item) => {
    //     deleteConfirmationDialog.current.open();
    //     setSelectedItem(items[items.indexOf(item)]);
    // }

    // date

    // const { isLoading, isSuccess, isError, data, error, refetch } = useQuery('allTest', fetchPosts, { enabled: false });

    // if (isLoading) {
    //     return <span>Loading...</span>;
    // }
    // if (isError) {
    //     return <span>Error: {error.message}</span>;
    // }
    // if (data === undefined) {
    //     refetch();
    // }

    async function fetchPosts() {
        const { data } = await axiosInstance.get(`/getTestMaster`);
        setData(data);
        // return data;
    }

    // var p = data.filter((e) => e.id == secondValue[i].id);

    const onChangeItemDetails = (key, value) => {
        var arr = { ...itemDetails };
        arr[key] = value;
        setItemDetails((prevState) => arr);
    };
    // const printIt = () => {
    //     const doc = new jsPDF();

    //     doc.text('Hello world!', 10, 10);
    //     doc.save('a4.pdf');
    // };

    // const { apiRef, columns } = useApiRef();
    const checkForFormula = (updatedRow, ano) => {
        // first of all check ki isme formula field hai ki nahi
        // agar hai to iska parent test ko pakaro
        //aur fir uske according testfunction ko call kar do.
        /*check for formula field */
        let parentCode = '';
        let parentFormulCode = '';
        // console.log('updatedRow is ', updatedRow[0]);
        if (updatedRow[0].formulaCode !== '') {
            // console.log('formula field is ', updatedRow[0].formulaCode);
            //now find out its parent test..
            for (let i = ano; i > 0; i--) {
                if (rows[i - 1].testCode !== '') {
                    //test code mil gaya so now this formula code belong to this test code
                    parentCode = rows[i - 1].testCode;
                    parentFormulCode = rows[i - 1].formulaCode;
                    break;
                }
            }
            //now call the function according to the test code or formula code.
        }
        // console.log('ano parentCode is  ' + parentCode + 'parent formula is ' + parentFormulCode);
        if (parentFormulCode === 'PROTHROMBINTS') {
            formula.formulaPROTHROMBIN(rows);
        }
        if (parentFormulCode === 'CBC') {
            formula.formulaCBC(rows, updatedRow[0].formulaCode);
        }
        if (parentFormulCode === 'CBCHMG') {
            formula.formulaCBCHMG(rows, updatedRow[0].formulaCode);
        }
        if (parentFormulCode === 'BILLIRUBIN') {
            formula.formulaBillirubin(rows);
        }
        if (parentFormulCode === 'PROTEIN') {
            formula.formulaGlobulin(rows);
        }
        if (parentFormulCode === 'LIPIDPROFILE') {
            formula.formulaLdlColestrol(rows);
        }
        if (parentFormulCode === 'ESR') {
            // console.log('parentformula is esr');
            formula.formulaEsrAverage(rows);
        }
        if (parentFormulCode === 'LFT') {
            formula.formulaLFT(rows);
        }
        // if (parentFormulCode === 'KFT') {
        //     formula.formulaKFT(rows);
        // }

        if (parentFormulCode === 'HAEMOGLOBIN') {
            formula.formulaHAEMOGLOBIN(rows, updatedRow[0].formulaCode);
        }
        if (parentFormulCode === 'formula24Hours') {
            formula.formula24Hours(rows);
        }
    };
    const handleOnAddItem = (cellData) => {
        // console.log('handle on add item , select ids are', selectedRowIds);
        const { id, field, value, row } = cellData;

        if (field === 'comment') {
            // Handle the cell content update using editorContent state
            const updatedComment = editorContent;
            const updatedRow = { ...cellData.row, [field]: updatedComment };
            // Update the DataGrid rows
            setTempVals((prevRows) => prevRows.map((row) => (row.id === id ? updatedRow : row)));
        }

        let arrs = tempVals.slice();
        const elementsIndex = arrs.findIndex((element) => element['id'] == id);
        arrs[elementsIndex][`${field}`] = value;
        setTempVals(arrs);
        const updatedRow = rows.filter((e) => e.id == id);
        // console.log('updated row', updatedRow, 'updatedRow[0].ano', updatedRow[0].ano);
        checkForFormula(updatedRow, updatedRow[0].ano);
    };
    const handleOnRowsModelChange = (cellData) => {
        // const { id, field, value } = cellData;
        // console.log('Row Data data is ' + JSON.stringify(cellData));
    };
    const handleCellKeyDown = (event) => {
        // alert('lalalli llala');
        const { testCode } = event.row;
        // console.log(event.row);
        if (testCode == 'Riya Singh') {
            // alert('lala li lala');
        }
    };
    const handleEventEdit = (details) => {
        // alert('lalalli llala');
        // console.log('le details', details);
        // const { testCode } = event.row;
        // console.log(event.row);
        // if (testCode == 'Riya Singh') {
        //     alert('lala li lala');
        // }
    };
    const handleEvent = (event) => {
        // alert('edit end hoga');
        // const { testCode } = event.row;
        // console.log(event);
    };

    //Add Modal
    const zeroPad = (num, places) => String(num).padStart(places, '0');
    const onReload = (e) => {
        e.preventDefault();
        setLoading(true);
        loadItems();
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    };

    const opnAddNewItemModal = (e) => {
        e.preventDefault();
        setAddNewItemModal(true);
    };

    const opnImportItemModal = (e) => {
        e.preventDefault();
        setImportItemModal(true);
    };

    const onEditItem = (item) => {
        setAddNewItemModal(true);
        setEditItem(items[items.indexOf(item)]);
    };

    const onAddUpdateItemModalClose = () => {
        props.setAddNewItemModal(false);
        props.setEditItem(null);
    };

    //Add Modal 2
    const onReload2 = (ev) => {
        ev.preventDefault();
        setLoading2(true);
        // loadItems2();
        setTimeout(() => {
            setLoading2(false);
        }, 2000);
    };

    const opnAddTest = (ev) => {
        ev.preventDefault();
        setAddTest(true);
    };

    const opnImportItemModal2 = (ev) => {
        ev.preventDefault();
        setImportItemModal2(true);
    };

    const rows = tempVals;

    const handleRowClick = (event) => {
        let lData = data.slice();
        if (event.row.testName == 'S.TYPHI"O" 1/20') {
            setShowWidal(true);
        }
        if (event.row.testName == 'AMPICILIN') {
            // let startedVal = parseInt(p[0].id) + parseInt(1) - parseInt(data[0].id);
            // let i = event.row.id - data[0].id;
            let i = lData.findIndex((e) => e['id'] == event.row.id);
            let tempCultureChecmical = [];
            while (lData[i].testName != 'MICROSCOPIC EXAMINATION') {
                let localVals = { id: lData[i].id, chemicals: lData[i].testName };
                tempCultureChecmical.push(localVals);
                i++;
            }

            setCultureChemicals(tempCultureChecmical);
            setShowCulture(true);
        }
    };
    const handleCultureTransfer = (id, keyVals) => {
        if (keyVals == '0') {
            let arr1 = [...chemicalsS];
            let p = cultureChemicals.filter((e) => e.id == id);
            arr1.push(p[0]);
            setChemicalsS(arr1);
            setCultureChemicals((current) => current.filter((chemical) => chemical.id !== id));
        }
        if (keyVals == '1') {
            let arr1 = [...chemicalsOnePlus];
            let p = cultureChemicals.filter((e) => e.id == id);
            arr1.push(p[0]);
            setChemicalsOnePlus(arr1);
            setCultureChemicals((current) => current.filter((chemical) => chemical.id !== id));
        }
        if (keyVals == '2') {
            let arr2 = [...chemicalsTwoPlus];
            let p = cultureChemicals.filter((e) => e.id == id);
            arr2.push(p[0]);
            setChemicalsTwoPlus(arr2);
            setCultureChemicals((current) => current.filter((chemical) => chemical.id !== id));
        }
        if (keyVals == '3') {
            let arr3 = [...chemicalsThreePlus];
            let p = cultureChemicals.filter((e) => e.id == id);
            arr3.push(p[0]);
            setChemicalsThreePlus(arr3);
            setCultureChemicals((current) => current.filter((chemical) => chemical.id !== id));
        }
        if (keyVals == '4') {
            let arr4 = [...chemicalsFourPlus];
            let p = cultureChemicals.filter((e) => e.id == id);
            arr4.push(p[0]);
            setChemicalsFourPlus(arr4);
            setCultureChemicals((current) => current.filter((chemical) => chemical.id !== id));
        }
        if (keyVals == '5') {
            let arr5 = [...chemicalsR];
            let p = cultureChemicals.filter((e) => e.id == id);
            arr5.push(p[0]);
            setChemicalsR(arr5);
            setCultureChemicals((current) => current.filter((chemical) => chemical.id !== id));
        }
    };
    const handleCultureOk = () => {
        //for one sensitive
        let arr = [...tempVals];

        for (let i = 0; i <= chemicalsS.length - 1; i++) {
            for (let j = 0; j <= tempVals.length - 1; j++) {
                if (tempVals[j].testName == chemicalsS[i].chemicals) {
                    arr[j].results = 'S';
                }
            }
        }
        //for one plus
        for (let i = 0; i <= chemicalsOnePlus.length - 1; i++) {
            for (let j = 0; j <= tempVals.length - 1; j++) {
                if (tempVals[j].testName == chemicalsOnePlus[i].chemicals) {
                    arr[j].results = '+';
                }
            }
        }
        //for two plus
        for (let i = 0; i <= chemicalsTwoPlus.length - 1; i++) {
            for (let j = 0; j <= tempVals.length - 1; j++) {
                if (tempVals[j].testName == chemicalsTwoPlus[i].chemicals) {
                    arr[j].results = '++';
                }
            }
        }
        //for three plus
        for (let i = 0; i <= chemicalsThreePlus.length - 1; i++) {
            for (let j = 0; j <= tempVals.length - 1; j++) {
                if (tempVals[j].testName == chemicalsThreePlus[i].chemicals) {
                    arr[j].results = '+++';
                }
            }
        }
        //for Four plus
        for (let i = 0; i <= chemicalsFourPlus.length - 1; i++) {
            for (let j = 0; j <= tempVals.length - 1; j++) {
                if (tempVals[j].testName == chemicalsFourPlus[i].chemicals) {
                    arr[j].results = '++++';
                }
            }
        }
        //for chemicalsR
        for (let i = 0; i <= chemicalsR.length - 1; i++) {
            for (let j = 0; j <= tempVals.length - 1; j++) {
                if (tempVals[j].testName == chemicalsR[i].chemicals) {
                    arr[j].results = 'R';
                }
            }
        }

        //  setTempVals(arr);
    };

    const handleSave = async () => {
        // sayHello();
        setSave(false);
        setPrint(true);

        let p = settings.data.filter((e) => e.name == 'ledgerGeneration');
        let cuts = consultant.data.filter((e) => e.cName == itemDetails.constName);
        // console.log('lo ji cuts', cuts[0]);
        let arr = { ...itemDetails };
        arr.ledgerGeneration = p[0].value;
        arr.cuts = cuts.length > 0 ? parseInt(cuts[0].cuts) : 0;
        arr.date = dateTime;
        arr.cashPay = itemDetails.paidAmount;
        // setItemDetails({ ...itemDetails, ledgerGeneration: p[0].value });
        let finalData = { arr, tempVals };
        NotificationManager.success('Saved Successfully', 'Test Saved');

        axiosInstance.post(`/BulkQuickReport02`, finalData).then((response) => {
            setItemDetails({
                ...itemDetails,
                ledgerNo: response.data.ledgerNo,
                labNo: 'Lab-' + zeroPad(response.data.labNo, 4),
                invoiceNo: 'INV-' + zeroPad(response.data.labNo, 4)
            });

            // NotificationManager.error('Saved Successfully');
            // NotificationManager.success()

            // alert('Data Has been Saved Successfully'); // try to notifiy it rather than alerting
        });
        //     await axios.post(`${baseUrl}/quickReport01`, tempVals);
    };
    const handleUpdate = async () => {
        setUpdate(false);
        NotificationManager.success('Updated Successfully', 'Test Updated');

        // console.log('update is ', itemDetails);
        // tempVals.ledgerNo = itemDetails.ledgerNo;
        // tempVals.labNo = itemDetails.labNo;

        // const originalLedgerNo = itemDetails.ledgerNo ? parseInt(itemDetails.ledgerNo.toString().split('-')[1], 10) : null;
        const originalLabNo = itemDetails.labNo ? parseInt(itemDetails.labNo.toString().split('-')[1], 10) : null;

        const updatedTempVals = tempVals.map((obj) => ({
            ...obj,
            ledgerNo: itemDetails.ledgerNo,
            labNo: originalLabNo
        }));
        // console.log('updatedtempVals' + JSON.stringify(updatedTempVals));

        let finalData = { itemDetails, updatedTempVals };
        axiosInstance.post(`/UpdateBulkQuickReport02Bulk`, finalData).then((response) => {
            // setItemDetails({ ...itemDetails, ledgerNo: response.data.ledgerNo, labNo: 'Lab-' + zeroPad(response.data.ledgerNo, 4) });
        });
    };

    const handleAddClick = () => {
        setSave(true);
        setUpdate(false);
        setPrint(false);
        setTempVals([]);
        setSecondValue([]);
        setChemicalsOnePlus([]);
        setChemicalsTwoPlus([]);
        setChemicalsThreePlus([]);
        setChemicalsFourPlus([]);
        setChemicalsR([]);
        setChemicalsS([]);
        setDateTime(new Date());
        setOn(new Date());
        setItemDetails({
            id: '',
            ledgerGeneration: '',
            ledgerNo: '',
            labNo: '',
            printReceipt: '',
            sampleCollectedForm: '',
            new: 'yes',
            address: '',
            title: 'Mr.',
            name: '',
            gender: 'Male',
            constId: '',
            constDesignation: '',
            constTitle: 'Mr.',
            constName: '',
            constFaculty: '',
            mobile: '',
            constHospital: '',
            performT: '',
            performSt: '',
            subTotal: 0,
            discAmount: 0,
            paidAmount: 0,
            dueAmount: 0,
            age_dd: '',
            age_mm: '',
            age_yy: '',
            email: ''
        });
    };
    const handleBackClick = async () => {
        if (itemDetails.labNo === '') {
            var primaryData = await axiosInstance.get(`getquickReport01WithoutId`);
        } else {
            var primaryData = await axiosInstance.get(`getquickReport01/${itemDetails.labNo}`);
        }
        setItemDetails({ ...itemDetails, labNo: 'Lab-' + zeroPad(primaryData.data.moni.labNo, 4) });
        setTempVals(primaryData.data.moni1);
    };
    const handleForwardClick = async () => {
        if (itemDetails.labNo === '') {
            var primaryData = await axiosInstance.get(`getquickReport01WithoutId`);
        } else {
            var primaryData = await axiosInstance.get(`getquickReport01/${itemDetails.labNo}`);
        }
        setItemDetails(primaryData.data.moni);
        setItemDetails({ ...itemDetails, labNo: 'Lab-' + zeroPad(primaryData.data.moni.id, 4) });
        setTempVals(primaryData.data.moni1);
    };
    const handleNavigationClick = async (navType, labNo) => {
        setSave(false);
        setUpdate(true);
        setPrint(true);
        if (labNo === '') {
            if (navType == 'backWard') {
                var primaryData = await axiosInstance.get(`getquickReport01WithoutId/${navType}`);
            }
        } else {
            var primaryData = await axiosInstance.get(`getquickReport01/${labNo}/${navType}`);
        }
        primaryData.data.moni.labNo = 'Lab-' + zeroPad(primaryData.data.moni.labNo, 4);
        primaryData.data.moni.invoiceNo = 'INV-' + zeroPad(primaryData.data.moni.invoiceNo, 4);
        // console.log('invi ' + JSON.stringify(primaryData.data.moni1));

        //here you have to do descriptive changes.
        // setItemDetails(primaryData.data.moni);4
        // console.log('Data from navigation', primaryData.data.moni1);
        primaryData.data.moni1.map(function (item, i) {
            item.ano = i + 1;
            return item;
        });

        setTempVals(primaryData.data.moni1);
        //   setItemDetails({ ...itemDetails, labNo: 'Lab-' + zeroPad(primaryData.data.moni.id, 4) });
        setDateTime(primaryData.data.moni.date);
        setOn(primaryData.data.moni.date);
        setItemDetails({
            id: primaryData.data.moni.id,
            ledgerGeneration: '',
            ledgerNo: primaryData.data.moni.ledgerNo,
            labNo: primaryData.data.moni.labNo,
            invoiceNo: primaryData.data.moni.invoiceNo,
            printReceipt: '',
            sampleCollectedForm: primaryData.data.moni.labNo,
            new: 'yes',
            address: primaryData.data.moni.address ? primaryData.data.moni.address : '',
            title: primaryData.data.moni.title ? primaryData.data.moni.title : '',
            name: primaryData.data.moni.name,
            gender: primaryData.data.moni.gender,
            constId: primaryData.data.moni.constId ? primaryData.data.moni.constId : '',
            constDesignation: primaryData.data.moni.constDesignation ? primaryData.data.moni.constDesignation : '',
            constTitle: primaryData.data.moni.constTitle ? primaryData.data.moni.constTitle : '',
            constName: primaryData.data.moni.constName ? primaryData.data.moni.constName : '',
            constFaculty: primaryData.data.moni.constFaculty ? primaryData.data.moni.constFaculty : '',
            mobile: primaryData.data.moni.mobile ? primaryData.data.moni.mobile : '',
            constHospital: primaryData.data.moni.constHospital ? primaryData.data.moni.constHospital : '',
            subTotal: primaryData.data.moni.subTotal,
            discAmount: primaryData.data.moni.discAmount,
            paidAmount: primaryData.data.moni.paidAmount,
            dueAmount: primaryData.data.moni.dueAmount,
            age_dd: primaryData.data.moni.age_dd ? primaryData.data.moni.age_dd : 0,
            age_mm: primaryData.data.moni.age_mm ? primaryData.data.moni.age_mm : 0,
            age_yy: primaryData.data.moni.age_yy ? primaryData.data.moni.age_yy : 0,
            email: primaryData.data.moni.email ? primaryData.data.moni.email : ''
        });
    };
    const handleRowUpdateCard = (updatedRows) => {
        // setRows(updatedRows);
        setTempVals(updatedRows);
    };
    const handleRowUpdate = (updatedRow) => {
        const updatedRows = rows.map((row) => {
            if (row.id === updatedRow.id) {
                return updatedRow;
            }
            return row;
        });

        setTempVals(updatedRows);
    };

    const handleCellEdit = (ankita) => {
        const id = ankita.id;
        const { field, value } = ankita;

        if (field === 'comment') {
            // Handle the cell content update using editorContent state
            const updatedComment = editorContent;
            const updatedRow = { ...ankita.row, [field]: updatedComment };
            // Update the DataGrid rows
            setTempVals((prevRows) => prevRows.map((row) => (row.id === id ? updatedRow : row)));
        } else {
            // Handle other field updates
            var vals = new Object();
            vals[field] = value;
            axiosInstance.patch(`/updateTestMaster/${id}`, vals);
        }
    };

    const handleOkButtonClick = () => {
        if (selectedCell && selectedCell.id) {
            // Step 3: Save the editorContent to the corresponding cell
            const updatedRow = { ...tempVals.find((row) => row.id === selectedCell.id) };
            updatedRow.comment = editorContent;

            // Update the DataGrid rows
            setTempVals((prevRows) => prevRows.map((row) => (row.id === selectedCell.id ? updatedRow : row)));
            var vals = new Object();
            vals['comment'] = editorContent;
            // axiosInstance.patch(`/updateTestMaster/${selectedCell.id}`, vals);
            // console.log(`/updateTestMaster/${selectedCell.id}`, vals);
            // Clear the selectedCell state to close the CKEditor
            setSelectedCell(null);
        }
    };
    const handleCancelButtonClick = () => {
        setSelectedCell(null);
    };
    const handleEditorChange = (event, editor) => {
        const updatedContent = editor.getData();
        setEditorContent(updatedContent);
    };

    return (
        <>
            {/* <PageTitleBar
                title={'Quick Result'}
                match={props.match}
            /> */}
            <RctCollapsibleCard fullBlock>
                <div className="row py-15 px-15 border-bottom">
                    {/* <div>
                        <a href="#" onClick={(e) => opnAddNewItemModal(e)} className="btn btn-primary d-flex"><AddIcon /> Add New</a>
                    </div> */}
                    <div className="col-lg-5">
                        <IconButton color="primary" aria-label="Add">
                            <AddIcon onClick={handleAddClick} />
                        </IconButton>
                        <IconButton color="primary" aria-label="Back">
                            <ArrowBackIosIcon onClick={() => handleNavigationClick('backWard', itemDetails.labNo)} />
                        </IconButton>
                        <IconButton color="primary" aria-label="Forward">
                            <ArrowForwardIosIcon onClick={() => handleNavigationClick('forWard', itemDetails.labNo)} />
                        </IconButton>
                        <IconButton color="primary" aria-label="Exit">
                            <ExitToAppIcon />
                        </IconButton>
                        <IconButton
                            disabled={!print}
                            color="primary"
                            onClick={() => {
                                console.log('datetime is  ', dateTime, 'On is ', on);
                                generalTestPrint(tempVals, settings.data, itemDetails, company.data, selectedRowIds, dateTime, on);
                            }}
                            aria-label="Print"
                        >
                            <PrintIcon />
                        </IconButton>
                        <IconButton color="primary" onClick={(ev) => opnSearchModal(ev)} aria-label="Search">
                            <SearchIcon />
                        </IconButton>
                        {/* <IconButton color="primary" aria-label="Preview" component={Link} to="/app/report"> */}
                        {/* <IconButton color="primary" aria-label="Preview" component={Link} to="/app/report-two"> */}
                        {/* <IconButton color="primary" aria-label="Preview" component={Link} to="/app/report-three"> */}
                        {/* <IconButton color="primary" aria-label="Preview" component={Link} to="/app/report-four"> */}
                        {/* <IconButton color="primary" aria-label="Preview" component={Link} to="/app/report-five"> */}
                        <IconButton
                            color="primary"
                            aria-label="Preview"
                            onClick={() => {
                                //tempVals, settings.data, itemDetails, company.data, selectedRowIds
                                BillCashMemo(tempVals, settings.data, itemDetails, company.data, dateTime, on);
                            }}
                        >
                            {/* <IconButton color="primary" aria-label="Preview" component={Link} to="/app/report-seven"> */}
                            {/* <IconButton color="primary" aria-label="Preview" component={Link} to="/app/report-eight"> */}
                            {/* <IconButton color="primary" aria-label="Preview" component={Link} to="/app/report-nine"> */}
                            <VisibilityIcon />
                        </IconButton>
                    </div>
                    <div className="col-lg-3 text-center">
                        <h1>Quick Result</h1>
                    </div>
                    <div className="col-lg-4 d-flex">
                        <Input
                            className="form-control"
                            type="text"
                            placeholder="Enter search text"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                        <button className="btn btn-primary ml-2">
                            <i className="ti-reload"></i>
                        </button>
                    </div>
                </div>
                <div className="d-flex justify-content-between py-15 px-15 border-bottom">
                    <Form className="row">
                        <div className="col-lg-12">
                            <div className="row">
                                <FormGroup className="col-lg-3">
                                    <LocalizationProvider className="managedate" dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="Date & Time"
                                            inputFormat="DD-MM-YYYY"
                                            format="d-m-Y"
                                            value={dateTime}
                                            onChange={(newDateTime) => {
                                                setDateTime(newDateTime);
                                            }}
                                            renderInput={(params) => <TextField {...params} id="dateTime" size="small" />}
                                        />
                                    </LocalizationProvider>
                                </FormGroup>
                                <FormGroup className="col-lg-2">
                                    <TextField
                                        fullWidth
                                        type="text"
                                        label="Lab No"
                                        value={itemDetails.labNo}
                                        onChange={(e) => onChangeItemDetails('labNo', e.target.value)}
                                        id="labNo"
                                        size="small"
                                    />
                                </FormGroup>
                                <FormGroup className="col-lg-2">
                                    <TextField
                                        fullWidth
                                        type="text"
                                        label="Ladger No"
                                        value={itemDetails.ledgerNo}
                                        onChange={(e) => onChangeItemDetails('ledgerNo', e.target.value)}
                                        id="ledgerNo"
                                        size="small"
                                    />
                                </FormGroup>
                                <FormGroup className="col-lg-2">
                                    <TextField
                                        fullWidth
                                        type="text"
                                        label="Sample Collected Form"
                                        value={itemDetails.sampleCollectedForm}
                                        onChange={(e) => onChangeItemDetails('sampleCollectedForm', e.target.value)}
                                        id="sampleCollectedForm"
                                        size="small"
                                    />
                                </FormGroup>
                                <FormGroup className="col-lg-3">
                                    <LocalizationProvider className="managedate" dateAdapter={AdapterDayjs}>
                                        <DateTimePicker
                                            label="On"
                                            // inputFormat="DD/MM/YYYY hh:mm a"
                                            inputFormat="DD-MM-YYYY"
                                            value={on}
                                            onChange={(newOn) => {
                                                setOn(newOn);
                                            }}
                                            renderInput={(params) => <TextField {...params} id="on" size="small" />}
                                        />
                                    </LocalizationProvider>
                                </FormGroup>
                                {/* <FormGroup className="col-lg-1">
                                    <Button variant="contained" className="text-white btn-primary" onClick={(e) => opnAddNewItemModal(e)}>
                                        C.P.O.
                                    </Button>
                                </FormGroup> */}
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="mngdevider">
                                <h3 className="mngtitle">Patient Details</h3>
                                <div className="mngradio">
                                    <div className="row">
                                        <div className="col-lg-6 col-6">
                                            <Label className="ml-20 mb-10">
                                                <Input
                                                    type="radio"
                                                    name="test"
                                                    defaultValue="yes"
                                                    checked={itemDetails.new === 'yes'}
                                                    onChange={(e) => onChangeItemDetails('new', e.target.value)}
                                                    id="new"
                                                />{' '}
                                                New
                                            </Label>
                                        </div>
                                        <div className="col-lg-6 col-6">
                                            <Label className="ml-20 mb-10">
                                                <Input
                                                    type="radio"
                                                    name="test"
                                                    defaultValue="no"
                                                    checked={itemDetails.new === 'no'}
                                                    onChange={(e) => onChangeItemDetails('new', e.target.value)}
                                                    id="old"
                                                />{' '}
                                                Old
                                            </Label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-2 col-4 mb-10">
                                        <FormControl sx={{ minWidth: '100%' }} size="small">
                                            <InputLabel id="title">Mr</InputLabel>
                                            <Select
                                                id="title"
                                                value={itemDetails.title}
                                                label="Mr."
                                                onChange={(e) => onChangeItemDetails('title', e.target.value)}
                                            >
                                                <MenuItem value={'Mr.'}>Mr.</MenuItem>
                                                <MenuItem value={'Mrs.'}>Mrs.</MenuItem>
                                                <MenuItem value={'Miss.'}>Miss.</MenuItem>
                                                <MenuItem value={'Master.'}>Master.</MenuItem>
                                                <MenuItem value={'Baby.'}>Baby.</MenuItem>
                                                <MenuItem value={'Md.'}>Md.</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <Tooltip title={!save ? 'You cannot edit this field' : ''} placement="bottom">
                                        <div className="col-lg-4 col-8 mb-10">
                                            <TextField
                                                // disabled={!isNameEditible || !save}
                                                disabled={!isNameEditible ? !save : false}
                                                fullWidth
                                                type="text"
                                                label="Name"
                                                value={itemDetails.name}
                                                onChange={(e) => onChangeItemDetails('name', e.target.value)}
                                                id="name"
                                                size="small"
                                            />
                                        </div>
                                    </Tooltip>
                                    <div className="col-lg-2 col-8 mb-10">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="age_yy"
                                            value={itemDetails.age_yy}
                                            onChange={(e) => onChangeItemDetails('age_yy', e.target.value)}
                                            id="age_yy"
                                            size="small"
                                        />
                                    </div>
                                    <div className="col-lg-2 col-8 mb-10">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="age_mm"
                                            value={itemDetails.age_mm}
                                            onChange={(e) => onChangeItemDetails('age_mm', e.target.value)}
                                            id="age_mm"
                                            size="small"
                                        />
                                    </div>
                                    <div className="col-lg-2 col-8 mb-10">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Date"
                                            value={itemDetails.age_dd}
                                            onChange={(e) => onChangeItemDetails('age_dd', e.target.value)}
                                            id="age_dd"
                                            size="small"
                                        />
                                    </div>
                                    {/* <div className='col-lg-6 mb-10'>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                label="Age"
                                                inputFormat="DD-MM-YYYY"
                                                value={age}
                                                onChange={(newAge) => {
                                                    setAge(newAge);
                                                }}
                                                renderInput={(params) => <TextField fullWidth {...params} size="small" 
                                                id="age" />}
                                            />
                                        </LocalizationProvider>
                                    </div> */}
                                    <div className="col-lg-6 mb-10">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Phone No"
                                            value={itemDetails.mobile}
                                            onChange={(e) => onChangeItemDetails('mobile', e.target.value)}
                                            id="mobile"
                                            size="small"
                                            style={{ borderRadius: '5px !important', backgroundColor: 'red !important' }}
                                        />
                                    </div>
                                    <div className="col-lg-3 mb-10">
                                        <FormControl sx={{ minWidth: '100%' }} size="small">
                                            <InputLabel id="gender">Gender</InputLabel>
                                            <Select
                                                id="gender"
                                                value={itemDetails.gender}
                                                label="Gender"
                                                onChange={(e) => onChangeItemDetails('gender', e.target.value)}
                                            >
                                                <MenuItem value={'Male'}>Male</MenuItem>
                                                <MenuItem value={'Female'}>Female</MenuItem>
                                                <MenuItem value={'Transgender'}>Transgender</MenuItem>
                                                <MenuItem value={'Other'}>Other</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-lg-2 mb-10">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={inPatient}
                                                    onChange={(event) => setInPatient(event.target.checked)}
                                                    // onChange={(e) =>
                                                    //     onChangeItemDetails("inPatient", e.target.value)}
                                                />
                                            }
                                            label="In Patient"
                                        />
                                    </div>
                                    <div className="col-lg-6 mb-10">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Address"
                                            value={itemDetails.address}
                                            onChange={(e) => onChangeItemDetails('address', e.target.value)}
                                            id="address"
                                            size="small"
                                        />
                                    </div>
                                    <div className="col-lg-6 mb-10">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Email"
                                            value={itemDetails.email}
                                            onChange={(e) => onChangeItemDetails('email', e.target.value)}
                                            id="email"
                                            size="small"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="mngdevider">
                                <h3 className="mngtitle">Consultant Details</h3>
                                <div className="row">
                                    <div className="col-lg-2 col-4 mb-10">
                                        <FormControl sx={{ minWidth: '100%' }} size="small">
                                            <InputLabel id="constTitle">Mr</InputLabel>
                                            <Select
                                                id="constTitle"
                                                value={itemDetails.constTitle}
                                                label="Mr."
                                                onChange={(e) => onChangeItemDetails('constTitle', e.target.value)}
                                            >
                                                <MenuItem value={'Mr.'}>Mr.</MenuItem>
                                                <MenuItem value={'Mrs.'}>Mrs.</MenuItem>
                                                <MenuItem value={'Miss.'}>Miss.</MenuItem>
                                                <MenuItem value={'Master.'}>Master.</MenuItem>
                                                <MenuItem value={'Baby.'}>Baby.</MenuItem>
                                                <MenuItem value={'Md.'}>Md.</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-lg-4 col-8 mb-10">
                                        <FormControl sx={{ minWidth: '100%' }} size="small">
                                            <InputLabel id="constName">consultant Name</InputLabel>
                                            <Select
                                                id="constName"
                                                value={itemDetails.constName}
                                                label="Mr."
                                                onChange={(e) => onChangeItemDetails('constName', e.target.value)}
                                            >
                                                {consultants}
                                            </Select>
                                        </FormControl>
                                        {/* consultants
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Consultant Name"
                                            value={itemDetails.constName}
                                            onChange={(e) => onChangeItemDetails('constName', e.target.value)}
                                            id="constName"
                                            size="small"
                                        /> */}
                                        {/* <Select
                                            id="constName"
                                            value={itemDetails.constName}
                                            label="Mr."
                                            onChange={(e) => onChangeItemDetails('constName', e.target.value)}
                                        >
                                            {consultants}
                                        </Select> */}
                                    </div>

                                    <div className="col-lg-6 mb-10">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="constDesignation"
                                            value={itemDetails.constDesignation}
                                            onChange={(e) => onChangeItemDetails('constDesignation', e.target.value)}
                                            id="constDesignation"
                                            size="small"
                                        />
                                    </div>
                                    {/* yahi par consultant name tha */}
                                    <div className="col-lg-6 mb-10">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Consultant Id"
                                            value={itemDetails.constId}
                                            onChange={(e) => onChangeItemDetails('constId', e.target.value)}
                                            id="constId"
                                            size="small"
                                        />
                                    </div>
                                    <div className="col-lg-6 mb-10">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="constFaculty"
                                            value={itemDetails.constFaculty}
                                            onChange={(e) => onChangeItemDetails('constFaculty', e.target.value)}
                                            id="constFaculty"
                                            size="small"
                                        />
                                    </div>
                                    {/* <div className="col-lg-6 mb-10">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Phone No"
                                            value={itemDetails.mobile}
                                            onChange={(e) => onChangeItemDetails('mobile', e.target.value)}
                                            id="mobile"
                                            size="small"
                                        />
                                    </div> */}
                                    <div className="col-lg-6 mb-10">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="const Hospital"
                                            value={itemDetails.constHospital}
                                            onChange={(e) => onChangeItemDetails('constHospital', e.target.value)}
                                            id="constHospital"
                                            size="small"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-2 mb-10">
                            <Button variant="contained" className="text-white btn-success w-100" onClick={(ev) => opnAddTest(ev)}>
                                Add Test
                            </Button>
                            {isMobileView ? (
                                <Button variant="contained" className="text-white btn-warning w-100" onClick={() => setIsMobileView(false)}>
                                    Change To Dekstop View
                                </Button>
                            ) : (
                                <Button variant="contained" className="text-white btn-warning w-100" onClick={() => setIsMobileView(true)}>
                                    Change To Mobile View
                                </Button>
                            )}
                        </div>
                        <div className="col-lg-1 mb-10">
                            <TextField
                                fullWidth
                                type="text"
                                label="T"
                                value={itemDetails.performT}
                                onChange={(e) => onChangeItemDetails('performT', e.target.value)}
                                id="performT"
                                size="small"
                            />
                        </div>
                        <div className="col-lg-1 mb-10">
                            <TextField
                                fullWidth
                                type="text"
                                label="ST"
                                value={itemDetails.performSt}
                                onChange={(e) => onChangeItemDetails('performSt', e.target.value)}
                                id="performSt"
                                size="small"
                            />
                        </div>
                        <div className="col-lg-8">
                            <div className="mngdevider">
                                <h3 className="mngtitle">Account Details</h3>
                                <div className="row">
                                    <div className="col-lg-3 mb-10">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="subTotal"
                                            value={itemDetails.subTotal}
                                            // onChange={(e) => onChangeItemDetails('subTotal', e.target.value)}
                                            id="subTotal"
                                            size="small"
                                        />
                                    </div>
                                    <div className="col-lg-3 mb-10">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Disc Amount"
                                            value={itemDetails.discAmount}
                                            onChange={(e) => {
                                                onChangeItemDetails('discAmount', e.target.value);
                                                // calculate('discAmount');
                                            }}
                                            // onBlur={() => calculate('discAmount')}
                                            id="discAmount"
                                            size="small"
                                        />
                                    </div>
                                    <div className="col-lg-3 mb-10">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Paid Amount"
                                            value={itemDetails.paidAmount}
                                            onChange={(e) => onChangeItemDetails('paidAmount', e.target.value)}
                                            id="paidAmount"
                                            size="small"
                                        />
                                    </div>
                                    <div className="col-lg-3 mb-10">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Dues Amount"
                                            value={itemDetails.dueAmount}
                                            id="dueAmount"
                                            size="small"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
                <div className="table-responsive">
                    {/* <GridToCard rows={rows} columns={columns} onUpdate={handleRowUpdateCard} /> */}

                    {isMobileView ? (
                        <GridToCard rows={rows} columns={columnsForMobile} onUpdate={handleRowUpdate} />
                    ) : (
                        <Box
                            sx={{
                                bgcolor: 'aliceblue',
                                height: 650,
                                width: '100%',
                                '& .result--cell': {
                                    backgroundColor: 'rgba(224, 183, 60, 0.55)',
                                    color: '#1a3e72'
                                },
                                '& .resultN--cell': {
                                    bgcolor: 'beige',
                                    color: '#1a3e72'
                                },
                                '& .resultC--cell': {
                                    bgcolor: 'thistle',
                                    color: '#1a3e72'
                                },
                                '& .resultP--cell': {
                                    bgcolor: 'khaki',
                                    color: '#1a3e72'
                                },
                                '& .MuiDataGrid-cell:focus': {
                                    // color: 'primary.main',
                                    backgroundColor: 'primary.main',
                                    minHeight: '35px !important',
                                    maxHeight: '35px !important'
                                }
                            }}
                        >
                            {/* onCellKeyDown={handleCellKeyDown}  */}
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                onCellEditCommit={handleOnAddItem}
                                onRowEditStart={handleCellKeyDown}
                                onRowClick={handleRowClick}
                                onRowSelected={handleRowSelected}
                                // onCellEditCommit={handleCellEdit}

                                onCellClick={handleCellClick}
                                //adding code to select whole row.
                                // onCellClick={handleCellClick}
                                onSelectionModelChange={handleSelectionModelChange}
                                selectionModel={selectedRowIds}
                                checkboxSelection
                                disableSelectionOnClick
                                ref={dataGridRef}
                                // disableRowSelectionOnClick={false}
                                //
                                getRowClassName={(params) => {
                                    if (params.row.type == 'Multiple') {
                                        return 'resultC--cell';
                                    }
                                    if (params.row.type == 'Panel') {
                                        return 'resultP--cell';
                                    }
                                }}
                                getCellClassName={(params) => {
                                    if (params.field === 'type') {
                                        return 'result--cell';
                                    }
                                }}
                            />
                            {selectedCell && (
                                <div
                                    style={{
                                        position: 'fixed',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        background: 'rgba(0, 0, 0, 0.5)',
                                        maxHeight: '80vh', // Limit the editor's height for scrolling
                                        overflow: 'auto', // Enable scrolling within the container
                                        zIndex: 9999
                                    }}
                                    // style={{
                                    //     position: 'absolute', // or 'relative' depending on your layout
                                    //     top: '50%', // Adjust the vertical positioning as needed
                                    //     left: '50%',
                                    //     transform: 'translate(-50%, -50%)', // Center the editor
                                    //     width: '100%', // Adjust the width as needed
                                    //     maxHeight: '80vh', // Limit the editor's height for scrolling
                                    //     overflow: 'auto', // Enable scrolling within the container
                                    //     background: '#fff',
                                    //     borderRadius: 4,
                                    //     zIndex: 10001
                                    // }}
                                >
                                    <div
                                        style={{
                                            maxWidth: 600,
                                            margin: '100px auto',
                                            padding: 20,
                                            background: '#fff',
                                            borderRadius: 4,
                                            zIndex: 10001
                                        }}
                                    >
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={selectedCell.comment} // If you want to show the existing comment in the CKEditor
                                            onChange={handleEditorChange}
                                        />
                                        <button onClick={handleOkButtonClick}>OK</button>
                                        <button onClick={handleCancelButtonClick}>Cancel</button>
                                    </div>
                                </div>
                            )}
                        </Box>
                    )}
                </div>
            </RctCollapsibleCard>
            <Button disabled={!update} variant="contained" className="text-white ml-2 my-3 btn-primary" onClick={handleUpdate}>
                Update
            </Button>
            <Button
                variant="contained"
                disabled={!save}
                style={{
                    backgroundColor: save ? '#28a745' : '#dcdcdc !important',
                    color: save ? 'white' : '#a8a8a8 !important'
                }}
                className="text-white mx-2 my-3 btn-success"
                onClick={handleSave}
            >
                Save
            </Button>
            {/* {save ? (
                <Button variant="contained" className="text-white mx-2 my-3 btn-success" onClick={handleSave}>
                    Save
                </Button>
            ) : null} */}

            <Button variant="contained" className="text-white my-3 btn-danger" onClick={() => onAddUpdateItemModalClose()}>
                Cancel
            </Button>
            <AddNewUserForm
                editItem={editItem}
                setEditItem={setEditItem}
                addNewItemModal={addNewItemModal}
                setAddNewItemModal={setAddNewItemModal}
                loadItems={loadItems}
            />

            <AddTest addNewItemModal={addNewItemModal2} setAddNewItemModal={setAddTest} loadItems={loadItems2} />
            <WidalTest addNewItemModal={showWidal} setAddNewItemModal={setShowWidal} widalOk={widalOk} />
            <CultureResult
                addNewItemModal={showCulture}
                setAddNewItemModal={setShowCulture}
                handleCultureOk={handleCultureOk}
                cultureChemicals={cultureChemicals}
                chemicalsOnePlus={chemicalsOnePlus}
                chemicalsTwoPlus={chemicalsTwoPlus}
                chemicalsThreePlus={chemicalsThreePlus}
                chemicalsFourPlus={chemicalsFourPlus}
                chemicalsR={chemicalsR}
                chemicalsS={chemicalsS}
                handleCultureTransfer={handleCultureTransfer}
            />
            <SearchModal
                editItem={editItem}
                setEditItem={setEditItem}
                searchModal={searchModal}
                setSearchModal={setSearchModal}
                loadItems={loadItems}
                onRecordClick={handleRecordClick}
            />
        </>
    );
}
