import React, { useState, useEffect } from 'react';
import { FormGroup } from 'reactstrap';
import { RadioGroup, Radio, Button, Tabs, Tab, Typography, Box, Checkbox, FormControlLabel, FormControl, TextField } from '@mui/material';
// tabs
import PropTypes from 'prop-types';
import { BeatLoader } from 'react-spinners';
import axiosInstance from '../../api/axiosInstance';
import { css } from '@emotion/react';

// api
// import RctSectionLoader from '../../../component/RctSectionLoader/RctSectionLoader';

// rct card box
import RctCollapsibleCard from '../../component/RctCollapsibleCard/RctCollapsibleCard';
import { useFetchSettings } from '../../commonFunctions/commonFunction';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

// Date
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

// tabs start
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}
//tabs end
const spinnerStyles = css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
`;

const Setting = (props) => {
    // const [dateTime, setDateTime] = React.useState(null);
    // const [age, setAge] = React.useState(null);

    const boxTitle = {
        position: 'absolute',
        fontSize: '1rem',
        fontWeight: 500,
        top: -12,
        left: 10,
        backgroundColor: 'white'
    };

    const [itemDetails, setItemDetails] = useState({
        ledgerGenration: '',
        paper: '',
        headergap: '',
        footergap: '',
        xrayResultAlignment: '',
        labAlias: '',
        childAge: '',
        AutoGenerationOfPatientId: '',
        autoGenerationOfLedger: '',
        cultureFristElement: '',
        // gapForHeader: '',
        gapBetweenHeader: '',
        patientDetailsFont: '',
        rectangleOverPatientDetails: '',
        linesOverPatientDetails: '',
        gapForContent: '',
        gapBetweenTest: '',
        gapBetweenSubTest: '',
        // gapForFooter: '',
        gapForDoctorName: '',
        testHeaderFont: '',
        testNameFont: '',
        // commentNoteFont: '',
        testNamePositin: '',
        subtestPosition: '',
        resultPosition: '',
        unitPosition: '',
        refRangePosition: '',
        resultEndPosition: '',
        cutsAgentNotConst: '',
        cutsAgent: '',
        cutsConst: '',
        cutsDiscNotConst: '',
        cutsDiscNotAgent: '',
        searchTestName: '',
        xrayTest: '',
        ctscanTest: '',
        ultraSoundTest: '',
        mriTest: '',
        agentPathologyCuts: '',
        agentXrayCuts: '',
        agentCtscanCuts: '',
        agentUltraCuts: '',
        agentMriCuts: '',
        constPathologyCuts: '',
        constXrayCuts: '',
        constCtscanCuts: '',
        constMriCuts: '',
        rectangleResult: '',
        printOption: '',
        allInPerformTest: '',
        unitwithresult: '',
        rectanglePatient: '',
        xrayHeader: '',
        xrayFooter: '',
        acceptaddress: '',
        acceptsample: '',
        autoledger: '',
        acceptprint: '',
        contentGap: '',
        testpos: '',
        resultpos: '',
        printpanel: '',
        panelpos: '',
        panelOnePage: '',
        panelNextPage: '',
        unitpos: '',
        normalpos: '',
        resultunitpos: '',
        resultendpos: '',
        xrayresultpos: '',
        notefont: '',
        page: '',
        acceptPhone: '',
        acceptEmail: '',
        labprint: '',
        routine: '',
        normalwithunit: '',
        linetest: '',
        testgap: '',
        fixedfooter: '',
        boldPatientName: '',
        boldDoctorName: '',
        boldLabRef: '',
        boldMultipleTest: '',
        boldPanelHeader: '',
        subtestGap: '',
        printaftersave: '',
        orrbold: '',
        orrSymbol: '',
        orrcolor: '',
        cashMemoAddressX: '',
        arrowdown: '',
        cashMemoAddressXdown: '',
        subtestpos: '',
        linespatient: '',
        prefixsymbol: '',
        boldmiscresult: '',
        hemounit: '',
        sample: '',
        ledgerGeneration: '',
        initialX: '',
        finalX: '',
        testLineR: '',
        resultLineR: '',
        unitLineR: '',
        commentHeaderPos: '',
        commentPos: '',
        testNameHeaderPos: '',
        resultHeaderPos: '',
        unitHeaderPos: '',
        normalHeaderPos: '',
        gapBetweenPanelAndHeader: '',
        gapForStartingTest: '',
        gapForPanelHeader: '',
        commentHeaderFontSize: '',
        commentFontSize: '',
        fontsize: '',
        logoPos: '',
        logoWidth: '',
        logoHeight: '',
        mobileNoPos: '',
        addressPos: '',
        emailPos: '',
        logoGap: '',
        companyGap: '',
        mobileNoGap: '',
        addressGap: '',
        emailGap: '',
        companyFontName: '',
        companyFontSize: '',
        addressFontName: '',
        addressFontSize: '',
        mobileNoFontName: '',
        mobileNoFontSize: '',
        emailFontName: '',
        emailFontSize: '',
        actionOnAbnormalResult: '',
        nameEdit: 'false'
    });

    const [value, setValue] = React.useState(0);
    const [posting, setPosting] = useState(false);

    const { data: settings, isLoading: isSettingsLoading, isError, error, isSuccess } = useFetchSettings();
    const valuesObject = {};
    React.useEffect(() => {
        if (isSettingsLoading || !settings) {
            return;
        }
        settings.data.forEach((item) => {
            valuesObject[item.nameOption] = item.value;
        });

        setItemDetails(valuesObject);
        // console.log(valuesObject);
    }, [settings]);
    useEffect(() => {
        const postData = async () => {
            try {
                // Make a POST request to the backend
                saveSettingDetails();
                // Data sent successfully
                // console.log('Data sent successfully!');
            } catch (error) {
                // Error occurred while sending data
                console.error('Error occurred while sending data:', error.message);
            }

            setPosting(false);
        };

        if (posting) {
            postData();
        }
    }, [posting]);
    if (isSettingsLoading || !settings) {
        return <div>Loading...</div>;
    }
    const saveSettingLoading = () => {
        setPosting(true);
    };
    const saveSettingDetails = async () => {
        // e.preventDefault();
        try {
            const response = await axiosInstance.patch('/updateSetting', itemDetails);
            // console.log(response);

            // setItemDetails({ ...itemDetails, ledgerNo: response.data.ledgerNo, labNo: 'Lab-' + zeroPad(response.data.ledgerNo, 4) });
        } catch (error) {
            console.error('Error occurred while updating company master:', error.message);
        }

        // axiosInstance.patch(`/updateCompanyMaster`, itemDetails).then((response) => {
        //     // setItemDetails({ ...itemDetails, ledgerNo: response.data.ledgerNo, labNo: 'Lab-' + zeroPad(response.data.ledgerNo, 4) });
        // });
    };
    // settings.data.map((item, i) => {
    //     items[item.nameOption] = item.value;
    // });

    const onChangeItemDetails = (key, value) => {
        var arr = { ...itemDetails };
        arr[key] = value;
        setItemDetails((prevState) => arr);
    };

    const handleOnAddItem = (e) => {
        e.preventDefault();
        // axios.get("ec2-15-206-84-254.ap-south-1.compute.amazonaws.com:3000/api/setting/setting").then((response) => console.log("response",response));

        // axios
        // .post(`${baseUrl}/api/setting/`,
        // )
        // .then((response) => {
        //     // successfully uploaded response
        // })
        // .catch((error) => {
        //     // error response
        // });
    };

    // tabs start

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    // tabs end

    return (
        <>
            <RctCollapsibleCard fullBlock>
                <div className="d-flex justify-content-between py-20 px-15 border-bottom">
                    <div className="d-flex">
                        <h1>Setting</h1>
                    </div>
                </div>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Patholory Master" {...a11yProps(0)} />
                            {/* <Tab label="Item Two" {...a11yProps(1)} />
                            <Tab label="Item Three" {...a11yProps(2)} /> */}
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <div className="row">
                            <div className="col-lg-4 mt-3">
                                <Box
                                    sx={{
                                        border: 2,
                                        borderColor: '#888888',
                                        py: 1,
                                        px: 2,
                                        borderRadius: 0,
                                        position: 'relative'
                                    }}
                                >
                                    <Typography sx={boxTitle}>Ledger Genration</Typography>
                                    <FormControl>
                                        <RadioGroup
                                            row
                                            aria-labelledby="ledgerGenration"
                                            value={itemDetails.ledgerGenration}
                                            onChange={(e) => onChangeItemDetails('ledgerGenration', e.target.value)}
                                        >
                                            <FormControlLabel
                                                value="Year Wise"
                                                control={<Radio color="secondary" sx={{ p: '2px', ml: '5px' }} size="small" />}
                                                label="Year Wise"
                                            />
                                            <FormControlLabel
                                                value="Month Wise"
                                                control={<Radio color="secondary" sx={{ p: '2px', ml: '5px' }} size="small" />}
                                                label="Month Wise"
                                            />
                                            <FormControlLabel
                                                value="Day Wise"
                                                control={<Radio color="secondary" sx={{ p: '2px', ml: '5px' }} size="small" />}
                                                label="Day Wise"
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Box>
                                {/* <div className="mngdevider">
                                    <h3 className="mngtitle">Ledger Genration</h3>
                                    <FormControl color="secondary">
                                        <RadioGroup
                                            row
                                            aria-labelledby="ledgerGenration"
                                            value={itemDetails.ledgerGenration}
                                            onChange={(e) => onChangeItemDetails('ledgerGenration', e.target.value)}
                                        >
                                            <FormControlLabel
                                                value="Year Wise"
                                                control={<Radio color="secondary" sx={{ p: '2px', ml: '5px' }} />}
                                                label="Year Wise"
                                            />
                                            <FormControlLabel
                                                value="Month Wise"
                                                control={<Radio color="secondary" sx={{ p: '2px', ml: '5px' }} />}
                                                label="Month Wise"
                                            />
                                            <FormControlLabel
                                                value="Day Wise"
                                                control={<Radio color="secondary" sx={{ p: '2px', ml: '5px' }} />}
                                                label="Day Wise"
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </div> */}
                            </div>
                            <div className="col-lg-4 mt-3">
                                <Box
                                    sx={{
                                        border: 2,
                                        borderColor: '#888888',
                                        py: 1,
                                        px: 2,
                                        borderRadius: 0,
                                        position: 'relative'
                                    }}
                                >
                                    <Typography sx={boxTitle}>Default Printing Option</Typography>
                                    <FormControl>
                                        <RadioGroup
                                            row
                                            aria-labelledby="paper"
                                            value={itemDetails.paper}
                                            onChange={(e) => onChangeItemDetails('paper', e.target.value)}
                                        >
                                            <FormControlLabel
                                                value="On Blank Paper"
                                                control={<Radio color="secondary" sx={{ p: '2px', ml: '5px' }} size="small" />}
                                                label="On Blank Paper"
                                            />
                                            <FormControlLabel
                                                value="On Printed Paper"
                                                control={<Radio color="secondary" sx={{ p: '2px', ml: '5px' }} size="small" />}
                                                label="On Printed Paper"
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Box>
                                {/* <div className="mngdevider">
                                    <h3 className="mngtitle">Default Printing Option</h3>
                                    <FormControl color="secondary">
                                        <RadioGroup
                                            row
                                            aria-labelledby="paper"
                                            value={itemDetails.paper}
                                            onChange={(e) => onChangeItemDetails('paper', e.target.value)}
                                        >
                                            <FormControlLabel
                                                value="On Blank Paper"
                                                control={<Radio color="secondary" sx={{ p: '2px', ml: '5px' }} />}
                                                label="On Blank Paper"
                                            />
                                            <FormControlLabel
                                                value="On Printed Paper"
                                                control={<Radio color="secondary" sx={{ p: '2px', ml: '5px' }} />}
                                                label="On Printed Paper"
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </div> */}
                            </div>
                            <div className="col-lg-4 mt-3">
                                <Box
                                    sx={{
                                        border: 2,
                                        borderColor: '#888888',
                                        py: 1,
                                        px: 2,
                                        borderRadius: 0,
                                        position: 'relative'
                                    }}
                                >
                                    <Typography sx={boxTitle}>Xray Result Alignment</Typography>
                                    <FormControl>
                                        <RadioGroup
                                            row
                                            aria-labelledby="xrayResultAlignment"
                                            value={itemDetails.xrayResultAlignment}
                                            onChange={(e) => onChangeItemDetails('xrayResultAlignment', e.target.value)}
                                        >
                                            <FormControlLabel
                                                value="Left"
                                                control={<Radio color="secondary" sx={{ p: '2px', ml: '5px' }} size="small" />}
                                                label="Left"
                                            />
                                            <FormControlLabel
                                                value="Middle"
                                                control={<Radio color="secondary" sx={{ p: '2px', ml: '5px' }} size="small" />}
                                                label="Middle"
                                            />
                                            <FormControlLabel
                                                value="Right"
                                                control={<Radio color="secondary" sx={{ p: '2px', ml: '5px' }} size="small" />}
                                                label="Right"
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Box>
                                {/* <div className="mngdevider">
                                    <h3 className="mngtitle">Xray Result Alignment</h3>
                                    <FormControl color="secondary">
                                        <RadioGroup
                                            row
                                            aria-labelledby="xrayResultAlignment"
                                            value={itemDetails.xrayResultAlignment}
                                            onChange={(e) => onChangeItemDetails('xrayResultAlignment', e.target.value)}
                                        >
                                            <FormControlLabel
                                                value="Left"
                                                control={<Radio color="secondary" sx={{ p: '2px', ml: '5px' }} />}
                                                label="Left"
                                            />
                                            <FormControlLabel
                                                value="Middle"
                                                control={<Radio color="secondary" sx={{ p: '2px', ml: '5px' }} />}
                                                label="Middle"
                                            />
                                            <FormControlLabel
                                                value="Right"
                                                control={<Radio color="secondary" sx={{ p: '2px', ml: '5px' }} />}
                                                label="Right"
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </div> */}
                            </div>
                            <div className="col-lg-4 mt-3">
                                <Box
                                    sx={{
                                        border: 5,
                                        borderColor: '#888888',
                                        py: 2,
                                        px: 2,
                                        borderRadius: 0,
                                        position: 'relative'
                                    }}
                                >
                                    <Typography sx={boxTitle}>Initial Gaps</Typography>
                                    <div className="row">
                                        <TextField
                                            className="col-lg-6"
                                            InputLabelProps={{
                                                style: { color: '#000', fontWeight: 500 }
                                            }}
                                            fullWidth
                                            type="text"
                                            label="Gap For Header"
                                            value={itemDetails.headergap}
                                            onChange={(e) => onChangeItemDetails('headergap', e.target.value)}
                                            id="headergap"
                                            size="small"
                                        />
                                        <TextField
                                            className="col-lg-6"
                                            InputLabelProps={{
                                                style: { color: '#000', fontWeight: 500 }
                                            }}
                                            fullWidth
                                            type="text"
                                            label="Gap for Footer"
                                            value={itemDetails.footergap}
                                            onChange={(e) => onChangeItemDetails('footergap', e.target.value)}
                                            id="footergap"
                                            size="small"
                                        />
                                    </div>
                                </Box>
                                {/* <div className="mngdevider">
                                    <h3 className="mngtitle">X-Ray Printing</h3>
                                    <div className="row">
                                        <FormGroup className="col-lg-6">
                                            <TextField
                                                InputLabelProps={{
                                                    style: { color: '#000',fontWeight: 500 },
                                                }}
                                                fullWidth
                                                type="text"
                                                label="Header"
                                                value={itemDetails.headergap}
                                                onChange={(e) => onChangeItemDetails('headergap', e.target.value)}
                                                id="headergap"
                                                size="small"
                                            />
                                        </FormGroup>
                                        <FormGroup className="col-lg-6">
                                            <TextField
                                                InputLabelProps={{
                                                    style: { color: '#000',fontWeight: 500 },
                                                }}
                                                fullWidth
                                                type="text"
                                                label="Footer"
                                                value={itemDetails.footergap}
                                                onChange={(e) => onChangeItemDetails('footergap', e.target.value)}
                                                id="footergap"
                                                size="small"
                                            />
                                        </FormGroup>
                                    </div>
                                </div> */}
                            </div>
                            <div className="col-lg-8 mt-3">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <FormControlLabel
                                            value={itemDetails.autoGenerationOfPatientId}
                                            control={<Checkbox />}
                                            onChange={(e) => onChangeItemDetails('autoGenerationOfPatientId', e.target.value)}
                                            label="Auto Generation of Patient Id"
                                            // labelPlacement="end"
                                        />
                                    </div>
                                    <div className="col-lg-6">
                                        <FormControlLabel
                                            value={itemDetails.rectangleOverPatientDetails}
                                            control={<Checkbox />}
                                            onChange={(e) => onChangeItemDetails('rectangleOverPatientDetails', e.target.value)}
                                            label="Rectangle Over Patient Details"
                                            // labelPlacement="end"
                                        />
                                    </div>
                                    <div className="col-lg-6">
                                        <FormControlLabel
                                            value={itemDetails.autoGenerationOfLedger}
                                            control={<Checkbox />}
                                            onChange={(e) => onChangeItemDetails('autoGenerationOfLedger', e.target.value)}
                                            label="Auto Generation of Ledger"
                                            // labelPlacement="end"
                                        />
                                    </div>
                                    <div className="col-lg-6">
                                        <FormControlLabel
                                            value={itemDetails.linesOverPatientDetails}
                                            control={<Checkbox />}
                                            onChange={(e) => onChangeItemDetails('linesOverPatientDetails', e.target.value)}
                                            label="Lines Over Patient Details"
                                            // labelPlacement="end"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bdrdevider">
                            <span>Banner Setting</span>
                        </div>
                        <div className="row">
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Logo Pos"
                                    value={itemDetails.logoPos}
                                    onChange={(e) => onChangeItemDetails('logoPos', e.target.value)}
                                    id="logoPos"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Logo Gap"
                                    value={itemDetails.logoGap}
                                    onChange={(e) => onChangeItemDetails('logoGap', e.target.value)}
                                    id="logoGap"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="company Pos"
                                    value={itemDetails.companyPos}
                                    onChange={(e) => onChangeItemDetails('companyPos', e.target.value)}
                                    id="companyPos"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="company Gap"
                                    value={itemDetails.companyGap}
                                    onChange={(e) => onChangeItemDetails('companyGap', e.target.value)}
                                    id="companyGap"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Company Font Name"
                                    value={itemDetails.companyFontName}
                                    onChange={(e) => onChangeItemDetails('companyFontName', e.target.value)}
                                    id="companyFontName"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Company Font Size"
                                    value={itemDetails.companyFontSize}
                                    onChange={(e) => onChangeItemDetails('companyFontSize', e.target.value)}
                                    id="companyFontSize"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Email Pos"
                                    value={itemDetails.emailPos}
                                    onChange={(e) => onChangeItemDetails('emailPos', e.target.value)}
                                    id="emailPos"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Email Gap"
                                    value={itemDetails.emailGap}
                                    onChange={(e) => onChangeItemDetails('emailGap', e.target.value)}
                                    id="emailGap"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Email Font Name"
                                    value={itemDetails.emailFontName}
                                    onChange={(e) => onChangeItemDetails('emailFontName', e.target.value)}
                                    id="emailFontName"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Email Font Size"
                                    value={itemDetails.emailFontSize}
                                    onChange={(e) => onChangeItemDetails('emailFontSize', e.target.value)}
                                    id="emailFontSize"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Address Pos"
                                    value={itemDetails.addressPos}
                                    onChange={(e) => onChangeItemDetails('addressPos', e.target.value)}
                                    id="addressPos"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Address Gap"
                                    value={itemDetails.addressGap}
                                    onChange={(e) => onChangeItemDetails('addressGap', e.target.value)}
                                    id="addressGap"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Address Font Name"
                                    value={itemDetails.addressFontName}
                                    onChange={(e) => onChangeItemDetails('addressFontName', e.target.value)}
                                    id="addressFontName"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Address Font Size"
                                    value={itemDetails.addressFontSize}
                                    onChange={(e) => onChangeItemDetails('addressFontSize', e.target.value)}
                                    id="addressFontSize"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Mobile No Pos"
                                    value={itemDetails.mobileNoPos}
                                    onChange={(e) => onChangeItemDetails('mobileNoPos', e.target.value)}
                                    id="mobileNoPos"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Mobile No Gap"
                                    value={itemDetails.mobileNoGap}
                                    onChange={(e) => onChangeItemDetails('mobileNoGap', e.target.value)}
                                    id="mobileNoGap"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Mobile No Font Name"
                                    value={itemDetails.mobileNoFontName}
                                    onChange={(e) => onChangeItemDetails('mobileNoFontName', e.target.value)}
                                    id="mobileNoFontName"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Mobile No Font Size"
                                    value={itemDetails.mobileNoFontSize}
                                    onChange={(e) => onChangeItemDetails('mobileNoFontSize', e.target.value)}
                                    id="mobileNoFontSize"
                                    size="small"
                                />
                            </FormGroup>
                        </div>

                        <div className="bdrdevider">
                            <span>Patient Detail Setting</span>
                        </div>
                        <div className="row">
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Accept Phone"
                                    value={itemDetails.acceptPhone}
                                    onChange={(e) => onChangeItemDetails('acceptPhone', e.target.value)}
                                    id="acceptPhone"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Accept Email"
                                    value={itemDetails.acceptEmail}
                                    onChange={(e) => onChangeItemDetails('acceptEmail', e.target.value)}
                                    id="acceptEmail"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Accept Address"
                                    value={itemDetails.acceptaddress}
                                    onChange={(e) => onChangeItemDetails('acceptaddress', e.target.value)}
                                    id="acceptaddress"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Accept Sample"
                                    value={itemDetails.acceptsample}
                                    onChange={(e) => onChangeItemDetails('acceptsample', e.target.value)}
                                    id="acceptsample"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Accept Print"
                                    value={itemDetails.acceptprint}
                                    onChange={(e) => onChangeItemDetails('acceptprint', e.target.value)}
                                    id="acceptprint"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Auto Ledger"
                                    value={itemDetails.autoledger}
                                    onChange={(e) => onChangeItemDetails('autoledger', e.target.value)}
                                    id="autoledger"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Bold Patient Name"
                                    value={itemDetails.boldPatientName}
                                    onChange={(e) => onChangeItemDetails('boldPatientName', e.target.value)}
                                    id="boldPatientName"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Bold Doctor Name"
                                    value={itemDetails.boldDoctorName}
                                    onChange={(e) => onChangeItemDetails('boldDoctorName', e.target.value)}
                                    id="boldDoctorName"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Bold Lab Ref"
                                    value={itemDetails.boldLabRef}
                                    onChange={(e) => onChangeItemDetails('boldLabRef', e.target.value)}
                                    id="boldLabRef"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Bold Multiple Test"
                                    value={itemDetails.boldMultipleTest}
                                    onChange={(e) => onChangeItemDetails('boldMultipleTest', e.target.value)}
                                    id="boldMultipleTest"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Bold Panel Header"
                                    value={itemDetails.boldPanelHeader}
                                    onChange={(e) => onChangeItemDetails('boldPanelHeader', e.target.value)}
                                    id="boldPanelHeader"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Lines Patient"
                                    value={itemDetails.linespatient}
                                    onChange={(e) => onChangeItemDetails('linespatient', e.target.value)}
                                    id="linespatient"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Prefix Symbol"
                                    value={itemDetails.prefixsymbol}
                                    onChange={(e) => onChangeItemDetails('prefixsymbol', e.target.value)}
                                    id="prefixsymbol"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Name DT"
                                    value={itemDetails.nameEdit}
                                    onChange={(e) => onChangeItemDetails('nameEdit', e.target.value)}
                                    id="prefixsymbol"
                                    size="small"
                                />
                            </FormGroup>
                        </div>
                        <div className="bdrdevider my-4">
                            <span>Test Setting</span>
                        </div>
                        <div className="row">
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Hemo Unit"
                                    value={itemDetails.hemounit}
                                    onChange={(e) => onChangeItemDetails('hemounit', e.target.value)}
                                    id="hemounit"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Sample"
                                    value={itemDetails.sample}
                                    onChange={(e) => onChangeItemDetails('sample', e.target.value)}
                                    id="sample"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                {/* <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Ledger Generation"
                                    value={itemDetails.ledgerGeneration}
                                    onChange={(e) => onChangeItemDetails('ledgerGeneration', e.target.value)}
                                    id="ledgerGeneration"
                                    size="small"
                                /> */}
                                <FormControl sx={{ minWidth: '100%' }} size="small">
                                    <InputLabel id="Ledger Generation-day/month/year">Ledger Generation-day/month/year</InputLabel>
                                    <Select
                                        id="Ledger Generation-day/month/year"
                                        value={itemDetails.ledgerGeneration}
                                        label="Mr."
                                        onChange={(e) => onChangeItemDetails('ledgerGeneration', e.target.value)}
                                    >
                                        <MenuItem value={'day'}>day</MenuItem>
                                        <MenuItem value={'month'}>month</MenuItem>
                                        <MenuItem value={'year'}>year</MenuItem>
                                    </Select>
                                </FormControl>
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="InitialX"
                                    value={itemDetails.initialX}
                                    onChange={(e) => onChangeItemDetails('initialX', e.target.value)}
                                    id="initialX"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="FinalX"
                                    value={itemDetails.finalX}
                                    onChange={(e) => onChangeItemDetails('finalX', e.target.value)}
                                    id="finalX"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Normal Header Pos"
                                    value={itemDetails.normalHeaderPos}
                                    onChange={(e) => onChangeItemDetails('normalHeaderPos', e.target.value)}
                                    id="normalHeaderPos"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Test Gap"
                                    value={itemDetails.testgap}
                                    onChange={(e) => onChangeItemDetails('testgap', e.target.value)}
                                    id="testgap"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Test Pos"
                                    value={itemDetails.testpos}
                                    onChange={(e) => onChangeItemDetails('testpos', e.target.value)}
                                    id="testpos"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Test Header Font"
                                    value={itemDetails.testHeaderFont}
                                    onChange={(e) => onChangeItemDetails('testHeaderFont', e.target.value)}
                                    id="testHeaderFont"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Test Name Header Pos"
                                    value={itemDetails.testNameHeaderPos}
                                    onChange={(e) => onChangeItemDetails('testNameHeaderPos', e.target.value)}
                                    id="testNameHeaderPos"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Test Name Font"
                                    value={itemDetails.testNameFont}
                                    onChange={(e) => onChangeItemDetails('testNameFont', e.target.value)}
                                    id="testNameFont"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Test Name Position"
                                    value={itemDetails.testNamePositin}
                                    onChange={(e) => onChangeItemDetails('testNamePositin', e.target.value)}
                                    id="testNamePositin"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Test LineR"
                                    value={itemDetails.testLineR}
                                    onChange={(e) => onChangeItemDetails('testLineR', e.target.value)}
                                    id="testLineR"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Sub Test Gap"
                                    value={itemDetails.subtestGap}
                                    onChange={(e) => onChangeItemDetails('subtestGap', e.target.value)}
                                    id="subtestGap"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Sub Test Pos"
                                    value={itemDetails.subtestpos}
                                    onChange={(e) => onChangeItemDetails('subtestpos', e.target.value)}
                                    id="subtestpos"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Sub Test Position"
                                    value={itemDetails.subtestPosition}
                                    onChange={(e) => onChangeItemDetails('subtestPosition', e.target.value)}
                                    id="subtestPosition"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Font Size"
                                    value={itemDetails.fontsize}
                                    onChange={(e) => onChangeItemDetails('fontsize', e.target.value)}
                                    id="fontsize"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Result LineR"
                                    value={itemDetails.resultLineR}
                                    onChange={(e) => onChangeItemDetails('resultLineR', e.target.value)}
                                    id="resultLineR"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Result Header Pos"
                                    value={itemDetails.resultHeaderPos}
                                    onChange={(e) => onChangeItemDetails('resultHeaderPos', e.target.value)}
                                    id="resultHeaderPos"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Unit LineR"
                                    value={itemDetails.unitLineR}
                                    onChange={(e) => onChangeItemDetails('unitLineR', e.target.value)}
                                    id="unitLineR"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Unit Header Pos"
                                    value={itemDetails.unitHeaderPos}
                                    onChange={(e) => onChangeItemDetails('unitHeaderPos', e.target.value)}
                                    id="unitHeaderPos"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Gap For Panel Header"
                                    value={itemDetails.gapForPanelHeader}
                                    onChange={(e) => onChangeItemDetails('gapForPanelHeader', e.target.value)}
                                    id="gapForPanelHeader"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Gap Between Panel And Header"
                                    value={itemDetails.gapBetweenPanelAndHeader}
                                    onChange={(e) => onChangeItemDetails('gapBetweenPanelAndHeader', e.target.value)}
                                    id="gapBetweenPanelAndHeader"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Comment Header Font Size"
                                    value={itemDetails.commentHeaderFontSize}
                                    onChange={(e) => onChangeItemDetails('commentHeaderFontSize', e.target.value)}
                                    id="commentHeaderFontSize"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Comment Font Size"
                                    value={itemDetails.commentFontSize}
                                    onChange={(e) => onChangeItemDetails('commentFontSize', e.target.value)}
                                    id="commentFontSize"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Comment Pos"
                                    value={itemDetails.commentPos}
                                    onChange={(e) => onChangeItemDetails('commentPos', e.target.value)}
                                    id="commentPos"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Comment Header Pos"
                                    value={itemDetails.commentHeaderPos}
                                    onChange={(e) => onChangeItemDetails('commentHeaderPos', e.target.value)}
                                    id="commentHeaderPos"
                                    size="small"
                                />
                            </FormGroup>
                            {/* <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Comment & Note Font"
                                    value={itemDetails.commentNoteFont}
                                    onChange={(e) => onChangeItemDetails('commentNoteFont', e.target.value)}
                                    id="commentNoteFont"
                                    size="small"
                                />
                            </FormGroup> */}
                            <FormGroup className="col-lg-2">
                                {/* <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="action On Abnormal Result"
                                    value={itemDetails.commentNoteFont}
                                    onChange={(e) => onChangeItemDetails('actionOnAbnormalResult', e.target.value)}
                                    id="commentNoteFont"
                                    size="small"
                                /> */}
                                <FormControl sx={{ minWidth: '100%' }} size="small">
                                    <InputLabel id="actionOnAbnormalResult">Action On Abnormal Result</InputLabel>
                                    <Select
                                        id="actionOnAbnormalResult"
                                        value={itemDetails.actionOnAbnormalResult}
                                        label="Mr."
                                        onChange={(e) => onChangeItemDetails('actionOnAbnormalResult', e.target.value)}
                                    >
                                        {/* {actionOnAbnormalResult} */}
                                        <MenuItem value={'None'}>None</MenuItem>
                                        <MenuItem value={'Marka'}>Marka</MenuItem>
                                        <MenuItem value={'Bold'}>Bold</MenuItem>
                                        <MenuItem value={'Italic'}>Italic</MenuItem>
                                        <MenuItem value={'Bold & Italic'}>Bold & Italic</MenuItem>
                                    </Select>
                                </FormControl>
                            </FormGroup>
                        </div>
                        <div className="bdrdevider my-4">
                            <span>Other Setting</span>
                        </div>
                        <div className="row">
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Logo height"
                                    value={itemDetails.logoHeight}
                                    onChange={(e) => onChangeItemDetails('logoHeight', e.target.value)}
                                    id="logoHeight"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="logoWidth"
                                    value={itemDetails.logoWidth}
                                    onChange={(e) => onChangeItemDetails('logoWidth', e.target.value)}
                                    id="logoWidth"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Gap For Doctor Name"
                                    value={itemDetails.gapForDoctorName}
                                    onChange={(e) => onChangeItemDetails('gapForDoctorName', e.target.value)}
                                    id="gapForDoctorName"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Gap For Content"
                                    value={itemDetails.gapForContent}
                                    onChange={(e) => onChangeItemDetails('gapForContent', e.target.value)}
                                    id="gapForContent"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Gap For Starting Test"
                                    value={itemDetails.gapForStartingTest}
                                    onChange={(e) => onChangeItemDetails('gapForStartingTest', e.target.value)}
                                    id="gapForStartingTest"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Gap Between Header"
                                    value={itemDetails.gapBetweenHeader}
                                    onChange={(e) => onChangeItemDetails('gapBetweenHeader', e.target.value)}
                                    id="gapBetweenHeader"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Gap Between Test"
                                    value={itemDetails.gapBetweenTest}
                                    onChange={(e) => onChangeItemDetails('gapBetweenTest', e.target.value)}
                                    id="gapBetweenTest"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Gap Between SubTest"
                                    value={itemDetails.gapBetweenSubTest}
                                    onChange={(e) => onChangeItemDetails('gapBetweenSubTest', e.target.value)}
                                    id="gapBetweenSubTest"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Line Test"
                                    value={itemDetails.linetest}
                                    onChange={(e) => onChangeItemDetails('linetest', e.target.value)}
                                    id="linetest"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Lab Alias"
                                    value={itemDetails.labAlias}
                                    onChange={(e) => onChangeItemDetails('labAlias', e.target.value)}
                                    id="labAlias"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Lab Print"
                                    value={itemDetails.labprint}
                                    onChange={(e) => onChangeItemDetails('labprint', e.target.value)}
                                    id="labprint"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Child Age (up to)"
                                    value={itemDetails.childAge}
                                    onChange={(e) => onChangeItemDetails('childAge', e.target.value)}
                                    id="childAge"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Result Pos"
                                    value={itemDetails.resultpos}
                                    onChange={(e) => onChangeItemDetails('resultpos', e.target.value)}
                                    id="resultpos"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Result Position"
                                    value={itemDetails.resultPosition}
                                    onChange={(e) => onChangeItemDetails('resultPosition', e.target.value)}
                                    id="resultPosition"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Result End Position"
                                    value={itemDetails.resultEndPosition}
                                    onChange={(e) => onChangeItemDetails('resultEndPosition', e.target.value)}
                                    id="resultEndPosition"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Result Unit Pos"
                                    value={itemDetails.resultunitpos}
                                    onChange={(e) => onChangeItemDetails('resultunitpos', e.target.value)}
                                    id="resultunitpos"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Result End pos"
                                    value={itemDetails.resultendpos}
                                    onChange={(e) => onChangeItemDetails('resultendpos', e.target.value)}
                                    id="resultendpos"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Patient Details Font"
                                    value={itemDetails.patientDetailsFont}
                                    onChange={(e) => onChangeItemDetails('patientDetailsFont', e.target.value)}
                                    id="patientDetailsFont"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Cuts Agent Not Const"
                                    value={itemDetails.cutsAgentNotConst}
                                    onChange={(e) => onChangeItemDetails('cutsAgentNotConst', e.target.value)}
                                    id="cutsAgentNotConst"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Cuts Agent"
                                    value={itemDetails.cutsAgent}
                                    onChange={(e) => onChangeItemDetails('cutsAgent', e.target.value)}
                                    id="cutsAgent"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Cuts Const"
                                    value={itemDetails.cutsConst}
                                    onChange={(e) => onChangeItemDetails('cutsConst', e.target.value)}
                                    id="cutsConst"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Cuts Disc Not Const"
                                    value={itemDetails.cutsDiscNotConst}
                                    onChange={(e) => onChangeItemDetails('cutsDiscNotConst', e.target.value)}
                                    id="cutsDiscNotConst"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Cuts Disc Not Agent"
                                    value={itemDetails.cutsDiscNotAgent}
                                    onChange={(e) => onChangeItemDetails('cutsDiscNotAgent', e.target.value)}
                                    id="cutsDiscNotAgent"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Agent Ctscan Cuts"
                                    value={itemDetails.agentCtscanCuts}
                                    onChange={(e) => onChangeItemDetails('agentCtscanCuts', e.target.value)}
                                    id="agentCtscanCuts"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Agent Ultra Cuts"
                                    value={itemDetails.agentUltraCuts}
                                    onChange={(e) => onChangeItemDetails('agentUltraCuts', e.target.value)}
                                    id="agentUltraCuts"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Agent Xray Cuts"
                                    value={itemDetails.agentXrayCuts}
                                    onChange={(e) => onChangeItemDetails('agentXrayCuts', e.target.value)}
                                    id="agentXrayCuts"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Agent Mri Cuts"
                                    value={itemDetails.agentMriCuts}
                                    onChange={(e) => onChangeItemDetails('agentMriCuts', e.target.value)}
                                    id="agentMriCuts"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Agent Pathology Cuts"
                                    value={itemDetails.agentPathologyCuts}
                                    onChange={(e) => onChangeItemDetails('agentPathologyCuts', e.target.value)}
                                    id="agentPathologyCuts"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Const Pathology Cuts"
                                    value={itemDetails.constPathologyCuts}
                                    onChange={(e) => onChangeItemDetails('constPathologyCuts', e.target.value)}
                                    id="constPathologyCuts"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Const Xray Cuts"
                                    value={itemDetails.constXrayCuts}
                                    onChange={(e) => onChangeItemDetails('constXrayCuts', e.target.value)}
                                    id="constXrayCuts"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Const Ctscan Cuts"
                                    value={itemDetails.constCtscanCuts}
                                    onChange={(e) => onChangeItemDetails('constCtscanCuts', e.target.value)}
                                    id="constCtscanCuts"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Const Mri Cuts"
                                    value={itemDetails.constMriCuts}
                                    onChange={(e) => onChangeItemDetails('constMriCuts', e.target.value)}
                                    id="constMriCuts"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Mri Test"
                                    value={itemDetails.mriTest}
                                    onChange={(e) => onChangeItemDetails('mriTest', e.target.value)}
                                    id="mriTest"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Xray Test"
                                    value={itemDetails.xrayTest}
                                    onChange={(e) => onChangeItemDetails('xrayTest', e.target.value)}
                                    id="xrayTest"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Xray Header"
                                    value={itemDetails.xrayHeader}
                                    onChange={(e) => onChangeItemDetails('xrayHeader', e.target.value)}
                                    id="xrayHeader"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Xray Footer"
                                    value={itemDetails.xrayFooter}
                                    onChange={(e) => onChangeItemDetails('xrayFooter', e.target.value)}
                                    id="xrayFooter"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Xray Result Pos"
                                    value={itemDetails.xrayresultpos}
                                    onChange={(e) => onChangeItemDetails('xrayresultpos', e.target.value)}
                                    id="xrayresultpos"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Print Option"
                                    value={itemDetails.printOption}
                                    onChange={(e) => onChangeItemDetails('printOption', e.target.value)}
                                    id="printOption"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Print Panel"
                                    value={itemDetails.printpanel}
                                    onChange={(e) => onChangeItemDetails('printpanel', e.target.value)}
                                    id="printpanel"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Print After Save"
                                    value={itemDetails.printaftersave}
                                    onChange={(e) => onChangeItemDetails('printaftersave', e.target.value)}
                                    id="printaftersave"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Orr Bold"
                                    value={itemDetails.orrbold}
                                    onChange={(e) => onChangeItemDetails('orrbold', e.target.value)}
                                    id="orrbold"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Orr Symbol"
                                    value={itemDetails.orrsymbol}
                                    onChange={(e) => onChangeItemDetails('orrSymbol', e.target.value)}
                                    id="orrSymbol"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Orr Color"
                                    value={itemDetails.orrcolor}
                                    onChange={(e) => onChangeItemDetails('orrcolor', e.target.value)}
                                    id="orrcolor"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Cash Memo Address Pos"
                                    value={itemDetails.cashMemoAddressX}
                                    onChange={(e) => onChangeItemDetails('cashMemoAddressX', e.target.value)}
                                    id="cashMemoAddressX"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Arrow Down"
                                    value={itemDetails.arrowdown}
                                    onChange={(e) => onChangeItemDetails('arrowdown', e.target.value)}
                                    id="arrowdown"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Arrow Up Down"
                                    value={itemDetails.cashMemoAddressXdown}
                                    onChange={(e) => onChangeItemDetails('cashMemoAddressXdown', e.target.value)}
                                    id="cashMemoAddressXdown"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Panel Pos"
                                    value={itemDetails.panelpos}
                                    onChange={(e) => onChangeItemDetails('panelpos', e.target.value)}
                                    id="panelpos"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Panel One Page"
                                    value={itemDetails.panelOnePage}
                                    onChange={(e) => onChangeItemDetails('panelOnePage', e.target.value)}
                                    id="panelOnePage"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Panel Next Page"
                                    value={itemDetails.panelNextPage}
                                    onChange={(e) => onChangeItemDetails('panelNextPage', e.target.value)}
                                    id="panelNextPage"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Normal Pos"
                                    value={itemDetails.normalpos}
                                    onChange={(e) => onChangeItemDetails('normalpos', e.target.value)}
                                    id="normalpos"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Normal With Unit"
                                    value={itemDetails.normalwithunit}
                                    onChange={(e) => onChangeItemDetails('normalwithunit', e.target.value)}
                                    id="normalwithunit"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Search Test Name"
                                    value={itemDetails.searchTestName}
                                    onChange={(e) => onChangeItemDetails('searchTestName', e.target.value)}
                                    id="searchTestName"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Ctscan Test"
                                    value={itemDetails.ctscanTest}
                                    onChange={(e) => onChangeItemDetails('ctscanTest', e.target.value)}
                                    id="ctscanTest"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Ultra Sound Test"
                                    value={itemDetails.ultraSoundTest}
                                    onChange={(e) => onChangeItemDetails('ultraSoundTest', e.target.value)}
                                    id="ultraSoundTest"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="All In Perform Test"
                                    value={itemDetails.allInPerformTest}
                                    onChange={(e) => onChangeItemDetails('allInPerformTest', e.target.value)}
                                    id="allInPerformTest"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Rectangle Result"
                                    value={itemDetails.rectangleResult}
                                    onChange={(e) => onChangeItemDetails('rectangleResult', e.target.value)}
                                    id="rectangleResult"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Rectangle Patient"
                                    value={itemDetails.rectanglePatient}
                                    onChange={(e) => onChangeItemDetails('rectanglePatient', e.target.value)}
                                    id="rectanglePatient"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Unit Pos"
                                    value={itemDetails.unitpos}
                                    onChange={(e) => onChangeItemDetails('unitpos', e.target.value)}
                                    id="unitpos"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Unit Position"
                                    value={itemDetails.unitPosition}
                                    onChange={(e) => onChangeItemDetails('unitPosition', e.target.value)}
                                    id="unitPosition"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Unit With Result"
                                    value={itemDetails.unitwithresult}
                                    onChange={(e) => onChangeItemDetails('unitwithresult', e.target.value)}
                                    id="unitwithresult"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Bold Misc Result"
                                    value={itemDetails.boldmiscresult}
                                    onChange={(e) => onChangeItemDetails('boldmiscresult', e.target.value)}
                                    id="boldmiscresult"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Content Gap"
                                    value={itemDetails.contentGap}
                                    onChange={(e) => onChangeItemDetails('contentGap', e.target.value)}
                                    id="contentGap"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Note Font"
                                    value={itemDetails.notefont}
                                    onChange={(e) => onChangeItemDetails('notefont', e.target.value)}
                                    id="notefont"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Page"
                                    value={itemDetails.page}
                                    onChange={(e) => onChangeItemDetails('page', e.target.value)}
                                    id="page"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Routine"
                                    value={itemDetails.routine}
                                    onChange={(e) => onChangeItemDetails('routine', e.target.value)}
                                    id="routine"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Culture Frist Element"
                                    value={itemDetails.cultureFristElement}
                                    onChange={(e) => onChangeItemDetails('cultureFristElement', e.target.value)}
                                    id="cultureFristElement"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Fixed Footer"
                                    value={itemDetails.fixedfooter}
                                    onChange={(e) => onChangeItemDetails('fixedfooter', e.target.value)}
                                    id="fixedfooter"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-2">
                                <TextField
                                    InputLabelProps={{
                                        style: { color: '#000', fontWeight: 500, fontSize: '1rem', lineHeight: '1em' }
                                    }}
                                    fullWidth
                                    type="text"
                                    label="Ref Range Position"
                                    value={itemDetails.refRangePosition}
                                    onChange={(e) => onChangeItemDetails('refRangePosition', e.target.value)}
                                    id="refRangePosition"
                                    size="small"
                                />
                            </FormGroup>
                        </div>
                        <div className="text-center mb-2">
                            {posting ? (
                                <BeatLoader color="#000" css={spinnerStyles} />
                            ) : (
                                <Button variant="contained" className="text-white mr-2 btn-success" onClick={saveSettingLoading}>
                                    Save
                                </Button>
                            )}
                        </div>
                    </TabPanel>
                    {/* <TabPanel value={value} index={1}>
                        Item Two
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        Item Three
                    </TabPanel> */}
                </Box>
            </RctCollapsibleCard>
        </>
    );
};
export default Setting;
