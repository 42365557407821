import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Input } from 'reactstrap';
// api

import AddIcon from '@mui/icons-material/Add';
import axiosInstance from '../../../api/axiosInstance';

// add new user form
import AddNewUserForm from './AddTest';
import { useQuery } from 'react-query';

// rct card box
import RctCollapsibleCard from '../../../component/RctCollapsibleCard/RctCollapsibleCard';

import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default function Test(props) {
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [addNewItemModal, setAddNewItemModal] = useState(false);
    const [editItem, setEditItem] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [tempVals, setTempVals] = useState([]);
    const [selectedCell, setSelectedCell] = useState(null);
    const [editorContent, setEditorContent] = useState('');

    const loadItems = async () => {
        (async () => {
            setLoading(true);
            await axiosInstance
                .get('api/master/product?PageNumber=' + (page + 1) + '&PageSize=' + rowsPerPage, {
                    params: {
                        searchText: searchText
                    }
                })
                .then((response) => {
                    setCount(response.data.totalRecords);
                    setItems(response.data.data);
                })
                .catch((error) => {
                    // console.log(JSON.stringify(error));
                })
                .finally(() => {
                    setLoading(false);
                });
        })();
    };

    const onReload = (e) => {
        e.preventDefault();
        setLoading(true);
        loadItems();
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    };
    const handleEditorChange = (event, editor) => {
        const updatedContent = editor.getData();
        setEditorContent(updatedContent);
    };

    const opnAddNewItemModal = (e) => {
        e.preventDefault();
        setAddNewItemModal(true);
    };

    const [editContactId, setEditContactId] = useState(null);
    const handleEditFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
    };
    const handleSubmit = (data) => {
        // console.log("index wala"+ JSON.stringify(data) );
        axiosInstance.post(`/addTest`, data).then((response) => {
            refetch();
            // successfully uploaded response
        });
    };
    // axiosInstance.post(`/BulkQuickReport02`, finalData).then((response) => {
    //     console.log('my mona', response.data);
    //     setItemDetails({ ...itemDetails, ledgerNo: response.data.ledgerNo, labNo: 'Lab-' + zeroPad(response.data.ledgerNo, 4) });
    // });

    const getTestMaster = async () => {
        const { data } = await axiosInstance.get(`/getTestMaster`);
        return data;
    };
    const handleCellEdit = (ankita) => {
        const id = ankita.id;
        const { field, value } = ankita;

        if (field === 'comment') {
            // Handle the cell content update using editorContent state
            const updatedComment = editorContent;
            const updatedRow = { ...ankita.row, [field]: updatedComment };
            // Update the DataGrid rows
            setTempVals((prevRows) => prevRows.map((row) => (row.id === id ? updatedRow : row)));
        } else {
            // Handle other field updates
            var vals = new Object();
            vals[field] = value;
            console.log(`/updateTestMaster/${id}`, vals);
            axiosInstance.patch(`/updateTestMaster/${id}`, vals);
        }
    };

    const handleCellClick = (params) => {
        // console.log(params);
        const field = params.field;
        if (field === 'comment') {
            const commentValue = params.formattedValue;
            // setSelectedCell({ comment: commentValue });
            setSelectedCell({ id: params.id, comment: commentValue });
        } else {
            setSelectedCell(null);
        }
    };

    const { isLoading, isError, data, error, refetch } = useQuery('getTstmaster', getTestMaster);
    useEffect(() => {
        if (!isLoading && !isError) {
            // const processedData = data.map((item) => ({
            //     ...item,
            //     comment: renderToString(parse(item.comment))
            // }));
            setTempVals(data);
            // setRows(data); // Update rows state with data
        }
    }, [data, isLoading, isError]);
    useEffect(() => {
        if (Array.isArray(data) && Array.isArray(tempVals)) {
            let filteredVals = [];
            if (searchText.trim() === '') {
                filteredVals = data;
            } else {
                filteredVals = data.filter((val) => val.testName.toLowerCase().includes(searchText.toLowerCase()));
            }
            setTempVals(filteredVals);
        }
    }, [searchText]);
    // useEffect(() => {
    //     setRows(tempVals); // Update rows state with tempVals
    // }, [tempVals]);

    if (isLoading) {
        return <div>Loading...</div>;
    }
    if (isError) {
        return <div> {error.message}</div>;
    }
    const columns = [
        { field: 'id', headerName: 'ID', width: 150, editable: true },
        {
            field: 'tstGrpName',
            headerName: 'Test Grp Name',
            width: 150,
            editable: true
        },
        {
            field: 'testCode',
            headerName: 'Test Code',
            width: 150,
            editable: true
        },
        {
            field: 'testName',
            headerName: 'Test Name',
            width: 150,
            editable: true
        },
        {
            field: 'subtest1',
            headerName: 'Subtest 1',
            width: 150,
            editable: true
        },
        {
            field: 'subtest2',
            headerName: 'Subtest 2',
            width: 150,
            editable: true
        },
        {
            field: 'testRate',
            headerName: 'Rate',
            width: 150,
            editable: true
        },
        {
            field: 'units',
            headerName: 'Units',
            width: 150,
            editable: true
        },
        {
            field: 'type',
            headerName: 'Type',
            width: 150,
            editable: true
        },
        {
            field: 'defaultValue',
            headerName: 'Default Value',
            width: 150,
            editable: true
        },
        {
            field: 'normalValueMen',
            headerName: 'Normal Value Men',
            width: 150,
            editable: true
        },
        {
            field: 'normalValueWomen',
            headerName: 'Normal Value Women',
            width: 150,
            editable: true
        },
        {
            field: 'normalValueChild',
            headerName: 'Normal value Child',
            width: 150,
            editable: true
        },
        {
            field: 'pathologyType',
            headerName: 'Pathology Type',
            width: 150,
            editable: true
        },
        {
            field: 'method',
            headerName: 'Method',
            width: 150,
            editable: true
        },
        {
            field: 'comment',
            headerName: 'Comment',
            width: 150,
            editable: true,
            renderCell: (params) => <div dangerouslySetInnerHTML={{ __html: params.value }} />
        },
        {
            field: 'note',
            headerName: 'Note',
            width: 150,
            editable: true
        }
    ];
    const handleOkButtonClick = () => {
        if (selectedCell && selectedCell.id) {
            // Step 3: Save the editorContent to the corresponding cell
            const updatedRow = { ...tempVals.find((row) => row.id === selectedCell.id) };
            updatedRow.comment = editorContent;

            // Update the DataGrid rows
            setTempVals((prevRows) => prevRows.map((row) => (row.id === selectedCell.id ? updatedRow : row)));
            var vals = new Object();
            vals['comment'] = editorContent;
            axiosInstance.patch(`/updateTestMaster/${selectedCell.id}`, vals);
            // console.log(`/updateTestMaster/${selectedCell.id}`, vals);
            // Clear the selectedCell state to close the CKEditor
            setSelectedCell(null);
        }
    };
    const handleCancelButtonClick = () => {
        setSelectedCell(null);
    };
    // setTempVals(data);

    return (
        <>
            <RctCollapsibleCard fullBlock>
                {}
                <div className="row py-20 px-15 border-bottom">
                    <div className="col-lg-2">
                        <a
                            href="#"
                            onClick={(e) => opnAddNewItemModal(e)}
                            className="btn btn-primary text-center justify-content-center mb-5 d-flex"
                        >
                            <AddIcon /> Add New
                        </a>
                    </div>
                    <div className="col-lg-6 text-center">
                        <h1>Test</h1>
                    </div>
                    <div className="col-lg-4 d-flex">
                        <Input
                            className="form-control"
                            type="text"
                            placeholder="Enter search text"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                        <button className="btn btn-primary ml-2">
                            <i className="ti-reload"></i>
                        </button>
                    </div>
                </div>

                <Box sx={{ height: 500, width: '100%' }}>
                    <DataGrid
                        rows={tempVals}
                        columns={columns}
                        onCellEditCommit={handleCellEdit}
                        pageSize={100}
                        onCellClick={handleCellClick}
                        rowId={(row) => row.id} // Specify the unique identifier field

                        // rowsPerPageOptions={[10]}
                    />
                    {selectedCell && (
                        <div
                            style={{
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                background: 'rgba(0, 0, 0, 0.5)',
                                maxHeight: '80vh', // Limit the editor's height for scrolling
                                overflow: 'auto', // Enable scrolling within the container
                                zIndex: 9999
                            }}
                        >
                            <div
                                style={{
                                    maxWidth: 600,
                                    margin: '100px auto',
                                    padding: 20,
                                    background: '#fff',
                                    borderRadius: 4,
                                    zIndex: 10001
                                }}
                            >
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={selectedCell.comment} // If you want to show the existing comment in the CKEditor
                                    onChange={handleEditorChange}
                                />
                                <button onClick={handleOkButtonClick}>OK</button>
                                <button onClick={handleCancelButtonClick}>Cancel</button>
                            </div>
                        </div>
                    )}
                </Box>
            </RctCollapsibleCard>
            <AddNewUserForm
                editItem={editItem}
                setEditItem={setEditItem}
                addNewItemModal={addNewItemModal}
                setAddNewItemModal={setAddNewItemModal}
                loadItems={loadItems}
                onHandleSubmit={handleSubmit}
            />
        </>
    );
}
