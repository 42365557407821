import React, { useState, useRef, useEffect } from 'react';
// import Button from '@mui/material/Button';
// api
// import axios from "axios";
import baseUrl from '../../../api';

// import RctSectionLoader from '../../../component/RctSectionLoader/RctSectionLoader';
import { Modal, Input, Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

//checkbox
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';

import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

const CultureResult = (props) => {
    const [searchText, setSearchText] = useState('');

    const [itemDetails, setItemDetails] = useState({
        // onPrintedPaper:"",
        // onBlankPaper:"",
    });
    //   const sFocus = useRef(null);
    const inputEl = useRef(null);
    // useEffect(() => {
    //     // sFocus.current.focus();
    //     //  inputEl.focus();
    //     setTimeout(() => inputEl.current.focus(), 0);
    // }, [props.cultureChemicals]);

    const onChangeItemDetails = (key, value) => {
        var arr = { ...itemDetails };
        arr[key] = value;
        setItemDetails((prevState) => arr);
    };

    const handleOnAddItem = (e) => {
        e.preventDefault();
        // axios.get("ec2-15-206-84-254.ap-south-1.compute.amazonaws.com:3000/api/master/site").then((response) => console.log("response",response));
        props.setAddNewItemModal(false);
        props.handleCultureOk();
    };

    const onAddUpdateItemModalClose = () => {
        props.setAddNewItemModal(false);
        props.setEditItem(null);
    };
    const columns = [
        { field: 'id', headerName: 'Column 1', width: 30, hide: true },
        { field: 'chemicals', headerName: 'Chemicals', width: 150 }
    ];
    const rows = props.cultureChemicals;
    const rowsOnePlus = props.chemicalsOnePlus;
    const rowsTwoPlus = props.chemicalsTwoPlus;
    const rowsThreePlus = props.chemicalsThreePlus;
    const rowsFourPlus = props.chemicalsFourPlus;
    const rowsR = props.chemicalsR;
    const rowsS = props.chemicalsS;
    const handleOnCellDown = (event, kev) => {
        kev.preventDefault();
        props.handleCultureTransfer(event.row.id, kev.key);
    };
    const dgv = {
        '& .MuiDataGrid-cell': {
            fontSize: '14px',
            minHeight: '35px !important',
            maxHeight: '35px !important'
        },
        '& .MuiDataGrid-row': {
            minHeight: '35px !important',
            maxHeight: '35px !important'
        }
    };
    const dgv1 = {
        '& .MuiDataGrid-cell': {
            fontSize: '12px',
            minHeight: '35px !important',
            maxHeight: '35px !important'
        },
        '& .MuiDataGrid-row': {
            minHeight: '35px !important',
            maxHeight: '35px !important'
        }
    };

    return (
        <>
            <Modal size="lg" className="cultureModal" isOpen={props.addNewItemModal} toggle={() => onAddUpdateItemModalClose()}>
                <ModalHeader toggle={() => onAddUpdateItemModalClose()}>
                    <div className="d-flex">
                        <Input
                            className="form-control"
                            type="text"
                            placeholder="Enter search text"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                        <button className="btn btn-primary ml-2">
                            <i className="ti-reload"></i>
                        </button>
                    </div>
                </ModalHeader>
                <ModalBody>
                    {/* {loading &&
                        <RctSectionLoader />
                    } */}
                    <div className="row">
                        <div className="col-lg-3">
                            Selection List
                            <div style={{ height: 450, width: '100%' }}>
                                <DataGrid
                                    autoFocus
                                    rows={rows}
                                    hideFooterPagination
                                    hideFooterSelectedRowCount
                                    columns={columns}
                                    editMode="row"
                                    focusedRowKey={3}
                                    onCellKeyDown={handleOnCellDown}
                                    ref={inputEl}
                                    sx={dgv}
                                />
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="row">
                                <div className="col-lg-2">
                                    Sensitive
                                    <div style={{ height: 450, width: '100%' }}>
                                        <DataGrid
                                            rows={rowsS}
                                            hideFooterPagination
                                            hideFooterSelectedRowCount
                                            columns={columns}
                                            onCellKeyDown={handleOnCellDown}
                                            sx={dgv1}
                                        />
                                    </div>{' '}
                                </div>
                                <div className="col-lg-2">
                                    +{' '}
                                    <div style={{ height: 450, width: '100%' }}>
                                        <DataGrid
                                            rows={rowsOnePlus}
                                            sx={dgv1}
                                            hideFooterPagination
                                            hideFooterSelectedRowCount
                                            columns={columns}
                                            onCellKeyDown={handleOnCellDown}
                                        />
                                    </div>{' '}
                                </div>
                                <div className="col-lg-2">
                                    ++
                                    <div style={{ height: 450, width: '100%' }}>
                                        <DataGrid
                                            sx={dgv1}
                                            rows={rowsTwoPlus}
                                            hideFooterPagination
                                            hideFooterSelectedRowCount
                                            columns={columns}
                                            onCellKeyDown={handleOnCellDown}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    +++
                                    <div style={{ height: 450, width: '100%' }}>
                                        <DataGrid
                                            sx={dgv1}
                                            rows={rowsThreePlus}
                                            hideFooterPagination
                                            hideFooterSelectedRowCount
                                            columns={columns}
                                            onCellKeyDown={handleOnCellDown}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    ++++
                                    <div style={{ height: 450, width: '100%' }}>
                                        <DataGrid
                                            sx={dgv1}
                                            rows={rowsFourPlus}
                                            hideFooterPagination
                                            hideFooterSelectedRowCount
                                            columns={columns}
                                            onCellKeyDown={handleOnCellDown}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    Resistance
                                    <div style={{ height: 450, width: '100%' }}>
                                        <DataGrid
                                            rows={rowsR}
                                            sx={dgv1}
                                            hideFooterPagination
                                            hideFooterSelectedRowCount
                                            columns={columns}
                                            onCellKeyDown={handleOnCellDown}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button variant="contained" className="text-white btn-success" onClick={handleOnAddItem}>
                        Ok
                    </Button>
                    <Button variant="contained" className="text-white btn-danger" onClick={() => onAddUpdateItemModalClose()}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};
export default CultureResult;
