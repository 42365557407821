import axios from 'axios';
// const baseURL = 'http://localhost:8000/nodejsapp';
const baseURL = 'https://ahiirc.co.in/nodejsapp';

const axiosInstance = axios.create({
    // baseURL: 'http://localhost:8000/nodejsapp',
    baseURL: 'https://ahiirc.co.in/nodejsapp',
    //    baseURL: 'https://billtronapi.hellopatna.com/',
    // timeout: 80000,
    headers: {
        Authorization: localStorage.getItem('token'),
        'Content-Type': 'application/json'
    }
});
export default axiosInstance;
// headers: {
//     Authorization: token,
//   }
