import React, { useState, useEffect } from 'react';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import Button from '@mui/material/Button';
// api
import axios from 'axios';
import baseUrl from '../../../api';

// import RctSectionLoader from '../../../component/RctSectionLoader/RctSectionLoader';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

// Date
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

//multiselect
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select, { SelectChangeEvent } from '@mui/material/Select';

const AddDuesReport = (props) => {
    const [from, setFrom] = React.useState(null);
    const [to, setTo] = React.useState(null);

    const [itemDetails, setItemDetails] = useState({
        totalPaidAmount: '',
        totalDuesAmount: '',
        totalAmount: ''
    });

    const onChangeItemDetails = (key, value) => {
        var arr = { ...itemDetails };
        arr[key] = value;
        setItemDetails((prevState) => arr);
    };

    const handleOnAddItem = (e) => {
        e.preventDefault();
        // axios.get("ec2-15-206-84-254.ap-south-1.compute.amazonaws.com:3000/api/master/site").then((response) => console.log("response",response));

        // axios
        // .post(`${baseUrl}/api/master/addConsultantDoctorMaster`,
        // )
        // .then((response) => {
        //     // successfully uploaded response
        // })
        // .catch((error) => {
        //     // error response
        // });
    };

    const onAddUpdateItemModalClose = () => {
        props.setAddNewItemModal(false);
        props.setEditItem(null);
    };

    return (
        <>
            <Modal className="modal-lg" isOpen={props.addNewItemModal} toggle={() => onAddUpdateItemModalClose()}>
                <ModalHeader toggle={() => onAddUpdateItemModalClose()}>Add New Item</ModalHeader>
                <ModalBody>
                    {/* {loading &&
                        <RctSectionLoader />
                    } */}
                    <Form className="row">
                        <div className="col-12">
                            <div className="mngdevider">
                                <h3 className="mngtitle">Dues Opction </h3>
                                <div className="row">
                                    <FormGroup check className="col-lg-4 col-4">
                                        <Label check className="ml-10 mb-10">
                                            <Input
                                                type="radio"
                                                name="test"
                                                defaultValue={itemDetails.all}
                                                onChange={(e) => onChangeItemDetails('all', e.target.value)}
                                                id="all"
                                            />{' '}
                                            All
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check className="col-lg-4 col-4">
                                        <Label check className="ml-10 mb-10">
                                            <Input
                                                type="radio"
                                                name="test"
                                                defaultValue={itemDetails.pending}
                                                onChange={(e) => onChangeItemDetails('pending', e.target.value)}
                                                id="pending"
                                            />{' '}
                                            Pending
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check className="col-lg-4 col-4">
                                        <Label check className="ml-10 mb-10">
                                            <Input
                                                type="radio"
                                                name="test"
                                                defaultValue={itemDetails.adjested}
                                                onChange={(e) => onChangeItemDetails('adjested', e.target.value)}
                                                id="adjested"
                                            />{' '}
                                            Adjested
                                        </Label>
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                        <FormGroup className="col-lg-6 col-6">
                            <LocalizationProvider className="managedate" dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="From"
                                    value={from}
                                    onChange={(newFrom) => {
                                        setFrom(newFrom);
                                    }}
                                    renderInput={(params) => <TextField fullWidth {...params} size="small" id="from" />}
                                />
                            </LocalizationProvider>
                        </FormGroup>
                        <FormGroup className="col-lg-6 col-6">
                            <LocalizationProvider className="managedate" dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="To"
                                    value={to}
                                    onChange={(newTo) => {
                                        setTo(newTo);
                                    }}
                                    renderInput={(params) => <TextField fullWidth {...params} size="small" id="to" />}
                                />
                            </LocalizationProvider>
                        </FormGroup>
                        <FormGroup className="col-12 text-center">
                            <Button variant="contained" className="text-white btn-success" onClick={handleOnAddItem}>
                                show
                            </Button>
                        </FormGroup>
                        <FormGroup className="col-lg-4 col-4">
                            <TextField
                                fullWidth
                                type="text"
                                label="Total Paid Amount"
                                value={itemDetails.totalPaidAmount}
                                onChange={(e) => onChangeItemDetails('totalPaidAmount', e.target.value)}
                                id="totalPaidAmount"
                                size="small"
                            />
                        </FormGroup>
                        <FormGroup className="col-lg-4 col-4">
                            <TextField
                                fullWidth
                                type="text"
                                label="Total Dues Amount"
                                value={itemDetails.totalDuesAmount}
                                onChange={(e) => onChangeItemDetails('totalDuesAmount', e.target.value)}
                                id="totalPaidAmount"
                                size="small"
                            />
                        </FormGroup>
                        <FormGroup className="col-lg-4 col-4">
                            <TextField
                                fullWidth
                                type="text"
                                label="Total Amount"
                                value={itemDetails.totalAmount}
                                onChange={(e) => onChangeItemDetails('totalAmount', e.target.value)}
                                id="totalAmount"
                                size="small"
                            />
                        </FormGroup>

                        <FormGroup className="col-12 text-center">
                            <Button variant="contained" className="text-white btn-success" onClick={handleOnAddItem}>
                                show
                            </Button>
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button variant="contained" className="text-white btn-success" onClick={handleOnAddItem}>
                        Save
                    </Button>
                    <Button variant="contained" className="text-white btn-danger" onClick={() => onAddUpdateItemModalClose()}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};
export default AddDuesReport;
