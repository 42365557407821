import React, { useState, useEffect } from 'react';
import { Form, FormGroup } from 'reactstrap';
import Button from '@mui/material/Button';
// api
import axios from "axios";
import baseUrl from "../../../api";

// import RctSectionLoader from '../../../component/RctSectionLoader/RctSectionLoader';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from 'reactstrap';


//multiselect
import TextField from '@mui/material/TextField';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const AddComment = (props) => {

    const [itemDetails, setItemDetails] = useState({
        testGrpName: "",
        testCode: "",
        testName: "",
        pathologyType:'',
        comments: "",
        Notes: "",
    });
    
    const onChangeItemDetails = (key, value) => {   
    var arr = { ...itemDetails };
    arr[key] = value;
    setItemDetails((prevState) => arr);
    };

    const handleOnAddItem = (e)=>{
    e.preventDefault();
    // axios.get("ec2-15-206-84-254.ap-south-1.compute.amazonaws.com:3000/api/master/site").then((response) => console.log("response",response));
    
    console.log("your form value is ", itemDetails)
    // console.log(`${baseUrl}/api/master/addtcomment`)
    // axios
    // .post(`${baseUrl}/api/master/addcommentMaster`, dataArray
    // )
    // .then((response) => {
    //     // successfully uploaded response
    // })
    // .catch((error) => {
    //     // error response
    // });
    alert(`${baseUrl}/api/master/addcommentMaster`);
    };

    const onAddUpdateItemModalClose = () => {
        props.setAddNewItemModal(false);
        props.setEditItem(null);
    }

    return (
        <>
            <Modal className='modal-lg' isOpen={props.addNewItemModal} toggle={() => onAddUpdateItemModalClose()}>
                <ModalHeader toggle={() => onAddUpdateItemModalClose()}>
                    Add New Item
                </ModalHeader>
                <ModalBody>
                    {/* {loading &&
                        <RctSectionLoader />
                    } */}
                    <Form className='row'>
                        <FormGroup className='col-lg-4'>
                                <TextField fullWidth 
                                type="text"
                                label="Test Grp Name"
                                defaultValue={itemDetails.testGrpName}
                                onChange={(e) =>
                                    onChangeItemDetails("testGrpName", e.target.value)
                                }
                                id="testGrpName"
                                size="small"
                            />
                        </FormGroup>
                        <FormGroup className='col-lg-4'> 
                            <TextField fullWidth 
                                type="text"
                                label="Test Code"
                                defaultValue={itemDetails.testCode}
                                onChange={(e) =>
                                    onChangeItemDetails("testCode", e.target.value)
                                }
                                id="testCode"
                                size="small"
                            />
                        </FormGroup>
                        <FormGroup className='col-lg-4'> 
                            <TextField fullWidth 
                                type="text"
                                label="Test Name"
                                defaultValue={itemDetails.testName}
                                onChange={(e) =>
                                    onChangeItemDetails("testName", e.target.value)
                                }
                                id="testName"
                                size="small"
                            />
                        </FormGroup>
                        <FormGroup className='col-lg-6'>
                            <label>Comment</label>
                            <CKEditor
                                editor={ ClassicEditor }
                                //data="<p>Hello from CKEditor 5!</p>"
                                onReady={ editor => {
                                // You can store the "editor" and use when it is needed.
                                console.log( 'Editor is ready to use!', editor );
                                } }
                                onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                console.log( { event, editor, data } );
                                } }
                                // onBlur={ ( event, editor ) => {
                                //     console.log( 'Blur.', editor );
                                // } }
                                // onFocus={ ( event, editor ) => {
                                //     console.log( 'Focus.', editor );
                                // } }
                            />
                            {/* <TextField fullWidth 
                                multiline rows={2}
                                type="text"
                                label="Comment"
                                defaultValue={itemDetails.comment}
                                onChange={(e) =>
                                    onChangeItemDetails("comment", e.target.value)
                                }
                                id="comment"
                                size="small"
                            /> */}
                        </FormGroup>
                        <FormGroup className='col-lg-6'>
                            <label>Note</label>
                            <CKEditor
                                editor={ ClassicEditor }
                                //data="<p>Hello from CKEditor 5!</p>"
                                onReady={ editor => {
                                // You can store the "editor" and use when it is needed.
                                console.log( 'Editor is ready to use!', editor );
                                } }
                                onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                console.log( { event, editor, data } );
                                } }
                                // onBlur={ ( event, editor ) => {
                                //     console.log( 'Blur.', editor );
                                // } }
                                // onFocus={ ( event, editor ) => {
                                //     console.log( 'Focus.', editor );
                                // } }
                            />
                            {/* <TextField fullWidth 
                                multiline rows={2} 
                                type="text"
                                label="Note"
                                defaultValue={itemDetails.note}
                                onChange={(e) =>
                                    onChangeItemDetails("note", e.target.value)
                                }
                                id="note"
                                size="small"
                            /> */}
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button variant="contained" className="text-white btn-success" onClick={handleOnAddItem}>Save</Button>
                    <Button variant="contained" className="text-white btn-danger" onClick={() => onAddUpdateItemModalClose()}>Cancel</Button>
                </ModalFooter>
            </Modal >
        </>
    )
}
export default AddComment;