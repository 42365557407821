import React, { useState, useEffect } from 'react';
import api from 'api';
import moment from 'moment';

export default function Footer(props) {
    const [currentDate, setCurrentDate] = useState();
    const [branch, setBranch] = useState('');

    useEffect(() => {
        // getDate();
        getDefaultBranch();
    }, []);

    const getDefaultBranch = async () => {
        await api
            .get('api/master/login/get-default-branch-name-user')
            .then((response) => {
                setBranch(response.data);
            })
            .catch((error) => {
                console.log(JSON.stringify(error));
            });
    };

    // const getDate = async () => {
    // 	await api.get('api/rest/day-closing/get-billing-date')
    // 		.then((response) => {
    // 			if (response.status === 200) {
    // 				setCurrentDate(response.data.dateOfSale);
    // 			}
    // 		})
    // 		.catch(error => {
    // 		});
    // }

    return (
        <div className="rct-footer d-flex justify-content-between align-items-center">
            <h6>Logged In User : {localStorage.getItem('user_id')}</h6>
            <h6>Branch : {branch}</h6>
            <h6>Date : {moment(currentDate).format('DD-MMM-yyyy')}</h6>
            <h6>Serial No. : {localStorage.getItem('tenantID')}</h6>
        </div>
    );
}
