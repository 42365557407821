import React, { useState, useEffect, useRef } from 'react';
// api
import axios from 'axios';
import baseUrl from '../../../api';

import AddIcon from '@mui/icons-material/Add';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PrintIcon from '@mui/icons-material/Print';
import VisibilityIcon from '@mui/icons-material/Visibility';

// Date
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

//multiselect
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';

import { Form, FormGroup, Label, Input } from 'reactstrap';
import Button from '@mui/material/Button';

// rct card box
import RctCollapsibleCard from '../../../component/RctCollapsibleCard/RctCollapsibleCard';

export default function AddReceiptVoucher(props) {
    //date
    const [payingDate, setPayingDate] = React.useState(null);
    const [age, setage] = React.useState(null);

    const [itemDetails, setItemDetails] = useState({
        acNameMobile: '',
        patientId: '',
        name: '',
        gender: '',
        pprTotalAmt: '',
        pprDiscAmt: '',
        adjusted: '',
        netAmt: '',
        duesAmt: '',
        totalAmt: '',
        discAmt: '',
        pdAdjusted: '',
        pdNetAmt: '',
        pdDuesAmt: '',
        pdPaidAmt: '',
        upiName: '',
        bankName: '',
        acNo: '',
        cdBankName: '',
        cdAcNo: '',
        chequeNo: '',
        description: '',
        ccName: '',
        ccdBankName: '',
        ccdAcNo: '',
        creditCardNo: '',
        cashAmt: '',
        chequeAmt: '',
        ccAmt: '',
        upiAmt: '',
        creditAmt: '',
        totalPayment: ''
    });

    const onChangeItemDetails = (key, value) => {
        var arr = { ...itemDetails };
        arr[key] = value;
        setItemDetails((prevState) => arr);
    };

    const handleOnAddItem = (e) => {
        e.preventDefault();
        // axios.get("ec2-15-206-84-254.ap-south-1.compute.amazonaws.com:3000/api/master/site").then((response) => console.log("response",response));

        // axios
        // .post(`${baseUrl}/api/master/addConsultantDoctorMaster`,
        // )
        // .then((response) => {
        //     // successfully uploaded response
        // })
        // .catch((error) => {
        //     // error response
        // });
    };

    return (
        <>
            <RctCollapsibleCard fullBlock>
                <div className="d-flex justify-content-between py-10 px-15 border-bottom">
                    <div className="d-flex">
                        <h1>Receipt Voucher</h1>
                    </div>
                </div>
                <div className="d-flex py-20 px-15 border-bottom">
                    <Form className="row">
                        <div className="col-lg-3">
                            <FormGroup className="col-lg-12">
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="A/c Name/Mobile"
                                    value={itemDetails.acNameMobile}
                                    onChange={(e) => onChangeItemDetails('acNameMobile', e.target.value)}
                                    id="acNameMobile"
                                    size="small"
                                />
                            </FormGroup>
                        </div>
                        <div className="col-lg-9">
                            <div className="mngdevider">
                                <h3 className="mngtitle">Patient Details</h3>
                                <div className="row">
                                    <div className="col-lg-3 mb-2">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Patient Id"
                                            value={itemDetails.patientId}
                                            onChange={(e) => onChangeItemDetails('patientId', e.target.value)}
                                            id="patientId"
                                            size="small"
                                        />
                                    </div>
                                    <div className="col-lg-3 mb-2">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Name"
                                            value={itemDetails.name}
                                            onChange={(e) => onChangeItemDetails('name', e.target.value)}
                                            id="name"
                                            size="small"
                                        />
                                    </div>
                                    <div className="col-lg-3 mb-2">
                                        <FormControl sx={{ minWidth: '100%' }} size="small">
                                            <InputLabel id="gender">Gender</InputLabel>
                                            <Select
                                                id="gender"
                                                value={itemDetails.gender}
                                                label="Gender"
                                                onChange={(e) => onChangeItemDetails('gender', e.target.value)}
                                            >
                                                <MenuItem value={'Male'}>Male</MenuItem>
                                                <MenuItem value={'Female'}>Female</MenuItem>
                                                <MenuItem value={'Trance Gender'}>Transgender</MenuItem>
                                                <MenuItem value={'Other'}>Other</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-lg-3 mb-2">
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                label="Age"
                                                value={age}
                                                onChange={(newage) => {
                                                    setage(newage);
                                                }}
                                                renderInput={(params) => <TextField fullWidth {...params} size="small" id="age" />}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="mngdevider">
                                <h3 className="mngtitle">Payment Mode</h3>
                                <div className="row">
                                    <div className="col-lg-2 col-6">
                                        <Label className="ml-20 mb-10">
                                            <Input
                                                type="radio"
                                                name="test"
                                                defaultValue={itemDetails.cash}
                                                onChange={(e) => onChangeItemDetails('cash', e.target.value)}
                                                id="cash"
                                            />{' '}
                                            Cash
                                        </Label>
                                    </div>
                                    <div className="col-lg-2 col-6">
                                        <Label className="ml-20 mb-10">
                                            <Input
                                                type="radio"
                                                name="test"
                                                defaultValue={itemDetails.cheque}
                                                onChange={(e) => onChangeItemDetails('cheque', e.target.value)}
                                                id="cheque"
                                            />{' '}
                                            Cheque
                                        </Label>
                                    </div>
                                    <div className="col-lg-2 col-6">
                                        <Label className="ml-20 mb-10">
                                            <Input
                                                type="radio"
                                                name="test"
                                                defaultValue={itemDetails.creditCard}
                                                onChange={(e) => onChangeItemDetails('creditCard', e.target.value)}
                                                id="creditCard"
                                            />{' '}
                                            Credit Card
                                        </Label>
                                    </div>
                                    <div className="col-lg-2 col-6">
                                        <Label className="ml-20 mb-10">
                                            <Input
                                                type="radio"
                                                name="test"
                                                defaultValue={itemDetails.upi}
                                                onChange={(e) => onChangeItemDetails('upi', e.target.value)}
                                                id="upi"
                                            />{' '}
                                            UPI
                                        </Label>
                                    </div>
                                    <div className="col-lg-2 col-6">
                                        <Label className="ml-20 mb-10">
                                            <Input
                                                type="radio"
                                                name="test"
                                                defaultValue={itemDetails.credit}
                                                onChange={(e) => onChangeItemDetails('credit', e.target.value)}
                                                id="credit"
                                            />{' '}
                                            Credit
                                        </Label>
                                    </div>
                                    <div className="col-lg-2 col-6">
                                        <Label className="ml-20 mb-10">
                                            <Input
                                                type="radio"
                                                name="test"
                                                defaultValue={itemDetails.composit}
                                                onChange={(e) => onChangeItemDetails('composit', e.target.value)}
                                                id="composit"
                                            />{' '}
                                            Composit
                                        </Label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 mb-2">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Paying Date"
                                    value={payingDate}
                                    onChange={(newPayingDate) => {
                                        setPayingDate(newPayingDate);
                                    }}
                                    renderInput={(params) => <TextField fullWidth {...params} size="small" id="payingDate" />}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className="col-lg-12">
                            <div className="mngdevider">
                                <h3 className="mngtitle">Previous Payment Record</h3>
                                <div className="row">
                                    <div className="col-lg-2 mb-2">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Total Amt"
                                            value={itemDetails.pprTotalAmt}
                                            onChange={(e) => onChangeItemDetails('pprTotalAmt', e.target.value)}
                                            id="pprTotalAmt"
                                            size="small"
                                        />
                                    </div>
                                    <div className="col-lg-2 mb-2">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Disc Amt"
                                            value={itemDetails.pprDiscAmt}
                                            onChange={(e) => onChangeItemDetails('pprDiscAmt', e.target.value)}
                                            id="pprDiscAmt"
                                            size="small"
                                        />
                                    </div>
                                    <div className="col-lg-2 mb-2">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Adjusted"
                                            value={itemDetails.adjusted}
                                            onChange={(e) => onChangeItemDetails('adjusted', e.target.value)}
                                            id="adjusted"
                                            size="small"
                                        />
                                    </div>
                                    <div className="col-lg-2 mb-2">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Net Amt"
                                            value={itemDetails.netAmt}
                                            onChange={(e) => onChangeItemDetails('netAmt', e.target.value)}
                                            id="netAmt"
                                            size="small"
                                        />
                                    </div>
                                    <div className="col-lg-2 mb-2">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Dues Amt"
                                            value={itemDetails.duesAmt}
                                            onChange={(e) => onChangeItemDetails('duesAmt', e.target.value)}
                                            id="duesAmt"
                                            size="small"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="mngdevider">
                                <h3 className="mngtitle">Payment Details</h3>
                                <div className="row">
                                    <div className="col-lg-2 mb-2">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Total Amt"
                                            value={itemDetails.totalAmt}
                                            onChange={(e) => onChangeItemDetails('totalAmt', e.target.value)}
                                            id="totalAmt"
                                            size="small"
                                        />
                                    </div>
                                    <div className="col-lg-2 mb-2">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Disc Amt"
                                            value={itemDetails.discAmt}
                                            onChange={(e) => onChangeItemDetails('discAmt', e.target.value)}
                                            id="discAmt"
                                            size="small"
                                        />
                                    </div>
                                    <div className="col-lg-2 mb-2">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Adjusted"
                                            value={itemDetails.pdAdjusted}
                                            onChange={(e) => onChangeItemDetails('pdAdjusted', e.target.value)}
                                            id="pdAdjusted"
                                            size="small"
                                        />
                                    </div>
                                    <div className="col-lg-2 mb-2">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Net Amt"
                                            value={itemDetails.pdNetAmt}
                                            onChange={(e) => onChangeItemDetails('pdNetAmt', e.target.value)}
                                            id="pdNetAmt"
                                            size="small"
                                        />
                                    </div>
                                    <div className="col-lg-2 mb-2">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Dues Amt"
                                            value={itemDetails.pdDuesAmt}
                                            onChange={(e) => onChangeItemDetails('pdDuesAmt', e.target.value)}
                                            id="pdDuesAmt"
                                            size="small"
                                        />
                                    </div>
                                    <div className="col-lg-2 mb-2">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Paid Amt"
                                            value={itemDetails.pdPaidAmt}
                                            onChange={(e) => onChangeItemDetails('pdPaidAmt', e.target.value)}
                                            id="pdPaidAmt"
                                            size="small"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="mngdevider">
                                <h3 className="mngtitle">UPI Details</h3>
                                <div className="row">
                                    <div className="col-lg-12 mb-2">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="UPI Name"
                                            value={itemDetails.upiName}
                                            onChange={(e) => onChangeItemDetails('upiName', e.target.value)}
                                            id="upiName"
                                            size="small"
                                        />
                                    </div>
                                    <div className="col-lg-12 mb-2">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Bank Name"
                                            value={itemDetails.bankName}
                                            onChange={(e) => onChangeItemDetails('bankName', e.target.value)}
                                            id="bankName"
                                            size="small"
                                        />
                                    </div>
                                    <div className="col-lg-12 mb-2">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="A/c No"
                                            value={itemDetails.acNo}
                                            onChange={(e) => onChangeItemDetails('acNo', e.target.value)}
                                            id="acNo"
                                            size="small"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="mngdevider">
                                <h3 className="mngtitle">Cheque Details</h3>
                                <div className="row">
                                    <div className="col-lg-12 mb-2">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Bank Name"
                                            value={itemDetails.cdBankName}
                                            onChange={(e) => onChangeItemDetails('cdBankName', e.target.value)}
                                            id="cdBankName"
                                            size="small"
                                        />
                                    </div>
                                    <div className="col-lg-12 mb-2">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="A/c No"
                                            value={itemDetails.cdAcNo}
                                            onChange={(e) => onChangeItemDetails('cdAcNo', e.target.value)}
                                            id="cdAcNo"
                                            size="small"
                                        />
                                    </div>
                                    <div className="col-lg-12 mb-2">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Cheque No"
                                            value={itemDetails.chequeNo}
                                            onChange={(e) => onChangeItemDetails('chequeNo', e.target.value)}
                                            id="chequeNo"
                                            size="small"
                                        />
                                    </div>
                                    <div className="col-lg-12 mb-2">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Description"
                                            value={itemDetails.description}
                                            onChange={(e) => onChangeItemDetails('description', e.target.value)}
                                            id="description"
                                            size="small"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="mngdevider">
                                <h3 className="mngtitle">Credit Card Detils</h3>
                                <div className="row">
                                    <div className="col-lg-12 mb-2">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="CC Name"
                                            value={itemDetails.ccName}
                                            onChange={(e) => onChangeItemDetails('ccName', e.target.value)}
                                            id="ccName"
                                            size="small"
                                        />
                                    </div>
                                    <div className="col-lg-12 mb-2">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Bank Name"
                                            value={itemDetails.ccdBankName}
                                            onChange={(e) => onChangeItemDetails('ccdBankName', e.target.value)}
                                            id="ccdBankName"
                                            size="small"
                                        />
                                    </div>
                                    <div className="col-lg-12 mb-2">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="A/c No"
                                            value={itemDetails.ccdAcNo}
                                            onChange={(e) => onChangeItemDetails('ccdAcNo', e.target.value)}
                                            id="ccdAcNo"
                                            size="small"
                                        />
                                    </div>
                                    <div className="col-lg-12 mb-2">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Credit Card No"
                                            value={itemDetails.creditCardNo}
                                            onChange={(e) => onChangeItemDetails('creditCardNo', e.target.value)}
                                            id="creditCardNo"
                                            size="small"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="row">
                                <div className="col-lg-12 mb-2">
                                    <TextField
                                        fullWidth
                                        type="text"
                                        label="Cash Amt"
                                        value={itemDetails.cashAmt}
                                        onChange={(e) => onChangeItemDetails('cashAmt', e.target.value)}
                                        id="cashAmt"
                                        size="small"
                                    />
                                </div>
                                <div className="col-lg-12 mb-2">
                                    <TextField
                                        fullWidth
                                        type="text"
                                        label="Cheque Amt"
                                        value={itemDetails.chequeAmt}
                                        onChange={(e) => onChangeItemDetails('chequeAmt', e.target.value)}
                                        id="chequeAmt"
                                        size="small"
                                    />
                                </div>
                                <div className="col-lg-12 mb-2">
                                    <TextField
                                        fullWidth
                                        type="text"
                                        label="CC Amt"
                                        value={itemDetails.ccAmt}
                                        onChange={(e) => onChangeItemDetails('ccAmt', e.target.value)}
                                        id="ccAmt"
                                        size="small"
                                    />
                                </div>
                                <div className="col-lg-12 mb-2">
                                    <TextField
                                        fullWidth
                                        type="text"
                                        label="UPI Amt"
                                        value={itemDetails.upiAmt}
                                        onChange={(e) => onChangeItemDetails('upiAmt', e.target.value)}
                                        id="upiAmt"
                                        size="small"
                                    />
                                </div>
                                <div className="col-lg-12 mb-2">
                                    <TextField
                                        fullWidth
                                        type="text"
                                        label="Credit Amt"
                                        value={itemDetails.creditAmt}
                                        onChange={(e) => onChangeItemDetails('creditAmt', e.target.value)}
                                        id="creditAmt"
                                        size="small"
                                    />
                                </div>
                                <div className="col-lg-12 mb-2">
                                    <TextField
                                        fullWidth
                                        type="text"
                                        label="Total Payment"
                                        value={itemDetails.totalPayment}
                                        onChange={(e) => onChangeItemDetails('totalPayment', e.target.value)}
                                        id="totalPayment"
                                        size="small"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 text-center">
                            <Button variant="contained" className="text-white btn-primary" onClick={handleOnAddItem}>
                                Update
                            </Button>
                            <Button variant="contained" className="text-white mx-2 btn-success" onClick={handleOnAddItem}>
                                Save
                            </Button>
                            <Button variant="contained" className="text-white btn-danger">
                                Clear
                            </Button>
                        </div>
                    </Form>
                </div>
            </RctCollapsibleCard>
        </>
    );
}
