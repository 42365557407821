import React, { useState, useEffect } from 'react';
import { Form, FormGroup } from 'reactstrap';
import Button from '@mui/material/Button';
// api
import axios from 'axios';
import baseUrl from '../../../api';

// import RctSectionLoader from '../../../component/RctSectionLoader/RctSectionLoader';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

// Date
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

//multiselect
import TextField from '@mui/material/TextField';
// import { FormLabel } from '@mui/material';

const AddCancelation = (props) => {
    const [from, setFrom] = React.useState(null);
    const [to, setTo] = React.useState(null);

    // const [itemDetails, setItemDetails] = useState({
    //     from: "",
    //     to: "",
    // });

    // const onChangeItemDetails = (key, value) => {
    // var arr = { ...itemDetails };
    // arr[key] = value;
    // setItemDetails((prevState) => arr);
    // };

    const handleOnAddItem = (e) => {
        e.preventDefault();
        // axios.get("ec2-15-206-84-254.ap-south-1.compute.amazonaws.com:3000/api/master/site").then((response) => console.log("response",response));

        // axios
        // .post(`${baseUrl}/api/master/addPatientMaster`, dataArray
        // )
        // .then((response) => {
        //     // successfully uploaded response
        // })
        // .catch((error) => {
        //     // error response
        // });
    };

    const onAddUpdateItemModalClose = () => {
        props.setAddNewItemModal(false);
        props.setEditItem(null);
    };

    return (
        <>
            <Modal className="modal-lg" isOpen={props.addNewItemModal} toggle={() => onAddUpdateItemModalClose()}>
                <ModalHeader toggle={() => onAddUpdateItemModalClose()}>Add New Item</ModalHeader>
                <ModalBody>
                    {/* {loading &&
                        <RctSectionLoader />
                    } */}
                    <Form className="row">
                        <div className="col-lg-6">
                            <FormGroup>
                                <LocalizationProvider className="managedate" dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="From"
                                        value={from}
                                        onChange={(newFrom) => {
                                            setFrom(newFrom);
                                        }}
                                        renderInput={(params) => <TextField fullWidth {...params} size="small" id="from" />}
                                    />
                                </LocalizationProvider>
                            </FormGroup>
                        </div>
                        <div className="col-lg-6">
                            <FormGroup>
                                <LocalizationProvider className="managedate" dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="To"
                                        value={to}
                                        onChange={(newTo) => {
                                            setTo(newTo);
                                        }}
                                        renderInput={(params) => <TextField fullWidth {...params} size="small" id="to" />}
                                    />
                                </LocalizationProvider>
                            </FormGroup>
                        </div>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button variant="contained" className="text-white btn-success" onClick={handleOnAddItem}>
                        Save
                    </Button>
                    <Button variant="contained" className="text-white btn-danger" onClick={() => onAddUpdateItemModalClose()}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};
export default AddCancelation;
