import React, { useState, useEffect } from 'react';
import { Form, FormGroup, Label, Input } from 'reactstrap';
// import Button from '@mui/material/Button';
// api
import axios from 'axios';
import baseUrl from '../../../api';

// import RctSectionLoader from '../../../component/RctSectionLoader/RctSectionLoader';
import {
    Modal,
    ModalHeader,
    ModalBody
    // ModalFooter
} from 'reactstrap';

const AddQuickResult = (props) => {
    const [itemDetails, setItemDetails] = useState({
        onPrintedPaper: '',
        onBlankPaper: ''
    });

    const onChangeItemDetails = (key, value) => {
        var arr = { ...itemDetails };
        arr[key] = value;
        setItemDetails((prevState) => arr);
    };

    const handleOnAddItem = (e) => {
        e.preventDefault();
        // axios.get("ec2-15-206-84-254.ap-south-1.compute.amazonaws.com:3000/api/master/site").then((response) => console.log("response",response));

        // axios
        // .post(`${baseUrl}/api/master/addConsultantDoctorMaster`,
        // )
        // .then((response) => {
        //     // successfully uploaded response
        // })
        // .catch((error) => {
        //     // error response
        // });
    };

    const onAddUpdateItemModalClose = () => {
        props.setAddNewItemModal(false);
        props.setEditItem(null);
    };

    return (
        <>
            <Modal size="md" isOpen={props.addNewItemModal} toggle={() => onAddUpdateItemModalClose()}>
                <ModalHeader toggle={() => onAddUpdateItemModalClose()}>Add New Item</ModalHeader>
                <ModalBody>
                    {/* {loading &&
                        <RctSectionLoader />
                    } */}
                    <Form className="row">
                        <div className="col-12">
                            <div className="mngdevider">
                                <h3 className="mngtitle">Printing Opction</h3>
                                <div className="row">
                                    <div className="col-lg-6 col-6">
                                        <Label className="ml-20 mb-10">
                                            <Input
                                                type="radio"
                                                name="test"
                                                defaultValue={itemDetails.onPrintedPaper}
                                                onChange={(e) => onChangeItemDetails('onPrintedPaper', e.target.value)}
                                                id="onPrintedPaper"
                                            />{' '}
                                            On Printed Paper
                                        </Label>
                                    </div>
                                    <div className="col-lg-6 col-6">
                                        <Label className="ml-20 mb-10">
                                            <Input
                                                type="radio"
                                                name="test"
                                                defaultValue={itemDetails.onBlankPaper}
                                                onChange={(e) => onChangeItemDetails('onBlankPaper', e.target.value)}
                                                id="onBlankPaper"
                                            />{' '}
                                            On Blank Paper
                                        </Label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </ModalBody>
                {/* <ModalFooter>
                    <Button variant="contained" className="text-white btn-success" onClick={handleOnAddItem}>Save</Button>
                    <Button variant="contained" className="text-white btn-danger" onClick={() => onAddUpdateItemModalClose()}>Cancel</Button>
                </ModalFooter> */}
            </Modal>
        </>
    );
};
export default AddQuickResult;
