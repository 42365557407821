import React, { useState, useEffect, useRef } from 'react';
import { Input } from 'reactstrap';
// api

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TableFooter from '@mui/material/TableFooter';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';

import axiosInstance from '../../../api/axiosInstance';

// add new user form

// rct card box
import RctCollapsibleCard from '../../../component/RctCollapsibleCard/RctCollapsibleCard';

export default function DailyReport(props) {
    const [items, setItems] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [to, setTo] = React.useState(new Date());
    const [from, setFrom] = React.useState(new Date());

    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);

    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totals, setTotals] = useState({
        totalSubTotal: 0,
        totalDues: 0,
        totalPaidAmount: 0,
        totalDiscAmount: 0
    });
    useEffect(() => {
        let newTotals = {
            totalSubTotal: 0,
            totalDues: 0,
            totalPaidAmount: 0,
            totalDiscAmount: 0
        };

        items.forEach((item) => {
            newTotals.totalSubTotal += parseFloat(item.subTotal);
            newTotals.totalDues += parseFloat(item.dueAmount);
            newTotals.totalPaidAmount += parseFloat(item.paidAmount);
            newTotals.totalDiscAmount += parseFloat(item.discAmount);
        });

        setTotals(newTotals);
    }, [items]); // This will recalculate whenever 'items' changes

    const fetchRecord = async () => {
        //getquickReport01/${itemDetails.labNo}
        const { data } = await axiosInstance.get(`/getPatientReport/${from}/${to}`);
        console.log('data is', data);
        setItems(data);
        setCount(data.length);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <>
            <RctCollapsibleCard fullBlock>
                <div className="row py-20 px-15 border-bottom">
                    <div className="col-sm-2">
                        <LocalizationProvider className="managedate" dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="From"
                                inputFormat="DD-MM-YYYY"
                                format="d-m-Y"
                                value={from}
                                onChange={(from) => {
                                    setFrom(from);
                                }}
                                renderInput={(params) => <TextField {...params} id="dateTime" size="small" />}
                            />
                        </LocalizationProvider>
                    </div>
                    <div className="col-sm-2">
                        <LocalizationProvider className="managedate" dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="To"
                                inputFormat="DD-MM-YYYY"
                                format="d-m-Y"
                                value={to}
                                onChange={(to) => {
                                    setTo(to);
                                }}
                                renderInput={(params) => <TextField {...params} id="dateTime" size="small" />}
                            />
                        </LocalizationProvider>
                    </div>
                    <div className="col-sm-2">
                        <a
                            href="#"
                            onClick={(e) => {
                                fetchRecord();
                            }}
                            className="btn btn-primary text-center justify-content-center mb-5 d-flex"
                        >
                            Load Report
                        </a>
                    </div>
                    <div className="col-lg-2 text-center">
                        <h1>Daily Report</h1>
                    </div>
                    <div className="col-lg-2 d-flex">
                        <Input
                            className="form-control"
                            type="text"
                            placeholder="Enter search text"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                        <button className="btn btn-primary ml-2">
                            <i className="ti-reload"></i>
                        </button>
                    </div>
                </div>

                <div className="table-responsive">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Lab</TableCell>
                                <TableCell>Ledger No</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Title</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Age</TableCell>
                                <TableCell>Gender</TableCell>
                                <TableCell>Mobile</TableCell>
                                <TableCell>Address</TableCell>
                                <TableCell>Sub Total</TableCell>
                                <TableCell>Disc Amt.</TableCell>
                                <TableCell>Paid Amount</TableCell>
                                <TableCell>Dues</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.map((item) => (
                                <TableRow key={item.id}>
                                    <TableCell>{item.labNo}</TableCell>
                                    <TableCell>{item.ledgerNo}</TableCell>
                                    <TableCell>{item.date}</TableCell>
                                    <TableCell>{item.title}</TableCell>
                                    <TableCell>{item.name}</TableCell>
                                    <TableCell>{`${item.age_yy}y ${item.age_mm}m ${item.age_dd}d`}</TableCell>{' '}
                                    {/* Assuming age is in years, months, days */}
                                    <TableCell>{item.gender}</TableCell>
                                    <TableCell>{item.mobile}</TableCell>
                                    <TableCell>{item.address}</TableCell>
                                    <TableCell>{item.subTotal}</TableCell>
                                    <TableCell>{item.discAmount}</TableCell>
                                    <TableCell>{item.paidAmount}</TableCell>
                                    <TableCell>{item.dueAmount}</TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell colSpan={7}></TableCell>
                                <TableCell colSpan={2}>Total</TableCell>
                                <TableCell>{totals.totalSubTotal.toFixed(2)}</TableCell>
                                <TableCell>{totals.totalDiscAmount.toFixed(2)}</TableCell>
                                <TableCell>{totals.totalPaidAmount.toFixed(2)}</TableCell>
                                <TableCell>{totals.totalDues.toFixed(2)}</TableCell>
                            </TableRow>
                        </TableBody>
                        <TableFooter></TableFooter>
                    </Table>
                </div>
            </RctCollapsibleCard>
        </>
    );
}
