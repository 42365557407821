// assets
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
// constant
const icons = {
    AccountBalanceIcon
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const account = {
    id: 'account',
    title: 'Account',
    caption: '',
    type: 'group',
    children: [
        {
            id: 'account',
            title: 'Account',
            type: 'collapse',
            icon: icons.AccountBalanceIcon,
            children: [
                {
                    id: 'paymentvoucher',
                    title: 'Payment Voucher',
                    type: 'collapse',
                    url: '/app/payment-voucher',
                    children: [
                        {
                            id: 'consultantdoctor',
                            title: 'Consultant Doctor',
                            type: 'item',
                            url: '/app/account-consultant-doctor',
                            breadcrumbs: false
                        },
                        {
                            id: 'agent',
                            title: 'Agent',
                            type: 'item',
                            url: '/app/account-agent',
                            breadcrumbs: false
                        }
                    ]
                },
                {
                    id: 'receiptvoucher',
                    title: 'Receipt Voucher',
                    type: 'item',
                    url: '/app/receipt-voucher',
                },
                {
                    id: 'dailyexpenditure',
                    title: 'Daily Expenditure',
                    type: 'item',
                    url: '/app/daily-expenditure',
                    breadcrumbs: false
                },
            ]
        }
    ]
};

export default account;
