
import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { Button } from "reactstrap"
import Barcode from 'assets/barcode.png';

// // rct card box
import RctCollapsibleCard from '../../component/RctCollapsibleCard/RctCollapsibleCard';

class ComponentToPrint extends React.Component {
    render() {
        return (
            <RctCollapsibleCard fullBlock>
                <br/><br/><br/><br/>
                <div style={{border:"1px solid #888",width: "80%",height:"190px",padding: "10px 0",margin: "0 auto",position: "relative"}}>
                    <div style={{border: "1px solid #888", borderRadius: "20px", backgroundColor: "#fff",position: "absolute",width: "110%",left: "-5%"}}>
                        <div style={{display: "flex",marginright: "-15px",marginLeft: "-15px"}}>
                            <div style={{flex: "0 0 60%",maxWidth: "60%",paddingRight: "15px",paddingLeft: "25px"}}>
                                <p style={{marginTop:"10px",marginBottom: "10px"}}>Name: Mr. ABCD</p>
                                <p style={{marginTop:"0",marginBottom: "10px"}}>Age: 25 Year</p>
                                <p style={{marginTop:"0",marginBottom: "10px;"}}>Sex: Male</p>
                                <p style={{marginTop:"0",marginBottom: "10px;"}}>Reffered By: Dr. Sachin Kr mbbs md</p>
                            </div>
                            <div style={{flex: "0 0 40%",maxWidth: "40%",paddingRight: "25px",paddingLeft: "15px",paddingTop:"10px"}}>
                                <img src={Barcode} alt="Qr Code" height="100px" />
                                <p style={{marginTop:"0",marginBottom: "10px"}}>Received On: 21-11-2022</p>
                                <p style={{marginTop:"0",marginBottom: "10px"}}>Received On: 21-11-2022</p>
                            </div>
                        </div>
                    </div>
                </div>
                <br/><br/>
                <div style={{width: "80%",margin: "0 auto"}}>
                    <div style={{textAlign: "center",marginBottom: "10px"}}><b style={{padding: "15px",textDecoration: "underline"}}>REPORT ON THE BACTERIOLOGICAL EXAMINATION</b></div>
                    <div style={{textAlign: "center"}}>
                        Nature Of Specimen:- Urine<br/>
                        Organism Isolated:- Enterococous Baecalis<br/>
                        Colony Count:- 10 colonies/ml grown
                    </div>
                    <br/>
                    <table style={{width: "80%",borderCollapse: "collapse"}}>
                        <tr>
                            <td><b>Sensitive to :</b> (in order of zone)</td>
                        </tr>
                        <tr>
                            <td>CEFTRIAXOME</td>
                            <td>(++++)</td>
                            <td>CEPHALEXIN</td>
                            <td>(++++)</td>
                        </tr>
                        <tr>
                            <td>CEFTRIAXOME</td>
                            <td>(++++)</td>
                            <td>CEPHALEXIN</td>
                            <td>(++++)</td>
                        </tr>
                        <tr>
                            <td>CEFTRIAXOME</td>
                            <td>(++++)</td>
                            <td>CEPHALEXIN</td>
                            <td>(++++)</td>
                        </tr>
                        <tr>
                            <td>CEFTRIAXOME</td>
                            <td>(++++)</td>
                            <td>CEPHALEXIN</td>
                            <td>(++++)</td>
                        </tr>
                        <tr>
                            <td>CEFTRIAXOME</td>
                            <td>(++++)</td>
                            <td>CEPHALEXIN</td>
                            <td>(++++)</td>
                        </tr>
                        <tr>
                            <td>CEFTRIAXOME</td>
                            <td>(++++)</td>
                            <td>CEPHALEXIN</td>
                            <td>(++++)</td>
                        </tr>
                        <tr>
                            <td>CEFTRIAXOME</td>
                            <td>(++++)</td>
                            <td>CEPHALEXIN</td>
                            <td>(++++)</td>
                        </tr>
                        <tr>
                            <td>CEFTRIAXOME</td>
                            <td>(++++)</td>
                            <td>CEPHALEXIN</td>
                            <td>(++++)</td>
                        </tr>
                        <tr>
                            <td>CEFTRIAXOME</td>
                            <td>(++++)</td>
                            <td>CEPHALEXIN</td>
                            <td>(++++)</td>
                        </tr>
                        <tr>
                            <td>CEFTRIAXOME</td>
                            <td>(++++)</td>
                            <td>CEPHALEXIN</td>
                            <td>(++++)</td>
                        </tr>
                        <tr>
                            <td>CEFTRIAXOME</td>
                            <td>(++++)</td>
                            <td>CEPHALEXIN</td>
                            <td>(++++)</td>
                        </tr>
                        <tr>
                            <td>CEFTRIAXOME</td>
                            <td>(++++)</td>
                            <td>CEPHALEXIN</td>
                            <td>(++++)</td>
                        </tr>
                        <tr>
                            <td>CEFTRIAXOME</td>
                            <td>(++++)</td>
                            <td>CEPHALEXIN</td>
                            <td>(++++)</td>
                        </tr>
                        <tr>
                            <td><b>Resistant to:-</b></td>
                        </tr>
                        <tr>
                            <td>CEFIXIME</td>
                            <td>NALIDIXIC ACID</td>
                            <td>ERYTHROMYCIN</td>
                        </tr>
                        <tr>
                            <td>FURADANTIN</td>
                            <td>PIPRACILLIN</td>
                            <td>ROXID</td>
                        </tr>
                    </table>
                </div>
                <br/>
                <div style={{width: "80%",margin: "0 auto",padding: "5px 0",borderTop: "2px solid #000",borderBottom: "2px solid #000"}}>
                    Note: (+)(S) Indicates Sensitively of the drug.: (R) Indicates Resistance to the drug.<br/>
                    Zone of inhavitation: (++++) - :- 22mm : (+++) - 18-22mm : (++) - 15 - 18mm : (+) - 11-15mm 
                </div>
                <br/>
                <div style={{width:"80%",margin: "0 auto",display: "flow-root"}}>
                    <div style={{float:"left", textAlign: "center"}}>
                        <img src="signature.png" alt="signature" height="50px" /><br/>
                        DR.D.N.PD.SINGH MBBS
                    </div>
                    <div style={{float: "right",textAlign: "center"}}>
                        <img src="signature.png" alt="signature" height="50px" /><br/>
                        Dr. S.K. Sinha M.D(Path)
                    </div>
                </div>
                <br/>
                <div style={{textAlign: "center"}}>***** End of Regen *****</div>
                <br/><br/>
                <div style={{width: "80%",margin: "5px auto"}}>Wish Your Good Health</div>
                <div style={{border: "1px solid #888",width: "80%",height: "120px",padding: "10px 0",margin: "0 auto",position: "relative"}}>
                    <div style={{border: "1px solid #888",borderRadius: "20px",backgroundColor: "#fff",position: "relative",width: "107%",height: "100px",left: "-4%",padding: "10px 30px"}}>
                        <p><b>Sample Collected:</b> <b style={{color: "red"}}>Out Side The Lab</b></p>
                        <p><center style={{color: "blue"}}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</center></p>
                    </div>
                </div>
                <br/>
            </RctCollapsibleCard>
        );
    }
}

const ReportSeven = () => {
    const componentRef = useRef();
    return (
      <div>
        <ReactToPrint
          trigger={() => <Button>Print this out!</Button>}
          content={() => componentRef.current}
        />
        <ComponentToPrint ref={componentRef} />
      </div>
    );
  };

export default ReportSeven;