import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Link } from 'react-router-dom';
import { Form, FormGroup, Input } from 'reactstrap';
import LinearProgress from '@mui/material/LinearProgress';
import QueueAnim from 'rc-queue-anim';
import { useAuth } from 'Auth';
import { useNavigate } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
// components
import { SessionSlider } from '../../component/Widgets';
import logo from 'assets/img/site-logo.png';

const Signin = () => {
    const [formValue, setFormValue] = useState({
        tenantID: '',
        userId: '',
        userPassword: ''
    });
    const [loading, setLoading] = useState(false);
    const auth = useAuth();
    const navigate = useNavigate();

    const onLogin = () => {
        if (formValue.tenantID.length == 0) {
            NotificationManager.error('Lab ID required !');
            return;
        }
        if (formValue.userId.length == 0) {
            NotificationManager.error('User Id required !');
            return;
        }
        if (formValue.userPassword.length == 0) {
            NotificationManager.error('Password required !');
            return;
        }
        auth.onLogin(formValue);
    };

    useEffect(() => {
        if (localStorage.getItem('token') != null) {
            navigate('/');
        }
    });

    return (
        <QueueAnim type="bottom" duration={2000}>
            <div className="rct-session-wrapper">
                {loading && <LinearProgress />}
                <AppBar position="static" className="session-header">
                    <Toolbar>
                        <div className="container">
                            <div className="d-none justify-content-between">
                                <div className="session-logo">
                                    <Link to="/">
                                        <img src={logo} alt="billtron-logo" className="img-fluid" width="110" height="35" />
                                    </Link>
                                </div>
                                <div></div>
                            </div>
                        </div>
                    </Toolbar>
                </AppBar>
                <div className="session-inner-wrapper">
                    <div className="container">
                        <div className="row row-eq-height">
                            <div className="col-sm-3 col-md-3 col-lg-3">
                                <SessionSlider />
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6">
                                <div className="session-body text-center">
                                    <div className="session-head mb-30">
                                        <h2 className="font-weight-bold">Get started with Danta Infotech PVT.LTD</h2>
                                        <p className="mb-0">Most powerful Pathology App</p>
                                    </div>

                                    <Form>
                                        <FormGroup className="has-wrapper">
                                            <Input
                                                type="mail"
                                                value={formValue.tenantID}
                                                name="user-SerialNo"
                                                id="user-SerialNo"
                                                className="has-input input-lg"
                                                placeholder="Enter Tenant ID"
                                                onChange={(e) => setFormValue({ ...formValue, tenantID: e.target.value })}
                                            />
                                            <span className="has-icon">
                                                <i className="ti-settings"></i>
                                            </span>
                                        </FormGroup>
                                        <FormGroup className="has-wrapper">
                                            <Input
                                                type="mail"
                                                value={formValue.userID}
                                                name="user-userID"
                                                id="user-userID"
                                                className="has-input input-lg"
                                                placeholder="Enter User Name"
                                                onChange={(e) => setFormValue({ ...formValue, userId: e.target.value })}
                                            />
                                            <span className="has-icon">
                                                <i className="ti-email"></i>
                                            </span>
                                        </FormGroup>
                                        <FormGroup className="has-wrapper">
                                            <Input
                                                value={formValue.password}
                                                type="Password"
                                                name="user-pwd"
                                                id="pwd"
                                                className="has-input input-lg"
                                                placeholder="Password"
                                                onChange={(e) => setFormValue({ ...formValue, userPassword: e.target.value })}
                                            />
                                            <span className="has-icon">
                                                <i className="ti-lock"></i>
                                            </span>
                                        </FormGroup>
                                        <FormGroup className="mb-15">
                                            <Button
                                                color="primary"
                                                className="btn-block text-white w-100"
                                                variant="contained"
                                                size="large"
                                                onClick={() => onLogin()}
                                            >
                                                Sign In
                                            </Button>
                                        </FormGroup>
                                    </Form>
                                </div>
                            </div>
                            <div className="col-sm-3 col-md-3 col-lg-3">
                                <SessionSlider />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </QueueAnim>
    );
};

export default Signin;
