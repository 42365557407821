import React, { useState, useEffect, useRef } from 'react';
import { Input } from 'reactstrap';
// api
import api from 'api';

import AddIcon from '@mui/icons-material/Add';

// add new user form
import AddNewUserForm from './AddConsultantDoctor';
import { useFetchConsultant } from '../../../commonFunctions/commonFunction';
import axiosInstance from '../../../api/axiosInstance';

// rct card box
import RctCollapsibleCard from '../../../component/RctCollapsibleCard/RctCollapsibleCard';

import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

const columns = [
    // { field: 'id', headerName: ' Id', width: 150,editable: true, },

    {
        field: 'consultantId',
        headerName: 'Consultant Id',
        width: 150,
        editable: true
    },
    {
        field: 'cName',
        headerName: 'cName',
        width: 150,
        editable: true
    },
    {
        field: 'sex',
        headerName: 'Sex',
        width: 150,
        editable: true
    },
    {
        field: 'cMobile1',
        headerName: 'cMobile1',
        width: 150,
        editable: true
    },
    {
        field: 'adress',
        headerName: 'Adress',
        width: 150,
        editable: true
    },
    {
        field: 'cTitle',
        headerName: 'cTitle',
        width: 150,
        editable: true
    },
    {
        field: 'designation',
        headerName: 'Designation',
        width: 150,
        editable: true
    },
    {
        field: 'faculty',
        headerName: 'Faculty',
        width: 150,
        editable: true
    },
    {
        field: 'dob',
        headerName: 'D-O-B',
        width: 150,
        editable: true
    },
    {
        field: ' marriageDay',
        headerName: 'Marriage Day',
        width: 150,
        editable: true
    },
    {
        field: 'noOfChildren',
        headerName: 'No Of Child',
        width: 150,
        editable: true
    },
    {
        field: 'email',
        headerName: 'Email',
        width: 150,
        editable: true
    },
    {
        field: 'cuts',
        headerName: 'Cuts',
        width: 150,
        editable: true
    },
    {
        field: 'hospital',
        headerName: 'hospital',
        width: 150,
        editable: true
    },
    {
        field: 'altracuts',
        headerName: 'Altracuts',
        width: 150,
        editable: true
    },
    {
        field: 'xraycuts',
        headerName: 'Xraycuts',
        width: 150,
        editable: true
    },
    {
        field: 'ctscanCuts',
        headerName: 'CtscanCuts',
        width: 150,
        editable: true
    },
    {
        field: 'mriCuts',
        headerName: 'MRICuts',
        width: 150,
        editable: true
    }
];
const rows = [
    {
        id: 1,
        consultantId: 'hghg',
        cName: 'Snow',
        sex: 'male',
        cMobile1: '8271161047',
        address: 'gfhjj',
        cTitle: 'gfhjgg',
        designation: 'bbcbv',
        faculty: 'vcbhvb',
        dob: '4667',
        marriageDay: '56879',
        noOfChildren: 'tdgtyy',
        email: 'satish@43gmail.com',
        cuts: 'ffghg',
        hospital: 'gfhfjjy',
        altracuts: 'dghfj',
        xraycuts: 'edfgdg',
        ctscanCuts: 'dfhg',
        mriCuts: 'vgfv'
    }
];

export default function ConsultantDoctor(props) {
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [addNewItemModal, setAddNewItemModal] = useState(false);
    const [editItem, setEditItem] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const deleteConfirmationDialog = useRef(null);
    const [importItemModal, setImportItemModal] = useState(false);
    const [searchText, setSearchText] = useState('');

    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const { data, isLoading, isError } = useFetchConsultant();

    if (isLoading) {
        return <div>Loading...</div>;
    }
    if (isError) {
        return <div>Error Occrued....</div>;
    }

    const rows = data.data;

    const loadItems = async () => {
        (async () => {
            setLoading(true);
            await api
                .get('api/master/product?PageNumber=' + (page + 1) + '&PageSize=' + rowsPerPage, {
                    params: {
                        searchText: searchText
                    }
                })
                .then((response) => {
                    setCount(response.data.totalRecords);
                    setItems(response.data.data);
                })
                .catch((error) => {
                    console.log(JSON.stringify(error));
                })
                .finally(() => {
                    setLoading(false);
                });
        })();
    };
    const handleCellEdit = (ankita) => {
        const id = ankita.id;
        const { field, value } = ankita;
        var vals = new Object();
        vals[field] = value;
        axiosInstance.patch(`/updateConsultant/${id}`, vals);
    };

    const onReload = (e) => {
        e.preventDefault();
        setLoading(true);
        loadItems();
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    };

    const opnAddNewItemModal = (e) => {
        e.preventDefault();
        setAddNewItemModal(true);
    };

    const opnImportItemModal = (e) => {
        e.preventDefault();
        setImportItemModal(true);
    };

    const onEditItem = (item) => {
        setAddNewItemModal(true);
        setEditItem(items[items.indexOf(item)]);
    };

    const onDelete = (item) => {
        deleteConfirmationDialog.current.open();
        setSelectedItem(items[items.indexOf(item)]);
    };

    return (
        <>
            <RctCollapsibleCard fullBlock>
                {}
                <div className="row py-20 px-15 border-bottom">
                    <div className="col-lg-2">
                        <a
                            href="#"
                            onClick={(e) => opnAddNewItemModal(e)}
                            className="btn btn-primary text-center justify-content-center mb-5 d-flex"
                        >
                            <AddIcon /> Add New
                        </a>
                    </div>
                    <div className="col-lg-6 text-center">
                        <h1>Consultant Doctor</h1>
                    </div>
                    <div className="col-lg-4 d-flex">
                        <Input
                            className="form-control"
                            type="text"
                            placeholder="Enter search text"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                        <button className="btn btn-primary ml-2">
                            <i className="ti-reload"></i>
                        </button>
                    </div>
                </div>
                <Box sx={{ height: 400, width: '100%' }}>
                    <DataGrid rows={rows} columns={columns} onCellEditCommit={handleCellEdit} pageSize={100} />
                </Box>
            </RctCollapsibleCard>
            <AddNewUserForm
                editItem={editItem}
                setEditItem={setEditItem}
                addNewItemModal={addNewItemModal}
                setAddNewItemModal={setAddNewItemModal}
                loadItems={loadItems}
            />
        </>
    );
}
