import React, { useState, useContext } from 'react';
import { Form, FormGroup, Label, Input } from 'reactstrap';
// import Button from '@mui/material/Button';
// api

import { useFetchTestCode } from '../../../commonFunctions/commonFunction';

// import RctSectionLoader from '../../../component/RctSectionLoader/RctSectionLoader';
import { Modal, Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

//checkbox
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { AuthContext } from '../../../hooks/context';

const AddTest = (props) => {
    const [searchText, setSearchText] = useState('');
    // const [secondValue, setSecondValue] = useState([
    //     {
    //         id: '',
    //         testCode: '',
    //         testName: ''
    //     }
    // ]);
    const { secondValue, setSecondValue } = useContext(AuthContext);

    // const { isLoading, isSuccess, isError, data, error } = useQuery('todos', fetchPosts);

    // const [addTestSelects, setAddTestSelects] = React.useState();
    const { data: testCode, isLoading, isError, error } = useFetchTestCode();
    // const [data1, setData1] = useState(data.data);

    // const [addTestSelect, setAddTestSelect] = React.useState(new Array(data1?.length).fill(false));
    // const [addTestSelect, setAddTestSelect] = React.useState(data1?.map((post, key) => post.id));
    const [itemDetails, setItemDetails] = useState({
        onPrintedPaper: '',
        onBlankPaper: ''
    });

    if (isLoading) {
        return <span>Loading...</span>;
    }
    if (isError) {
        return <span>Error: {error.message}</span>;
    }
    // async function fetchPosts() {
    //     const { data } = await axiosInstance.get(`/getTestCode`);
    //     return data;
    // }
    const onChangeItemDetails = (key, value) => {
        var arr = { ...itemDetails };
        arr[key] = value;
        setItemDetails((prevState) => arr);
    };
    const handleOnChange = (position) => {
        const elementsIndex = secondValue.findIndex((element) => element['id'] == position.id);
        if (elementsIndex == -1) {
            let temp = { id: position.id, testCode: position.testCode, testName: position.testName, testRate: position.testRate };
            setSecondValue((current) => [...current, temp]);
        } else {
            const arrs = [...secondValue];
            arrs.splice(elementsIndex, 1);
            setSecondValue(arrs);
        }
    };

    const handleOnAddItem = (e) => {
        e.preventDefault();
        props.setAddNewItemModal(false);
        props.loadItems();
    };

    const onAddUpdateItemModalClose = () => {
        props.setAddNewItemModal(false);
        // props.setEditItem(null);
    };

    return (
        <>
            <Modal size="lg" isOpen={props.addNewItemModal} toggle={() => onAddUpdateItemModalClose()}>
                <ModalHeader toggle={() => onAddUpdateItemModalClose()}>
                    <div className="d-flex">
                        <Input
                            className="form-control"
                            type="text"
                            placeholder="Enter search text"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                        <button className="btn btn-primary ml-2">
                            <i className="ti-reload"></i>
                        </button>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="table-responsive">
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Id</TableCell>
                                            <TableCell>Test Code</TableCell>
                                            <TableCell>Test Name</TableCell>
                                            <TableCell>Rate</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {testCode.data
                                            .filter((vals) => {
                                                if (searchText === '') {
                                                    return vals;
                                                } else if (vals.testName.toLowerCase().includes(searchText.toLowerCase())) {
                                                    return vals;
                                                }
                                            })
                                            .map((post, index) => {
                                                return (
                                                    <TableRow onClick={() => handleOnChange(post)} key={index}>
                                                        <TableCell>{post.id}</TableCell>
                                                        <TableCell>{post.testCode}</TableCell>
                                                        <TableCell>{post.testName}</TableCell>
                                                        <TableCell>{post.testRate}</TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="table-responsive">
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>#</TableCell>
                                            <TableCell>Code</TableCell>
                                            <TableCell>Selected Test</TableCell>
                                            <TableCell> Rate</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {secondValue?.map((vals, index) => {
                                            return (
                                                <TableRow>
                                                    <TableCell>{vals.id}</TableCell>
                                                    <TableCell>{vals.testCode}</TableCell>
                                                    <TableCell>{vals.testName}</TableCell>
                                                    <TableCell>{vals.testRate}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button variant="contained" className="text-white btn-success" onClick={handleOnAddItem}>
                        Ok
                    </Button>
                    <Button variant="contained" className="text-white btn-danger" onClick={() => onAddUpdateItemModalClose()}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};
export default AddTest;
