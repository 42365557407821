import React from "react";
// import React, { useEffect, useState } from "react";
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import ReactCurvedText from "react-curved-text";
import banner from "./img/banner01.jpg";
import banner2 from "./img/banner02.jpg";
import banner3 from "./img/banner03.jpg";
import DraftsIcon from '@mui/icons-material/Drafts';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import LanguageIcon from '@mui/icons-material/Language';
// import baseUrl from "../../navigation/base";
// import axios from "axios";
// import loadings from "../../assets/img/loader.gif";

export default function Banner() {

//   const [items, setItems] = useState([]);
//   const [loading, setLoading] = useState(true);
//   useEffect(() => {
//     getItems();
//   },[]);

//   function getItems() {
//     setLoading(true);
//     axios.get(`${baseUrl}/banner?type=Slider`).then(function(response) {
//       // console.log (response.data);
//       setLoading(false);
//       setItems(response.data);
//     });
//   }
  
//   if (loading) {
//     // Display a loading indicator
//     return <div className="text-center"><img width="120" src={loadings} alt="Loading..." /></div>;
//   }

  var settings = {
    dots: false,
    arrows:false,
    infinite: true,
    speed: 3000,
    autoplay: true,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
    <Slider {...settings}>
      {/* {Array.isArray(items) && items.map((user, key) =>
      <section className="section-hero section-shaped" key={key}>
        <img src={(`uploads/${user.banner_img}`)} alt={user.title} width="100%" />
        <div className="carousel-caption" dangerouslySetInnerHTML={{ __html: user.content}}></div>
      </section>
      )} */}
      <section className="section-hero">
        <img src={banner} alt="Lab Super Manager" width="100%" height="530" />
        {/* <div className="carousel-heding">
          <ReactCurvedText
            width='100%'
            height='200'
            cx='520'
            cy='130'
            rx='275'
            ry='80'
            startOffset='70'
            reversed={true}
            text='Lab Super Manager'
            // textProps={{"style": {"fontSize": "60"}}}
            textPathProps={null}
            tspanProps={null}
            ellipseProps={null}
            svgProps={null}
          />          
        </div> */}
        {/* <div className="number">8271161047 | 9631001342</div> */}
        <div className="carousel-caption">
          <span>Jai Shree Hnuman</span>
          <div>
            <div><PhoneInTalkIcon />8271161047 | 9631001342</div>
            <div><LanguageIcon />https://danta.co.in/</div>
            <div><DraftsIcon />satishkishore13@gmail.com</div>
          </div>
        </div>
      </section>
      <section className="section-hero">
        <img src={banner2} alt="Lab Super Manager" width="100%" height="530" />
        {/* <div className="carousel-heding">
          <ReactCurvedText
            width='100%'
            height='200'
            cx='520'
            cy='130'
            rx='275'
            ry='80'
            startOffset='70'
            reversed={true}
            text='Lab Super Manager'
            // textProps={{"style": {"fontSize": "60"}}}
            textPathProps={null}
            tspanProps={null}
            ellipseProps={null}
            svgProps={null}
          />
        </div> */}
        {/* <div className="number">8271161047 | 9631001342</div> */}
        <div className="carousel-caption">
          <span>Jai Shree Hnuman</span>
          <div>
            <div><PhoneInTalkIcon />8271161047 | 9631001342</div>
            <div><LanguageIcon />https://danta.co.in/</div>
            <div><DraftsIcon />satishkishore13@gmail.com</div>
          </div>
        </div>
      </section>
      <section className="section-hero">
        <img src={banner3} alt="Lab Super Manager" width="100%" height="530" />
        {/* <div className="carousel-heding">
          <ReactCurvedText
            width='100%'
            height='200'
            cx='520'
            cy='130'
            rx='275'
            ry='80'
            startOffset='70'
            reversed={true}
            text='Lab Super Manager'
            // textProps={{"style": {"fontSize": "60"}}}
            textPathProps={null}
            tspanProps={null}
            ellipseProps={null}
            svgProps={null}
          />
        </div> */}
        {/* <div className="number">8271161047 | 9631001342</div> */}
        <div className="carousel-caption">
          <span>Jai Shree Hnuman</span>
          <div>
            <div><PhoneInTalkIcon />8271161047 | 9631001342</div>
            <div><LanguageIcon />https://danta.co.in/</div>
            <div><DraftsIcon />satishkishore13@gmail.com</div>
          </div>
        </div>
      </section>
    </Slider>
  );
}