import React, { useState, useEffect } from 'react';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import Button from '@mui/material/Button';
// api
import { BeatLoader } from 'react-spinners';

// import RctSectionLoader from '../../../component/RctSectionLoader/RctSectionLoader';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { NotificationManager } from 'react-notifications';

// Date
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { firstOfCurrentMonth } from '../../../commonFunctions/commonFunction';
import { formatDateDMY } from '../../../commonFunctions/commonFunction';

//multiselect
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import { useFetchConsultant } from '../../../commonFunctions/commonFunction';
import axiosInstance from '../../../api/axiosInstance';
import { css } from '@emotion/react';

const AccountConsultantDoctor = (props) => {
    const [from, setFrom] = React.useState(firstOfCurrentMonth);
    const [to, setTo] = React.useState(new Date());
    const [save, setSave] = useState(true);
    const [posting, setPosting] = useState(false);

    // const [date, setDate] = React.useState(new Date());
    const [itemDetails, setItemDetails] = useState({
        from: '',
        to: '',
        consultantTitle: '',
        constName: '',
        pathologyCuts: '',
        totalPaymentForPathology: '',
        consultantId: '',
        constDesignation: '',
        noOfTest: '',
        gender: '',
        mobileNo: '',
        // dob: "",
        emailId: '',
        payment: '',
        date: new Date(),
        paidAmount: '0',
        dues: '0',
        totalCommission: '0',
        totalPaid: '0'
    });
    const setConsultantDetails = (constDetails) => {
        let p = consultant?.data.filter((e) => e.cName == constDetails);
        if (p !== undefined && p.length > 0) {
            setItemDetails({
                ...itemDetails,
                consultantId: p[0].consultantId ? p[0].consultantId : '',
                constDesignation: p[0].designation ? p[0].designation : ''
                // constFaculty: p[0].faculty ? p[0].faculty : '',
                // constHospital: p[0].hospital ? p[0].hospital : ''
            });
        }
    };
    const { data: consultant, isLoading: isConsultantLoading } = useFetchConsultant();
    useEffect(() => {
        setConsultantDetails(itemDetails.constName);
    }, [itemDetails.constName]);

    useEffect(() => {
        setItemDetails({
            ...itemDetails,
            dues: parseInt(itemDetails.totalCommission) - parseInt(itemDetails.paidAmount) - parseInt(itemDetails.totalPaid)
        });
    }, [itemDetails.paidAmount]);

    if (isConsultantLoading) {
        return <div>Loading...</div>;
    }
    let consultants = [];
    if (Array.isArray(consultant?.data)) {
        consultants = consultant.data.map((item, index) => (
            <MenuItem value={item.cName} key={index}>
                {item.cName}
            </MenuItem>
        ));
    }

    const onChangeItemDetails = (key, value) => {
        var arr = { ...itemDetails };
        arr[key] = value;
        setItemDetails((prevState) => arr);
    };
    const handleAdd = () => {
        setSave(true);
        setItemDetails({
            from: '',
            to: '',
            consultantTitle: '',
            constName: '',
            pathologyCuts: '',
            totalPaymentForPathology: '',
            consultantId: '',
            constDesignation: '',
            noOfTest: '',
            gender: '',
            mobileNo: '',
            // dob: "",
            emailId: '',
            payment: '',
            date: new Date(),
            paidAmount: '0',
            dues: '0',
            totalCommission: '0',
            totalPaid: '0'
        });
    };

    const handleSave = (e) => {
        e.preventDefault();
        if (itemDetails.constName == '') {
            NotificationManager.error('You Must Select Consultant Name', 'Field Empty');

            // alert('You Must Select Consultant Name');

            return;
        }
        setSave(false);
        NotificationManager.success('Saved Successfully', 'Test Saved');

        axiosInstance.post(`/reffPayment`, itemDetails).then((response) => {
            // alert('paid successfully');
            // setItemDetails({
            //     ...itemDetails,
            //     ledgerNo: response.data.ledgerNo,
            //     labNo: 'Lab-' + zeroPad(response.data.labNo, 4),
            //     invoiceNo: 'Lab-' + zeroPad(response.data.labNo, 4)
            // });
        });
        // console.log('form ', from);
    };
    const spinnerStyles = css`
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
    `;

    const onAddUpdateItemModalClose = () => {
        props.setAddNewItemModal(false);
        props.setEditItem(null);
    };
    // payingAmount: '0',
    // dues: '',
    // totalCommission: '',
    // totalPaid: ''
    const getDetails = async () => {
        setPosting(true);
        var primaryData = await axiosInstance.get(`getCutsForTimeRange?from=${from}&to=${to}&constName=${itemDetails.constName}`);
        console.log('primaryData is ', primaryData.data.totalCommission);
        setItemDetails({
            ...itemDetails,
            totalCommission: primaryData.data.totalCommission,
            totalPaid: primaryData.data.reffPayment ? primaryData.data.reffPayment : 0,
            dues: parseInt(primaryData.data.totalCommission) - parseInt(primaryData.data.reffPayment ? primaryData.data.reffPayment : 0)
        });
        setPosting(false);
    };

    return (
        <>
            <Modal className="modal-lg" isOpen={props.addNewItemModal} toggle={() => onAddUpdateItemModalClose()}>
                <ModalHeader toggle={() => onAddUpdateItemModalClose()}>Add New Item</ModalHeader>
                <ModalBody>
                    {/* {loading &&
                        <RctSectionLoader />
                    } */}

                    <Form className="row">
                        <div className="col-12">
                            <div className="mngdevider">
                                <div class="row">
                                    <h3 className="mngtitle">Doctor Payment Voucher </h3>
                                    <FormGroup className="col-lg-6">
                                        <LocalizationProvider className="managedate" dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                label="From"
                                                // value={from}
                                                value={from}
                                                inputFormat="DD-MM-YYYY"
                                                format="d-m-Y"
                                                onChange={(newFrom) => {
                                                    setFrom(newFrom);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        fullWidth
                                                        {...params}
                                                        value={formatDateDMY(params.value)}
                                                        size="small"
                                                        id="from"
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    </FormGroup>
                                    <FormGroup className="col-lg-6">
                                        <LocalizationProvider className="managedate" dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                label="To"
                                                inputFormat="DD-MM-YYYY"
                                                format="d-m-Y"
                                                value={to}
                                                onChange={(newTo) => {
                                                    setTo(newTo);
                                                }}
                                                renderInput={(params) => <TextField fullWidth {...params} size="small" id="to" />}
                                            />
                                        </LocalizationProvider>
                                    </FormGroup>
                                    <FormGroup className="col-lg-2">
                                        <FormControl sx={{ minWidth: '100%' }} size="small">
                                            <InputLabel id="consultantTitle">Mr</InputLabel>
                                            <Select
                                                id="consultantTitle"
                                                value={itemDetails.consultantTitle}
                                                label="Mr"
                                                onChange={(e) => onChangeItemDetails('consultantTitle', e.target.value)}
                                            >
                                                <MenuItem value={'Mr'}>Mr.</MenuItem>
                                                <MenuItem value={'Mrs'}>Mrs.</MenuItem>
                                                <MenuItem value={'Miss'}>Miss.</MenuItem>
                                                <MenuItem value={'Master'}>Master.</MenuItem>
                                                <MenuItem value={'Baby'}>Baby.</MenuItem>
                                                <MenuItem value={'Md'}>Md.</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </FormGroup>
                                    <FormGroup className="col-lg-4">
                                        <FormControl sx={{ minWidth: '100%' }} size="small">
                                            <InputLabel id="constName">consultant Name</InputLabel>
                                            <Select
                                                id="constName"
                                                value={itemDetails.constName}
                                                label="Mr."
                                                onChange={(e) => onChangeItemDetails('constName', e.target.value)}
                                            >
                                                {consultants}
                                            </Select>
                                        </FormControl>
                                    </FormGroup>
                                    <FormGroup className="col-lg-3">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="consultant Designation"
                                            value={itemDetails.constDesignation}
                                            onChange={(e) => onChangeItemDetails('constDesignation', e.target.value)}
                                            id="constDesignation"
                                            size="small"
                                        />
                                    </FormGroup>
                                    <FormGroup className="col-lg-3">
                                        <FormControl sx={{ minWidth: '100%' }} size="small">
                                            {posting ? (
                                                <BeatLoader color="#000" css={spinnerStyles} />
                                            ) : (
                                                <Button variant="contained" className="text-white btn-warning" onClick={getDetails}>
                                                    Get Details
                                                </Button>
                                            )}
                                        </FormControl>
                                    </FormGroup>
                                    <FormGroup className="col-lg-3">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Total Cuts "
                                            value={itemDetails.totalCommission}
                                            onChange={(e) => onChangeItemDetails('totalCommission', e.target.value)}
                                            id="totalCommission"
                                            size="small"
                                        />
                                    </FormGroup>{' '}
                                    <FormGroup className="col-lg-3">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Previous Payment For Pathology"
                                            value={itemDetails.totalPaid}
                                            onChange={(e) => onChangeItemDetails('totalPaid', e.target.value)}
                                            id="totalPaid"
                                            size="small"
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="mngdevider">
                                <h3 className="mngtitle">Payment </h3>
                                <div class="row">
                                    <FormGroup className="col-lg-4">
                                        <LocalizationProvider className="managedate" dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                inputFormat="DD-MM-YYYY"
                                                format="d-m-Y"
                                                label="Date"
                                                value={itemDetails.date}
                                                onChange={(newDate) => {
                                                    setItemDetails({ ...itemDetails, date: newDate });
                                                }}
                                                renderInput={(params) => <TextField fullWidth {...params} size="small" id="date" />}
                                            />
                                        </LocalizationProvider>
                                    </FormGroup>
                                    <FormGroup className="col-lg-4">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Paid Amount"
                                            value={itemDetails.paidAmount}
                                            onChange={(e) => onChangeItemDetails('paidAmount', e.target.value)}
                                            id="paidAmount"
                                            size="small"
                                        />
                                    </FormGroup>
                                    <FormGroup className="col-lg-4">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Dues"
                                            value={itemDetails.dues}
                                            onChange={(e) => onChangeItemDetails('dues', e.target.value)}
                                            id="dues"
                                            size="small"
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button variant="contained" disabled={save} className="text-white btn-primary-dark" onClick={handleAdd}>
                        Add
                    </Button>
                    <Button variant="contained" disabled={!save} className="text-white btn-success" onClick={handleSave}>
                        Save
                    </Button>
                    <Button variant="contained" className="text-white btn-danger" onClick={() => onAddUpdateItemModalClose()}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};
export default AccountConsultantDoctor;
