
import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { Button } from "reactstrap";

// // rct card box
import RctCollapsibleCard from '../../component/RctCollapsibleCard/RctCollapsibleCard';

class ComponentToPrint extends React.Component {
    render() {
        return (
            <RctCollapsibleCard fullBlock>
                <br/><br/><br/><br/><br/>
                <div style={{width: "80%",margin: "0 auto",borderBottom:"2px solid #000",borderTop:"2px solid #000",marginTop: "5px"}}>
                    <table style={{width: "100%",borderCollapse: "collapse"}}>
                        <tr>
                            <td>Patient Name:- ABC</td>
                            <td></td>
                            <td>Test Date:- 07-07-2021</td>
                            <td>Ledger No.:- 1</td>
                        </tr>
                        <tr>
                            <td>Discount:- 0</td>
                            <td>Paid Amount:- 1000</td>
                            <td>Dues Amount:- 0</td>
                            <td>Total Amount:- 1000</td>
                        </tr>
                    </table>
                    <table style={{width: "100%",borderCollapse: "collapse"}}>
                        <tr>
                            <td>Investigation:- Lorem Ipsum is simply dummy text of the printing and typesetting industry.</td>
                        </tr>
                    </table>
                </div>
                <div style={{width: "80%",margin: "0 auto",borderBottom:"2px solid #000",marginTop: "5px"}}>
                    <table style={{width: "100%",borderCollapse: "collapse"}}>
                        <tr>
                            <td>Patient Name:- ABC</td>
                            <td></td>
                            <td>Test Date:- 07-07-2021</td>
                            <td>Ledger No.:- 1</td>
                        </tr>
                        <tr>
                            <td>Discount:- 0</td>
                            <td>Paid Amount:- 1000</td>
                            <td>Dues Amount:- 0</td>
                            <td>Total Amount:- 1000</td>
                        </tr>
                    </table>
                    <table style={{width: "100%",borderCollapse: "collapse"}}>
                        <tr>
                            <td>Investigation:- Lorem Ipsum is simply dummy text of the printing and typesetting industry.</td>
                        </tr>
                    </table>
                </div>
                <div style={{width: "80%",margin: "0 auto",borderBottom:"2px solid #000",marginTop: "5px"}}>
                    <table style={{width: "100%",borderCollapse: "collapse"}}>
                        <tr>
                            <td>Patient Name:- ABC</td>
                            <td></td>
                            <td>Test Date:- 07-07-2021</td>
                            <td>Ledger No.:- 1</td>
                        </tr>
                        <tr>
                            <td>Discount:- 0</td>
                            <td>Paid Amount:- 1000</td>
                            <td>Dues Amount:- 0</td>
                            <td>Total Amount:- 1000</td>
                        </tr>
                    </table>
                    <table style={{width: "100%",borderCollapse: "collapse"}}>
                        <tr>
                            <td>Investigation:- Lorem Ipsum is simply dummy text of the printing and typesetting industry.</td>
                        </tr>
                    </table>
                </div>
                <div style={{width: "80%",margin: "0 auto",borderBottom:"2px solid #000",marginTop: "5px"}}>
                    <table style={{width: "100%",borderCollapse: "collapse"}}>
                        <tr>
                            <td>Patient Name:- ABC</td>
                            <td></td>
                            <td>Test Date:- 07-07-2021</td>
                            <td>Ledger No.:- 1</td>
                        </tr>
                        <tr>
                            <td>Discount:- 0</td>
                            <td>Paid Amount:- 1000</td>
                            <td>Dues Amount:- 0</td>
                            <td>Total Amount:- 1000</td>
                        </tr>
                    </table>
                    <table style={{width: "100%",borderCollapse: "collapse"}}>
                        <tr>
                            <td>Investigation:- Lorem Ipsum is simply dummy text of the printing and typesetting industry.</td>
                        </tr>
                    </table>
                </div>
                <div style={{width: "80%",margin: "0 auto",borderBottom:"2px solid #000",marginTop: "5px"}}>
                    <table style={{width: "100%",borderCollapse: "collapse"}}>
                        <tr>
                            <td>Patient Name:- ABC</td>
                            <td></td>
                            <td>Test Date:- 07-07-2021</td>
                            <td>Ledger No.:- 1</td>
                        </tr>
                        <tr>
                            <td>Discount:- 0</td>
                            <td>Paid Amount:- 1000</td>
                            <td>Dues Amount:- 0</td>
                            <td>Total Amount:- 1000</td>
                        </tr>
                    </table>
                    <table style={{width: "100%",borderCollapse: "collapse"}}>
                        <tr>
                            <td>Investigation:- Lorem Ipsum is simply dummy text of the printing and typesetting industry.</td>
                        </tr>
                    </table>
                </div>
                <div style={{width: "80%",margin: "0 auto",borderBottom:"2px solid #000",marginTop: "5px"}}>
                    <table style={{width: "100%",borderCollapse: "collapse"}}>
                        <tr>
                            <td>Patient Name:- ABC</td>
                            <td></td>
                            <td>Test Date:- 07-07-2021</td>
                            <td>Ledger No.:- 1</td>
                        </tr>
                        <tr>
                            <td>Discount:- 0</td>
                            <td>Paid Amount:- 1000</td>
                            <td>Dues Amount:- 0</td>
                            <td>Total Amount:- 1000</td>
                        </tr>
                    </table>
                    <table style={{width: "100%",borderCollapse: "collapse"}}>
                        <tr>
                            <td>Investigation:- Lorem Ipsum is simply dummy text of the printing and typesetting industry.</td>
                        </tr>
                    </table>
                </div>
                <div style={{width: "80%",margin: "0 auto",borderBottom:"2px solid #000",marginTop: "5px"}}>
                    <table style={{width: "100%",borderCollapse: "collapse"}}>
                        <tr>
                            <td>Patient Name:- ABC</td>
                            <td></td>
                            <td>Test Date:- 07-07-2021</td>
                            <td>Ledger No.:- 1</td>
                        </tr>
                        <tr>
                            <td>Discount:- 0</td>
                            <td>Paid Amount:- 1000</td>
                            <td>Dues Amount:- 0</td>
                            <td>Total Amount:- 1000</td>
                        </tr>
                    </table>
                    <table style={{width: "100%",borderCollapse: "collapse"}}>
                        <tr>
                            <td>Investigation:- Lorem Ipsum is simply dummy text of the printing and typesetting industry.</td>
                        </tr>
                    </table>
                </div>
                <br/>
                <div style={{textAlign: "center",fontWeight: "bold"}}>-:GRAND TOTAL:-</div>
                <div style={{width: "80%",margin: "0 auto",padding: "5px 0",borderTop: "2px solid #000",borderBottom: "2px solid #000"}}>
                    <table style={{width: "100%",borderCollapse: "collapse"}}>
                        <tr>
                            <td>Doctor Payment:- 900</td>
                            <td>Agent Payment:- 0</td>
                            <td>Expenses:- 2550</td>
                            <td>Discount:- 0</td>
                        </tr>
                        <tr>
                            <td>Duse Amount:- 450</td>
                            <td>Paid Amount:- 2550</td>
                            <td>Total Ammount: 2950</td>
                            <td><b>Cash In Hand:- 1450</b></td>
                        </tr>
                    </table>
                </div>
                <br/>
            </RctCollapsibleCard>
        );
    }
}

const ReportNine = () => {
    const componentRef = useRef();
    return (
      <div>
        <ReactToPrint
          trigger={() => <Button>Print this out!</Button>}
          content={() => componentRef.current}
        />
        <ComponentToPrint ref={componentRef} />
      </div>
    );
  };

export default ReportNine;