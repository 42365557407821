import React, { useState, useEffect } from 'react';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import Button from '@mui/material/Button';
// api
import axios from 'axios';
import baseUrl from '../../../api';

// import RctSectionLoader from '../../../component/RctSectionLoader/RctSectionLoader';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

// Date
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

//multiselect
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select, { SelectChangeEvent } from '@mui/material/Select';

const AddViewResult = (props) => {
    const [date, setDate] = React.useState(null);
    const [age, setage] = React.useState(null);

    const [itemDetails, setItemDetails] = useState({
        ledgerNo: '',
        labNo: '',
        patientName: '',
        gender: '',
        age: '',
        doctorName: '',
        degsignation: '',
        hospital: '',
        st: '',
        t: ''
    });

    const onChangeItemDetails = (key, value) => {
        var arr = { ...itemDetails };
        arr[key] = value;
        setItemDetails((prevState) => arr);
    };

    const handleOnAddItem = (e) => {
        e.preventDefault();
        // axios.get("ec2-15-206-84-254.ap-south-1.compute.amazonaws.com:3000/api/master/site").then((response) => console.log("response",response));

        // axios
        // .post(`${baseUrl}/api/master/addConsultantDoctorMaster`,
        // )
        // .then((response) => {
        //     // successfully uploaded response
        // })
        // .catch((error) => {
        //     // error response
        // });
    };

    const onAddUpdateItemModalClose = () => {
        props.setAddNewItemModal(false);
        props.setEditItem(null);
    };

    return (
        <>
            <Modal className="modal-lg" isOpen={props.addNewItemModal} toggle={() => onAddUpdateItemModalClose()}>
                <ModalHeader toggle={() => onAddUpdateItemModalClose()}>Add New Item</ModalHeader>
                <ModalBody>
                    {/* {loading &&
                        <RctSectionLoader />
                    } */}
                    <Form className="row">
                        <FormGroup className="col-lg-4">
                            <LocalizationProvider className="managedate" dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Date"
                                    // value={itemDetails.dob}
                                    // onChange={(e) =>
                                    //     onChangeItemDetails("dob", e.target.value)
                                    // }
                                    value={date}
                                    onChange={(newDate) => {
                                        setDate(newDate);
                                    }}
                                    renderInput={(params) => <TextField fullWidth {...params} size="small" id="date" />}
                                />
                            </LocalizationProvider>
                        </FormGroup>
                        <FormGroup className="col-lg-4">
                            <TextField
                                fullWidth
                                type="text"
                                label="Ledger No"
                                value={itemDetails.ledgerNo}
                                onChange={(e) => onChangeItemDetails('ledgerNo', e.target.value)}
                                id="ledgerNo"
                                size="small"
                            />
                        </FormGroup>
                        <FormGroup className="col-lg-4">
                            <TextField
                                fullWidth
                                type="text"
                                label="Lab No"
                                value={itemDetails.labNo}
                                onChange={(e) => onChangeItemDetails('labNo', e.target.value)}
                                id="labNo"
                                size="small"
                            />
                        </FormGroup>
                        <div className="col-12">
                            <div className="mngdevider">
                                <h3 className="mngtitle">Patient Datils </h3>
                                <div className="row">
                                    <FormGroup className="col-lg-4">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Patient Name"
                                            value={itemDetails.patientName}
                                            onChange={(e) => onChangeItemDetails('patientName', e.target.value)}
                                            id="patientName"
                                            size="small"
                                        />
                                    </FormGroup>
                                    <FormGroup className="col-lg-4">
                                        <FormControl sx={{ minWidth: '100%' }} size="small">
                                            <InputLabel id="gender">Gender</InputLabel>
                                            <Select
                                                id="gender"
                                                value={itemDetails.gender}
                                                label="Gender"
                                                onChange={(e) => onChangeItemDetails('gender', e.target.value)}
                                            >
                                                <MenuItem value={'Male'}>Male</MenuItem>
                                                <MenuItem value={'Female'}>Female</MenuItem>
                                                <MenuItem value={'Transgender'}>Transgender</MenuItem>
                                                <MenuItem value={'Other'}>Other</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </FormGroup>
                                    <FormGroup className="col-lg-4">
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                label="Age"
                                                // value={itemDetails.marrigeDay}
                                                // onChange={(e) =>
                                                //     onChangeItemDetails("marrigeDay", e.target.value)
                                                // }
                                                value={age}
                                                onChange={(newage) => {
                                                    setage(newage);
                                                }}
                                                renderInput={(params) => <TextField fullWidth {...params} size="small" id="age" />}
                                            />
                                        </LocalizationProvider>
                                    </FormGroup>
                                    <FormGroup className="col-lg-2">
                                        <FormControl sx={{ minWidth: '100%' }} size="small">
                                            <InputLabel id="doctorTitle">Mr</InputLabel>
                                            <Select
                                                id="doctorTitle"
                                                value={itemDetails.doctorTitle}
                                                label="Mr"
                                                onChange={(e) => onChangeItemDetails('doctorTitle', e.target.value)}
                                            >
                                                <MenuItem value={'Mr'}>Mr.</MenuItem>
                                                <MenuItem value={'Mrs'}>Mrs.</MenuItem>
                                                <MenuItem value={'Miss'}>Miss.</MenuItem>
                                                <MenuItem value={'Md'}>Md.</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </FormGroup>
                                    <FormGroup className="col-lg-4">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Doctor Name"
                                            value={itemDetails.doctorName}
                                            onChange={(e) => onChangeItemDetails('doctorName', e.target.value)}
                                            id="doctorName"
                                            size="small"
                                        />
                                    </FormGroup>
                                    <FormGroup className="col-lg-3">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Degsignation"
                                            value={itemDetails.degsignation}
                                            onChange={(e) => onChangeItemDetails('degsignation', e.target.value)}
                                            id="degsignation"
                                            size="small"
                                        />
                                    </FormGroup>
                                    <FormGroup className="col-lg-3">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Hospital"
                                            value={itemDetails.hospital}
                                            onChange={(e) => onChangeItemDetails('hospital', e.target.value)}
                                            id="hospital"
                                            size="small"
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                        <FormGroup className="col-lg-2">
                            <TextField
                                fullWidth
                                type="text"
                                label="St"
                                value={itemDetails.st}
                                onChange={(e) => onChangeItemDetails('st', e.target.value)}
                                id="st"
                                size="small"
                            />
                        </FormGroup>
                        <FormGroup className="col-lg-2">
                            <TextField
                                fullWidth
                                type="text"
                                label="T"
                                value={itemDetails.t}
                                onChange={(e) => onChangeItemDetails('t', e.target.value)}
                                id="t"
                                size="small"
                            />
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button variant="contained" className="text-white btn-success" onClick={handleOnAddItem}>
                        Save
                    </Button>
                    <Button variant="contained" className="text-white btn-danger" onClick={() => onAddUpdateItemModalClose()}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};
export default AddViewResult;
