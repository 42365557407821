import axiosInstance from '../api/axiosInstance';
import { useQuery } from 'react-query';
export function sayHello() {}
export const useFetchTestCode = () => {
    return useQuery(
        'getAllTest',
        () => {
            return axiosInstance.get('getTestCode');
        },
        {
            refetchOnWindowFocus: false,
            // onSuccess: onSuccess,
            cacheTime: 60 * 1000 * 720, // 12 hours
            staleTime: 60 * 1000 * 720 // 12 hours
        }
    );
};
// export const useFetchSettings = () => {
//     return useQuery(
//         'getAllSettings',
//         () => {
//             return axiosInstance.get('getsetting');
//         },
//         {
//             refetchOnWindowFocus: false,
//             // onSuccess: onSuccess,
//             cacheTime: 60 * 1000 * 720, // 12 hours
//             staleTime: 60 * 1000 * 720 // 12 hours
//         }
//     );

// };
export const useFetchSettings = () => {
    const query = useQuery(
        'getAllSettings',
        () => {
            return axiosInstance.get('getsetting');
        },
        {
            refetchOnWindowFocus: false,
            cacheTime: 60 * 1000 * 720, // 12 hours
            staleTime: 60 * 1000 * 720 // 12 hours
        }
    );

    if (query.error) {
        console.log(query.error); // or display the error message in the UI
    }

    return query;
};

export const useFetchCompanyDetails = () => {
    return useQuery(
        'getCompanyDetails',
        () => {
            return axiosInstance.get('getCompany');
        },
        {
            refetchOnWindowFocus: false,
            // onSuccess: onSuccess,
            cacheTime: 60 * 1000 * 720, // 12 hours
            staleTime: 60 * 1000 * 720 // 12 hours
        },
        []
    );
};

export const useFetchConsultant = () => {
    return useQuery(
        'getConsultant',
        () => {
            return axiosInstance.get('getConsultant');
        },
        {
            refetchOnWindowFocus: false,
            // onSuccess: onSuccess,
            cacheTime: 60 * 1000 * 720, // 12 hours
            staleTime: 60 * 1000 * 720 // 12 hours
        }
    );
};

export const useFetchPatient = () => {
    return useQuery(
        'getPatient',
        () => {
            return axiosInstance.get('getPatient');
        },
        {
            refetchOnWindowFocus: false,
            // onSuccess: onSuccess,
            cacheTime: 60 * 1000 * 720, // 12 hours
            staleTime: 60 * 1000 * 720 // 12 hours
        }
    );
};
export const formatDateYMD = (date) => {
    if (!date || !(date instanceof Date)) {
        return '';
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};
export const formatDateDMY = (date) => {
    if (!date || !(date instanceof Date)) {
        return '';
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${day}-${month}-${year}`;
};

export const firstOfCurrentMonth = () => {
    const currentDate = new Date();
    const currentMonthDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    let returnDate = formatDateYMD(currentMonthDate);
    return returnDate;
};
export function price_in_words(price) {
    var sglDigit = ['Zero', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'],
        dblDigit = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'],
        tensPlace = ['', 'Ten', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'],
        handle_tens = function (dgt, prevDgt) {
            return 0 == dgt ? '' : ' ' + (1 == dgt ? dblDigit[prevDgt] : tensPlace[dgt]);
        },
        handle_utlc = function (dgt, nxtDgt, denom) {
            return (0 != dgt && 1 != nxtDgt ? ' ' + sglDigit[dgt] : '') + (0 != nxtDgt || dgt > 0 ? ' ' + denom : '');
        };

    var str = '',
        digitIdx = 0,
        digit = 0,
        nxtDigit = 0,
        words = [];
    if (((price += ''), isNaN(parseInt(price)))) str = '';
    else if (parseInt(price) > 0 && price.length <= 10) {
        for (digitIdx = price.length - 1; digitIdx >= 0; digitIdx--)
            switch (((digit = price[digitIdx] - 0), (nxtDigit = digitIdx > 0 ? price[digitIdx - 1] - 0 : 0), price.length - digitIdx - 1)) {
                case 0:
                    words.push(handle_utlc(digit, nxtDigit, ''));
                    break;
                case 1:
                    words.push(handle_tens(digit, price[digitIdx + 1]));
                    break;
                case 2:
                    words.push(
                        0 != digit
                            ? ' ' + sglDigit[digit] + ' Hundred' + (0 != price[digitIdx + 1] && 0 != price[digitIdx + 2] ? ' and' : '')
                            : ''
                    );
                    break;
                case 3:
                    words.push(handle_utlc(digit, nxtDigit, 'Thousand'));
                    break;
                case 4:
                    words.push(handle_tens(digit, price[digitIdx + 1]));
                    break;
                case 5:
                    words.push(handle_utlc(digit, nxtDigit, 'Lakh'));
                    break;
                case 6:
                    words.push(handle_tens(digit, price[digitIdx + 1]));
                    break;
                case 7:
                    words.push(handle_utlc(digit, nxtDigit, 'Crore'));
                    break;
                case 8:
                    words.push(handle_tens(digit, price[digitIdx + 1]));
                    break;
                case 9:
                    words.push(
                        0 != digit
                            ? ' ' +
                                  sglDigit[digit] +
                                  ' Hundred' +
                                  (0 != price[digitIdx + 1] || 0 != price[digitIdx + 2] ? ' and' : ' Crore')
                            : ''
                    );
            }
        str = words.reverse().join('');
    } else str = '';
    return str;
}
