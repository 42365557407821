import React, { useState, useEffect } from 'react';
import { Form, FormGroup } from 'reactstrap';
import Button from '@mui/material/Button';
// api
import axios from 'axios';
import baseUrl from '../../../api';

// import RctSectionLoader from '../../../component/RctSectionLoader/RctSectionLoader';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

//multiselect
import TextField from '@mui/material/TextField';

const AddSMSMaster = (props) => {
    const [itemDetails, setItemDetails] = useState({
        reportMessage: '',
        mobile: '',
        paymentMessage: '',
        MSG: '',
        Url: ''
    });

    const onChangeItemDetails = (key, value) => {
        var arr = { ...itemDetails };
        arr[key] = value;
        setItemDetails((prevState) => arr);
    };

    const handleOnAddItem = (e) => {
        e.preventDefault();
        // axios.get("ec2-15-206-84-254.ap-south-1.compute.amazonaws.com:3000/api/master/site").then((response) => console.log("response",response));

        // axios
        // .post(`${baseUrl}/api/master/addSMSMaster`, dataArray
        // )
        // .then((response) => {
        //     // successfully uploaded response
        // })
        // .catch((error) => {
        //     // error response
        // });
    };

    const onAddUpdateItemModalClose = () => {
        props.setAddNewItemModal(false);
        props.setEditItem(null);
    };

    return (
        <>
            <Modal className="modal-lg" isOpen={props.addNewItemModal} toggle={() => onAddUpdateItemModalClose()}>
                <ModalHeader toggle={() => onAddUpdateItemModalClose()}>Add New Item</ModalHeader>
                <ModalBody>
                    {/* {loading &&
                        <RctSectionLoader />
                    } */}
                    <Form className="row">
                        <div className="col-lg-6">
                            <FormGroup>
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={4}
                                    type="text"
                                    label="Report Message"
                                    defaultValue={itemDetails.reportMessage}
                                    onChange={(e) => onChangeItemDetails('reportMessage', e.target.value)}
                                    id="reportMessage"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup>
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="Mobile"
                                    defaultValue={itemDetails.mobile}
                                    onChange={(e) => onChangeItemDetails('mobile', e.target.value)}
                                    id="mobile"
                                    size="small"
                                />
                            </FormGroup>
                        </div>
                        <div className="col-lg-6">
                            <FormGroup>
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={4}
                                    type="text"
                                    label="Payment Message"
                                    defaultValue={itemDetails.paymentMessage}
                                    onChange={(e) => onChangeItemDetails('paymentMessage', e.target.value)}
                                    id="paymentMessage"
                                    size="small"
                                />
                            </FormGroup>
                            <FormGroup>
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="MSG"
                                    defaultValue={itemDetails.MSG}
                                    onChange={(e) => onChangeItemDetails('MSG', e.target.value)}
                                    id="MSG"
                                    size="small"
                                />
                            </FormGroup>
                        </div>
                        <FormGroup className="col-lg-12">
                            <TextField
                                fullWidth
                                type="text"
                                label="Url"
                                defaultValue={itemDetails.Url}
                                onChange={(e) => onChangeItemDetails('Url', e.target.value)}
                                id="Url"
                                size="small"
                            />
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button variant="contained" className="text-white btn-success" onClick={handleOnAddItem}>
                        Save
                    </Button>
                    <Button variant="contained" className="text-white btn-danger" onClick={() => onAddUpdateItemModalClose()}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};
export default AddSMSMaster;
