
import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { Button } from "reactstrap"
import Barcode from 'assets/barcode.png';

// // rct card box
import RctCollapsibleCard from '../../component/RctCollapsibleCard/RctCollapsibleCard';

class ComponentToPrint extends React.Component {
    render() {
        return (
            <RctCollapsibleCard fullBlock><br/><br/><br/><br/>
                <div style={{border: "3px solid #888",width: "80%",padding: "10px 0", margin: "0 auto",position: "relative"}}>
                    <div style={{display: "flex",marginRight: "-15px",marginLeft: "-15px"}}>
                        <div style={{float: "left",minHeight: "1px",flex: "0 0 60%",maxWidth: "60%",paddingRight: "15px",paddingLeft: "25px"}}>
                            <p style={{marginTop:"10px",marginBottom: "10px"}}>Name: Mr. ABCD</p>
                            <p style={{margintTop:"0",marginBottom:"10px"}}>Age: 25 Year</p>
                            <p style={{margintop:"0",marginBottom: "10px" }}>Sex: Male</p>
                            <p style={{marginBop:"0",marginBottom: "10px"}}>Reffered By: Dr. Sachin Kr mbbs md</p>
                        </div>
                        <div style={{float: "left",minHeight: "1px",position: "relative",width: "100%",flex: "0 0 40%",maxWidth: "40%",paddingRight: "25px",paddingLeft: "15px",paddingTop:"10px"}}>
                            <img src={Barcode} alt="Qr Code" height="80px" />
                            <p style={{marginBop:"0",marginBottom: "10px"}}>Received On: 21-11-2022</p>
                            <p style={{marginBop:"0",marginBottom: "10px"}}>Received On: 21-11-2022</p>
                        </div>
                    </div>
                </div>
                <br/><br/>
                <div style={{width: "80%",margin: "0 auto",borderBottom:"2px solid #000",marginTop: "5px"}}>
                    <table style={{width: "100%",borderCollapse: "collapse"}}>
                    <tr>
                        <td>Patient Name:- ABC</td>
                        <td>No. Of Test:- 3</td>
                        <td>Patho Comm:- 0</td>
                        <td>U.Sound Comm:- 0</td>
                    </tr>
                    <tr>
                        <td>Paid Amount:- 1000</td>
                        <td>X-Ray Comm:- 500</td>
                        <td>M.R.I Comm:- 0</td>
                        <td>Ledger No.:- 1</td>
                    </tr>
                    <tr>
                        <td>Net Amount:- 1000</td>
                        <td>O.T.Scan Comm:- 0</td>
                        <td>Test Date:- 07-07-2021</td>
                     </tr>
                    </table>
                    <table style={{width: "100%",borderCollapse: "collapse"}}>
                        <tr>
                            <td>Investigation:- Lorem Ipsum is simply dummy text of the printing and typesetting industry.</td>
                        </tr>
                    </table>
                </div>
                <br/>
                <div style={{textAlign: "center",fontWeight: "bold"}}>-:GRAND TOTAL:-</div>
                <div style={{width: "80%",margin: "0 auto",padding: "5px 0",borderTop: "2px solid #000",borderBottom: "2px solid #000"}}>
                    <table style={{width: "100%",borderCollapse: "collapse"}}>
                        <tr>
                            <td>Doctor Payment:- 900</td>
                            <td>Agent Payment:- 0</td>
                            <td>Expenses:- 2550</td>
                            <td>Discount:- 0</td>
                        </tr>
                        <tr>
                            <td>Duse Amount:- 450</td>
                            <td>Paid Amount:- 2550</td>
                            <td>Total Ammount: 2950</td>
                            <td><b>Cash In Hand:- 1450</b></td>
                        </tr>
                    </table>
                </div>
                <br/>
            </RctCollapsibleCard>
        );
    }
}

const ReportEight = () => {
    const componentRef = useRef();
    return (
      <div>
        <ReactToPrint
          trigger={() => <Button>Print this out!</Button>}
          content={() => componentRef.current}
        />
        <ComponentToPrint ref={componentRef} />
      </div>
    );
  };

export default ReportEight;