import React, { useState, useEffect } from 'react';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import Button from '@mui/material/Button';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
//multiselect
import TextField from '@mui/material/TextField';

// Add Content Start

import data from './mock-data.json';
// Add Content End

const AddTest = (props) => {
    // Add Content Start
    const [contacts, setContacts] = useState(data);
    const [addFormData, setAddFormData] = useState({
        tstGrpName: '',
        testCode: '',
        testName: '',
        subtest1: '',
        subtest2: '',
        testRate: '',
        units: '',
        type: '',
        defaultValue: '',
        normalValueMen: '',
        normalValueWomen: '',
        normalValueChild: '',
        pathologyType: '',
        method: '',
        comment: '',
        note: ''
    });

    //     event.preventDefault();
    //     const newContact = {
    //         id: nanoid(),
    //         tstGrpName : addFormData.tstGrpName ,
    //         testCode: addFormData.testCode,
    //         testName: addFormData.testName,
    //         subtest1: addFormData.subtest1,
    //         subtest2: addFormData.subtest2,
    //          testRate : addFormData. testRate ,
    //         units: addFormData.units,
    //         type: addFormData.type,
    //         defaultValue: addFormData.defaultValue,
    //         NVForMen: addFormData.NVForMen,
    //         normalValueWomen: addFormData.normalValueWomen,
    //         normalValueChild: addFormData.NVForChild,
    //         pathologyType: addFormData.pathologyType,
    //         method: addFormData.method,
    //         comment: addFormData.comment,
    //         note: addFormData.note,
    //     };
    //     const newContacts = [...contacts, newContact];
    //     setContacts(newContacts);
    // };

    const handleAddFormSubmit = (event) => {
        event.preventDefault();
    };

    const handleAddFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...addFormData };
        newFormData[fieldName] = fieldValue;
        setAddFormData(newFormData);
    };

    const onAddUpdateItemModalClose = () => {
        props.setAddNewItemModal(false);
        props.setEditItem(null);
    };
    const handleSubmit = () => {
        console.log('show details', addFormData);
        props.onHandleSubmit(addFormData);
        // console.log(`${baseUrl}/api/master/addtTestGroup`)
        // alert(`${baseUrl}/addTest`);
        // axios
        // .post(`${baseUrl}/addTest`,addFormData
        // )
        // .then((response) => {
        //     // refetch();
        //     // successfully uploaded response
        // })
        // console.log("on handle submit",addFormData)
    };
    const handleOnAddItem = (addFormData) => {
        // axios.get("ec2-15-206-84-254.ap-south-1.compute.amazonaws.com:3000/api/master/site").then((response) => console.log("response",response));
        // .catch((error) => {
        //     // error response
        // });
        // alert(`${baseUrl}/api/master/addtTestGroupMaster`);
    };

    return (
        <>
            <Modal className="modal-lg" isOpen={props.addNewItemModal} toggle={() => onAddUpdateItemModalClose()}>
                <ModalHeader toggle={() => onAddUpdateItemModalClose()}>Search By</ModalHeader>
                <form onSubmit={handleAddFormSubmit}>
                    <ModalBody>
                        {/* {loading &&
                            <RctSectionLoader />
                        } */}
                        <div className="row">
                            {/* <FormGroup check className='col-lg-6'>
                                <Label check className='ml-10'>
                                    <Input type="radio" name="test" 
                                        defaultValue={itemDetails.testName}
                                        onChange={(e) =>
                                            onChangeItemDetails("testName", e.target.value)
                                        }
                                        id="testName"
                                    /> Test Code
                                </Label>
                            </FormGroup>
                            <FormGroup check className='col-lg-6'>
                                <Label check className='ml-10'>
                                    <Input type="radio" name="test" 
                                        defaultValue={itemDetails.testCode}
                                        onChange={(e) =>
                                            onChangeItemDetails("testCode", e.target.value)
                                        }
                                        id="testCode"
                                    /> Test Name
                                </Label>
                            </FormGroup> */}

                            <FormGroup className="col-lg-4">
                                <TextField
                                    fullWidth
                                    type="text"
                                    name="tstGrpName "
                                    label="Test Grp Name"
                                    // onChange={{...addFormData},addFormData.tstGrpName: e.target.value}
                                    // onChange={(e)=>{setAddFormData(...addFormData,"tstGrpName": e.target.value)}}
                                    // onChange={(e)=>{setAddFormData({...addFormData,tstGrpName:{e.target.value})}}
                                    onChange={(e) => {
                                        setAddFormData({ ...addFormData, tstGrpName: e.target.value });
                                    }}
                                    size="small"
                                    value={addFormData.tstGrpName}
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-4">
                                <TextField
                                    fullWidth
                                    type="text"
                                    name="testCode"
                                    label="Test Code"
                                    onChange={(e) => {
                                        setAddFormData({ ...addFormData, testCode: e.target.value });
                                    }}
                                    size="small"
                                    value={addFormData.testCode}
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-4">
                                <TextField
                                    fullWidth
                                    type="text"
                                    name="testName"
                                    label="Test Name"
                                    onChange={(e) => {
                                        setAddFormData({ ...addFormData, testName: e.target.value });
                                    }}
                                    size="small"
                                    value={addFormData.testName}
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-4">
                                <TextField
                                    fullWidth
                                    type="text"
                                    name="subtest1"
                                    label="Subtest 1"
                                    onChange={(e) => {
                                        setAddFormData({ ...addFormData, subtest1: e.target.value });
                                    }}
                                    size="small"
                                    value={addFormData.subtest1}
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-4">
                                <TextField
                                    fullWidth
                                    type="text"
                                    name="subtest2"
                                    label="Subtest 2"
                                    onChange={(e) => {
                                        setAddFormData({ ...addFormData, subtest2: e.target.value });
                                    }}
                                    size="small"
                                    value={addFormData.subtest2}
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-4">
                                <TextField
                                    fullWidth
                                    type="text"
                                    name=" testRate "
                                    label=" testRate "
                                    onChange={(e) => {
                                        setAddFormData({ ...addFormData, testRate: e.target.value });
                                    }}
                                    size="small"
                                    value={addFormData.testRate}
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-4">
                                <TextField
                                    fullWidth
                                    type="text"
                                    name="units"
                                    label="Units"
                                    onChange={(e) => {
                                        setAddFormData({ ...addFormData, units: e.target.value });
                                    }}
                                    size="small"
                                    value={addFormData.units}
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-4">
                                <TextField
                                    fullWidth
                                    type="text"
                                    name="type"
                                    label="Type"
                                    onChange={(e) => {
                                        setAddFormData({ ...addFormData, type: e.target.value });
                                    }}
                                    size="small"
                                    value={addFormData.type}
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-4">
                                <TextField
                                    fullWidth
                                    type="text"
                                    name="defaultValue"
                                    label="Default Value"
                                    onChange={(e) => {
                                        setAddFormData({ ...addFormData, defaultValue: e.target.value });
                                    }}
                                    size="small"
                                    value={addFormData.defaultValue}
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-4">
                                <TextField
                                    fullWidth
                                    type="text"
                                    name="normalValueMen"
                                    label="Normal Value Men"
                                    onChange={(e) => {
                                        setAddFormData({ ...addFormData, normalValueMen: e.target.value });
                                    }}
                                    size="small"
                                    value={addFormData.normalValueMen}
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-4">
                                <TextField
                                    fullWidth
                                    type="text"
                                    name="normalValueWomen"
                                    label="Normal Value Women"
                                    onChange={(e) => {
                                        setAddFormData({ ...addFormData, normalValueWomen: e.target.value });
                                    }}
                                    size="small"
                                    value={addFormData.normalValueWomen}
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-4">
                                <TextField
                                    fullWidth
                                    type="text"
                                    name="normalValueChild"
                                    label="Normal Value Child"
                                    onChange={(e) => {
                                        setAddFormData({ ...addFormData, normalValueChild: e.target.value });
                                    }}
                                    size="small"
                                    value={addFormData.normalValueChild}
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-4">
                                <TextField
                                    fullWidth
                                    type="text"
                                    name="pathologyType"
                                    label="Pathology Type"
                                    onChange={(e) => {
                                        setAddFormData({ ...addFormData, pathologyType: e.target.value });
                                    }}
                                    size="small"
                                    value={addFormData.pathologyType}
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-4">
                                <TextField
                                    fullWidth
                                    type="text"
                                    name="method"
                                    label="Method"
                                    onChange={(e) => {
                                        setAddFormData({ ...addFormData, method: e.target.value });
                                    }}
                                    size="small"
                                    value={addFormData.method}
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-4">
                                {/* <TextField
                                    fullWidth
                                    type="text"
                                    name="comment"
                                    label="Comment"
                                    onChange={(e) => {
                                        setAddFormData({ ...addFormData, comment: e.target.value });
                                    }}
                                    size="small"
                                    value={addFormData.comment}
                                /> */}
                                <CKEditor
                                    editor={ClassicEditor}
                                    onReady={(editor) => {
                                        console.log('Editor is ready to use!', editor);
                                    }}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();

                                        setAddFormData({ ...addFormData, comment: data });

                                        console.log({ event, editor, data });
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className="col-lg-12">
                                <TextField
                                    fullWidth
                                    type="text"
                                    name="note"
                                    label="Note"
                                    onChange={(e) => {
                                        setAddFormData({ ...addFormData, note: e.target.value });
                                    }}
                                    size="small"
                                    value={addFormData.note}
                                />
                            </FormGroup>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="contained" className="text-white btn-success" onClick={handleSubmit} type="submit">
                            Save
                        </Button>
                        {/* <Button variant="contained" className="text-white btn-success" onClick={handleOnAddItem}>Save</Button> */}
                        <Button variant="contained" className="text-white btn-danger" onClick={() => onAddUpdateItemModalClose()}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </form>
            </Modal>
        </>
    );
};
export default AddTest;
