// assets
import SettingsIcon from '@mui/icons-material/Settings';

// constant
const icons = { SettingsIcon };

// ==============================|| TABLE MENU ITEMS ||============================== //

const setting = {
    id: 'setting',
    title: 'Setting',
    type: 'group',
    children: [
        {
            id: 'setting',
            title: 'Setting',
            type: 'item',
            url: 'app/setting',
            icon: icons.SettingsIcon,
            breadcrumbs: false
        }
    ]
};

export default setting;