import React, { useEffect, useState, useMemo } from 'react';
import { AuthProvider } from 'Auth';

import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { Routes, Route } from 'react-router-dom';

// defaultTheme
import themes from 'themes';
import MainLayout from 'layout/MainLayout';
import Dashboard from 'views/dashboard/Default';
// import Table from 'views/table/Table';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import Login from 'views/login/Login';
import ChangePassword from 'views/login/ChangePassword';
import ChangeBranch from 'views/login/ChangeBranch';

// master imports
import TestGroup from 'views/master/testGroup/TestGroup';
import Result from 'views/master/result';
import Test from 'views/master/test';
import Patient from 'views/master/patient';
import Method from 'views/master/method';
import Comment from 'views/master/comment';
import Rename from 'views/master/rename';
import ConsultantDoctor from 'views/master/consultantDoctor';
import Agent from 'views/master/agent';
import Company from 'views/master/company';
import Cancelation from 'views/master/cancelation';
import SmsMaster from 'views/master/smsMaster';
import Media from 'views/master/media';

// booking imports
// import AcceptTest from 'views/booking/acceptTest';
import QuickResult from 'views/booking/quickResult';

// account imports
// import PaymentVoucher from 'views/account/paymentVoucher';
import AccountConsultantDoctor from 'views/account/consultantDoctor';
import AccountAgent from 'views/account/agent';
import ReceiptVoucher from 'views/account/receiptVoucher';
import DailyExpenditure from 'views/account/dailyExpenditure';

// setting imports
import Setting from 'views/setting';

// performtest imports
import PerformTest from 'views/testPerform/performTest';

// report imports
import ViewResult from 'views/reports/viewResult';
import GenerateReport from 'views/reports/generateReport';
import ConsultantReport from 'views/reports/consultantReport';
import DailyReport from 'views/reports/dailyReport';
import MonthReport from 'views/reports/monthReport';
import AgentReport from 'views/reports/agentReport';
import ExpenditureReport from 'views/reports/expenditureReport';
import UserReport from 'views/reports/userReport';
import DuesReport from 'views/reports/duesReport';
import TestCounterReport from 'views/reports/testCounterReport';
import CancelationReport from 'views/reports/cancelationReport';

import Report from 'views/print/Report';
import ReportTwo from 'views/print/ReportTwo';
import ReportThree from 'views/print/ReportThree';
import ReportFour from 'views/print/ReportFour';
import ReportFive from 'views/print/ReportFive';
import ReportSix from 'views/print/ReportSix';
import ReportSeven from 'views/print/ReportSeven';
import ReportEight from 'views/print/ReportEight';
import ReportNine from 'views/print/ReportNine';

import Protected from 'ProtectedRoute';
import { AuthContext } from './hooks/context';
import { NotificationContainer } from 'react-notifications';
import { useNavigate } from 'react-router-dom';
// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);
    const [isLoggedIn, setIsLoggedIn] = useState(null);
    const [secondValue, setSecondValue] = useState([]);
    const value = useMemo(() => ({ secondValue, setSecondValue }), [secondValue]);

    useEffect(() => {
        if (isLoggedIn == null) {
            if (localStorage.getItem('token') == null) {
                setIsLoggedIn(false);
            } else {
                setIsLoggedIn(true);
            }
        }
    });
    const navigate = useNavigate();

    const handleLogout = () => {
        // setToken(null);

        localStorage.removeItem('token');
        localStorage.removeItem('expires_at');
        localStorage.removeItem('user_id');
        localStorage.removeItem('tenantID');
        navigate('/login');
    };
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    <AuthProvider>
                        <NotificationContainer />
                        <AuthContext.Provider value={value}>
                            <Routes>
                                <Route path="/login" element={<Login />} />
                                <Route
                                    path="/"
                                    element={
                                        <Protected isLoggedIn={isLoggedIn}>
                                            <MainLayout />
                                        </Protected>
                                    }
                                >
                                    {/* <Route index element={<Dashboard />} /> */}
                                    <Route path="/" element={<Dashboard />} />
                                    {/* <Route path='app/table' element={<Table />} /> */}

                                    <Route path="app/change-password" element={<ChangePassword />} />
                                    <Route path="app/change-branch" element={<ChangeBranch />} />

                                    <Route path="app/test-group" element={<TestGroup />} />
                                    <Route path="app/result" element={<Result />} />
                                    <Route path="app/test" element={<Test />} />
                                    <Route path="app/patient" element={<Patient />} />
                                    <Route path="app/method" element={<Method />} />
                                    <Route path="app/comment" element={<Comment />} />
                                    <Route path="app/rename" element={<Rename />} />
                                    <Route path="app/consultant-doctor" element={<ConsultantDoctor />} />
                                    <Route path="app/agent" element={<Agent />} />
                                    <Route path="app/company" element={<Company />} />
                                    <Route path="app/cancelation" element={<Cancelation />} />
                                    <Route path="app/sms-master" element={<SmsMaster />} />
                                    <Route path="app/media" element={<Media />} />

                                    {/* <Route path='app/accept-test' element={<AcceptTest />} /> */}
                                    <Route path="app/quick-result" element={<QuickResult />} />

                                    {/* <Route path='app/payment-voucher' element={<PaymentVoucher />} /> */}
                                    <Route path="app/account-consultant-doctor" element={<AccountConsultantDoctor />} />
                                    <Route path="app/account-agent" element={<AccountAgent />} />
                                    <Route path="app/receipt-voucher" element={<ReceiptVoucher />} />
                                    <Route path="app/Daily-Expenditure" element={<DailyExpenditure />} />

                                    <Route path="app/perform-test" element={<PerformTest />} />

                                    <Route path="app/setting" element={<Setting />} />

                                    <Route path="app/view-result" element={<ViewResult />} />
                                    <Route path="app/generate-report" element={<GenerateReport />} />
                                    <Route path="app/consultant-report" element={<ConsultantReport />} />
                                    <Route path="app/daily-report" element={<DailyReport />} />
                                    <Route path="app/month-report" element={<MonthReport />} />
                                    <Route path="app/agent-report" element={<AgentReport />} />
                                    <Route path="app/expenditure-report" element={<ExpenditureReport />} />
                                    <Route path="app/user-report" element={<UserReport />} />
                                    <Route path="app/dues-report" element={<DuesReport />} />
                                    <Route path="app/test-counter-report" element={<TestCounterReport />} />
                                    <Route path="app/cancelation-report" element={<CancelationReport />} />

                                    <Route path="app/report" element={<Report />} />
                                    <Route path="app/report-two" element={<ReportTwo />} />
                                    <Route path="app/report-three" element={<ReportThree />} />
                                    <Route path="app/report-four" element={<ReportFour />} />
                                    <Route path="app/report-five" element={<ReportFive />} />
                                    <Route path="app/report-six" element={<ReportSix />} />
                                    <Route path="app/report-seven" element={<ReportSeven />} />
                                    <Route path="app/report-eight" element={<ReportEight />} />
                                    <Route path="app/report-nine" element={<ReportNine />} />
                                    <Route path="app/help" onClick={() => console.log('Heading to /')} />
                                </Route>
                            </Routes>
                        </AuthContext.Provider>
                    </AuthProvider>
                    {/* <button onClick={handleLogout}>Click it </button> */}
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};
export default App;
