// assets
import HelpIcon from '@mui/icons-material/Help';

// constant
const icons = { HelpIcon };

// ==============================|| TABLE MENU ITEMS ||============================== //

const help = {
    id: 'help',
    title: 'Help',
    type: 'group',
    children: [
        {
            id: 'help',
            title: 'Help',
            type: 'item',
            url: 'app/help',
            icon: icons.HelpIcon,
            breadcrumbs: false
        }
    ]
};

export default help;