import dashboard from './dashboard';
// import table from './table';
import master from './master';
import booking from './booking';
import testperform from './testperform';
import account from './account';
import setting from './setting';
// import transaction from './transaction';
import report from './report';
import contactUs from './contactUs';
import help from './help';
// import configuration from './configuration';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    // items: [master, booking, testperform, account, setting, report, contactUs, help]
    items: [dashboard, master, booking, testperform, account, setting, report, contactUs, help]
};
// items: [dashboard, table, master, transaction, configuration, reports]

export default menuItems;
