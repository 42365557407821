import React, { useState } from 'react';
import { Grid, Paper, Typography, TextField, Button } from '@mui/material';

const RowCard = ({ row, columns, onUpdate }) => {
    const [editedRow, setEditedRow] = useState({ ...row });
    const [isEditMode, setIsEditMode] = useState(true);

    const handleFieldChange = (field, value) => {
        setEditedRow((prevState) => ({
            ...prevState,
            [field]: value
        }));
    };

    const handleSave = () => {
        onUpdate(editedRow);
        setIsEditMode(false);
    };

    return (
        //     <FormGroup className="col-lg-2">
        //     <TextField
        //         fullWidth
        //         type="text"
        //         label="Lab No"
        //         value={itemDetails.labNo}
        //         onChange={(e) => onChangeItemDetails('labNo', e.target.value)}
        //         id="labNo"
        //         size="small"
        //     />
        // </FormGroup>
        <Grid item xs={12} sm={6} md={4} lg={3}>
            <Paper elevation={3} sx={{ p: 1 }}>
                {columns.map((column) => (
                    <TextField
                        key={column.field}
                        label={column.headerName}
                        value={editedRow[column.field]}
                        onChange={(e) => handleFieldChange(column.field, e.target.value)}
                        fullWidth
                        margin="dense"
                        size="small"
                        disabled={!isEditMode}
                    />
                ))}
                {isEditMode ? (
                    <>
                        <Button variant="contained" onClick={handleSave} sx={{ marginRight: 1 }}>
                            Save
                        </Button>
                    </>
                ) : (
                    <Button variant="outlined" onClick={() => setIsEditMode(true)}>
                        Edit
                    </Button>
                )}
            </Paper>
        </Grid>
    );
};

const GridToCard = ({ rows, columns, onUpdate }) => {
    return (
        <Grid container spacing={1}>
            {rows.map((row) => (
                <RowCard key={row.id} row={row} columns={columns} onUpdate={onUpdate} />
            ))}
        </Grid>
    );
};

export default GridToCard;
