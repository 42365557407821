
import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { Button } from "reactstrap"
import Barcode from 'assets/barcode.png';

// // rct card box
import RctCollapsibleCard from '../../component/RctCollapsibleCard/RctCollapsibleCard';

class ComponentToPrint extends React.Component {
    render() {
        return (
            <RctCollapsibleCard fullBlock>
                <br/><br/><br/><br/><br/><br/><br/><br/><br/>
                <div style={{border:"1px solid #888",width: "80%",height:"190px",padding: "10px 0",margin: "0 auto",position: "relative"}}>
                    <div style={{border: "1px solid #888", borderRadius: "20px", backgroundColor: "#fff",position: "absolute",width: "110%",left: "-5%"}}>
                        <div style={{display: "flex",marginright: "-15px",marginLeft: "-15px"}}>
                            <div style={{flex: "0 0 60%",maxWidth: "60%",paddingRight: "15px",paddingLeft: "25px"}}>
                                <p style={{marginTop:"10px",marginBottom: "10px"}}>Name: Mr. ABCD</p>
                                <p style={{marginTop:"0",marginBottom: "10px"}}>Age: 25 Year</p>
                                <p style={{marginTop:"0",marginBottom: "10px;"}}>Sex: Male</p>
                                <p style={{marginTop:"0",marginBottom: "10px;"}}>Reffered By: Dr. Sachin Kr mbbs md</p>
                            </div>
                            <div style={{flex: "0 0 40%",maxWidth: "40%",paddingRight: "25px",paddingLeft: "15px",paddingTop:"10px"}}>
                                <img src={Barcode} alt="Qr Code" height="100px" />
                                <p style={{marginTop:"0",marginBottom: "10px"}}>Received On: 21-11-2022</p>
                                <p style={{marginTop:"0",marginBottom: "10px"}}>Received On: 21-11-2022</p>
                            </div>
                        </div>
                    </div>
                </div>
                <br/><br/><br/>
                <div style={{textAlign: "center"}}><b style={{padding: "15px"}}>COMPLETE BLOOD COUNT</b></div>
                <br/><br/>
                <table style={{width: "80%",margin: "0 auto",borderCollapse: "collapse"}}>
                    <tr>
                        <th align="left">Incestigation</th>
                        <th align="left">Result</th>
                        <th align="left">Units</th>
                        <th align="left">Ref. Range</th>
                    </tr>
                    <tr>
                        <td>Total W.B.C Count</td>
                        <td>4000</td>
                        <td>cu mm</td>
                        <td>4000 . 11000</td>
                    </tr>
                    <tr>
                        <td><b>Differential W.B.C Count</b></td>
                    </tr>
                    <tr>
                        <td>Neutrophils</td>
                        <td>20</td>
                        <td>%</td>
                        <td>50 - 70</td>
                    </tr>
                    <tr>
                        <td>Lymphocytes</td>
                        <td>20</td>
                        <td>%</td>
                        <td>20 - 40</td>
                    </tr>
                    <tr>
                        <td>Lymphocytes</td>
                        <td>20</td>
                        <td>%</td>
                        <td>20 - 40</td>
                    </tr>
                    <tr>
                        <td>Lymphocytes</td>
                        <td>20</td>
                        <td>%</td>
                        <td>20 - 40</td>
                    </tr>
                    <tr>
                        <td>Lymphocytes</td>
                        <td>20</td>
                        <td>%</td>
                        <td>20 - 40</td>
                    </tr>
                    <tr>
                        <td><b>Haemoglobin</b></td>
                    </tr>
                    <tr>
                        <td>Result</td>
                        <td>12</td>
                        <td>gm/dl</td>
                        <td>14.6</td>
                    </tr>
                    <tr>
                        <td>Percent</td>
                        <td>82.19</td>
                        <td>%</td>
                        <td>100</td>
                    </tr>
                </table>
                <br/>
                <div style={{width: "80%",margin: "0 auto"}}>
                    <div>Comment:-</div>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                </div>
                <br/>
                <div style={{width:"80%",margin: "0 auto",display: "flow-root"}}>
                    <div style={{float:"left", textAlign: "center"}}>
                        <img src="signature.png" alt="signature" height="50px" /><br/>
                        DR.D.N.PD.SINGH MBBS
                    </div>
                    <div style={{float: "right",textAlign: "center"}}>
                        <img src="signature.png" alt="signature" height="50px" /><br/>
                        Dr. S.K. Sinha M.D(Path)
                    </div>
                </div>
                <br/><br/>
            </RctCollapsibleCard>
        );
    }
}

const ReportThree = () => {
    const componentRef = useRef();
    return (
      <div>
        <ReactToPrint
          trigger={() => <Button>Print this out!</Button>}
          content={() => componentRef.current}
        />
        <ComponentToPrint ref={componentRef} />
      </div>
    );
  };

export default ReportThree;