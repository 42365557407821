// import { useEffect, useState } from 'react';
import React from 'react';

// material-ui
import { Button, Grid } from '@mui/material';

// project imports
import Banner from './Banner';
// import PaymentsDue from './PaymentsDue';
// import PendingReport from './PendingReport';
// import EarningCard from './EarningCard';
// import DiagnofyCard from './DiagnofyCard';
import { gridSpacing } from 'store/constant';
// 👇️ import useNavigate
import { useNavigate } from 'react-router-dom';

// ==============================|| DEFAULT DASHBOARD ||============================== //

const Dashboard = () => {
    const navigate = useNavigate();

    const quickResult = () => {
        // 👇️ navigate programmatically
        navigate('/app/quick-result');
    };
    const receiptVoucher = () => {
        // 👇️ navigate programmatically
        navigate('/app/receipt-voucher');
    };
    const dailyReport = () => {
        // 👇️ navigate programmatically
        navigate('/app/daily-report');
    };

    // const [isLoading, setLoading] = useState(true);
    // useEffect(() => {
    //     setLoading(false);
    // }, []);

    return (
        <>
            <Grid sx={{ textAlign: 'center', mb: 2 }} container spacing={gridSpacing}>
                <Grid item xs={4}>
                    <Button variant="contained" style={{ backgroundColor: 'brown' }} onClick={quickResult}>
                        Quick Report
                    </Button>
                </Grid>
                <Grid item xs={4}>
                    <Button variant="contained" style={{ backgroundColor: 'brown' }} onClick={receiptVoucher}>
                        Receipt Voucher
                    </Button>
                </Grid>
                <Grid item xs={4}>
                    <Button variant="contained" style={{ backgroundColor: 'brown' }} onClick={dailyReport}>
                        Daily Report
                    </Button>
                </Grid>
            </Grid>
            <Banner />
            {/* <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item lg={4} md={6} sm={6} xs={12}>
                            <DiagnofyCard isLoading={isLoading} />
                        </Grid>
                        <Grid item lg={4} md={6} sm={6} xs={12}>
                            <EarningCard isLoading={isLoading} />
                        </Grid>
                        <Grid item lg={4} md={6} sm={6} xs={12}>
                            <EarningCard isLoading={isLoading} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12} md={6}>
                            <PaymentsDue isLoading={isLoading} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <PendingReport isLoading={isLoading} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12} md={6}>
                            <Payments isLoading={isLoading} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Payments isLoading={isLoading} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid> */}
        </>
    );
};

export default Dashboard;
