import React, { useState, useEffect } from 'react';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import Button from '@mui/material/Button';
// api
import axios from 'axios';
import baseUrl from '../../../api';

// import RctSectionLoader from '../../../component/RctSectionLoader/RctSectionLoader';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

// Date
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

//multiselect
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select, { SelectChangeEvent } from '@mui/material/Select';

const AddExpenditureReport = (props) => {
    const [from, setFrom] = React.useState(null);
    const [to, setTo] = React.useState(null);

    const [itemDetails, setItemDetails] = useState({
        dateWiseReport: '',
        dailyReport: '',
        personName: '',
        totalExpenditure: ''
    });

    const onChangeItemDetails = (key, value) => {
        var arr = { ...itemDetails };
        arr[key] = value;
        setItemDetails((prevState) => arr);
    };

    const handleOnAddItem = (e) => {
        e.preventDefault();
        // axios.get("ec2-15-206-84-254.ap-south-1.compute.amazonaws.com:3000/api/master/site").then((response) => console.log("response",response));

        // axios
        // .post(`${baseUrl}/api/master/addConsultantDoctorMaster`,
        // )
        // .then((response) => {
        //     // successfully uploaded response
        // })
        // .catch((error) => {
        //     // error response
        // });
    };

    const onAddUpdateItemModalClose = () => {
        props.setAddNewItemModal(false);
        props.setEditItem(null);
    };

    return (
        <>
            <Modal className="modal-lg" isOpen={props.addNewItemModal} toggle={() => onAddUpdateItemModalClose()}>
                <ModalHeader toggle={() => onAddUpdateItemModalClose()}>Add New Item</ModalHeader>
                <ModalBody>
                    {/* {loading &&
                        <RctSectionLoader />
                    } */}
                    <Form className="row">
                        <FormGroup className="col-lg-6">
                            <TextField
                                fullWidth
                                type="text"
                                label="Daily Report"
                                value={itemDetails.dailyReport}
                                onChange={(e) => onChangeItemDetails('dailyReport', e.target.value)}
                                id="dailyReport"
                                size="small"
                            />
                        </FormGroup>
                        <FormGroup className="col-lg-4">
                            <TextField
                                fullWidth
                                type="text"
                                label="Date Wise Report"
                                value={itemDetails.dateWiseReport}
                                onChange={(e) => onChangeItemDetails('dateWiseReport', e.target.value)}
                                id="dateWiseReport"
                                size="small"
                            />
                        </FormGroup>
                        <FormGroup className="col-lg-6 col-6">
                            <LocalizationProvider className="managedate" dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="From"
                                    value={from}
                                    onChange={(newFrom) => {
                                        setFrom(newFrom);
                                    }}
                                    renderInput={(params) => <TextField fullWidth {...params} size="small" id="from" />}
                                />
                            </LocalizationProvider>
                        </FormGroup>
                        <FormGroup className="col-lg-6 col-6">
                            <LocalizationProvider className="managedate" dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="To"
                                    value={to}
                                    onChange={(newTo) => {
                                        setTo(newTo);
                                    }}
                                    renderInput={(params) => <TextField fullWidth {...params} size="small" id="to" />}
                                />
                            </LocalizationProvider>
                        </FormGroup>
                        <div className="col-12">
                            <div className="mngdevider">
                                <h3 className="mngtitle">Search</h3>
                                <div className="row">
                                    <FormGroup className="col-lg-8">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Person Name"
                                            value={itemDetails.personName}
                                            onChange={(e) => onChangeItemDetails('personName', e.target.value)}
                                            id="personName"
                                            size="small"
                                        />
                                    </FormGroup>
                                    <FormGroup className="col-12 text-center">
                                        <Button variant="contained" className="text-white btn-success" onClick={handleOnAddItem}>
                                            show
                                        </Button>
                                    </FormGroup>
                                    <FormGroup check className="col-lg-6 col-6">
                                        <Label check className="ml-10 mb-10">
                                            <Input
                                                type="radio"
                                                name="test"
                                                defaultValue={itemDetails.onPrintedPaper}
                                                onChange={(e) => onChangeItemDetails('onPrintedPaper', e.target.value)}
                                                id="onPrintedPaper"
                                            />{' '}
                                            On Printed Paper
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check className="col-lg-6 col-6">
                                        <Label check className="ml-10 mb-10">
                                            <Input
                                                type="radio"
                                                name="test"
                                                defaultValue={itemDetails.onBlankPaper}
                                                onChange={(e) => onChangeItemDetails('onBlankPaper', e.target.value)}
                                                id="onBlankPaper"
                                            />{' '}
                                            On Blank Paper
                                        </Label>
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                        <FormGroup className="col-lg-4">
                            <TextField
                                fullWidth
                                type="text"
                                label="Total Expenditure"
                                value={itemDetails.totalExpenditure}
                                onChange={(e) => onChangeItemDetails('totalExpenditure', e.target.value)}
                                id="totalExpenditure"
                                size="small"
                            />
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button variant="contained" className="text-white btn-success" onClick={handleOnAddItem}>
                        Save
                    </Button>
                    <Button variant="contained" className="text-white btn-danger" onClick={() => onAddUpdateItemModalClose()}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};
export default AddExpenditureReport;
