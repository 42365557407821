import React, { useState } from 'react';
import GridToCard from '../../../commonFunctions/GridToCard';
import { Button } from 'reactstrap';

const App = () => {
    const [rows, setRows] = useState([
        { id: 1, name: 'Card 1', description: 'This is the description of Card 1.' },
        { id: 2, name: 'Card 2', description: 'This is the description of Card 2.' },
        { id: 3, name: 'Card 3', description: 'This is the description of Card 3.' }
    ]);

    // const handleRowUpdate = (updatedRow) => {
    //     const updatedRows = rows.map((row) => {
    //         if (row.id === updatedRow.id) {
    //             return updatedRow;
    //         }
    //         return row;
    //     });

    //     setRows(updatedRows);
    //     console.log('the updated rdow in onUpdate is ', rows);
    // };
    const handleRowUpdate = (updatedRows) => {
        setRows(updatedRows);
    };

    return (
        <div>
            <h1>DataGrid to Card Example</h1>
            <GridToCard rows={rows} onUpdate={handleRowUpdate} />
            <Button variant="contained" className="text-white btn-success" onClick={() => console.log('clicking', rows)}>
                Ok
            </Button>
        </div>
    );
};

export default App;
