import { number } from 'prop-types';

export const checkDcRecord = (rows) => {
    let sum = 0;
    let str = '';
    console.log('rows in checkDcRecord', rows);
    let dgvRowindex = null;
    for (let i = 0; i < rows.length; i++) {
        if (rows[i].formulaCode !== '') {
            str = rows[i].formulaCode.trim();
        }
        if (str === 'Neutrophils') {
            sum = parseFloat(rows[i].results) + sum;
        }
        if (str === 'Lymphocytes') {
            sum = parseFloat(rows[i].results) + sum;
        }
        if (str === 'Monocytes') {
            sum = parseFloat(rows[i].results) + sum;
        }
        if (str === 'Eosinophils') {
            sum = parseFloat(rows[i].results) + sum;
            rows[i].results = 24;
        }
        if (str === 'Basophils') {
            sum = parseFloat(rows[i].results) + sum;
            rows[i].results = 25;
        }
    }
    if (sum !== 100) {
        // const ans = window.confirm('The sum should be 100....Do You Want to add some tests' + sum);
        // if (ans) {
        //     // window.alert('Click On add rows button Below');
        //     // setButton1Visible(true);
        // }
    }
    let _calcDc = 0;
};
export const formulaPROTHROMBIN = (rows) => {
    let tv = 0;
    let cv = 0;
    let pi = 0;
    let pr = 0;
    let isi = 0;
    let inr = 0;
    let r = 0;
    let str = '';
    let STR2 = '';
    for (let i = 0; i < rows.length; i++) {
        if (rows[i].testCode !== '' && rows[i].formulaCode !== null) {
            STR2 = rows[i].formulaCode.trim();
        }
        if (STR2 === 'PROTHROMBINTS') {
            str = rows[i].formulaCode.trim();
            if (str === 'PROTHROMBINTV') {
                tv = parseFloat(rows[i].results);
            }
            if (str === 'PROTHROMBINCV') {
                cv = parseFloat(rows[i].results);
            }
            if (str === 'PROTHROMBINISI') {
                isi = parseFloat(rows[i].results);
            }
            if (tv !== 0 && cv !== 0 && !isNaN(tv) && !isNaN(cv)) {
                if (str === 'PROTHROMBINI') {
                    pi = (cv / tv) * 100;
                    // Number(number.toFixed(2));
                    rows[i].results = Number(pi.toFixed(2));
                }
                if (str === 'PROTHROMBINPR') {
                    pr = tv / cv;
                    rows[i].results = Number(pr.toFixed(2));
                }
            }

            if (tv !== 0 && cv !== 0 && !isNaN(tv) && !isNaN(cv)) {
                if (str === 'PROTHROMBININR' && isi > 0) {
                    r = tv / cv;
                    inr = Math.round(Math.pow(r, isi) * 100) / 100; //Math.round(r ** isi, 2);
                    rows[i].results = Number(inr.toFixed(2));
                }
            }
        }
    }
};
export const formulaBillirubin = (rows) => {
    var i, bt, bd, bi;
    var str = '';
    var STR2 = '';
    bt = 0;
    bd = 0;

    for (i = 0; i < rows.length; i++) {
        if (rows[i].testCode !== '' && rows[i].formulaCode !== null) {
            STR2 = rows[i].formulaCode.trim();
        }
        if (STR2 === 'BILLIRUBIN') {
            str = rows[i].formulaCode.trim();

            if (str === 'BILLIRUBINTOTAL') {
                bt = parseFloat(rows[i].results);
            }
            if (str === 'BILLIRUBINDIRECT') {
                bd = parseFloat(rows[i].results);
            }
            if (bt !== 0 && bd !== 0 && !isNaN(bt) && !isNaN(bd)) {
                if (rows[i].formulaCode.trim() === 'BILLIRUBININDIRECT') {
                    bi = ((bt - bd) * 100) / 100;
                    rows[i].results = Number(bi.toFixed(2));
                }
            }
        }
    }
};

export const formulaGlobulin = (rows) => {
    let i,
        pt = 0,
        al = 0,
        gl = 0,
        ag = 0;
    let str = '',
        STR2 = '';

    for (i = 0; i < rows.length; i++) {
        if (rows[i].testCode !== '' && rows[i].formulaCode !== null) {
            STR2 = rows[i].formulaCode.trim();
        }
        if (STR2 === 'PROTEIN') {
            str = rows[i].formulaCode.trim();
            if (str === 'PROTEINTOTAL') {
                pt = parseFloat(rows[i].results);
            }

            if (str === 'PROTEINALBUMIN') {
                al = parseFloat(rows[i].results);
            }
            if (pt !== 0 && al !== 0 && !isNaN(pt) && !isNaN(al)) {
                if (rows[i].formulaCode.trim() === 'PROTEINGLOBULIN') {
                    gl = pt - al;
                    rows[i].results = Number(gl.toFixed(2));
                }
            }
            if (al !== 0 && gl !== 0 && !isNaN(al) && !isNaN(gl)) {
                if (rows[i].formulaCode.trim() === 'PROTEINAGRATIO') {
                    ag = al / gl;
                    rows[i].results = Number(ag.toFixed(2));
                }
            }
        }
    }
};
export const formulaLdlColestrol = (rows) => {
    let i, ldlc, hdl, vldl, tg, tc;
    let str, testn;
    testn = '';
    str = '';
    for (i = 0; i < rows.length; i++) {
        if (rows[i].testCode !== '' && rows[i].formulaCode !== null) {
            testn = rows[i].formulaCode.trim();
        }

        if (testn === 'LIPIDPROFILE') {
            // console.log('rows is ', rows);
            // console.log(`tstn is ${testn}`);
            //&& !isNaN(rows[i].formulaCode) && rows[i].formulaCode !== 'null'
            if (rows[i].formulaCode !== '') {
                str = rows[i].formulaCode.trim();
            }
            // console.log(`str is ${str}`);
            if (str === 'LIPIDTOTALCHOLESTEROL') {
                tc = parseFloat(rows[i].results);
                console.log(`tc is ${tc}`);
            }
            if (str === 'LIPIDTRIGLYCERIDES') {
                tg = parseFloat(rows[i].results);
                if (tg > 0) {
                    vldl = tg / 5;
                }
            }
            if (str === 'LIPIDHDL') {
                hdl = parseFloat(rows[i].results);
            }
            if (str === 'LIPIDLDL') {
                if (tc > 0 && hdl > 0 && vldl > 0) {
                    // console.log('tci is ', tc, 'hdl isi ', hdl, 'vldl is ', vldl, 'final result is ', (tc - (hdl + vldl)).toFixed(2));
                    rows[i].results = (tc - (hdl + vldl)).toFixed(2);
                    ldlc = (tc - (hdl + vldl)).toFixed(2);
                }
            }
            if (str === 'LIPIDVLDL') {
                rows[i].results = vldl;
            }
            if (str === 'LIPIDTCHDLRATIO') {
                // console.log('tc is ', tc, 'hdl is ', hdl);
                if (tc !== 0 && hdl !== 0 && !isNaN(tc) && !isNaN(hdl)) {
                    if (tc >= hdl) {
                        rows[i].results = (tc / hdl).toFixed(1) + ':1';
                    } else {
                        rows[i].results = '1:' + (hdl / tc).toFixed(1);
                    }
                } else {
                    rows[i].results = '';
                }
            }
            if (str === 'LIPIDLDLHDLRATIO') {
                if (ldlc !== 0 && hdl !== 0 && !isNaN(ldlc) && !isNaN(hdl)) {
                    if (ldlc >= hdl) {
                        rows[i].results = (ldlc / hdl).toFixed(1) + ':1';
                    } else {
                        rows[i].results = '1:' + (hdl / ldlc).toFixed(1);
                    }
                } else {
                    rows[i].results = '';
                }
            }
            if (str === 'LIPIDNONHDLCHOLESTEROL') {
                if (tc !== 0 && hdl !== 0 && !isNaN(tc) && !isNaN(hdl)) {
                    rows[i].results = tc - hdl;
                }
            }
        }
    }
};
export const formulaEsrAverage = (rows) => {
    try {
        let i, f1, f2, av;
        f1 = 0;
        f2 = 0;
        let STR2 = '';
        for (i = 0; i < rows.length; i++) {
            if (rows[i].testCode !== '' && rows[i].formulaCode !== null) {
                STR2 = rows[i].formulaCode.trim();
            }
            if (STR2 === 'ESR') {
                if (rows[i].formulaCode.trim() === 'ESRFIRST') {
                    f1 = parseFloat(rows[i].results);
                }
                if (rows[i].formulaCode.trim() === 'ESRSECOND') {
                    f2 = parseFloat(rows[i].results);
                }
                if (f1 !== 0 && f2 !== 0 && !isNaN(f1) && !isNaN(f2)) {
                    if (rows[i].formulaCode.trim() === 'ESRAVERAGE') {
                        av = (f1 + f2 / 2) / 2;
                        rows[i].results = av;
                    }
                }
            }
        }
    } catch (ex) {}
};
export const formulaHAEMOGLOBIN = (rows, formulaCode) => {
    try {
        let i, hem, res, normlv;
        let str = '';
        hem = 0;
        for (i = 0; i < rows.length; i++) {
            if (rows[i].testCode !== '' && rows[i].formulaCode !== null) {
                str = rows[i].formulaCode.trim();
            }
            if (str === 'HAEMOGLOBIN') {
                // let subtest = rows[i].cells['subtest'].value.toLowerCase();
                if (formulaCode == 'HAEMOGLOBINRESULT') {
                    if (rows[i].formulaCode == 'HAEMOGLOBINRESULT') {
                        let res = parseFloat(rows[i].results);
                        rows[i + 1].results = ((res * 100) / 14.6).toFixed(2);
                        break;
                    }
                } else {
                    if (rows[i].formulaCode == 'HAEMOGLOBINPERCENT') {
                        let res = parseFloat(rows[i].results);
                        rows[i - 1].results = (14.6 * (res / 100)).toFixed(2);
                        break;
                    }
                }
            }
        }
    } catch (ex) {}
};

export const formulaCBC = (rows, formulaCode) => {
    let hb = 0; //haemoglobin
    let cbctrc = 0; //total rbc count
    let mch = 0;
    let pcv = 0;
    let isi = 0;
    let inr = 0;
    let r = 0;
    let str = '';
    let STR2 = '';
    let Neutrophil = 0;
    let Lymphocyte = 0;
    let Monocyte = 0;
    let Eosinophil = 0;
    let Basophil = 0;
    let wbc = 0;
    for (let i = 0; i < rows.length; i++) {
        if (rows[i].testCode !== '' && rows[i].formulaCode !== null) {
            STR2 = rows[i].formulaCode.trim();
        }
        //for testing subtest
        if (STR2 === 'CBC') {
            str = rows[i].formulaCode.trim();
            //FIRST OF ALL I WANTED TO CLEAR THE HAEMOGLOBIN FORMULA
            if (formulaCode == 'HAEMOGLOBINRESULT') {
                if (rows[i].formulaCode == 'HAEMOGLOBINRESULT') {
                    let res = parseFloat(rows[i].results);
                    hb = res;
                    rows[i + 1].results = ((res * 100) / 14.6).toFixed(2);
                    // break;
                }
            } else {
                if (rows[i].formulaCode == 'HAEMOGLOBINPERCENT') {
                    let res = parseFloat(rows[i].results);
                    hb = (14.6 * (res / 100)).toFixed(2);
                    rows[i - 1].results = hb;
                    // break;
                }
            }

            if (str === 'CBCTRC') {
                cbctrc = parseFloat(rows[i].results);
            }
            if (str === 'CBCPCV') {
                pcv = parseFloat(rows[i].results);
            }
            //TOTOAL WBC K LIYE
            if (str === 'CBCWBC') {
                wbc = parseFloat(rows[i].results);
            }
            //for absoulute count of lyucosytes.
            if (str === 'CBCN') {
                Neutrophil = parseFloat(rows[i].results);
            } else if (str === 'CBCL') {
                Lymphocyte = parseFloat(rows[i].results);
            } else if (str === 'CBCM') {
                Monocyte = parseFloat(rows[i].results);
            } else if (str === 'CBCE') {
                Eosinophil = parseFloat(rows[i].results);
            } else if (str === 'CBCB') {
                Basophil = parseFloat(rows[i].results);
                // if (Neutrophil + Lymphocyte + Monocyte + Eosinophil + Basophil !== 100) {
                //     alert('It Should be 100', Neutrophil + Lymphocyte + Monocyte + Eosinophil + Basophil);
                //     console.log('It Should be 100', Neutrophil + Lymphocyte + Monocyte + Eosinophil + Basophil);
                // }
            }
            if (str === 'CBCABN') {
                if (wbc !== 0 && !isNaN(wbc) && Neutrophil !== 0 && !isNaN(Neutrophil))
                    rows[i].results = Number(((wbc * Neutrophil) / 100).toFixed(2));
            }
            if (str === 'CBCABL') {
                if (wbc !== 0 && !isNaN(wbc) && Lymphocyte !== 0 && !isNaN(Lymphocyte))
                    rows[i].results = Number(((wbc * Lymphocyte) / 100).toFixed(2));
            }
            if (str === 'CBCABM') {
                if (wbc !== 0 && !isNaN(wbc) && Monocyte !== 0 && !isNaN(Monocyte))
                    rows[i].results = Number(((wbc * Monocyte) / 100).toFixed(2));
            }
            if (str === 'CBCABE') {
                if (wbc !== 0 && !isNaN(wbc) && Eosinophil !== 0 && !isNaN(Eosinophil))
                    rows[i].results = Number(((wbc * Eosinophil) / 100).toFixed(2));
            }
            if (str === 'CBCABB') {
                if (wbc !== 0 && !isNaN(wbc) && Basophil !== 0 && !isNaN(Basophil))
                    rows[i].results = Number(((wbc * Basophil) / 100).toFixed(2));
            }
            //calculation for absolute count is over

            if (hb !== 0 && cbctrc !== 0 && !isNaN(cbctrc) && !isNaN(hb)) {
                if (str === 'CBCMCH') {
                    rows[i].results = Number(((hb * 10) / cbctrc).toFixed(2));
                    // isi = parseFloat(rows[i].results);
                }
            }
            if (cbctrc !== 0 && pcv !== 0 && !isNaN(cbctrc) && !isNaN(pcv)) {
                if (str === 'CBCMCV') {
                    rows[i].results = Number(((pcv * 10) / cbctrc).toFixed(2));
                    // isi = parseFloat(rows[i].results);
                }
            }
            if (hb !== 0 && pcv !== 0 && !isNaN(hb) && !isNaN(pcv)) {
                if (str === 'CBCMCHC') {
                    rows[i].results = Number(((hb * 100) / pcv).toFixed(2));
                    // isi = parseFloat(rows[i].results);
                }
            }

            // if (tv !== 0 && cv !== 0 && !isNaN(tv) && !isNaN(cv)) {
            //     if (str === 'PROTHROMBININR' && isi > 0) {
            //         r = tv / cv;
            //         inr = Math.round(Math.pow(r, isi) * 100) / 100; //Math.round(r ** isi, 2);
            //         rows[i].results = Number(inr.toFixed(2));
            //     }
            // }
        }
    }
};

export const formulaLFT = (rows) => {
    var i, bt, bd, bi;
    var str = '';
    var STR2 = '';
    bt = 0;
    bd = 0;
    let pt = 0,
        al = 0,
        gl = 0,
        ag = 0;
    for (let i = 0; i < rows.length; i++) {
        if (rows[i].testCode !== '' && rows[i].formulaCode !== null) {
            STR2 = rows[i].formulaCode.trim();
        }
        //for testing subtest
        if (STR2 === 'LFT') {
            str = rows[i].formulaCode.trim();
            if (str === 'BILLIRUBINTOTAL') {
                bt = parseFloat(rows[i].results);
            }
            if (str === 'BILLIRUBINDIRECT') {
                bd = parseFloat(rows[i].results);
            }
            if (bt !== 0 && bd !== 0 && !isNaN(bt) && !isNaN(bd)) {
                if (rows[i].formulaCode.trim() === 'BILLIRUBININDIRECT') {
                    bi = ((bt - bd) * 100) / 100;
                    rows[i].results = Number(bi.toFixed(2));
                }
            }
            if (str === 'PROTEINTOTAL') {
                pt = parseFloat(rows[i].results);
            }

            if (str === 'PROTEINALBUMIN') {
                al = parseFloat(rows[i].results);
            }
            if (pt !== 0 && al !== 0 && !isNaN(pt) && !isNaN(al)) {
                if (rows[i].formulaCode.trim() === 'PROTEINGLOBULIN') {
                    gl = pt - al;
                    rows[i].results = Number(gl.toFixed(2));
                }
            }
            if (al !== 0 && gl !== 0 && !isNaN(al) && !isNaN(gl)) {
                if (rows[i].formulaCode.trim() === 'PROTEINAGRATIO') {
                    ag = al / gl;
                    rows[i].results = Number(ag.toFixed(2));
                }
            }
        }
    }
};

export const formulaKFT = (rows) => {
    var str = '';
    var STR2 = '';
    let urea = 0;
    let bun = 0;

    for (let i = 0; i < rows.length; i++) {
        if (rows[i].testCode !== '' && rows[i].formulaCode !== null) {
            STR2 = rows[i].formulaCode.trim();
        }
        //for testing subtest
        if (STR2 === 'KFT') {
            str = rows[i].formulaCode.trim();

            if (str === 'KFTUREA' && !isNaN(urea)) {
                urea = parseFloat(rows[i].results);
            }
            if (str === 'KFTBUN') {
                rows[i].results = Number((urea * 0.467).toFixed(2));
            }
        }
    }
};
export const formulaCBCHMG = (rows, formulaCode) => {
    let hb = 0; //haemoglobin
    let cbctrc = 0; //total rbc count
    let mch = 0;
    let pcv = 0;
    let isi = 0;
    let inr = 0;
    let r = 0;
    let str = '';
    let STR2 = '';
    let Neutrophil = 0;
    let Lymphocyte = 0;
    let Monocyte = 0;
    let Eosinophil = 0;
    let Basophil = 0;
    let wbc = 0;
    for (let i = 0; i < rows.length; i++) {
        if (rows[i].testCode !== '' && rows[i].formulaCode !== null) {
            STR2 = rows[i].formulaCode.trim();
        }
        //for testing subtest
        if (STR2 === 'CBCHMG') {
            str = rows[i].formulaCode.trim();
            //FIRST OF ALL I WANTED TO CLEAR THE HAEMOGLOBIN FORMULA
            if (formulaCode == 'HAEMOGLOBINRESULT') {
                if (rows[i].formulaCode == 'HAEMOGLOBINRESULT') {
                    let res = parseFloat(rows[i].results);
                    hb = res;
                    console.log('hb is ' + hb);

                    // break;
                }
            }

            if (str === 'CBCTRC') {
                cbctrc = parseFloat(rows[i].results);
            }
            if (str === 'CBCPCV') {
                pcv = parseFloat(rows[i].results);
            }
            //TOTOAL WBC K LIYE
            if (str === 'CBCWBC') {
                wbc = parseFloat(rows[i].results);
            }

            if (hb !== 0 && cbctrc !== 0 && !isNaN(cbctrc) && !isNaN(hb)) {
                if (str === 'CBCMCH') {
                    rows[i].results = Number(((hb * 10) / cbctrc).toFixed(2));
                    // isi = parseFloat(rows[i].results);
                }
            }
            if (cbctrc !== 0 && pcv !== 0 && !isNaN(cbctrc) && !isNaN(pcv)) {
                if (str === 'CBCMCV') {
                    rows[i].results = Number(((pcv * 10) / cbctrc).toFixed(2));
                    // isi = parseFloat(rows[i].results);
                }
            }
            if (hb !== 0 && pcv !== 0 && !isNaN(hb) && !isNaN(pcv)) {
                if (str === 'CBCMCHC') {
                    rows[i].results = Number(((hb * 10) / pcv).toFixed(2));
                    // isi = parseFloat(rows[i].results);
                }
            }
        }
    }
};
export const formula24Hours = (rows) => {
    var str = '';
    var STR2 = '';
    let total = 0;
    let other = 0;

    for (let i = 0; i < rows.length; i++) {
        if (rows[i].testCode !== '' && rows[i].formulaCode !== null) {
            STR2 = rows[i].formulaCode.trim();
        }
        //for testing subtest
        if (STR2 === 'formula24Hours') {
            str = rows[i].formulaCode.trim();

            if (str === '24Total') {
                total = parseFloat(rows[i].results);
            }
            if (str === '24other') {
                other = parseFloat(rows[i].results);
            }
            if (str === '24result') {
                if (total > 0 && other > 0 && !isNaN(total) && !isNaN(other)) {
                    rows[i].results = parseFloat((total * other) / 100000);
                }
            }
        }
    }
};
