// assets
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';

// constant
const icons = {
    PermContactCalendarIcon
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const booking = {
    id: 'booking',
    title: 'Booking',
    caption: '',
    type: 'group',
    children: [
        {
            id: 'booking',
            title: 'Booking',
            type: 'collapse',
            icon: icons.PermContactCalendarIcon,
            children: [
                // {
                //     id: 'accepttest',
                //     title: 'Accept Test',
                //     type: 'item',
                //     url: '/app/accept-test',
                //     breadcrumbs: false
                // },
                {
                    id: 'quickresult',
                    title: 'Quick Result',
                    type: 'item',
                    url: '/app/quick-result',
                    breadcrumbs: false
                },
            ]
        }
    ]
};

export default booking;
