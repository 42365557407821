// const baseURL = 'http://ahiirc.co.in/nodejsapp';
// const baseURL = 'http://192.168.141.43:8000';
const baseURL = 'http://localhost:8000/nodejsapp';

export default baseURL;

// axios.create({
//    // baseURL: 'http://192.168.29.99:58375/',
//    baseURL: 'https://billtronapi.hellopatna.com/',
//    timeout: 80000,
//    headers: {
//       'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
//       'Content-Type': 'application/json'
//    }
// });
