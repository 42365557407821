// assets
import { IconTools } from '@tabler/icons';

// constant
const icons = {
    IconTools
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const master = {
    id: 'master',
    title: 'Master',
    caption: '',
    type: 'group',
    children: [
        {
            id: 'master',
            title: 'Master',
            type: 'collapse',
            icon: icons.IconTools,
            children: [
                {
                    id: 'testgroup',
                    title: 'Test Group',
                    type: 'item',
                    url: '/app/test-group',
                    breadcrumbs: false
                },
                {
                    id: 'test',
                    title: 'Test',
                    type: 'item',
                    url: '/app/test',
                    breadcrumbs: false
                },
                {
                    id: 'patient',
                    title: 'Patient',
                    type: 'item',
                    url: '/app/patient',
                    breadcrumbs: false
                },
                {
                    id: 'consultantdoctor',
                    title: 'Consultant Doctor',
                    type: 'item',
                    url: '/app/consultant-doctor',
                    breadcrumbs: false
                },
                {
                    id: 'method',
                    title: 'Method',
                    type: 'item',
                    url: '/app/method',
                    breadcrumbs: false
                },
                {
                    id: 'media',
                    title: 'Media',
                    type: 'item',
                    url: '/app/media',
                    breadcrumbs: false
                },
                {
                    id: 'result',
                    title: 'Result',
                    type: 'item',
                    url: '/app/result',
                    breadcrumbs: false
                },
                {
                    id: 'comment',
                    title: 'Comment',
                    type: 'item',
                    url: '/app/comment',
                    breadcrumbs: false
                },
                {
                    id: 'rename',
                    title: 'Rename',
                    type: 'item',
                    url: '/app/rename',
                    breadcrumbs: false
                },
                {
                    id: 'agent',
                    title: 'Agent',
                    type: 'item',
                    url: '/app/agent',
                    breadcrumbs: false
                },
                {
                    id: 'company',
                    title: 'Company',
                    type: 'item',
                    url: '/app/company',
                    breadcrumbs: false
                },
                {
                    id: 'cancelation',
                    title: 'Cancelation',
                    type: 'item',
                    url: '/app/cancelation',
                    breadcrumbs: false
                },
                {
                    id: 'smsmaster',
                    title: 'SMS Master',
                    type: 'item',
                    url: '/app/sms-master',
                    breadcrumbs: false
                },
            ]
        }
    ]
};

export default master;
