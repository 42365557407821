import { jsPDF } from 'jspdf';
import { formatDateDMY } from '../../commonFunctions/commonFunction';

export const generalTestPrint = (tempVals, settings, itemDetails, companyDetails, selectedRowIds, dateTime, on) => {
    // console.log('selectedRowsId is ', selectedRowIds, 'and its lenght is ', selectedRowIds.length);
    // console.log('setting is ', settings);
    const doc = new jsPDF('p', 'mm', 'a4');
    // console.log('company Datails', companyDetails);

    // doc.addFont('Wingdings', 'Wingdings', 'normal');

    var x = 0;
    var y = 0;
    let p = settings.filter((e) => e.nameOption === 'headergap')[0].value;

    var gapForHeader = parseInt(p);
    var startingPoint = 0;
    var gapBetweenHeader = 0;
    var patientDetailsFont = 0;
    var gapForContent = parseInt(settings.filter((e) => e.nameOption === 'gapForContent')[0].value);
    var gapForStartingTest = parseInt(settings.filter((e) => e.nameOption === 'gapForStartingTest')[0].value);
    var gapBetweenTest = 0;
    var gapBetweenSubtest = 0;
    var gapForFooter = parseInt(settings.filter((e) => e.nameOption === 'footergap')[0].value);
    var gapForDoctorName = parseInt(
        settings.filter((e) => e.nameOption === 'gapForDoctorName')[0].value
            ? settings.filter((e) => e.nameOption === 'gapForDoctorName')[0].value
            : 5
    );
    var gapForSignature = 5;
    let vOffSet = gapForStartingTest;
    let lOffset1 = 0;
    let lOffsetRMarka = 0;
    let maxLine = 0;
    let fontsize = parseInt(settings.filter((e) => e.nameOption === 'fontsize')[0].value);
    let gapBetweenSingleTest = 4;
    let gapBetweenMultipleTest = 3;
    let gapForComment = 3;
    let testType = '';
    let multipleSubTestGap = 0;
    let globalMultipleCount = 0;
    let globalPanelCount = 0;
    let multipleHascomment = false;
    let multiplecomment = '';
    let multipleHasNote = false;
    let multipleNote = '';
    let panelHascomment = false;
    let panelcomment = '';
    let panelHasNote = false;
    let panelNote = '';
    let panelFirst = true;
    let widalFirst = true;
    let cultureFirst = true;
    var panelI = [];
    var widalI = 0;
    var hasWidal = false;

    var cultureI = 0;
    var hasCulture = false;

    var testNameHeaderPos = parseInt(settings.filter((e) => e.nameOption === 'testNameHeaderPos')[0].value);
    var resultHeaderPos = parseInt(settings.filter((e) => e.nameOption === 'resultHeaderPos')[0].value);
    var unitHeaderPos = parseInt(settings.filter((e) => e.nameOption === 'unitHeaderPos')[0].value);
    var normalHeaderPos = parseInt(settings.filter((e) => e.nameOption === 'normalHeaderPos')[0].value);

    var commentHeaderPos = parseInt(settings.filter((e) => e.nameOption === 'commentHeaderPos')[0].value);
    var commentPos = parseInt(settings.filter((e) => e.nameOption === 'commentPos')[0].value);
    var commentHeaderFontSize = parseInt(settings.filter((e) => e.nameOption === 'commentHeaderFontSize')[0].value);
    var commentFontSize = parseInt(settings.filter((e) => e.nameOption === 'commentFontSize')[0].value);
    var testLineR = parseInt(settings.filter((e) => e.nameOption === 'testLineR')[0].value);
    var resultLineR = parseInt(settings.filter((e) => e.nameOption === 'resultLineR')[0].value);
    var unitLineR = parseInt(settings.filter((e) => e.nameOption === 'unitLineR')[0].value);
    var initialX = parseInt(settings.filter((e) => e.nameOption === 'initialX')[0].value);
    var finalX = parseInt(settings.filter((e) => e.nameOption === 'finalX')[0].value);
    var testpos = parseInt(settings.filter((e) => e.nameOption === 'testpos')[0].value);
    var unitpos = parseInt(settings.filter((e) => e.nameOption === 'unitpos')[0].value);
    var subtestpos = parseInt(settings.filter((e) => e.nameOption === 'subtestpos')[0].value);
    var resultpos = parseInt(settings.filter((e) => e.nameOption === 'resultpos')[0].value);
    var normalpos = parseInt(settings.filter((e) => e.nameOption === 'normalpos')[0].value);
    var resultpos = parseInt(settings.filter((e) => e.nameOption === 'resultpos')[0].value);
    // var resultendpos = parseInt(settings.filter((e) => e.nameOption === 'resultendpos')[0].value);
    // var resultunitpos = parseInt(settings.filter((e) => e.nameOption === 'resultunitpos')[0].value);
    // var testgap = parseInt(settings.filter((e) => e.nameOption === 'testgap')[0].value);
    // var subtestGap = parseInt(settings.filter((e) => e.nameOption === 'subtestGap')[0].value);
    var gapBetweenPanelAndHeader = parseInt(settings.filter((e) => e.nameOption === 'gapBetweenPanelAndHeader')[0].value);
    var gapForPanelHeader = parseInt(settings.filter((e) => e.nameOption === 'gapForPanelHeader')[0].value);

    var logoPos = parseInt(settings.filter((e) => e.nameOption === 'logoPos')[0].value);
    var filteredSettings = settings.filter((e) => e.nameOption === 'logoWidth');
    var logoWidth = filteredSettings.length > 0 ? parseInt(filteredSettings[0].value) : 5;
    var filteredSettings = settings.filter((e) => e.nameOption === 'logoHeight');
    var logoHeight = filteredSettings.length > 0 ? parseInt(filteredSettings[0].value) : 5;

    // var logoHeight = parseInt(settings.filter((e) => e.nameOption === 'logoHeight')[0].value);
    var mobileNoPos = parseInt(settings.filter((e) => e.nameOption === 'mobileNoPos')[0].value);
    var addressPos = parseInt(settings.filter((e) => e.nameOption === 'addressPos')[0].value);
    var emailPos = parseInt(settings.filter((e) => e.nameOption === 'emailPos')[0].value);

    var logoGap = parseInt(settings.filter((e) => e.nameOption === 'logoGap')[0].value);
    var companyGap = parseInt(settings.filter((e) => e.nameOption === 'companyGap')[0].value);
    var mobileNoGap = parseInt(settings.filter((e) => e.nameOption === 'mobileNoGap')[0].value);
    var addressGap = parseInt(settings.filter((e) => e.nameOption === 'addressGap')[0].value);
    var emailGap = parseInt(settings.filter((e) => e.nameOption === 'emailGap')[0].value);
    var onBlankPaper = settings.filter((e) => e.nameOption === 'printOption')[0].value;
    var companyFontName = settings.filter((e) => e.nameOption === 'companyFontName')[0].value;
    var companyFontSize = parseInt(settings.filter((e) => e.nameOption === 'companyFontSize')[0].value);

    var addressFontName = settings.filter((e) => e.nameOption === 'addressFontName')[0].value;
    var addressFontSize = parseInt(settings.filter((e) => e.nameOption === 'addressFontSize')[0].value);

    var mobileNoFontName = settings.filter((e) => e.nameOption === 'mobileNoFontName')[0].value;
    var mobileNoFontSize = parseInt(settings.filter((e) => e.nameOption === 'mobileNoFontSize')[0].value);

    var emailFontName = settings.filter((e) => e.nameOption === 'emailFontName')[0].value;
    var emailFontSize = parseInt(settings.filter((e) => e.nameOption === 'emailFontSize')[0].value);
    // var actionOnAbnormalResult = settings.filter((e) => e.nameOption === 'actionOnAbnormalResult')[0].value;

    var filteredSettings = settings.filter((e) => e.nameOption === 'actionOnAbnormalResult');
    var actionOnAbnormalResult = filteredSettings.length > 0 ? filteredSettings[0].value : 'Bold';

    var companyName = companyDetails[0].companyName ? companyDetails[0].companyName : null;
    var address = companyDetails[0].address ? companyDetails[0].address : null;
    var mobileNo = companyDetails[0].phone ? companyDetails[0].phone : null;
    var email = companyDetails[0].email;

    // var reultWidth = unitpos - resultpos;testLineR
    var reultWidth = resultLineR - testLineR;
    var unitWidth = unitLineR - resultLineR;
    var normalWidth = finalX - unitLineR;
    // console.log('reult width ' + reultWidth + ' unit width ' + unitWidth + ' normal width ' + normalWidth);

    var patientDetailsFont = parseInt(settings.filter((e) => e.nameOption === 'patientDetailsFont')[0].value);

    var fTestPos = testpos; //this test pose will responsible for print

    function stripHtml(html) {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        const lines = [];
        let inTable = false;

        function processNode(node) {
            if (node.nodeType === Node.TEXT_NODE) {
                const text = node.textContent.trim();
                lines.push(text);
            } else if (node.nodeType === Node.ELEMENT_NODE) {
                const nodeName = node.nodeName.toLowerCase();
                if (nodeName === 'ul' || nodeName === 'ol') {
                    const listItems = node.getElementsByTagName('li');
                    for (const listItem of listItems) {
                        lines.push('\u2022 ' + listItem.textContent.trim());
                    }
                } else if (nodeName === 'li') {
                    lines.push('\u2022 ' + node.textContent.trim());
                } else if (nodeName === 'table') {
                    inTable = true;
                    const tableRows = node.getElementsByTagName('tr');
                    for (const row of tableRows) {
                        const cells = row.getElementsByTagName('td');
                        const cellTexts = Array.from(cells).map((cell) => cell.textContent.trim());
                        lines.push(cellTexts.join('\t')); // Separate cells with a tab
                    }
                    inTable = false;
                    // lines.push('-------------------'); // Add a line after the table
                } else {
                    for (const childNode of node.childNodes) {
                        processNode(childNode);
                    }
                }
            }
        }

        processNode(doc.body);
        return lines.join('\n');
    }

    const calculateLineCount = (text, width) => {
        const lines = doc.splitTextToSize(text, width);
        return lines.length;
    };

    const downMarka = require('./downVector.png'); // Path to the image file
    const upMarka = require('./upVector.png'); // Path to the image file
    function convert() {
        var imageData = companyDetails[0]?.logo;
        if (imageData) {
            doc.addImage(imageData, 'JPEG', logoPos, logoGap, logoWidth, logoHeight);
        }
    }

    function printHeader() {
        if (onBlankPaper == 'true' || onBlankPaper == 'TRUE') {
            //tabhi ye print hoga
            var companyName = doc.setFontSize(companyFontSize).splitTextToSize(companyDetails[0].companyName, finalX - initialX);
            doc.setFontSize(companyFontSize).setFont(companyFontName);
            // doc
            doc.text(companyName, (finalX - initialX) / 2, companyGap, 'center');
            doc.setFontSize(mobileNoFontSize).setFont(mobileNoFontName);
            // doc.setFont('century');
            doc.text(mobileNo, mobileNoPos, mobileNoGap);
            doc.setFontSize(addressFontSize).setFont(addressFontName);

            doc.text(address, addressPos, addressGap);
            doc.setFontSize(emailFontSize).setFont(emailFontName);
            doc.text(email, emailPos, emailGap);
            convert();
        }

        // var img1 = new Image();
        // img1.onload = function () {
        //     doc.addImage(this, x + 137, y + 23, 23, 23);
        //     // doc.save('test.pdf');
        // };
        // img1.crossOrigin = '*'; // for demo as we are at different origin than image
        // img1.src =
        //     'https://chart.googleapis.com/chart?chs=100x100&cht=qr&chl=https://bdcollegepatna.in/certificate/charactercertificatepdf.php?userId=lalalilala';
        y = gapForHeader;
        doc.rect(x + 20, y + 20, 170, 35);
        doc.setFillColor(255, 255, 255);
        doc.roundedRect(x + 18, y + 22, 180, 31, 3, 3, 'F');
        doc.roundedRect(x + 15, y + 22, 180, 31, 3, 3);

        doc.setFontSize(10);
        doc.setFont('arial', 'bold').setFontSize(10);

        doc.text(`Name: ${itemDetails.title} ${itemDetails.name}`, x + 20, y + 28);
        doc.text(`Reffered By: Dr. ${itemDetails.constName}`, x + 128, y + 46);
        doc.text(` ${itemDetails.constDesignation}`, x + 147, y + 50);
        doc.text(`Ledger No: ${itemDetails.ledgerNo} `, x + 128, y + 28);

        doc.text(`/ ${itemDetails.labNo} `, x + 153, y + 28);

        doc.setFont('arial', 'normal').setFontSize(10);

        let age = 'Age: ';
        if (itemDetails.age_yy > 0) {
            age += `${itemDetails.age_yy}-yy `;
        }
        if (itemDetails.age_mm > 0) {
            age += `${itemDetails.age_mm}-mm `;
        }
        if (itemDetails.age_dd > 0) {
            age += `${itemDetails.age_dd}-dd`;
        }

        doc.text(age, x + 20, y + 34);
        doc.text(` ${itemDetails.gender}`, x + 60, y + 34);
        doc.text(`Mobile:  ${itemDetails.mobile}`, x + 20, y + 40);
        doc.text(`Address: ${itemDetails.address}`, x + 20, y + 46);

        // doc.text(`Reported On: ${dateTime}`, x + 128, y + 34);
        // doc.text(`Received On: ${formatDateDMY(on)}`, x + 128, y + 40);

        // doc.text(`Reported On: ${dateTime}`, x + 128, y + 34);
        const printedOn = formatDateDMY(new Date(dateTime)); // Replace with your date
        const recivedOn = formatDateDMY(new Date(on)); // Replace with your date
        // console.log('in pdf recivedOn is ', dateTime, 'formated ', recivedOn);
        doc.text(`Received On: ${recivedOn}`, 128, y + 34);
        doc.text(`Reported On: ${printedOn}`, x + 128, y + 40);

        doc.setFont('arial', 'bold').setFontSize(14);
        //     const imageURL =
        //     'https://chart.googleapis.com/chart?chs=50x50&cht=qr&chl=https://bdcollegepatna.in/certificate/charactercertificatepdf.php?userId=lalalilala'; // URL of the image
        // doc.addImage(imageURL, 'PNG', x + 90, y + 26, 29, 29);
        const imageURL =
            ' https://api.qrserver.com/v1/create-qr-code/?size=50x50&data=http://danta.co.in/website_designing.html?luserId=lalalilala/'; // URL of the image
        doc.addImage(imageURL, 'PNG', x + 90, y + 23, 35, 29);
        // https://api.qrserver.com/v1/create-qr-code/?size=100x100&data=http://visionclasses.co.in/
        //     'https://chart.googleapis.com/chart?chs=50x50&cht=qr&chl=http://danta.co.in/website_designing.html?luserId=lalalilala'; // URL of the image
    }
    const PrintContentHeader = () => {
        y = gapForContent;
        doc.text('Investigation', testNameHeaderPos, y);
        doc.text('Result', resultHeaderPos, y);
        doc.text('Units', unitHeaderPos, y);
        doc.text('Ref.Range', normalHeaderPos, y);
    };
    const PrintFooter = () => {
        // convert();

        // y = gapForFooter + gapForDoctorName;
        y = vOffSet + gapForDoctorName;
        // console.log('gapfor doctoername is ', gapForDoctorName);

        for (let m = 1; m <= 3; m++) {
            if (companyDetails[0][`status${m}`] == 'true') {
                // console.log(`footer to hai name is `, companyDetails[0][`name${m}`]);
                if (companyDetails[0][`heading${m}`]) {
                    doc.text(companyDetails[0][`heading${m}`], companyDetails[0][`position${m}`], y - 15);
                }
                if (companyDetails[0][`name${m}`]) {
                    doc.text(companyDetails[0][`name${m}`], companyDetails[0][`position${m}`], y);
                }
                if (companyDetails[0][`details${m}`]) {
                    doc.text(companyDetails[0][`details${m}`], companyDetails[0][`position${m}`], y + 5);
                }
                if (companyDetails[0][`signature${m}`]) {
                    var imageData = companyDetails[0][`signature${m}`];
                    doc.addImage(imageData, 'JPEG', companyDetails[0][`position${m}`], y - 13, 19, 10);
                }
            }
        }
    };

    const newPageCalculation = (lOffset1, commentLines) => {
        // vOffSet += fontsize / 4  + gapBetweenSingleTest;
        let tempVoffset = vOffSet + lOffset1 + commentLines.length * 3 + 10 + gapForComment + fontsize / 4 + gapBetweenSingleTest;
        // console.log('tempVoffset is ', tempVoffset);
        checkForNewPage(tempVoffset);
    };

    const checkForNewPage = (currentHeight) => {
        if (currentHeight > gapForFooter) {
            PrintFooter();
            doc.addPage();
            printHeader();
            PrintContentHeader();
            vOffSet = gapForStartingTest;
            globalMultipleCount = 0;
        }
    };
    var hasPrintHeader = false;
    var hasPrintContentHeader = false; // isme investinga, unit testname ka header hai

    for (let i = 0; i < tempVals.length; i++) {
        //pahle pahal ye check kar lo ki wo list me hai bhi ki nahi
        let valueSelectedHai = false;
        for (let k = 0; k < selectedRowIds.length; k++) {
            if (tempVals[i].id === selectedRowIds[k]) {
                valueSelectedHai = true;
                break;
            }
        }
        if (!valueSelectedHai) {
            continue;
        }
        lOffset1 = 0;
        maxLine = 0;
        // console.log('in the i  me pravesh to ho hi raha hai', i, tempVals[i].pathologyType);
        let testWidth = parseInt(testpos) - parseInt(resultpos);
        if (testType == 'Multiple') {
            j--;
        }
        //multiple ka comment print kar do. bina kisi test ke print kiye hue
        if (j == 0) {
            if (multipleHascomment == true) {
                let commentPlainText = multiplecomment;
                if (commentPlainText.length >= 1) {
                    // checkForNewPage(comment.length * 3 + 10 + gapForComment + vOffSet);
                    // doc.setFont('Arial', 'bold').setFontSize(commentHeaderFontSize);
                    // doc.text('Comment :-', commentHeaderPos, vOffSet + lOffset1 + gapForComment);
                    // doc.setFont('Arial', 'normal').setFontSize(commentFontSize);
                    // doc.text(comment, commentPos, vOffSet + 5 + lOffset1 + gapForComment);
                    // maxLine = comment.length;
                    // lOffset1 += comment.length * 3 + 10 + gapForComment;
                    // vOffSet += fontsize / 4 + lOffset1 + gapBetweenMultipleTest;
                    const commentLines = doc.splitTextToSize(commentPlainText, finalX - commentPos);
                    // console.log('commentlines ', commentLines, 'and pain text is ', commentPlainText);

                    checkForNewPage(commentLines.length * 3 + 10 + gapForComment + vOffSet);
                    doc.setFont('Arial', 'bold').setFontSize(commentHeaderFontSize);
                    doc.text(commentHeaderPos, vOffSet + 2 + lOffset1 + gapForComment, 'Comment :-');
                    doc.setFont('Arial', 'normal').setFontSize(commentFontSize);

                    // Print the comment text line by line
                    for (let j = 0; j < commentLines.length; j++) {
                        doc.text(commentPos, vOffSet + 8 + lOffset1 + gapForComment + j * 3, commentLines[j]);
                    }
                    maxLine = commentLines.length;
                    lOffset1 += commentLines.length * 3 + 10 + gapForComment;
                    vOffSet += fontsize / 4 + lOffset1 + gapBetweenMultipleTest;

                    multipleHascomment = false;
                    multiplecomment = '';
                    checkForNewPage(vOffSet);
                    lOffset1 = 0;
                }
            }
        }

        if (tempVals[i].type == 'Multiple' || tempVals[i].type == 'multiple') {
            if (globalMultipleCount > 0) {
                vOffSet += gapBetweenMultipleTest;
            }
            globalMultipleCount += 1;
            testWidth = 199;
        } else {
            testWidth = 49;
        }

        var testName = doc.setFontSize(fontsize).splitTextToSize(tempVals[i].testName, testWidth);
        if (testName.length > 1) {
            maxLine = testName.length;
            lOffset1 = (testName.length * fontsize) / 4;
        }
        var result = doc.setFontSize(fontsize).splitTextToSize(tempVals[i].results, reultWidth);
        // console.log('result kya hai', !tempVals[i].results);
        if (!tempVals[i].results && tempVals[i].type == 'Single') {
            continue;
        }
        if (result.length > 1) {
            maxLine = result.length;
            lOffset1 = (result.length * fontsize) / 4;
            lOffsetRMarka = lOffset1;
        } else {
            lOffsetRMarka = 0;
        }
        var normalValue = doc.setFontSize(fontsize).splitTextToSize(tempVals[i].normalValue, normalWidth);
        if (normalValue.length > 1 && normalValue.length > maxLine) {
            lOffset1 = (normalValue.length * fontsize) / 3;
        }
        if ((tempVals[i].type == 'Single' || tempVals[i].type == 'single') && tempVals[i].results !== '') {
            testType = 'Single';
            multipleSubTestGap = 0;
            gapBetweenSingleTest = 4;
            fTestPos = testpos;
        }

        if (tempVals[i].testName == 'Widal' || tempVals[i].testName == 'widal' || tempVals[i].testName == 'WIDAL') {
            testType = 'Widal';
            hasWidal = true;
            widalI = i;

            continue;
        }
        if (
            (tempVals[i].pathologyType == 'Culture' || tempVals[i].pathologyType == 'CULTURE' || tempVals[i].pathologyType == 'culture') &&
            (tempVals[i].type == 'Multiple' || tempVals[i].type == 'multiple')
        ) {
            testType = 'Culture';
            hasCulture = true;
            // if (i == 0) {
            //     cultureFirst = true;
            // }
            cultureI = i;
            continue;
        }
        if (tempVals[i].type == 'Panel' || tempVals[i].type == 'panel') {
            testType = 'Panel';
            panelI.push(i);
            // if (i == 0) {
            //     panelFirst = true;
            // }
            continue;
        }

        if (tempVals[i].type == 'Multiple' || tempVals[i].type == 'multiple') {
            if (i == 0) {
                PrintContentHeader();
                hasPrintContentHeader = true;
            }
            testType = 'Multiple';
            gapBetweenSingleTest = 3;
            lOffset1 = 0;
            var j = printMultiple(i);
            j++;
            continue;
        }

        if (testType == 'Multiple' || testType == 'multiple') {
            // multipleSubTestGap = 10;
            fTestPos = subtestpos;
        }

        if (testType == 'Panel' || testType == 'panel') {
            continue;
        }
        if (testType == 'Widal' || testType == 'widal') {
            continue;
        }
        if (testType == 'Culture' || testType == 'CULTURE' || testType == 'culture') {
            continue;
        }
        panelFirst = false;
        widalFirst = false;
        cultureFirst = false;
        // console.log('yahan at line 387 panelFirst is', panelFirst);
        if (!hasPrintHeader) {
            printHeader();
            hasPrintHeader = true;
        }

        if (i == 0 || !hasPrintContentHeader) {
            PrintContentHeader();
            hasPrintContentHeader = true;
        }

        if (tempVals[i].results) {
            // sara test value ka x postion usek right line r pe depend karega.
            var commentPlainText = stripHtml(tempVals[i].comment);
            var commentLineCount = calculateLineCount(commentPlainText, finalX - commentPos);
            if (commentLineCount > 1) {
                //there is comment check for its lenght. if height exceeds then go to new page.
                // console.log('and the test is ', testName);
                const commentLines = doc.splitTextToSize(commentPlainText, finalX - commentPos);
                newPageCalculation(lOffset1, commentLines);
            }
            doc.setFont('Century', 'Normal').setFontSize(fontsize);
            doc.text(testName, fTestPos + multipleSubTestGap, vOffSet + 1);
            let finalist = resultCheck(tempVals[i].results, normalValue);
            if (actionOnAbnormalResult == 'Marka') {
                if (finalist[1]) {
                    doc.addImage(upMarka, 'PNG', resultLineR - 5, vOffSet + lOffsetRMarka - 3.5, 5, 5);
                }
                if (finalist[2]) {
                    doc.addImage(downMarka, 'PNG', resultLineR - 5, vOffSet + lOffsetRMarka - 3.5, 5, 5);
                }
                doc.text(result, (testLineR + resultLineR) / 2, vOffSet + 1, 'center');
            } else if (actionOnAbnormalResult == 'Bold') {
                doc.setFont('Century', 'Normal').setFontSize(fontsize);
                if (finalist[1]) {
                    doc.setFont('arial', 'bold').setFontSize(fontsize);
                }
                if (finalist[2]) {
                    doc.setFont('arial', 'bold').setFontSize(fontsize);
                }
                doc.text(result, (testLineR + resultLineR) / 2, vOffSet + 1, 'center');
                doc.setFont('Century', 'Normal').setFontSize(fontsize);
            } else if (actionOnAbnormalResult == 'Italic') {
                doc.setFont('Century', 'Normal').setFontSize(fontsize);
                if (finalist[1]) {
                    doc.setFont('arial', 'italic');
                    doc.setFont('Helvetica', 'BoldOblique');
                }
                if (finalist[2]) {
                    doc.setFont('arial', 'italic');
                }
                doc.text(result, (testLineR + resultLineR) / 2, vOffSet + 1, 'center');
                doc.setFont('Century', 'Normal').setFontSize(fontsize);
            } else if (actionOnAbnormalResult == 'Bold & Italic') {
                doc.setFont('Century', 'Normal').setFontSize(fontsize);
                if (finalist[1]) {
                    doc.setFont('Helvetica', 'BoldOblique');
                }
                if (finalist[2]) {
                    doc.setFont('Helvetica', 'BoldOblique');
                }
                doc.text(result, (testLineR + resultLineR) / 2, vOffSet + 1, 'center');
                doc.setFont('Century', 'Normal').setFontSize(fontsize);
            } else {
                doc.text(result, (testLineR + resultLineR) / 2, vOffSet + 1, 'center');
            }
            doc.setFont('Century', 'Normal').setFontSize(fontsize);
            doc.text(tempVals[i].units, resultLineR + 2, vOffSet + 1);
            doc.text(normalValue, (finalX + unitLineR) / 2, vOffSet + 1, 'center');
            doc.setDrawColor(128, 128, 128);
            doc.line(initialX, vOffSet - 4, finalX, vOffSet - 4);
            doc.line(initialX, vOffSet - 4, initialX, vOffSet + 2 + lOffset1); //Line Test Name L
            doc.line(testLineR, vOffSet - 4, testLineR, vOffSet + 2 + lOffset1); //Line Test Name R
            doc.line(resultLineR, vOffSet - 4, resultLineR, vOffSet + 2 + lOffset1); //Line Result R
            doc.line(unitLineR, vOffSet - 4, unitLineR, vOffSet + 2 + lOffset1); //Line Unit R
            doc.line(finalX, vOffSet - 4, finalX, vOffSet + 2 + lOffset1); //Line Unit Ref Range
            doc.line(initialX, vOffSet + 2 + lOffset1, finalX, vOffSet + 2 + lOffset1); //bottom
            doc.setDrawColor(0, 0, 0);
            var comment = doc
                .setFont('Arial')
                .setFontSize(commentFontSize)
                .splitTextToSize(tempVals[i].comment, finalX - commentPos);
            // Calculate the line count for comment text after stripping HTML
            // var note = doc
            //     .setFont('Arial')
            //     .setFontSize(commentFontSize)
            //     .splitTextToSize(tempVals[i].note, finalX - commentPos);

            if (commentLineCount > 1) {
                // Adjust the comment text to fit within the given width
                const commentLines = doc.splitTextToSize(commentPlainText, finalX - commentPos);

                checkForNewPage(commentLines.length * 3 + 10 + gapForComment + vOffSet);
                doc.setFont('Arial', 'bold').setFontSize(commentHeaderFontSize);
                doc.text(commentHeaderPos, vOffSet + 5 + lOffset1 + gapForComment, 'Comment :-');
                doc.setFont('Arial', 'normal').setFontSize(commentFontSize);

                // Print the comment text line by line
                for (let j = 0; j < commentLines.length; j++) {
                    doc.text(commentPos, vOffSet + 10 + lOffset1 + gapForComment + j * 3, commentLines[j]);
                }
                maxLine = commentLines.length;
                lOffset1 += commentLines.length * 3 + 10 + gapForComment;
            }
            vOffSet += fontsize / 4 + lOffset1 + gapBetweenSingleTest;
            checkForNewPage(vOffSet);
            lOffset1 = 0;
        }
    }
    if (testType !== 'Culture' && testType !== 'Multiple' && testType !== 'Panel' && testType !== 'Widal') {
        // convert();
        PrintFooter();
    }

    if (multipleHascomment == true) {
        //kyuki last loop me hoga to loop me nahi jayega sidhe bahar aa jayega tabhi chek kar lenge
        let commentPlainText = multiplecomment;
        if (commentPlainText.length >= 1) {
            const commentLines = doc.splitTextToSize(commentPlainText, 185);
            checkForNewPage(commentLines.length * 3 + 10 + gapForComment + vOffSet);
            doc.setFont('Arial', 'bold').setFontSize(commentHeaderFontSize);
            doc.text(commentHeaderPos, vOffSet + 5 + lOffset1 + gapForComment, 'Comment :-');
            doc.setFont('Arial', 'normal').setFontSize(commentFontSize);
            // Print the comment text line by line
            for (let j = 0; j < commentLines.length; j++) {
                doc.text(commentPos, vOffSet + 10 + lOffset1 + gapForComment + j * 3, commentLines[j]);
            }

            maxLine = commentLines.length;
            lOffset1 += commentLines.length * 3 + 10 + gapForComment;

            checkForNewPage(vOffSet);
            multipleHascomment = false;
            multiplecomment = '';
            checkForNewPage(vOffSet);
            lOffset1 = 0;
        }
        PrintFooter();
    }

    if (hasWidal) {
        panelFirst = false;
        cultureFirst = false;
        printWidal();
    }
    if (hasCulture) {
        panelFirst = false; //as we are printing widal firstt then culture then panel so if it enter in the widal then it cant be on the first page it should take  a new page same in culture

        let k = printCulture();
        printCultureSensitivity(k, '++++');
        printCultureSensitivity(k, '+++');
        printCultureSensitivity(k, '++');
        printCultureSensitivity(k, '+');
        printCultureSensitivity(k, 'S');
        printCultureResistance(k);
        //R ka alag se jugar hoga
        // panelcomment = comment;
        // panelHascomment = true;

        let comment = panelcomment;
        if (panelHascomment) {
            checkForNewPage(comment.length * 3 + 10 + gapForComment + vOffSet);
            doc.setFont('Arial', 'bold').setFontSize(commentHeaderFontSize);
            doc.text('Comment :-', commentHeaderPos, vOffSet + 5 + lOffset1 + gapForComment);
            doc.setFont('Arial', 'normal').setFontSize(commentFontSize);
            // doc.setFontSize(11);
            doc.text(comment, commentPos, vOffSet + 10 + lOffset1 + gapForComment);
            maxLine = comment.length;
            // console.log('comment length: ' + comment.length);
            lOffset1 += comment.length * 3 + 10 + gapForComment;
            panelHascomment = false;
            panelcomment = '';
            // console.log('offset is ', lOffset1);
        }
        let note = panelNote;
        if (panelHasNote) {
            checkForNewPage(note.length * 3 + 10 + gapForComment + vOffSet);
            doc.setFont('Arial', 'bold').setFontSize(commentHeaderFontSize);
            doc.text('Note :-', commentHeaderPos, vOffSet + 5 + lOffset1 + gapForComment);
            doc.setFont('Arial', 'normal').setFontSize(commentFontSize);
            doc.text(note, commentPos, vOffSet + 10 + lOffset1 + gapForComment);
            maxLine = note.length;
            lOffset1 += comment.length * 3 + 10 + gapForComment;
            panelHasNote = false;
            panelNote = '';
        }
        PrintFooter();
    }
    if (panelI.length > 0) {
        panelI.map((i, key) => {
            printPanel(i);

            //agar comment hai to print karen.
            if (panelHascomment) {
                // if (panelcomment.length > 1) {
                //     checkForNewPage(panelcomment.length * 3 + 10 + gapForComment + vOffSet);
                //     doc.setFont('Arial', 'bold').setFontSize(commentHeaderFontSize);
                //     doc.text('Comment :-', commentHeaderPos, vOffSet + 5 + lOffset1 + gapForComment);
                //     doc.setFont('Arial', 'normal').setFontSize(commentFontSize);
                //     doc.text(panelcomment, commentPos, vOffSet + 10 + lOffset1 + gapForComment);
                //     maxLine = panelcomment.length;
                //     lOffset1 += panelcomment.length * 3 + 10 + gapForComment;
                //     vOffSet += lOffset1 + gapForComment;
                // }
                var commentPlainText = stripHtml(panelcomment);
                var commentLineCount = calculateLineCount(commentPlainText, finalX - commentPos);

                if (commentLineCount > 1) {
                    // Adjust the comment text to fit within the given width
                    const commentLines = doc.splitTextToSize(commentPlainText, finalX - commentPos);
                    checkForNewPage(commentLines.length * 3 + 10 + gapForComment + vOffSet);
                    doc.setFont('Arial', 'bold').setFontSize(commentHeaderFontSize);
                    doc.text(commentHeaderPos, vOffSet + 5 + lOffset1 + gapForComment, 'Comment :-');
                    doc.setFont('Arial', 'normal').setFontSize(commentFontSize);
                    // Print the comment text line by line
                    for (let j = 0; j < commentLines.length; j++) {
                        doc.text(commentPos, vOffSet + 10 + lOffset1 + gapForComment + j * 3, commentLines[j]);
                    }
                    maxLine = commentLines.length;
                    lOffset1 += commentLines.length * 3 + 10 + gapForComment;
                    vOffSet += lOffset1 + gapForComment;
                }
            }
        });
        PrintFooter();
    }

    function caclculateForMultiple(k) {
        let j = 0; //for counting total multiple test
        let lVoffset = vOffSet;
        let lCommnetGap = 0;
        //check for comments gap
        var commentPlainText = stripHtml(tempVals[k].comment);
        var commentLineCount = calculateLineCount(commentPlainText, finalX - commentPos);

        // var comment = doc.setFont('Arial').setFontSize(fontsize).splitTextToSize(tempVals[k].comment, 185);
        if (commentLineCount >= 1) {
            multiplecomment = commentPlainText;
            const commentLines = doc.splitTextToSize(commentPlainText, finalX - commentPos);
            multipleHascomment = true;
            lVoffset += commentLines.length * 3 + 10 + gapForComment;
        }

        k++;

        for (k; k < tempVals.length; k++) {
            if (tempVals[k].testCode != '') {
                if (lVoffset > gapForFooter) {
                    checkForNewPage(lVoffset);
                    return j;
                }
                return j;
            }
            j++;
            // calculating max line for the size of the test
            let testWidth = parseInt(resultpos) - parseInt(testpos);
            let reultWidth = parseInt(unitpos) - parseInt(resultpos);
            let normalWidth = parseInt(normalpos) - parseInt(unitpos);

            var testName = doc.setFontSize(fontsize).splitTextToSize(tempVals[k].testName, testWidth);
            let localMaxLine = 1;
            if (testName.length > 1) {
                localMaxLine = testName.length;
                // lOffset1 = (testName.length * fontsize) / 4;
            }
            var result = doc.setFontSize(fontsize).splitTextToSize(tempVals[k].results, reultWidth);
            // console.log('result kya hai', !tempVals[i].results);

            if (result.length > 1) {
                if (localMaxLine > result.length) {
                    localMaxLine = result.length;
                }
            }
            var normalValue = doc.setFontSize(fontsize).splitTextToSize(tempVals[k].normalValue, normalWidth);
            if (normalValue.length > 1 && normalValue.length > localMaxLine) {
                localMaxLine = normalValue.length;
            }
            //caculation over
            lVoffset += lOffset1 + gapBetweenMultipleTest + (localMaxLine * fontsize) / 4;
        }
        if (lVoffset > gapForFooter) {
            checkForNewPage(lVoffset);
            return j;
        }
        return j;
    }
    function printMultiple(i) {
        //calculate for multiple gap
        let j = caclculateForMultiple(i);
        lOffset1 = 0;
        // console.log('vOffset in after calculate at start of ' + testName + 'is ' + vOffSet + 'l offset is ' + lOffset1);

        doc.setFont('Century', 'bold').setFontSize(fontsize);
        doc.text(testName, testpos, vOffSet + 1);
        doc.setDrawColor(128, 128, 128);
        doc.line(initialX, vOffSet - 4, finalX, vOffSet - 4);
        doc.line(initialX, vOffSet - 4, initialX, vOffSet + 2 + lOffset1); //Line Test Name L
        doc.line(finalX, vOffSet - 4, finalX, vOffSet + 2 + lOffset1); //Line Unit Ref Range
        doc.line(initialX, vOffSet + 2 + lOffset1, finalX, vOffSet + 2 + lOffset1); //bottom

        doc.setDrawColor(0, 0, 0);
        vOffSet += fontsize / 4 + lOffset1 + 3;
        checkForNewPage(vOffSet);
        lOffset1 = 0;

        return j;
    }

    function printPanel(j) {
        lOffset1 = 0;
        if (!panelFirst) {
            PrintFooter();
            doc.addPage();
        }
        panelFirst = false;
        vOffSet = gapForPanelHeader;
        // let m = i + 1;
        printHeader();
        panelcomment = '';
        panelHascomment = false;
        for (let i = j; i < tempVals.length; i++) {
            if (tempVals[i].testCode != '') {
                if (i != j) {
                    // console.log('m is ', m, 'and tempVals is ', tempVals.length, 'rest test is ', restTest);
                    return;
                }
            }
            if (i == j) {
                //Print the Header
                doc.text(tempVals[i].testName, (initialX + finalX) / 2, vOffSet + 1, 'center');

                vOffSet += fontsize / 4 + gapBetweenPanelAndHeader;
                doc.text('Investigation', testNameHeaderPos, vOffSet);

                doc.text('Result', resultHeaderPos, vOffSet);
                // doc.line(x+88,y+panelGap+93,x+88,y+panelGap+91);
                doc.text('Units', unitHeaderPos, vOffSet);
                // doc.line(x+138,y+panelGap+93,x+138,y+panelGap+91);
                doc.text('Ref.Range', normalHeaderPos, vOffSet);
                vOffSet += fontsize / 4 + lOffset1 + gapBetweenSingleTest;

                var comment = doc.setFont('Arial').setFontSize(commentFontSize).splitTextToSize(tempVals[i].comment, 185);
                if (comment.length > 1) {
                    panelcomment = comment;
                    panelHascomment = true;
                }
                var note = doc.setFont('Arial').setFontSize(commentFontSize).splitTextToSize(tempVals[i].note, 185);
                if (note.length > 1) {
                    panelNote = note;
                    panelHasNote = true;
                }

                continue;
            }
            lOffset1 = 0;
            maxLine = 0;
            let testWidth = parseInt(resultpos) - parseInt(testpos);
            if (tempVals[i].testName != '') {
                fTestPos = testpos;
                var testName = doc.setFontSize(fontsize).splitTextToSize(tempVals[i].testName, testWidth);
            } else if (tempVals[i].subtest1 != '') {
                fTestPos = subtestpos;
                var testName = doc.setFontSize(fontsize).splitTextToSize(tempVals[i].subtest1, testWidth);
            }
            if (testName.length > 1) {
                maxLine = testName.length;
                lOffset1 = (testName.length * fontsize) / 4;
            }
            var result = doc.setFontSize(12).splitTextToSize(tempVals[i].results, reultWidth);
            if (result.length > 1) {
                maxLine = result.length;
                lOffset1 = (result.length * fontsize) / 4;
            }
            var normalValue = doc.setFontSize(12).splitTextToSize(tempVals[i].normalValue, normalWidth);
            if (normalValue.length > 1 && normalValue.length > maxLine) {
                lOffset1 = (normalValue.length * fontsize) / 3;
            }
            // console.log('maxLine : ' + maxLine + 'lOffset1:-' + lOffset1);
            multipleSubTestGap = 0;

            doc.setFont('Century', 'Normal').setFontSize(fontsize);

            if (tempVals[i].units == '.') {
                doc.setFont('Century', 'bold').setFontSize(fontsize);
                doc.text(testName, fTestPos + multipleSubTestGap, vOffSet + 1); // ye multiple ka test name hai
            } else {
                doc.setFont('Century', 'Normal').setFontSize(fontsize);
            }
            // console.log('starting me vOffset:- ' + vOffSet);

            if (tempVals[i].units != '.') {
                //iska matalb hai ki ye multiple nahi hai
                if (!tempVals[i].results) {
                    continue;
                }
                doc.text(testName, fTestPos + multipleSubTestGap, vOffSet + 1);

                // doc.text(result, testLineR+2, vOffSet + 1);
                let finalist = resultCheck(tempVals[i].results, normalValue);

                if (actionOnAbnormalResult == 'Marka') {
                    if (finalist[1]) {
                        doc.addImage(upMarka, 'PNG', resultLineR - 5, vOffSet + lOffsetRMarka - 3.5, 5, 5);
                    }
                    if (finalist[2]) {
                        doc.addImage(downMarka, 'PNG', resultLineR - 5, vOffSet + lOffsetRMarka - 3.5, 5, 5);
                    }
                    doc.text(result, (testLineR + resultLineR) / 2, vOffSet + 1, 'center');
                } else if (actionOnAbnormalResult == 'Bold') {
                    // doc.setFont('Century', 'Bold').setFontSize(fontsize);
                    doc.setFont('Century', 'Normal').setFontSize(fontsize);

                    if (finalist[1]) {
                        doc.setFont('arial', 'bold').setFontSize(fontsize);
                    }
                    if (finalist[2]) {
                        doc.setFont('arial', 'bold').setFontSize(fontsize);
                    }
                    doc.text(result, (testLineR + resultLineR) / 2, vOffSet + 1, 'center');
                    doc.setFont('Century', 'Normal').setFontSize(fontsize);
                } else if (actionOnAbnormalResult == 'Italic') {
                    // doc.setFont('Century', 'Bold').setFontSize(fontsize);
                    doc.setFont('Century', 'Normal').setFontSize(fontsize);

                    if (finalist[1]) {
                        doc.setFont('arial', 'italic');
                        doc.setFont('Helvetica', 'BoldOblique');
                    }
                    if (finalist[2]) {
                        doc.setFont('arial', 'italic');
                    }
                    doc.text(result, (testLineR + resultLineR) / 2, vOffSet + 1, 'center');
                    doc.setFont('Century', 'Normal').setFontSize(fontsize);
                } else if (actionOnAbnormalResult == 'Bold & Italic') {
                    // doc.setFont('Century', 'Bold').setFontSize(fontsize);
                    doc.setFont('Century', 'Normal').setFontSize(fontsize);

                    if (finalist[1]) {
                        doc.setFont('Helvetica', 'BoldOblique');
                    }
                    if (finalist[2]) {
                        doc.setFont('Helvetica', 'BoldOblique');
                    }
                    doc.text(result, (testLineR + resultLineR) / 2, vOffSet + 1, 'center');
                    doc.setFont('Century', 'Normal').setFontSize(fontsize);
                } else {
                    doc.text(result, (testLineR + resultLineR) / 2, vOffSet + 1, 'center');
                }
                doc.text(tempVals[i].units, resultLineR + 2, vOffSet + 1);
                // doc.text(normalValue, unitLineR+2, vOffSet + 1);
                doc.text(normalValue, (finalX + unitLineR) / 2, vOffSet + 1, 'center');
                doc.setDrawColor(128, 128, 128);
                doc.line(initialX, vOffSet - 4, finalX, vOffSet - 4);
                doc.line(initialX, vOffSet - 4, initialX, vOffSet + 2 + lOffset1); //Line Test Name L
                doc.line(testLineR, vOffSet - 4, testLineR, vOffSet + 2 + lOffset1); //Line Test Name R
                doc.line(resultLineR, vOffSet - 4, resultLineR, vOffSet + 2 + lOffset1); //Line Result R
                doc.line(unitLineR, vOffSet - 4, unitLineR, vOffSet + 2 + lOffset1); //Line Unit R
                doc.line(finalX, vOffSet - 4, finalX, vOffSet + 2 + lOffset1); //Line Unit Ref Range
                doc.line(initialX, vOffSet + 2 + lOffset1, finalX, vOffSet + 2 + lOffset1); //bottom
                doc.setDrawColor(0, 0, 0);
                vOffSet += fontsize / 4 + lOffset1 + gapBetweenMultipleTest;
                checkForNewPage(vOffSet);
                lOffset1 = 0;
                continue;
            } else {
                doc.setDrawColor(128, 128, 128);
                doc.line(initialX, vOffSet - 4, finalX, vOffSet - 4);
                doc.line(initialX, vOffSet - 4, initialX, vOffSet + 2 + lOffset1); //Line Test Name L
                doc.line(finalX, vOffSet - 4, finalX, vOffSet + 2 + lOffset1); //Line Unit Ref Range
                doc.line(initialX, vOffSet + 2 + lOffset1, finalX, vOffSet + 2 + lOffset1); //bottom
                doc.setDrawColor(0, 0, 0);

                vOffSet += fontsize / 4 + lOffset1 + gapBetweenMultipleTest;
                checkForNewPage(vOffSet);
                lOffset1 = 0;
            }
        }
    }
    function printWidal() {
        if (!widalFirst) {
            PrintFooter();
            doc.addPage();
        }
        printHeader();

        doc.text('Investigation Requested', x + 25, y + 76);
        doc.line(x + 24, y + 77, x + 64, y + 77);
        doc.text('WIDAL TEST', x + 95, y + 76);
        doc.text('1:20', x + 80, y + 90);
        // doc.line(x+15,y+93,x+195,y+93);
        doc.text('1:40', x + 100, y + 90);
        // doc.line(x+88,y+93,x+88,y+91);
        doc.text('1:80', x + 120, y + 90);
        // doc.line(x+138,y+93,x+138,y+91);
        doc.text('1:160', x + 140, y + 90);
        // doc.line(x+168,y+93,x+168,y+91);
        doc.text('1:320', x + 160, y + 90);

        doc.text("S.TYPHI'O'", x + 20, y + 98);
        // doc.line(x+15,y+101,x+195,y+101);

        doc.text(`(${tempVals[widalI + 1].results})`, x + 82, y + 98);
        doc.text(`(${tempVals[widalI + 5].results})`, x + 101, y + 98);
        // doc.line(x+88,y+93,x+88,y+101);
        doc.text(`(${tempVals[widalI + 9].results})`, x + 121, y + 98);
        // doc.line(x+138,y+93,x+138,y+101);
        doc.text(`(${tempVals[widalI + 13].results})`, x + 141, y + 98);
        // doc.line(x+168,y+93,x+168,y+101);
        doc.text(`(${tempVals[widalI + 17].results})`, x + 161, y + 98);

        doc.text("S.TYPHI'H'", x + 20, y + 106);
        // doc.line(x+15,y+109,195,y+109);
        doc.text(`(${tempVals[widalI + 2].results})`, x + 82, y + 105); // typhi h 20
        doc.text(`(${tempVals[widalI + 6].results})`, x + 101, y + 105);
        doc.text(`(${tempVals[widalI + 10].results})`, x + 121, y + 105);
        doc.text(`(${tempVals[widalI + 14].results})`, x + 141, y + 105);
        doc.text(`(${tempVals[widalI + 18].results})`, x + 161, y + 105);

        doc.text("S.PARATYPHI'AH'", x + 20, y + 114);
        // doc.line(x+15,y+117,195,y+117);
        doc.text(`(${tempVals[widalI + 3].results})`, x + 82, y + 113); // para TYPHI AH20
        // // doc.line(x+88,y+109,x+88,y+149);
        doc.text(`(${tempVals[widalI + 7].results})`, x + 101, y + 113);
        // // doc.line(x+138,y+109,x+138,y+149);
        doc.text(`(${tempVals[widalI + 11].results})`, x + 121, y + 113);
        doc.text(`(${tempVals[widalI + 15].results})`, x + 141, y + 113);
        doc.text(`(${tempVals[widalI + 19].results})`, x + 161, y + 113);
        // // doc.line(x+168,y+109,x+168,y+149);

        doc.text("S.PARATYPHI'BH'", x + 20, y + 122);
        doc.text(`(${tempVals[widalI + 4].results})`, x + 82, y + 120);
        doc.text(`(${tempVals[widalI + 8].results})`, x + 101, y + 120);
        doc.text(`(${tempVals[widalI + 12].results})`, x + 121, y + 120);
        doc.text(`(${tempVals[widalI + 16].results})`, x + 141, y + 120);
        doc.text(`(${tempVals[widalI + 20].results})`, x + 161, y + 120);

        // doc.text(`${tempVals[widalI].comment}`, x + 20, y + 150);
        vOffSet = 135;
        var comment = doc
            .setFont('Arial')
            .setFontSize(commentFontSize)
            .splitTextToSize(tempVals[widalI].comment, finalX - initialX);
        if (comment.length > 1 && comment[0] != '') {
            checkForNewPage(comment.length * 3 + 10 + gapForComment + vOffSet);
            doc.setFont('Arial', 'bold').setFontSize(commentHeaderFontSize);
            doc.text('Comment :-', commentHeaderPos, vOffSet + lOffset1 + gapForComment);
            doc.setFont('Arial', 'normal').setFontSize(commentFontSize);
            doc.text(comment, commentPos, vOffSet + 5 + lOffset1 + gapForComment);
            lOffset1 += comment.length * 3 + gapForComment;
            vOffSet += commentFontSize / 4 + lOffset1 + 10;
            // checkForNewPage(vOffSet);
        }
        var note = doc
            .setFont('Arial')
            .setFontSize(commentFontSize)
            .splitTextToSize(tempVals[widalI].note, finalX - initialX);
        if (note.length > 1 && note[0] != '') {
            checkForNewPage(note.length * 3 + 10 + gapForComment + vOffSet);
            doc.setFont('Arial', 'bold').setFontSize(commentHeaderFontSize);
            doc.text('Note :-', commentHeaderPos, vOffSet + gapForComment);
            doc.setFont('Arial', 'normal').setFontSize(commentFontSize);
            doc.text(note, commentPos, vOffSet + 5 + gapForComment);
            lOffset1 += note.length * 3 + 10 + gapForComment;
            vOffSet += fontsize / 4 + lOffset1 + gapBetweenMultipleTest;
            // checkForNewPage(vOffSet);
        }

        doc.setFont('Century', 'Normal').setFontSize(fontsize);

        PrintFooter();
    }

    function printCulture() {
        lOffset1 = 0;
        if (!cultureFirst) {
            doc.addPage();
        }
        vOffSet = gapForPanelHeader;
        // let m = i + 1;

        printHeader();

        panelcomment = '';
        panelHascomment = false;
        panelNote = '';
        panelHasNote = false;
        for (let i = cultureI; i < tempVals.length; i++) {
            // if (tempVals[i].testCode != '') {
            //     if (i != cultureI) {
            //         // console.log('m is ', m, 'and tempVals is ', tempVals.length, 'rest test is ', restTest);
            //         return;
            //     }
            // }
            if (i == cultureI) {
                //Print the Header
                doc.text('REPORT ON THE BACTERIOLOGICAL EXAMINATION', (initialX + finalX) / 2, vOffSet + 1, 'center');
                vOffSet += fontsize / 4 + gapBetweenPanelAndHeader;
                var comment = doc.setFont('Arial').setFontSize(commentFontSize).splitTextToSize(tempVals[i].comment, 185);
                if (comment.length > 1 && comment[0] != '') {
                    panelcomment = comment;
                    panelHascomment = true;
                }
                var note = doc.setFont('Arial').setFontSize(commentFontSize).splitTextToSize(tempVals[i].note, 185);
                if (note.length > 1 && note[0] != '') {
                    panelNote = note;
                    panelHasNote = true;
                }

                continue;
            }
            // vOffSet += fontsize / 4 + lOffset1 + gapBetweenMultipleTest;

            if (tempVals[i].testName == 'AMPICILIN') {
                lOffset1 = 0;
                maxLine = 0;
                doc.setFont('Century', 'bold').setFontSize(fontsize);
                doc.text('Sensitive to :', testpos, vOffSet);
                doc.line(testpos, vOffSet + 0.4, testpos + 22, vOffSet + 0.4);
                doc.setFont('Arial', 'normal').setFontSize(9);
                doc.text('(In order of zone of inhibition)', testpos + 26, vOffSet);
                vOffSet += fontsize / 4 + gapBetweenMultipleTest;

                //         doc.line(x + 15, vOffSet - 6, x + 15, vOffSet + 1 + lOffset1); //Line Test Name L

                return i;
                //test ka name print kijiye.
                //         doc.line(x + 15, vOffSet - 6, x + 15, vOffSet + 1 + lOffset1); //Line Test Name L
                //
            }
            if (tempVals[i].results != null && tempVals[i].results != '') {
                doc.setFont('Century', 'Normal').setFontSize(fontsize);
                doc.text(tempVals[i].testName, (initialX + finalX) / 2 - 40, vOffSet);
                doc.setFont('Century', 'bold').setFontSize(fontsize);
                doc.text(':-', (initialX + finalX) / 2, vOffSet);
                doc.text(tempVals[i].results + '  ' + tempVals[i].units, 111, vOffSet);
                vOffSet += fontsize / 4 + gapBetweenMultipleTest;
            }
        }
    }
    function printCultureSensitivity(i, j) {
        let m = 0;
        doc.setFont('Arial', 'normal').setFontSize(fontsize);
        for (let i = cultureI; i < tempVals.length; i++) {
            if (tempVals[i].results == j) {
                if (m % 2 == 0) {
                    // doc.setFont('Century', 'bold').setFontSize(fontsize);

                    doc.text(tempVals[i].testName, testpos, vOffSet);
                    doc.text(`(${j})`, testpos + 48, vOffSet);

                    //ye wala even hai to left me print hoga
                } else {
                    doc.text(tempVals[i].testName, 111, vOffSet);
                    doc.text(`(${j})`, 111 + 48, vOffSet);
                    vOffSet += fontsize / 4 + gapBetweenMultipleTest;
                    //ye wala odd hai to right me print hoga.
                }
                m++;
            }
        }
        if (m % 2 == 1) {
            //even me hi end hoga loop to y ko bada deteh hain
            vOffSet += fontsize / 4 + gapBetweenMultipleTest + 3;
        } else {
            vOffSet += 3;
        }
    }
    function printCultureResistance(i) {
        let m = 0;
        doc.setFont('Century', 'bold').setFontSize(fontsize);
        doc.text('Resistant to :', testpos, vOffSet);
        doc.line(testpos, vOffSet + 0.4, testpos + 22, vOffSet + 0.4);
        vOffSet += fontsize / 4 + gapBetweenMultipleTest;

        doc.setFont('Arial', 'normal').setFontSize(fontsize);
        for (let i = cultureI; i < tempVals.length; i++) {
            if (tempVals[i].results == 'R') {
                if (m % 3 == 0) {
                    // doc.setFont('Century', 'bold').setFontSize(fontsize);

                    doc.text(tempVals[i].testName, testpos, vOffSet);

                    //ye wala even hai to left me print hoga
                } else if (m % 3 == 1) {
                    doc.text(tempVals[i].testName, 111, vOffSet);

                    //ye wala odd hai to right me print hoga.
                } else {
                    doc.text(tempVals[i].testName, 168, vOffSet);

                    vOffSet += fontsize / 4 + gapBetweenMultipleTest;
                }
                m++;
            }
        }
        if (m % 3 == 1 || m % 3 == 2) {
            //even me hi end hoga loop to y ko bada deteh hain
            vOffSet += fontsize / 4 + gapBetweenMultipleTest + 3;
        } else {
            vOffSet += 3;
        }
    }

    doc.save(` ${itemDetails.labNo} ${itemDetails.name} `);
};
const resultCheck = (resu, normal) => {
    // console.log('reus in result check ', resu);
    let stringcheck = false;
    let norValInRange = false;
    let norValIsGrtr = false;
    let norValIsLes = false;
    let greaterResult = false;
    let lessResult = false;
    let notMatchedString = false;
    let finalcheck = false;
    normal = normal.toString();

    for (let i = 0; i < normal.length; i++) {
        const c = normal[i];
        if (c === '-') {
            norValInRange = true;
            break;
        } else if (c === '>') {
            norValIsGrtr = true;
            break;
        } else if (c === '<') {
            norValIsLes = true;
            break;
        }
    }

    if (norValInRange) {
        const res = parseFloat(resu);
        if (!isNaN(res)) {
            const words = normal.split('-');
            if (res >= parseFloat(words[0]) && res <= parseFloat(words[1])) {
                finalcheck = true;
            } else {
                if (res < parseFloat(words[0])) {
                    lessResult = true;
                } else {
                    greaterResult = true;
                }
                finalcheck = false;
            }
        } else {
            notMatchedString = true;
            finalcheck = true;
        }
    } else if (norValIsGrtr) {
        const res = parseFloat(resu);
        if (!isNaN(res)) {
            const words = normal.split('>');
            if (res >= parseFloat(words[1])) {
                finalcheck = true;
            } else {
                lessResult = true;
                finalcheck = false;
            }
        } else {
            notMatchedString = true;
            finalcheck = true;
        }
    } else if (norValIsLes) {
        const res = parseFloat(resu);
        if (!isNaN(res)) {
            const words = normal.split('<');
            if (res <= parseFloat(words[1])) {
                lessResult = true;
            } else {
                greaterResult = true;
                finalcheck = false;
            }
        } else {
            notMatchedString = true;
            finalcheck = true;
        }
    } else {
        if (parseFloat(normal) > 0 && parseFloat(resu) > 0) {
            if (parseFloat(resu) > parseFloat(normal)) {
                greaterResult = true;
            } else if (parseFloat(resu) < parseFloat(normal)) {
                lessResult = true;
            } else {
                finalcheck = true;
            }
        } else if (normal !== '') {
            if (normal.toUpperCase().trim() === resu.toUpperCase().trim()) {
                finalcheck = true;
            } else {
                notMatchedString = true;
                finalcheck = false;
            }
        } else {
            finalcheck = true;
        }
    }

    for (let i = 0; i < normal.length; i++) {
        if (normal[i] === '\n') {
            finalcheck = true;
        }
    }

    finalcheck = finalcheck || false; // finalcheck should never be null or undefined
    var finalist = [finalcheck, greaterResult, lessResult, notMatchedString];

    try {
        return finalist;
        // do something
    } catch (ex) {
        finalcheck = true;
        finalist = { ...finalist, finalcheck: true };
        return finalist;
    }

    // result = result.toString();
    // console.log('result length is ' + result + 'result length is ' + result.length);
    // let numberHai = false;
    // let characterHai = false;
    // let specialHai = false;

    // let greaterResult = false;
    // let lessResult = false;
    // let notMatchedString = false;
    // let norValInRange = false;
    // let norValIsGrtr = false;
    // let norValIsLes = false;
    // for (var i = 0; i < result.length; i++) {
    //     let char = result.charAt(i);
    //     if (!isNaN(char)) {
    //         numberHai = true;
    //         break;
    //     } else if (/^[a-zA-Z]+$/.test(char)) {
    //         characterHai = true;
    //         break;
    //     } else {
    //         specialHai = true;
    //         break;
    //     }
    // }
    // if (numberHai) {
    //     console.log('ye to number hai');
    // } else if (characterHai) {
    //     console.log('ye to character hai');
    // } else if (specialHai) {
    //     console.log('ye to special hai');
    // } else {
    //     console.log('do nothing');
    // }
    // try {
    //     adddlert("Welcome guest!");
    //   }
    //   catch(err) {
    //     // document.getElementById("demo").innerHTML = err.message;
    //   }
};
