import React, { useState, useEffect, useRef } from 'react';
import {
    Input,
} from 'reactstrap';
// api
import api from 'api';

import AddIcon from '@mui/icons-material/Add';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';

import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';

// add new user form
import AddNewUserForm from './AddGenerateResult';

// rct card box
import RctCollapsibleCard from '../../../component/RctCollapsibleCard/RctCollapsibleCard';

export default function GenerateReport(props) {

    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [addNewItemModal, setAddNewItemModal] = useState(false);
    const [editItem, setEditItem] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const deleteConfirmationDialog = useRef(null);
    const [importItemModal, setImportItemModal] = useState(false);
    const [searchText, setSearchText] = useState('');

    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);



    const loadItems = async () => {
        (async () => {
            setLoading(true);
            await api.get('api/master/product?PageNumber=' + (page + 1) + '&PageSize=' + rowsPerPage, {
                params: {
                    searchText: searchText
                }
            })
                .then((response) => {
                    setCount(response.data.totalRecords);
                    setItems(response.data.data);
                })
                .catch(error => {
                    console.log(JSON.stringify(error));
                }).finally(() => {
                    setLoading(false);
                });
        })();
    }

    const onReload = (e) => {
        e.preventDefault();
        setLoading(true);
        loadItems();
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }

    const opnAddNewItemModal = (e) => {
        e.preventDefault();
        setAddNewItemModal(true);
    }

    const opnImportItemModal = (e) => {
        e.preventDefault();
        setImportItemModal(true);
    }

    const onEditItem = (item) => {
        setAddNewItemModal(true);
        setEditItem(items[items.indexOf(item)]);
    }

    const onDelete = (item) => {
        deleteConfirmationDialog.current.open();
        setSelectedItem(items[items.indexOf(item)]);
    }

    return (
        <>
            <RctCollapsibleCard fullBlock>
                        <div className="row py-20 px-15 border-bottom">
                <div className='col-lg-2'>
                    <a href="#" onClick={(e) => opnAddNewItemModal(e)} className="btn btn-primary text-center justify-content-center mb-5 d-flex"><AddIcon /> Add New</a>
                </div>
                <div className='col-lg-6 text-center'>
                     <h1>Generate Report</h1>
                </div>
                <div className='col-lg-4 d-flex'>
                    <Input
                        className='form-control'
                        type="text"
                        placeholder="Enter search text"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                    <button className="btn btn-primary ml-2"><i className="ti-reload"></i></button>
                </div>               
            </div>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>From</TableCell>
                            <TableCell>To</TableCell>
                            <TableCell>On Paper</TableCell>
                            <TableCell>Ledger No</TableCell>
                            <TableCell>To</TableCell>
                            <TableCell>Total No Of Report</TableCell>
                            <TableCell>T</TableCell>
                            <TableCell>St</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>00</TableCell>
                            <TableCell>00</TableCell>
                            <TableCell>Prothombin Time Studies</TableCell>
                            <TableCell>01</TableCell>
                            <TableCell>10</TableCell>
                            <TableCell>11</TableCell>
                            <TableCell>00</TableCell>
                            <TableCell>00</TableCell>
                            

                            <TableCell>
                                <IconButton
                                    color='primary'
                                    aria-label="Edit"
                                    size='small'
                                >
                                    <EditIcon />
                                </IconButton>
                                <IconButton
                                    color='error'
                                    aria-label="Delete"
                                    size='small'
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                count={count}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                                labelRowsPerPage={<span>Rows:</span>}
                                labelDisplayedRows={({ page }) => {
                                    return `Page: ${page + 1}`;
                                }}
                                backIconButtonProps={{
                                    color: "secondary"
                                }}
                                nextIconButtonProps={{ color: "secondary" }}
                                SelectProps={{
                                    inputProps: {
                                        "aria-label": "page number"
                                    }
                                }}
                                showFirstButton={true}
                                showLastButton={true}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </RctCollapsibleCard>
            <AddNewUserForm editItem={editItem} setEditItem={setEditItem} addNewItemModal={addNewItemModal} setAddNewItemModal={setAddNewItemModal} loadItems={loadItems} />
        </>
    );
}