import React, { useState, useEffect, useRef } from 'react';
import { Input } from 'reactstrap';
// api
import api from 'api';

import AddIcon from '@mui/icons-material/Add';
import { DataGrid } from '@mui/x-data-grid';

import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';

// add new user form
import AddNewUserForm from './AddTestGroup';
// import { useQuery } from 'react-query';
import { useQuery, useMutation, queryCache } from 'react-query';

// rct card box
import RctCollapsibleCard from '../../../component/RctCollapsibleCard/RctCollapsibleCard';
import axiosInstance from '../../../api/axiosInstance';

export default function TestGroup(props) {
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [addNewItemModal, setAddNewItemModal] = useState(false);
    const [editItem, setEditItem] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const deleteConfirmationDialog = useRef(null);
    const [importItemModal, setImportItemModal] = useState(false);
    const [searchText, setSearchText] = useState('');

    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [data1, setData1] = useState({ id: '', tstGrpName: '' });
    const fetchTestGroup = async () => {
        const { data } = await axiosInstance.get(`/getTestGroup`);
        setData1(data);
        return data;
    };
    // useEffect(() => {
    //     fetchTestGroup();
    // }, []);
    // const [data1, setData1] = useState({ id: '', tstGrpName: '' });
    const { isLoading, isError, isSuccess, data, error, refetch } = useQuery('testGroup', fetchTestGroup, {
        // enabled: false,
        refetchOnWindowFocus: false
        //  defual: false
    });

    // if (isSuccess) {
    //     // setData1(data);
    //     console.log('success hua');
    // }

    const columns = [
        { field: 'id', headerName: 'ID', width: 150, editable: true },
        { field: 'tstGrpName', headerName: 'Test Group Name', width: 350, editable: true }
    ];
    const rows = data1;
    // let rows = { id: '', tstGrpName: '' };
    // if (isSuccess) {
    //     alert('success hai');
    //     // setData1(data);
    //     rows = data;
    // }

    // console.log('value of data   is ', data);
    // const [mutation] = useMutation(handleOnAddItem1,{})
    const handleOnAddItem = (itemDetails) => {
        axiosInstance
            .post(`/addTstGrp`, itemDetails)
            .then((response) => {
                refetch();
            })
            .catch((error) => {});
    };
    const handleCellEdit = (ankita) => {
        const id = ankita.id;
        const { field, value } = ankita;
        var vals = new Object();
        vals[field] = value;
        axiosInstance.patch(`/updateTestGrp/${id}`, vals);
    };

    const loadItems = async () => {
        (async () => {
            setLoading(true);
            await api
                .get('api/master/product?PageNumber=' + (page + 1) + '&PageSize=' + rowsPerPage, {
                    params: {
                        searchText: searchText
                    }
                })
                .then((response) => {
                    setCount(response.data.totalRecords);
                    setItems(response.data.data);
                })
                .catch((error) => {
                    console.log(JSON.stringify(error));
                })
                .finally(() => {
                    setLoading(false);
                });
        })();
    };

    const onReload = (e) => {
        e.preventDefault();
        setLoading(true);
        loadItems();
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    };

    const opnAddNewItemModal = (e) => {
        e.preventDefault();
        setAddNewItemModal(true);
    };

    const opnImportItemModal = (e) => {
        e.preventDefault();
        setImportItemModal(true);
    };

    const onEditItem = (item) => {
        setAddNewItemModal(true);
        setEditItem(items[items.indexOf(item)]);
    };

    const onDelete = (item) => {
        deleteConfirmationDialog.current.open();
        setSelectedItem(items[items.indexOf(item)]);
    };

    return (
        <>
            <RctCollapsibleCard fullBlock>
                <div className="row py-20 px-15 border-bottom">
                    <div className="col-lg-2">
                        <a
                            href="#"
                            onClick={(e) => opnAddNewItemModal(e)}
                            className="btn btn-primary text-center justify-content-center mb-5 d-flex"
                        >
                            <AddIcon /> Add New
                        </a>
                    </div>
                    <div className="col-lg-6 text-center">
                        <h1>Test Group</h1>
                    </div>
                    <div className="col-lg-4 d-flex">
                        <Input
                            className="form-control"
                            type="text"
                            placeholder="Enter search text"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                        <button className="btn btn-primary ml-2">
                            <i className="ti-reload"></i>
                        </button>
                    </div>
                </div>
                <div style={{ height: 600, width: '100%' }}>
                    <DataGrid rows={rows} columns={columns} onCellEditCommit={handleCellEdit} />
                </div>
            </RctCollapsibleCard>
            <AddNewUserForm
                editItem={editItem}
                setEditItem={setEditItem}
                addNewItemModal={addNewItemModal}
                setAddNewItemModal={setAddNewItemModal}
                loadItems={loadItems}
                handleOnAddItem={handleOnAddItem}
                // mutate={mutate}
            />
        </>
    );
}
