import React, { useState, useEffect, useRef } from 'react';
import { Input } from 'reactstrap';
// api
import api from 'api';

import AddIcon from '@mui/icons-material/Add';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';

import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';

// add new user form
import AddNewUserForm from './AddConsultaltDoctor';

// rct card box
import RctCollapsibleCard from '../../../component/RctCollapsibleCard/RctCollapsibleCard';

export default function AccountConsultantDoctor(props) {
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [addNewItemModal, setAddNewItemModal] = useState(false);
    const [editItem, setEditItem] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const deleteConfirmationDialog = useRef(null);
    const [importItemModal, setImportItemModal] = useState(false);
    const [searchText, setSearchText] = useState('');

    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const loadItems = async () => {
        (async () => {
            setLoading(true);
            await api
                .get('api/master/product?PageNumber=' + (page + 1) + '&PageSize=' + rowsPerPage, {
                    params: {
                        searchText: searchText
                    }
                })
                .then((response) => {
                    setCount(response.data.totalRecords);
                    setItems(response.data.data);
                })
                .catch((error) => {
                    console.log(JSON.stringify(error));
                })
                .finally(() => {
                    setLoading(false);
                });
        })();
    };

    const onReload = (e) => {
        e.preventDefault();
        setLoading(true);
        loadItems();
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    };

    const opnAddNewItemModal = (e) => {
        e.preventDefault();
        setAddNewItemModal(true);
    };

    const opnImportItemModal = (e) => {
        e.preventDefault();
        setImportItemModal(true);
    };

    const onEditItem = (item) => {
        setAddNewItemModal(true);
        setEditItem(items[items.indexOf(item)]);
    };

    const onDelete = (item) => {
        deleteConfirmationDialog.current.open();
        setSelectedItem(items[items.indexOf(item)]);
    };

    return (
        <>
            <RctCollapsibleCard fullBlock>
                <div className="row py-20 px-15 border-bottom">
                    <div className="col-lg-2">
                        <a
                            href="#"
                            onClick={(e) => opnAddNewItemModal(e)}
                            className="btn btn-primary text-center justify-content-center mb-5 d-flex"
                        >
                            <AddIcon /> Add New
                        </a>
                    </div>
                    <div className="col-lg-6 text-center">
                        <h1>Consultant Doctor</h1>
                    </div>
                    <div className="col-lg-4 d-flex">
                        <Input
                            className="form-control"
                            type="text"
                            placeholder="Enter search text"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                        <button className="btn btn-primary ml-2">
                            <i className="ti-reload"></i>
                        </button>
                    </div>
                </div>
                <div className="table-responsive">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>from</TableCell>
                                <TableCell>To</TableCell>
                                <TableCell>Consultant Title</TableCell>
                                <TableCell>Consultant Name</TableCell>
                                <TableCell>Pathology Cuts</TableCell>
                                <TableCell>Total Payment For Pathology</TableCell>
                                <TableCell>Consultant Id</TableCell>
                                <TableCell>Degsignation</TableCell>
                                <TableCell>No Of Test</TableCell>
                                <TableCell>Gender</TableCell>
                                <TableCell>Mobile No</TableCell>
                                <TableCell>emailId</TableCell>
                                <TableCell>Payment</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Paying Amount</TableCell>
                                <TableCell>Dues</TableCell>
                                <TableCell>Total Commission</TableCell>
                                <TableCell>Total Paid</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {/* <TableRow>
                                 <TableCell>000</TableCell>
                            <TableCell>111</TableCell>
                            <TableCell>dr</TableCell>
                            <TableCell>ayushi</TableCell>
                            <TableCell>mba</TableCell>
                            <TableCell>fyujijh</TableCell>
                            <TableCell>556689</TableCell>
                            <TableCell>uygkj</TableCell>
                            <TableCell>hgjk</TableCell>
                            <TableCell>002</TableCell>
                            <TableCell>female</TableCell>
                            <TableCell>7870080516</TableCell>
                            <TableCell>ayushiteju14@gmail.com</TableCell>
                            <TableCell>dues</TableCell>
                            <TableCell>14-08-22</TableCell>
                            <TableCell>5000</TableCell>
                            <TableCell>5000</TableCell>
                            <TableCell>500</TableCell>
                            <TableCell>0000</TableCell>
                           

                                <TableCell>
                                    <IconButton color="primary" aria-label="Edit" size="small">
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton color="error" aria-label="Delete" size="small">
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow> */}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    count={count}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                                    labelRowsPerPage={<span>Rows:</span>}
                                    labelDisplayedRows={({ page }) => {
                                        return `Page: ${page + 1}`;
                                    }}
                                    backIconButtonProps={{
                                        color: 'secondary'
                                    }}
                                    nextIconButtonProps={{ color: 'secondary' }}
                                    SelectProps={{
                                        inputProps: {
                                            'aria-label': 'page number'
                                        }
                                    }}
                                    showFirstButton={true}
                                    showLastButton={true}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </div>
            </RctCollapsibleCard>
            <AddNewUserForm
                editItem={editItem}
                setEditItem={setEditItem}
                addNewItemModal={addNewItemModal}
                setAddNewItemModal={setAddNewItemModal}
                loadItems={loadItems}
            />
        </>
    );
}
