import React, { useState, useEffect } from 'react';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import Button from '@mui/material/Button';
// api
import axios from 'axios';
import baseUrl from '../../../api';

// import RctSectionLoader from '../../../component/RctSectionLoader/RctSectionLoader';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

// Date
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

//multiselect
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

const AddReceiptVoucher = (props) => {
    const [date, setDateOfPayment] = React.useState(null);
    const [from, setFrom] = React.useState(null);
    const [to, setTo] = React.useState(null);

    const [itemDetails, setItemDetails] = useState({
        agentName: '',
        totalPaymentForPathology: '',
        pathologyCuts: '',
        personWhoIsTakingAmount: '',
        address: '',
        mobileNo: '',
        dateOfPayment: '',
        address: ''
    });

    const onChangeItemDetails = (key, value) => {
        var arr = { ...itemDetails };
        arr[key] = value;
        setItemDetails((prevState) => arr);
    };

    const handleOnAddItem = (e) => {
        e.preventDefault();
        // axios.get("ec2-15-206-84-254.ap-south-1.compute.amazonaws.com:3000/api/master/site").then((response) => console.log("response",response));

        // axios
        // .post(`${baseUrl}/api/master/addConsultantDoctorMaster`,
        // )
        // .then((response) => {
        //     // successfully uploaded response
        // })
        // .catch((error) => {
        //     // error response
        // });
    };

    const onAddUpdateItemModalClose = () => {
        props.setAddNewItemModal(false);
        props.setEditItem(null);
    };

    return (
        <>
            <Modal className="modal-lg" isOpen={props.addNewItemModal} toggle={() => onAddUpdateItemModalClose()}>
                <ModalHeader toggle={() => onAddUpdateItemModalClose()}>Add New Item</ModalHeader>
                <ModalBody>
                    {/* {loading &&
                        <RctSectionLoader />
                    } */}
                    <Form className="row">
                        <FormGroup className="col-lg-6 col-6">
                            <LocalizationProvider className="managedate" dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="From"
                                    value={from}
                                    onChange={(newFrom) => {
                                        setFrom(newFrom);
                                    }}
                                    renderInput={(params) => <TextField fullWidth {...params} size="small" id="from" />}
                                />
                            </LocalizationProvider>
                        </FormGroup>
                        <FormGroup className="col-lg-6 col-6">
                            <LocalizationProvider className="managedate" dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="To"
                                    value={to}
                                    onChange={(newTo) => {
                                        setTo(newTo);
                                    }}
                                    renderInput={(params) => <TextField fullWidth {...params} size="small" id="to" />}
                                />
                            </LocalizationProvider>
                        </FormGroup>
                        <div className="col-12">
                            <div className="mngdevider">
                                <h3 className="mngtitle">Agent Payment Voucher</h3>
                                <div className="row">
                                    <FormGroup className="col-lg-4">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Agent Name"
                                            value={itemDetails.agentName}
                                            onChange={(e) => onChangeItemDetails('agentName', e.target.value)}
                                            id="agentName"
                                            size="small"
                                        />
                                    </FormGroup>
                                    <FormGroup className="col-lg-4">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Total PaymentFor Pathology"
                                            value={itemDetails.totalPaymentForPathology}
                                            onChange={(e) => onChangeItemDetails('totalPaymentForPathology', e.target.value)}
                                            id="totalPaymentForPathology"
                                            size="small"
                                        />
                                    </FormGroup>
                                    <FormGroup className="col-lg-4">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Pathology Cuts"
                                            value={itemDetails.pathologyCuts}
                                            onChange={(e) => onChangeItemDetails('pathologyCuts', e.target.value)}
                                            id="pathologyCuts"
                                            size="small"
                                        />
                                    </FormGroup>
                                    <FormGroup className="col-lg-4">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="mobile No"
                                            value={itemDetails.mobileNo}
                                            onChange={(e) => onChangeItemDetails('mobileNo', e.target.value)}
                                            id="mobileNo"
                                            size="small"
                                        />
                                    </FormGroup>
                                    <FormGroup className="col-lg-4">
                                        <LocalizationProvider className="managedate" dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                label="Date Of Payment"
                                                // value={itemDetails.dob}
                                                // onChange={(e) =>
                                                //     onChangeItemDetails("dob", e.target.value)
                                                // }
                                                value={date}
                                                onChange={(newDate) => {
                                                    setDateOfPayment(newDate);
                                                }}
                                                renderInput={(params) => <TextField fullWidth {...params} size="small" id="date" />}
                                            />
                                        </LocalizationProvider>
                                    </FormGroup>
                                    <FormGroup className="col-lg-4">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Total Paid"
                                            value={itemDetails.subTotal}
                                            onChange={(e) => onChangeItemDetails('totalPaid', e.target.value)}
                                            id="totalPaid"
                                            size="small"
                                        />
                                    </FormGroup>
                                    <FormGroup className="col-lg-12">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Address"
                                            multiline
                                            rows={2}
                                            value={itemDetails.address}
                                            onChange={(e) => onChangeItemDetails('address', e.target.value)}
                                            id="address"
                                            size="small"
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button variant="contained" className="text-white btn-success" onClick={handleOnAddItem}>
                        Save
                    </Button>
                    <Button variant="contained" className="text-white btn-danger" onClick={() => onAddUpdateItemModalClose()}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};
export default AddReceiptVoucher;
