import React, { useState, useEffect, useRef } from 'react';
import {
    Input,
} from 'reactstrap';
// api
import api from 'api';

import AddIcon from '@mui/icons-material/Add';


// add new user form
import AddNewUserForm from './AddMedia';

// rct card box
import RctCollapsibleCard from '../../../component/RctCollapsibleCard/RctCollapsibleCard';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 150,editable: true, },
    {
      field: 'testgrpname',
      headerName:  'Test Grp Name',
      width: 150,
      editable: true,
    },
    {
        field: 'testcode',
        headerName:  'TestCode',
        width: 150,
        editable: true,
      },
      {
        field: 'testname',
        headerName:  'TestName',
        width: 150,
        editable: true,
      },
];

const rows = [
        { id: 1, testgrpname: 'Snow',testcode: 'address',testname: 'hgjjk', },
];
export default function Media(props) {

    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [addNewItemModal, setAddNewItemModal] = useState(false);
    const [editItem, setEditItem] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const deleteConfirmationDialog = useRef(null);
    const [importItemModal, setImportItemModal] = useState(false);
    const [searchText, setSearchText] = useState('');

    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);



    const loadItems = async () => {
        (async () => {
            setLoading(true);
            await api.get('api/master/product?PageNumber=' + (page + 1) + '&PageSize=' + rowsPerPage, {
                params: {
                    searchText: searchText
                }
            })
                .then((response) => {
                    setCount(response.data.totalRecords);
                    setItems(response.data.data);
                })
                .catch(error => {
                    console.log(JSON.stringify(error));
                }).finally(() => {
                    setLoading(false);
                });
        })();
    }

    const onReload = (e) => {
        e.preventDefault();
        setLoading(true);
        loadItems();
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }

    const opnAddNewItemModal = (e) => {
        e.preventDefault();
        setAddNewItemModal(true);
    }

    const opnImportItemModal = (e) => {
        e.preventDefault();
        setImportItemModal(true);
    }

    const onEditItem = (item) => {
        setAddNewItemModal(true);
        setEditItem(items[items.indexOf(item)]);
    }

    const onDelete = (item) => {
        deleteConfirmationDialog.current.open();
        setSelectedItem(items[items.indexOf(item)]);
    }

    return (
        <>
            <RctCollapsibleCard fullBlock>
                <div className="row py-20 px-15 border-bottom">
                    <div className='col-lg-2'>
                        <a href="#" onClick={(e) => opnAddNewItemModal(e)} className="btn btn-primary text-center justify-content-center mb-5 d-flex"><AddIcon /> Add New</a>
                    </div>
                    <div className='col-lg-6 text-center'>
                        <h1>Media</h1>
                    </div>
                    <div className='col-lg-4 d-flex'>
                        <Input
                            className='form-control'
                            type="text"
                            placeholder="Enter search text"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                        <button className="btn btn-primary ml-2"><i className="ti-reload"></i></button>
                    </div>               
                </div>
                   <Box sx={{ height: 400, width: '100%' }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        checkboxSelection
                        disableSelectionOnClick
                        experimentalFeatures={{ newEditingApi: true }}
                    />
                    </Box>
                
            </RctCollapsibleCard>
            <AddNewUserForm editItem={editItem} setEditItem={setEditItem} addNewItemModal={addNewItemModal} setAddNewItemModal={setAddNewItemModal} loadItems={loadItems} />
        </>
    );
}   