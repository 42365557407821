import React, { createContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from './api/axiosInstance';
import { NotificationManager } from 'react-notifications';
const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(null);
    const navigate = useNavigate();

    const handleLogin = async (formValue) => {
        // let expiresAt = JSON.stringify(1000 * 1000 + new Date().getTime());
        // localStorage.setItem('access_token', 'response.data.token');
        // localStorage.setItem('expires_at', 'expiresAt');
        // localStorage.setItem('user_id', 'response.data.username');
        // localStorage.setItem('tenantID', 's');
        // window.location.href = '/';
        // NotificationManager.success('User Login Successfully!');
        await axiosInstance
            .post('/login', formValue)
            .then((response) => {
                if (response.data.status == 'success') {
                    localStorage.setItem('token', response.data.token);
                    let expiresAt = JSON.stringify(1000 * 1000 + new Date().getTime());

                    // localStorage.setItem('user_id',

                    //  formValue.data.userId);
                    // localStorage.setItem('tenantID', formValue.tenantID);
                    window.location.href = '/';
                    NotificationManager.success('User Login Successfully!');
                } else {
                    console.log(response.data);
                }
                // let expiresAt = JSON.stringify(response.data.expiration * 1000 + new Date().getTime());
            })
            .catch((error) => {
                // NotificationManager.error(JSON.stringify(error.response.data));
            });
    };

    const handleLogout = () => {
        setToken(null);
        localStorage.removeItem('token');
        localStorage.removeItem('expires_at');
        localStorage.removeItem('user_id');
        localStorage.removeItem('tenantID');
        navigate('/login');
    };

    const value = {
        token,
        onLogin: handleLogin,
        onLogout: handleLogout
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return React.useContext(AuthContext);
};
