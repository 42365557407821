import React, { useState } from 'react';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import Button from '@mui/material/Button';
// api
import axiosInstance from '../../../api/axiosInstance';

// import RctSectionLoader from '../../../component/RctSectionLoader/RctSectionLoader';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

// Date
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

//multiselect
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';

const AddConsultantDoctor = (props) => {
    const [dob, setDob] = React.useState(null);
    const [marrigeDay, setMarrigeDay] = React.useState(null);

    const [itemDetails, setItemDetails] = useState({
        consultantId: '',
        cName: '',
        sex: '',
        cMobile1: '',
        address: '',
        cTitle: '',
        designation: '',
        faculty: '',
        dob: '',
        marriageDay: '',
        noOfChildren: '',
        email: '',
        cuts: '',
        hospital: '',
        ultraCuts: '',
        xrayCuts: '',
        ctscanCuts: '',
        mriCuts: ''
    });

    const onChangeItemDetails = (key, value) => {
        var arr = { ...itemDetails };
        arr[key] = value;
        setItemDetails((prevState) => arr);
    };

    const handleOnAddItem = (e) => {
        e.preventDefault();
        // axios.get("ec2-15-206-84-254.ap-south-1.compute.amazonaws.com:3000/api/master/site").then((response) => console.log("response",response));

        // console.log("your form value is ", itemDetails)
        axiosInstance.post(`/addConsultant`, itemDetails).then((response) => {
            alert('Saved');
            // setItemDetails({ ...itemDetails, ledgerNo: response.data.ledgerNo, labNo: 'Lab-' + zeroPad(response.data.ledgerNo, 4) });
        });
        //  console.log(`${baseUrl}/api/master/addConsultantDoctor`)
        //  axios
        //  .post(`${baseUrl}/addConsultantDoctor`,itemDetails
        //  )
        //  .then((response) => {

        //  })
        // .catch((error) => {
        //     // error response
        // });
        // alert(`${baseUrl}/api/master/AddConsultantDoctor`);
    };

    const onAddUpdateItemModalClose = () => {
        props.setAddNewItemModal(false);
        props.setEditItem(null);
    };

    return (
        <>
            <Modal className="modal-lg" isOpen={props.addNewItemModal} toggle={() => onAddUpdateItemModalClose()}>
                <ModalHeader toggle={() => onAddUpdateItemModalClose()}>Add New Item</ModalHeader>
                <ModalBody>
                    {/* {loading &&
                        <RctSectionLoader />
                    } */}
                    <Form className="row">
                        <FormGroup className="col-lg-2">
                            <TextField
                                fullWidth
                                type="text"
                                label="Consultant Id"
                                value={itemDetails.consultantId}
                                onChange={(e) => onChangeItemDetails('consultantId', e.target.value)}
                                id="consultantId"
                                size="small"
                            />
                        </FormGroup>
                        <FormGroup className="col-lg-4">
                            <TextField
                                fullWidth
                                type="text"
                                label="Consultant Name"
                                value={itemDetails.cName}
                                onChange={(e) => onChangeItemDetails('cName', e.target.value)}
                                id="cName"
                                size="small"
                            />
                        </FormGroup>
                        <FormGroup className="col-lg-2">
                            <FormControl sx={{ minWidth: '100%' }} size="small">
                                <InputLabel id="gender">Sex</InputLabel>
                                <Select
                                    labelId="sex"
                                    id="sex"
                                    value={itemDetails.sex}
                                    label="sex"
                                    onChange={(e) => onChangeItemDetails('sex', e.target.value)}
                                >
                                    <MenuItem value={'Male'}>Male</MenuItem>
                                    <MenuItem value={'Female'}>Female</MenuItem>
                                    {/* <MenuItem value={"Trance Gender"}>Transgender</MenuItem>                                     */}
                                    <MenuItem value={'Other'}>Other</MenuItem>
                                </Select>
                            </FormControl>
                        </FormGroup>
                        <FormGroup className="col-lg-4">
                            <TextField
                                fullWidth
                                type="text"
                                label="Mobile No"
                                value={itemDetails.cMobile1}
                                onChange={(e) => onChangeItemDetails('cMobile1', e.target.value)}
                                id="cMobile1"
                                size="small"
                            />
                        </FormGroup>
                        <FormGroup className="col-lg-2">
                            <TextField
                                fullWidth
                                type="text"
                                label="Title"
                                value={itemDetails.cTitle}
                                onChange={(e) => onChangeItemDetails('cTitle', e.target.value)}
                                id="cTitle"
                                size="small"
                            />
                        </FormGroup>
                        <FormGroup className="col-lg-2">
                            <TextField
                                fullWidth
                                type="text"
                                label="designation"
                                value={itemDetails.designation}
                                onChange={(e) => onChangeItemDetails('designation', e.target.value)}
                                id="designation"
                                size="small"
                            />
                        </FormGroup>
                        <FormGroup className="col-lg-4">
                            <TextField
                                fullWidth
                                type="text"
                                label="Faculty"
                                value={itemDetails.faculty}
                                onChange={(e) => onChangeItemDetails('faculty', e.target.value)}
                                id="faculty"
                                size="small"
                            />
                        </FormGroup>
                        <FormGroup className="col-lg-4">
                            <TextField
                                fullWidth
                                type="text"
                                label="Hospital"
                                value={itemDetails.hospital}
                                onChange={(e) => onChangeItemDetails('hospital', e.target.value)}
                                id="hospital"
                                size="small"
                            />
                        </FormGroup>
                        <FormGroup className="col-lg-4">
                            <TextField
                                fullWidth
                                type="text"
                                label="Pathology Cuts"
                                value={itemDetails.cuts}
                                onChange={(e) => onChangeItemDetails('cuts', e.target.value)}
                                id="pathologyCuts"
                                size="small"
                            />
                        </FormGroup>
                        <FormGroup className="col-lg-4">
                            <TextField
                                fullWidth
                                type="text"
                                label="Ultra Sound Cuts"
                                value={itemDetails.ultraCuts}
                                onChange={(e) => onChangeItemDetails('ultraCuts', e.target.value)}
                                id="ultraSoundCuts"
                                size="small"
                            />
                        </FormGroup>
                        <FormGroup className="col-lg-4">
                            <TextField
                                fullWidth
                                type="text"
                                label="M.R.I Cuts"
                                value={itemDetails.mriCuts}
                                onChange={(e) => onChangeItemDetails('mriCuts', e.target.value)}
                                id="mriCuts"
                                size="small"
                            />
                        </FormGroup>
                        <FormGroup className="col-lg-4">
                            <TextField
                                fullWidth
                                type="text"
                                label="Ct Scan"
                                value={itemDetails.ctscanCuts}
                                onChange={(e) => onChangeItemDetails('ctscanCuts', e.target.value)}
                                id="ctScan"
                                size="small"
                            />
                        </FormGroup>
                        <FormGroup className="col-lg-4">
                            <TextField
                                fullWidth
                                type="text"
                                label="Xray Cuts"
                                value={itemDetails.xrayCuts}
                                onChange={(e) => onChangeItemDetails('xrayCuts', e.target.value)}
                                id="xrayCuts"
                                size="small"
                            />
                        </FormGroup>
                        <FormGroup className="col-lg-4">
                            <LocalizationProvider className="managedate" dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="DOB"
                                    // value={itemDetails.dob}
                                    // onChange={(e) =>
                                    //     onChangeItemDetails("dob", e.target.value)
                                    // }
                                    value={dob}
                                    onChange={(newDob) => {
                                        setDob(newDob);
                                    }}
                                    renderInput={(params) => <TextField fullWidth {...params} size="small" id="dob" />}
                                />
                            </LocalizationProvider>
                        </FormGroup>
                        <FormGroup className="col-lg-4">
                            <TextField
                                fullWidth
                                type="text"
                                label="Email Id"
                                value={itemDetails.email}
                                onChange={(e) => onChangeItemDetails('email', e.target.value)}
                                id="emailId"
                                size="small"
                            />
                        </FormGroup>
                        <FormGroup className="col-lg-4 mt-2">
                            <div className="row">
                                <FormGroup check className="col-lg-6">
                                    <Label check className="ml-10">
                                        <Input
                                            type="radio"
                                            name="test"
                                            value={itemDetails.married}
                                            onChange={(e) => onChangeItemDetails('married', e.target.value)}
                                            id="married"
                                        />{' '}
                                        Married
                                    </Label>
                                </FormGroup>
                                <FormGroup check className="col-lg-6">
                                    <Label check className="ml-10">
                                        <Input
                                            type="radio"
                                            name="test"
                                            value={itemDetails.unMarried}
                                            onChange={(e) => onChangeItemDetails('unMarried', e.target.value)}
                                            id="unMarried"
                                        />{' '}
                                        Unmarried
                                    </Label>
                                </FormGroup>
                            </div>
                        </FormGroup>
                        <FormGroup className="col-lg-4">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Marrige Day"
                                    // value={itemDetails.marrigeDay}
                                    // onChange={(e) =>
                                    //     onChangeItemDetails("marrigeDay", e.target.value)
                                    // }
                                    value={marrigeDay}
                                    onChange={(newMarrigeDay) => {
                                        setMarrigeDay(newMarrigeDay);
                                    }}
                                    renderInput={(params) => <TextField fullWidth {...params} size="small" id="marrigeDay" />}
                                />
                            </LocalizationProvider>
                        </FormGroup>
                        <FormGroup className="col-lg-12">
                            <TextField
                                fullWidth
                                type="text"
                                label="Address"
                                multiline
                                rows={2}
                                value={itemDetails.address}
                                onChange={(e) => onChangeItemDetails('address', e.target.value)}
                                id="address"
                                size="small"
                            />
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button variant="contained" className="text-white btn-success" onClick={handleOnAddItem}>
                        Save
                    </Button>
                    <Button variant="contained" className="text-white btn-danger" onClick={() => onAddUpdateItemModalClose()}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};
export default AddConsultantDoctor;
